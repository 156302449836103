export const getWindSpeedStyle = (speed: number, theme: any) => {
  const converted = Math.round(speed / 1.609)

  if (converted >= 15 && converted < 30) {
    return { color: theme.palette.warning.light }
  } else if (converted >= 30) {
    return { color: theme.palette.error.light }
  }

  return {}
}
