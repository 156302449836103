import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import BroadcastOnPersonalIcon from "@mui/icons-material/BroadcastOnPersonal"
import { Link } from "react-router-dom"
import { getStaticTileUrl } from "../api/utilities"

import { AvalancheDanger } from "./AvalancheDanger"
import { MiniDailySnowBarChart } from "./MiniDailySnowBarChart"
import { PointSummary } from "../models/models"
import { graphqlClient } from "../graphql"
import { updateFavoriteMutation } from "../graphql/mutations"
import { ActivityContext, UserPremiumContext } from "../pages/Header"
import { SubscribeModal } from "./SubscribeModal"
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Legend,
} from "recharts"
import dayjs from "dayjs"
import { MiniDailyWeatherIcons } from "./MiniDailyWeatherIcons"

export interface SkiCardProps {
  pointData: PointSummary
  onClick?: (point: PointSummary) => any
  setIsFavorite?: (pointId: string, isFavorite: boolean) => any
  favoritesCount: number
  link?: boolean
}

export function SkiCard({
  pointData,
  onClick,
  setIsFavorite,
  link,
  favoritesCount,
}: SkiCardProps) {
  const selectedActivity = useContext(ActivityContext)
  const [isFavorite, setFavorite] = useState(false)
  const [showSubscribePopup, setShowSubscribePopup] = useState(false)
  const userIsPremium = useContext(UserPremiumContext)
  const theme = useTheme()

  useEffect(() => {
    if (pointData && pointData.isFavorite) {
      setFavorite(pointData.isFavorite)
    }
  }, [pointData])

  const onFavorite = (event: any, isFavorite: boolean) => {
    event.stopPropagation()
    if (setIsFavorite) {
      if (favoritesCount >= 3 && !userIsPremium && isFavorite) {
        setShowSubscribePopup(true)
      } else {
        setFavorite(isFavorite)
        setIsFavorite(pointData.id, isFavorite)
        graphqlClient
          .mutation(updateFavoriteMutation, {
            pointId: pointData.id,
            isFavorite: isFavorite,
            type: selectedActivity,
          })
          .toPromise()
      }
    }
  }

  // TODO: clear stale corn days in db entry
  const hasCornDay = pointData.potentialCornDays
    ? pointData.potentialCornDays.find((pc) => !!pc)
    : false

  const getGraphDate = (data: any) => {
    if (data.forecast) {
      return dayjs(data.forecast.date).format("MM/DD")
    } else if (data.observation) {
      return dayjs(data.observation.date).format("dd")
    }

    return ""
  }

  return (
    <>
      <SubscribeModal
        message={
          "You've reached the maximum number of favorites as a free user. Consider subscribing to get unlimited favorites and more."
        }
        open={showSubscribePopup}
        onClose={() => setShowSubscribePopup(false)}
      />
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          minWidth: "255px",
        }}
        onClick={() => (onClick ? onClick(pointData) : null)}
      >
        <Box
          sx={{
            height: "100px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            height="135"
            image={getStaticTileUrl({
              ...pointData,
              width: 300,
              height: 200,
              zoom: 11,
            })}
            alt="Paella dish"
          />
        </Box>
        <CardContent sx={{ flexGrow: 1, mt: 1 }}>
          <Grid container alignItems="center">
            <Grid item xs={10} display="flex" alignItems="center">
              <Typography noWrap variant="h5" component="h2">
                {link ? (
                  <Link to={`/point/${pointData.id}`}>{pointData.name}</Link>
                ) : (
                  pointData.name
                )}
              </Typography>
              {hasCornDay ? (
                <img
                  src="/corn.png"
                  style={{ width: "auto", height: "25px" }}
                  alt=""
                />
              ) : null}
            </Grid>

            <Grid
              item
              xs={2}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              {isFavorite ? (
                <Tooltip title="Save to my spots">
                  <IconButton
                    style={{ padding: 0, marginBottom: 1 }}
                    onClick={(event) => onFavorite(event, false)}
                  >
                    <StarIcon sx={{ color: "gold" }} />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Save to my spots">
                  <IconButton
                    style={{ padding: 0, marginBottom: 1 }}
                    onClick={(event) => onFavorite(event, true)}
                  >
                    <StarBorderIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          {pointData.processing ? (
            <CircularProgress />
          ) : (
            <Box>
              <Box display="flex" justifyContent={"center"}>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                  Snow Forecast
                </Typography>
              </Box>
              <Box sx={{ ml: "-55px" }}>
                <MiniDailySnowBarChart pointData={pointData} />
              </Box>

              <Box>
                <Box display="flex" justifyContent={"center"}>
                  <Box>
                    <Typography variant="subtitle1" fontWeight={"bold"}>
                      Freezing Level
                    </Typography>
                  </Box>
                </Box>
                <ResponsiveContainer width="100%" height={120}>
                  <LineChart data={pointData.hourlyForecast || []}>
                    <Line
                      type="monotone"
                      unit="ft"
                      name="Fcst"
                      dot={false}
                      stroke={theme.palette.primary.main}
                      dataKey={(data) => Math.round(data.snowLevel)}
                    />

                    <Line
                      type="monotone"
                      unit="ft"
                      name={`${pointData.name}`}
                      dot={false}
                      dataKey={(data) =>
                        pointData ? pointData.noaaElevation : 0
                      }
                      stroke="black"
                      strokeDasharray={"5 5"}
                    />
                    <CartesianGrid stroke="#ccc" />
                    <XAxis
                      interval={24}
                      dataKey={(data) => {
                        return dayjs
                          .unix(Number(data.date) / 1000)
                          .format("dd")
                          .slice(0, 1)
                      }}
                    />
                    <Legend align="center" iconType="plainline" />
                  </LineChart>
                </ResponsiveContainer>
              </Box>

              {/* <Box>
                <Box
                  height="120px"
                  display="flex"
                  flexDirection={"column"}
                  justifyContent="flex-end"
                >
                  <MiniDailyWeatherIcons point={pointData} />
                </Box>
              </Box> */}
            </Box>
          )}
          {/* {pointData?.avalanche?.danger ? (
            <Box sx={{ ml: "-40px" }}>
              <AvalancheDanger
                danger={pointData.avalanche.danger}
                url={pointData.avalanche.url}
                expires={pointData.avalanche.expires}
              />
            </Box>
          ) : null} */}
        </CardContent>
      </Card>
    </>
  )
}
