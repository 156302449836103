import { Box, Typography, useTheme } from "@mui/material"
import React from "react"
import WaterDropIcon from "@mui/icons-material/WaterDrop"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { RainIcon } from "../../icons/RainIcon"
import { SunIcon } from "../../icons/SunIcon"
import ThermostatIcon from "@mui/icons-material/Thermostat"
import CloudQueueIcon from "@mui/icons-material/CloudQueue"

export function HelpSnowIcons() {
  const theme = useTheme()
  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography variant="h5">Ski/Ride Icon Guide</Typography>
        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="30px">
            <img
              src="/corn.png"
              style={{ width: "auto", height: "25px" }}
              alt=""
            />
          </Box>
          <Typography ml={1}>
            Keep an eye out for the corn icon! It tells you when an upcoming
            melt freeze cycle is forecasted so you can hit that perfect window.
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="30px">
            <SunIcon height="30px" width="30px" fill="orange" />
          </Box>
          <Typography ml={1}>
            It's going to be a sunny clear day. Great day to go get some views
            and a tan.
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="30px">
            <CloudQueueIcon fontSize="small" />
          </Box>
          <Typography ml={1}>
            Cloudy day, no reason not to go outside.
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="30px">
            <RainIcon
              fill={theme.palette.primary.main}
              height="25px"
              width="25px"
            />
          </Box>

          <Typography ml={1}>
            It's gonna rain. No need to let it stop you, but less than ideal.
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="40px" display="flex" ml="-10px">
            <ThermostatIcon />
            <Box display="flex" flexDirection={"column"}>
              <Typography variant="caption">70&#176;</Typography>
              <Typography variant="caption">50&#176;</Typography>
            </Box>
          </Box>

          <Typography ml={1}>
            The high and low temperature from sun up to sun down. Do I need the
            puffy or not?
          </Typography>
        </Box>
      </Box>
    </>
  )
}
