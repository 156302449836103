import { Alert, Box, useMediaQuery } from "@mui/material"
import React, { useContext, useEffect, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { UserPremiumContext } from "../pages/Header"

export function AdBanner() {
  const adRef = useRef<any>(null)
  const isSmall = useMediaQuery("(max-width:600px)")
  const navigate = useNavigate()
  const userIsPremium = useContext(UserPremiumContext)

  useEffect(() => {
    try {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    } catch (e) {
      console.error(e)
    }
  }, [])

  if (userIsPremium) {
    return null
  }

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        'ins.adsbygoogle[data-ad-status="unfilled"]': {
          display: "none !important",
        },
      }}
    >
      <ins
        ref={adRef}
        className="adsbygoogle"
        style={
          isSmall
            ? {
                display: "block",
                width: "300px",
                maxHeight: "100px",
                margin: "auto",
              }
            : {
                display: "block",
                width: "970px",
                maxHeight: "90px",
                margin: "auto",
              }
        }
        data-ad-client="ca-pub-6288231818875389"
        data-ad-slot="5349994013"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      {/* {adRef?.current?.dataset?.adStatus &&
        adRef?.current?.dataset?.adStatus !== "unfilled" && (
          <Box onClick={() => navigate("/subscribe")}>
            <Alert variant="filled" severity="info">
              <Link to={"/subscribe"}>Click here</Link> to go ad free and
              support Snarf.
            </Alert>
          </Box>
        )} */}
    </Box>
  )
}
