import { Box } from "@mui/system"
import dayjs, { Dayjs } from "dayjs"
import React from "react"
import { RockStatus, UserPointOfInterestType } from "../api/models"
import { SunIcon } from "../icons/SunIcon"
import { PointSummary, RockClimbingSummary } from "../models/models"
import CloudQueueIcon from "@mui/icons-material/CloudQueue"
import { RainIcon } from "../icons/RainIcon"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Typography, useTheme } from "@mui/material"
import ThermostatIcon from "@mui/icons-material/Thermostat"
import { cToF, mmToIn } from "../utilities/conversion"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

export interface RockDaySnapshotProps {
  rockSummary?: RockClimbingSummary
}

export function RockDaySnapshot({ rockSummary }: RockDaySnapshotProps) {
  const theme = useTheme()
  const getWeatherIcon = (summary?: RockClimbingSummary) => {
    if (!summary) {
      return null
    }
    if (
      summary.avgCloudCover < 30 &&
      summary.precip <= 0 &&
      summary.maxProbabilityOfPrecip < 10
    ) {
      return <SunIcon height="30px" width="30px" fill="orange" />
    } else if (
      summary.avgCloudCover > 20 &&
      summary.precip <= 0 &&
      summary.maxProbabilityOfPrecip < 10
    ) {
      return <CloudQueueIcon fontSize="small" />
    } else if (summary.precip > 0 || summary.maxProbabilityOfPrecip >= 10) {
      return (
        <Box display="flex" alignItems="center">
          <RainIcon
            fill={theme.palette.primary.main}
            height="25px"
            width="25px"
          />
          <Box
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
            pl="2px"
          >
            <Typography variant="caption">
              {rockSummary?.maxProbabilityOfPrecip || "na"}%
            </Typography>
            <Typography variant="caption">
              {mmToIn(rockSummary?.precip || 0, 100)}
            </Typography>
          </Box>
        </Box>
      )
    }
  }

  const isInIcon = (status: RockStatus) => {
    switch (status) {
      case RockStatus.YES:
        return <CheckCircleIcon htmlColor="green" />
      case RockStatus.MAYBE:
        return <CheckCircleOutlineIcon color="warning" />
      case RockStatus.NO:
        return null
    }
  }

  const getStyle = (status: RockStatus) => {
    switch (status) {
      case RockStatus.YES:
        return {
          border: "2px solid green",
          borderRadius: "10px",
          width: "100%",
        }
      case RockStatus.MAYBE:
        return { border: "2px solid #ed6c02", borderRadius: "10px" }
      case RockStatus.NO:
        return {}
    }
  }

  const getStatus = () => {
    if (rockSummary && rockSummary.rating) {
      return rockSummary.rating
    }

    return RockStatus.NO
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      width="100%"
      sx={getStyle(getStatus())}
    >
      <Box>{isInIcon(getStatus())}</Box>
      <Box display="flex" alignItems="center">
        {getWeatherIcon(rockSummary)}
        <Box display="flex" alignItems="center">
          <ThermostatIcon />
          <Box display="flex" flexDirection={"column"}>
            <Typography variant="caption">
              {cToF(rockSummary?.maxTemp || 0)}&#176;
            </Typography>
            <Typography variant="caption">
              {cToF(rockSummary?.minTemp || 0)}&#176;
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
