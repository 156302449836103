import * as React from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { Container, Grid, Paper, Switch, Link as MUILink } from "@mui/material"
import { RegisterPage } from "./RegisterPage"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import { useQuery } from "@tanstack/react-query"
import { getUser, setNotifications } from "../api/snarf-api"
import { User } from "../models/models"
import { Link, useNavigate } from "react-router-dom"
import { sendEvent, SnarfEvents } from "../utilities/metrics"

export function GettingStarted() {
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const [user] = useAuthState(auth)

  const navigate = useNavigate()
  const [snarfUser, setSnarfUser] = React.useState<User | null>(null)

  localStorage.setItem("snarf-tour", "true")

  const userRequest = useQuery({
    queryKey: ["user", user?.email],
    queryFn: (): any => getUser(),
    enabled: !!user,
  })

  if (!userRequest.isLoading && !snarfUser) {
    if (!userRequest.data.processing) {
      setSnarfUser({ ...userRequest.data })
    }
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    sendEvent(SnarfEvents.GuidedStartNext)

    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    if (activeStep > 5) {
      sendEvent(SnarfEvents.GuidedStartFinished)
      navigate("/intro?stay=true")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const getStepImage = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            py={1}
            sx={{
              background: "url(/sunset-background-small.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "45%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              height={"100%"}
              style={{ borderRadius: "8px" }}
              src="/ski-weather-splash.png"
              alt="ski splash"
            />
          </Box>
        )
      case 1:
        return (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            py={1}
            sx={{
              background: "url(/sunset-background.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "45%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              height={"100%"}
              style={{ borderRadius: "8px" }}
              src="/snow-scanner-demo.png"
              alt="ski splash"
            />
          </Box>
        )
      case 2:
        return (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            py={1}
            sx={{
              background: "url(/sunset-background.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "45%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              height={"100%"}
              style={{ borderRadius: "8px" }}
              src="/camera-demo.png"
              alt="ski splash"
            />
          </Box>
        )
      case 3:
        return (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            py={1}
            sx={{
              background: "url(/sunset-background.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "45%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              height={"100%"}
              style={{ borderRadius: "8px" }}
              src="/map-demo.png"
              alt="ski splash"
            />
          </Box>
        )
      case 4:
        return (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            py={1}
            sx={{
              background: "url(/sunset-background.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "45%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              height={"100%"}
              style={{ borderRadius: "8px" }}
              src="/stations-demo.png"
              alt="ski splash"
            />
          </Box>
        )

      case 5:
        return (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            py={1}
            sx={{
              background: "url(/sunset-background.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "45%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              height={"100%"}
              style={{ borderRadius: "8px" }}
              src="/favorites.png"
              alt="ski splash"
            />
          </Box>
        )
      case 6:
        return (
          <Box
            width="100%"
            height="100%"
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            py={1}
            sx={{
              background: "url(/sunset-background.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "45%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              display="flex"
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box py={2} width={200}>
                <Button
                  onClick={() => navigate("/subscribe")}
                  variant="contained"
                  sx={{ py: 1, width: "100%" }}
                >
                  Sign up
                </Button>
              </Box>

              <Box py={2} width={200}>
                <Button
                  onClick={() => navigate("/snow-scanner")}
                  variant="contained"
                  sx={{ py: 1, width: "100%" }}
                >
                  Snow Scanner
                </Button>
              </Box>
            </Box>
          </Box>
        )
      default:
        break
    }
  }

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <>
            <Typography variant="h6" fontWeight={"bold"}>
              Powder centric forecasts
            </Typography>
            <Typography variant="body1">
              Made by snowboarders, for snowboarders, and I guess skiers too.
              Easy to read visuals give you everything you need to know to find
              the best snow.
            </Typography>
          </>
        )

      case 1:
        return (
          <>
            <Typography variant="h6" fontWeight={"bold"}>
              Snow Scanner
            </Typography>
            <Typography variant="body1">
              Easily find the top forecasts across the US or by your State.
            </Typography>
          </>
        )

      case 2:
        return (
          <>
            <Typography variant="h6" fontWeight={"bold"}>
              Live Webcams
            </Typography>
            <Typography variant="body1">
              Seeing is believing, explore webcams from resorts, highway cameras
              and more.
            </Typography>
          </>
        )
      case 3:
        return (
          <>
            <Typography variant="h6" fontWeight={"bold"}>
              Maps
            </Typography>
            <Typography variant="body1">
              View forecasts for thousands of skiing and climbing locations.
              Check weather station measurements and view live cams.
            </Typography>
          </>
        )
      case 4:
        return (
          <>
            <Typography variant="h6" fontWeight={"bold"}>
              Realtime Snowpack measurements
            </Typography>
            <Typography variant="body1">
              Live measurements from Weather Stations throughout the mountains.
              Machines can't lie about how much snow there is or isn't!
            </Typography>
          </>
        )
      case 5:
        return (
          <>
            <Typography variant="h6" fontWeight={"bold"}>
              Favorites Lists
            </Typography>
            <Typography variant="body1">
              Compare your favorite locations to make planning and tracking
              storms simple.
            </Typography>
          </>
        )

      case 6:
        return (
          <>
            <Typography variant="h6" fontWeight={"bold"}>
              Happy Trails
            </Typography>
            <Typography variant="body1">
              Sign up to get all Snarf has to offer, explore top forecasts with
              the Snow Scanner, or search for your favorite ski area.
            </Typography>
          </>
        )

      default:
        return null
    }
  }

  return (
    <Box sx={{ width: "100%" }} display="flex" flexDirection={"column"}>
      <Box height="calc(75vh - 120px)">{getStepImage()}</Box>

      <Box
        height="25vh"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={"column"}
          p={1}
        >
          {getStepContent()}
        </Box>

        <Box p={1} display="flex" justifyContent="space-between">
          <Button
            onClick={() => {
              sendEvent(SnarfEvents.GuidedStartClose)
              navigate("/intro?stay=true")
            }}
          >
            <Typography>Skip</Typography>
          </Button>
          <Button onClick={handleNext} variant="contained">
            <Typography>{activeStep === 6 ? "Finish" : "Next"}</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
