import { Box, IconButton, Paper, Typography, useTheme } from "@mui/material"
import React, { useContext, useState } from "react"
import { ActivityContext } from "../pages/Header"
import CloseIcon from "@mui/icons-material/Close"
import ThermostatIcon from "@mui/icons-material/Thermostat"
import CloudQueueIcon from "@mui/icons-material/CloudQueue"
import { SunIcon } from "../icons/SunIcon"
import { RainIcon } from "../icons/RainIcon"
import { UserPointOfInterestType } from "../api/models"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"

export function FavoritesHelpBanner() {
  const selectedActivity = useContext(ActivityContext)
  const key = `snarf-favorites-banner-${selectedActivity}`
  const [showBanner, setShowBanner] = useState(!localStorage.getItem(key))
  const theme = useTheme()

  switch (selectedActivity) {
    case UserPointOfInterestType.SKI:
      if (localStorage.getItem(key)) {
        return null
      }

      return null

    case UserPointOfInterestType.ROCK_CLIMB:
      if (localStorage.getItem(key) || true) {
        return null
      }

      return (
        <Paper sx={{ p: 2, my: 2 }}>
          <Box
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems={"center"}>
              <Typography variant="h6" ml={1}>
                How do I use this page?
              </Typography>
            </Box>
            <IconButton
              onClick={() => {
                setShowBanner(!showBanner)
                localStorage.setItem(key, "true")
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box display="flex" alignItems={"center"} pb={1}>
            <Typography ml={1}>
              Not sure what to do this week? Start here.
            </Typography>
          </Box>

          <Box display="flex" alignItems={"center"} pb={1}>
            <Box width="30px">
              <CheckCircleIcon htmlColor="green" />
            </Box>
            <Typography ml={1}>
              Go climb! No rain in the last 24 hours, something will be dry!
            </Typography>
          </Box>

          <Box display="flex" alignItems={"center"} pb={1}>
            <Box width="30px">
              <CheckCircleOutlineIcon color="warning" />
            </Box>
            <Typography ml={1}>
              It rained yesterday, but will be dry today. There's a chance you
              can find something dry. Better than sitting at home!
            </Typography>
          </Box>

          <Box display="flex" alignItems={"center"} pb={1}>
            <Box width="30px">
              <SunIcon height="30px" width="30px" fill="orange" />
            </Box>
            <Typography ml={1}>
              It's going to be a sunny clear day. Great day to go get some views
              and a tan.
            </Typography>
          </Box>

          <Box display="flex" alignItems={"center"} pb={1}>
            <Box width="30px">
              <CloudQueueIcon fontSize="small" />
            </Box>
            <Typography ml={1}>
              Cloudy day, no reason not to go outside.
            </Typography>
          </Box>

          <Box display="flex" alignItems={"center"} pb={1}>
            <Box width="30px">
              <RainIcon
                fill={theme.palette.primary.main}
                height="25px"
                width="25px"
              />
            </Box>

            <Typography ml={1}>
              It's gonna rain. While wet slab is a delicacy, might be better to
              look elsewhere.
            </Typography>
          </Box>

          <Box display="flex" alignItems={"center"} pb={1}>
            <Box width="40px" display="flex" ml="-10px">
              <ThermostatIcon />
              <Box display="flex" flexDirection={"column"}>
                <Typography variant="caption">70&#176;</Typography>
                <Typography variant="caption">50&#176;</Typography>
              </Box>
            </Box>

            <Typography ml={1}>
              The high and low temperature from sun up to sun down. Do I need
              the puffy or not?
            </Typography>
          </Box>
        </Paper>
      )

    default:
      return null
  }
}
