import { Box, Paper, Typography, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"

export interface TabConfig {
  id: string | number
  label: string
  icon?: (
    color:
      | "inherit"
      | "disabled"
      | "action"
      | "primary"
      | "secondary"
      | "error"
      | "info"
      | "success"
      | "warning"
      | undefined
  ) => React.ReactElement
}
export interface TabBarProps {
  tabs: TabConfig[]
  onTabSelect: (id: string | number) => any
  selectedTab: string | number
}

export function TabBar({ tabs, onTabSelect, selectedTab }: TabBarProps) {
  const [internalSelectedTab, setSelectedTab] = useState<string | number>(
    selectedTab
  )
  const theme = useTheme()

  useEffect(() => {
    if (selectedTab !== internalSelectedTab) {
      setSelectedTab(selectedTab)
    }
  }, [selectedTab, internalSelectedTab])

  const tabSelect = (id: string | number) => {
    setSelectedTab(id)
    onTabSelect(id)
  }

  return (
    <Paper sx={{ borderRadius: 0 }}>
      <Box
        py={1}
        px={2}
        display="flex"
        justifyContent={tabs.length > 2 ? "space-between" : "space-around"}
      >
        {tabs.map((tab) => (
          <Box
            key={tab.id}
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={() => tabSelect(tab.id)}
          >
            {tab.icon
              ? tab.icon(
                  internalSelectedTab === tab.id ? "primary" : "disabled"
                )
              : null}
            <Typography
              color={internalSelectedTab === tab.id ? "primary" : "grey"}
            >
              {tab.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </Paper>
  )

  //   return (
  //     <View>
  //       <ScrollView horizontal width="100%">
  //         <HStack justifyContent="space-between" background="white" width="100%">
  //           {tabs.map(tab => (
  //             <Pressable p={2} key={tab.id} onPress={() => tabSelect(tab.id)}>
  //               <VStack justifyContent="center" alignItems="center">
  //                 <Box width={5} height={6}>
  //                   {tab.icon
  //                     ? tab.icon(
  //                         selectedTab === tab.id
  //                           ? theme.colors.primary['400']
  //                           : 'grey',
  //                         20,
  //                       )
  //                     : null}
  //                   {/* <SnowflakeIcon
  //                   fill={
  //                     selectedTab === tab.id
  //                       ? theme.colors.primary['400']
  //                       : 'grey'
  //                   }
  //                 /> */}
  //                 </Box>
  //                 <Text
  //                   color={
  //                     selectedTab === tab.id
  //                       ? theme.colors.primary['400']
  //                       : 'grey'
  //                   }>
  //                   {tab.displayName}
  //                 </Text>
  //               </VStack>
  //             </Pressable>
  //           ))}
  //         </HStack>
  //       </ScrollView>
  //     </View>
  //   );
}
