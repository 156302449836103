import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import CellTowerIcon from "@mui/icons-material/CellTower"
import PlaceIcon from "@mui/icons-material/Place"
import LandscapeIcon from "@mui/icons-material/Landscape"
import {
  AcUnit,
  Air,
  Star,
  StarBorder,
  Straighten,
  Terrain,
  Thermostat,
  Water,
} from "@mui/icons-material"
import { cToF, mmToIn, msToMph } from "../utilities/conversion"
import { useNavigate } from "react-router-dom"
import { graphqlClient } from "../graphql"
import { updateStationFavoriteMutation } from "../graphql/mutations"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import { ActivityContext } from "../pages/Header"
import { LoginModal } from "./LoginModal"
import { getStaticTileUrl } from "../api/utilities"

export interface StationCardProps {
  station: any
}

export function StationCard({ station }: StationCardProps) {
  const navigate = useNavigate()
  const selectedActivity = useContext(ActivityContext)
  const [user, loadingAuthState] = useAuthState(auth)
  const [isFavorite, setIsFavorite] = useState(false)
  const [showLoginPopup, setShowLoginPopup] = useState(false)

  useEffect(() => {
    if (station && station.isFavorite) {
      setIsFavorite(station.isFavorite)
    }
  }, [station])

  const onFavorite = (event: any, isFavorite: boolean) => {
    event.stopPropagation()
    if (user) {
      setIsFavorite(isFavorite)
      graphqlClient
        .mutation(updateStationFavoriteMutation, {
          stationId: station.id,
          isFavorite: isFavorite,
          type: selectedActivity,
        })
        .toPromise()
    } else {
      setShowLoginPopup(true)
    }
  }
  return (
    <>
      <LoginModal
        open={showLoginPopup}
        onClose={() => setShowLoginPopup(false)}
      />
      <Box maxWidth={350}>
        <Paper
          sx={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/station/${station.id}`, {
              state: { back: "Station" },
            })
          }
        >
          <Box overflow={"hidden"}>
            <img
              src={getStaticTileUrl({
                lat: station.lat,
                lon: station.lon,
                width: 350,
                height: 200,
                zoom: 11,
              })}
              alt="map view"
            />
          </Box>
          <Box display="flex" justifyContent={"space-between"} pb={2} m={1}>
            <Box display="flex" flexDirection={"column"} width={"100%"}>
              <Box display="flex" justifyContent={"space-between"}>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <CellTowerIcon />
                  <Typography
                    fontWeight="bold"
                    variant={"h5"}
                    maxWidth={275}
                    noWrap
                  >
                    {station.NAME || station.name}
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                  {isFavorite ? (
                    <Tooltip title="Save to my spots">
                      <IconButton
                        style={{ padding: 0, marginBottom: 1 }}
                        onClick={(event) => onFavorite(event, false)}
                      >
                        <Star sx={{ color: "gold" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Save to my spots">
                      <IconButton
                        style={{ padding: 0, marginBottom: 1 }}
                        onClick={(event) => onFavorite(event, true)}
                      >
                        <StarBorder />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>

              <Box display="flex" alignItems="center">
                <Terrain />
                <Typography fontWeight={"bold"}>
                  {Number(station.ELEVATION) || station.elevation} ft
                </Typography>
                {station.distance && (
                  <>
                    <PlaceIcon sx={{ pl: 1 }} />
                    <Typography fontWeight={"bold"}>
                      {Math.round(station.distance * 10) / 10} miles
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          </Box>

          <Grid container spacing={2} pb={1}>
            {station.snowDepth !== undefined && station.snowDepth !== null ? (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <Box sx={{ transform: "rotate(90deg)" }}>
                    <Straighten color="primary" />
                  </Box>
                  <Typography fontWeight="bold">Snow Depth</Typography>
                </Box>
                <Typography fontWeight={"bold"} sx={{ mt: "-6px" }}>
                  {mmToIn(station.snowDepth)} in
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <Box sx={{ transform: "rotate(90deg)" }}>
                    <Straighten color="disabled" />
                  </Box>
                  <Typography
                    fontWeight="bold"
                    color="disabled"
                    sx={{ color: "grey" }}
                  >
                    Snow Depth
                  </Typography>
                </Box>
                <Typography sx={{ mt: "-6px", color: "grey" }}>--</Typography>
              </Grid>
            )}

            {station.twentyFourHourSnow !== undefined &&
            station.twentyFourHourSnow !== null ? (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <AcUnit color="primary" />
                  <Typography fontWeight="bold">Snow Accum</Typography>
                </Box>
                <Typography fontWeight={"bold"} sx={{ mt: "-6px" }}>
                  {mmToIn(station.twentyFourHourSnow)} in
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <AcUnit sx={{ color: "grey" }} />
                  <Typography
                    fontWeight="bold"
                    color="disabled"
                    sx={{ color: "grey" }}
                  >
                    Snow Accum
                  </Typography>
                </Box>
                <Typography sx={{ mt: "-6px", color: "grey" }}>--</Typography>
              </Grid>
            )}

            {station.temperature ? (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <Thermostat color="secondary" />
                  <Typography fontWeight="bold">Temperature</Typography>
                </Box>
                <Typography fontWeight="bold">
                  {cToF(station.temperature)} &#176;F
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <Thermostat color={"disabled"} />
                  <Typography color="disabled" sx={{ color: "grey" }}>
                    Temperature
                  </Typography>
                </Box>
                <Typography sx={{ color: "grey" }}>--</Typography>
              </Grid>
            )}

            {station.windSpeed !== undefined && station.windSpeed !== null ? (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <Air color="secondary" />
                  <Typography fontWeight="bold">Wind Speed</Typography>
                </Box>
                <Typography fontWeight={"bold"}>
                  {msToMph(station.windSpeed)} mph
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Air color="disabled" />
                    <Typography
                      fontWeight="bold"
                      color="disabled"
                      sx={{ color: "grey" }}
                    >
                      Wind Speed
                    </Typography>
                  </Box>
                </Box>
                <Typography sx={{ color: "grey" }}>--</Typography>
              </Grid>
            )}

            {station.relativeHumidity !== undefined &&
            station.relativeHumidity !== null ? (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <Water color="secondary" />
                  <Typography fontWeight="bold">Relative Humidity</Typography>
                </Box>
                <Typography fontWeight={"bold"}>
                  {station.relativeHumidity} %
                </Typography>
              </Grid>
            ) : (
              <Grid
                item
                display={"flex"}
                flexDirection="column"
                alignItems="center"
                xs={6}
              >
                <Box pb={1} display="flex" alignItems="center">
                  <Water color="disabled" />
                  <Typography
                    fontWeight="bold"
                    color="disabled"
                    sx={{ color: "grey" }}
                  >
                    Relative Humidity
                  </Typography>
                </Box>
                <Typography sx={{ color: "grey" }}>--</Typography>
              </Grid>
            )}
          </Grid>

          <Box display={"flex"} justifyContent={"center"} py={1}>
            <Button
              variant="outlined"
              onClick={() =>
                navigate(`/station/${station.id}`, {
                  state: { back: "Station" },
                })
              }
            >
              <Typography>View Hourly Data</Typography>
            </Button>
          </Box>
        </Paper>
      </Box>
    </>
  )
}
