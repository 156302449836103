import { Container } from "@mui/material"
import React, { useContext } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { updateFavorite } from "../api/snarf-api"
import { PointDetail } from "../components/PointDetail"
import { graphqlClient } from "../graphql"
import { updateFavoriteMutation } from "../graphql/mutations"
import { NoaaStation } from "../models/models"
import { ActivityContext } from "./Header"

export function PointDetailPage() {
  const params = useParams()
  const routePointId = params.pointId
  const navigate = useNavigate()
  const selectedActivity = useContext(ActivityContext)

  const onStationClick = (station: NoaaStation) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    navigate(`/point/${station.id}`)
  }

  const onFavoriteClick = (pointId: string, isFavorite: boolean) => {
    graphqlClient
      .mutation(updateFavoriteMutation, {
        pointId: pointId,
        isFavorite,
        type: selectedActivity,
      })
      .toPromise()
  }

  return (
    <PointDetail
      key={routePointId}
      pointId={routePointId}
      onStationClick={onStationClick}
      setIsFavorite={onFavoriteClick}
    />
  )
}
