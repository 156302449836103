import { gql } from "urql"

export const userSubsQuery = gql`
  query () {
    user {
      id
      email
      subscriptions(status: "active") {
        id
        type
        status
        zoneId
        avalancheZone {
          id
          name
          url
        }
      }
    }
  }
`
