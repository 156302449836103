import React from "react"
import { SkiAreaType } from "../../api/models"

export interface SkiAreaIconProps {
  type: SkiAreaType
  width?: number
  height?: number
}
export function SkiAreaIcon({ type, width, height }: SkiAreaIconProps) {
  switch (type) {
    case SkiAreaType.BACKCOUNTRY:
      return (
        <img
          width={width || "auto"}
          height={height || "auto"}
          src="/backcountry-circle-black.png"
          alt="backcountry skier"
        />
      )

    case SkiAreaType.RESORT:
      return (
        <img
          width={width || "auto"}
          height={height || "auto"}
          src="/snowboarder-circle-black.png"
          alt="backcountry skier"
        />
      )

    default:
      return (
        <img
          width={width || "auto"}
          height={height || "auto"}
          src="/backcountry-circle-black.png"
          alt="resort skier"
        />
      )
  }
}
