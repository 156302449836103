import { gql } from "urql"

export const pointQuery = gql`
  query pointQuery($pointId: ID!, $type: Int!) {
    point(id: $pointId, type: $type) {
      id
      name
      lat
      lon
      isFavorite
    }
  }
`
