import React from "react"
import { Camera } from "../../api/models"
import { Box, IconButton } from "@mui/material"
import { Fullscreen } from "@mui/icons-material"

export interface CameraImageProps {
  camera: Camera
}

export function CameraImage({ camera }: CameraImageProps) {
  return <Box display={'flex'} alignItems={'center'} position='relative' onClick={() => window.open(camera.url, "_blank", "noreferrer")}>
    <img src={camera.url} style={{ width: "100%" }} alt="camera" />
    <Box position={'absolute'} right={0} bottom={0}>
      <IconButton>
        <Fullscreen htmlColor="white" />
      </IconButton>
    </Box>
  </Box>
}
