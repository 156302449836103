import React from "react"
import { UserPointOfInterestType } from "../api/models"
import SnowboardingIcon from "@mui/icons-material/Snowboarding"
import LandscapeIcon from "@mui/icons-material/Landscape"
import { SnowRainMixIcon } from "../icons/SnowRainMixIcon"
import { PartlyCloudyNightIcon } from "../icons/PartlyNightCloudyIcon"

export const getActivityIcon = (type: UserPointOfInterestType) => {
  switch (type) {
    case UserPointOfInterestType.SKI:
      return <SnowboardingIcon />

    case UserPointOfInterestType.ROCK_CLIMB:
      return (
        <img
          style={{ width: "25px", height: "25px" }}
          src="/climber.png"
          alt=""
        />
      )

    default:
      return <LandscapeIcon />
  }
}

export const getActivityName = (activity: UserPointOfInterestType) => {
  switch (activity) {
    case UserPointOfInterestType.ROCK_CLIMB:
      return "Climbing"

    case UserPointOfInterestType.SKI:
      return "Skiing"

    default:
      break
  }
}

export const getWeatherIcon = (description: string, isDay: boolean) => {
  if (!description) {
    return null
  }

  const lowercaseDescription = description.toLowerCase()
  const split = lowercaseDescription.split("then")
  if (split.length > 1) {
    // double photo could split here to show side by side?
  }
  const hasRain = lowercaseDescription.includes("rain")
  const hasSnow = lowercaseDescription.includes("snow")
  const tStorms =
    lowercaseDescription.includes("t-storms") ||
    lowercaseDescription.includes("thunderstorms")
  const partlySunny = lowercaseDescription.includes("partly sunny")
  const partlyCloudy = lowercaseDescription.includes("partly cloudy")
  const mostlyClear = lowercaseDescription.includes("mostly clear")
  const cloudy = lowercaseDescription.includes("cloudy")
  const clear = lowercaseDescription.includes("clear")

  if (hasRain && hasSnow) {
    return <SnowRainMixIcon title={description} />
  } else if (hasRain || tStorms) {
    return <img src="/rain-cloud.png" width={50} height={50} alt="" />
    // return <RainIcon title={description} />
  } else if (hasSnow) {
    return <img src="/snow-cloud.png" width={50} height={50} alt="" />
    // return <SnowIcon title={description} />
  } else if (partlySunny) {
    return <img src="/day-partly-cloudy.png" width={50} height={50} alt="" />
    // return <SunIcon title={description} />
  } else if (partlyCloudy) {
    if (isDay) {
      return <img src="/day-partly-cloudy.png" width={50} height={50} alt="" />
    } else {
      return (
        <img src="/night-partly-cloudy.png" width={50} height={50} alt="" />
      )
    }
  } else if (mostlyClear) {
    if (isDay) {
      return <img src="/sunny.png" width={50} height={50} alt={description} />
    } else {
      return <img src="/moon.png" width={50} height={50} alt={description} />
    }
  } else if (cloudy) {
    return <img src="/cloud.png" width={50} height={50} alt={description} />
  } else if (clear) {
    if (isDay) {
      return <img src="/sunny.png" width={50} height={50} alt={description} />
    } else {
      return <img src="/moon.png" width={50} height={50} alt={description} />
    }
  }

  if (isDay) {
    return <img src="/sunny.png" width={50} height={50} alt={description} />
    // return <SunIcon title={description} />
  } else {
    return <img src="/moon.png" width={50} height={50} alt={description} />
    // return <MoonIcon title={description} />
  }

  // return <img src="/sunny.png" width={50} height={50} alt={description} />
  // return <SunIcon title={description} />
}



export function capitalizeFirstLetter(string: string): string {
  const split = string.split(" ");
  if (split.length > 1) {
    return (
      capitalizeFirstLetter(split[0]) + " " + capitalizeFirstLetter(split[1])
    );
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}