import { Box } from "@mui/system"
import dayjs, { Dayjs } from "dayjs"
import React from "react"
import { UserPointOfInterestType } from "../api/models"
import { SunIcon } from "../icons/SunIcon"
import { PointSummary, RockClimbingSummary } from "../models/models"
import CloudQueueIcon from "@mui/icons-material/CloudQueue"
import { RainIcon } from "../icons/RainIcon"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Typography, useTheme } from "@mui/material"
import ThermostatIcon from "@mui/icons-material/Thermostat"
import { cToF, mmToIn } from "../utilities/conversion"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

export interface SkiDaySnapshotProps {
  point: PointSummary
  type: UserPointOfInterestType
  day: Dayjs
  rockSummary?: RockClimbingSummary
}

enum SkiStatus {
  CORN,
  YES,
  MAYBE,
  NO,
}

export function SkiDaySnapshot({
  rockSummary,
  point,
  day,
}: SkiDaySnapshotProps) {
  const theme = useTheme()
  const getWeatherIcon = (summary?: RockClimbingSummary) => {
    if (!summary) {
      return null
    }
    if (summary.avgCloudCover < 30 && summary.precip <= 0) {
      return <SunIcon height="30px" width="30px" fill="orange" />
    } else if (summary.avgCloudCover > 20 && summary.precip <= 0) {
      return <CloudQueueIcon fontSize="small" />
    } else if (summary.precip > 0) {
      return (
        <Box display="flex" alignItems="center">
          <RainIcon
            fill={theme.palette.primary.main}
            height="25px"
            width="25px"
          />
          <Box
            display="flex"
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
            pl="2px"
          >
            <Typography variant="caption">
              {rockSummary?.maxProbabilityOfPrecip || "na"}%
            </Typography>
            <Typography variant="caption">
              {mmToIn(rockSummary?.precip || 0, 100)}
            </Typography>
          </Box>
        </Box>
      )
    }
  }

  const isInIcon = (status: SkiStatus) => {
    switch (status) {
      case SkiStatus.YES:
        return <CheckCircleIcon htmlColor="green" />
      case SkiStatus.CORN:
        return (
          <img
            src="/corn.png"
            style={{ width: "auto", height: "25px" }}
            alt=""
          />
        )
      case SkiStatus.MAYBE:
        return <CheckCircleOutlineIcon color="warning" />
      case SkiStatus.NO:
        return null
    }
  }

  const getStyle = (status: SkiStatus) => {
    switch (status) {
      case SkiStatus.YES:
        return { border: "2px solid green", borderRadius: "10px" }
      case SkiStatus.CORN:
        return { border: "2px solid green", borderRadius: "10px" }
      case SkiStatus.MAYBE:
        return { border: "2px solid #ed6c02", borderRadius: "10px" }
      case SkiStatus.NO:
        return {}
    }
  }

  const getStatus = () => {
    const cornDay = point.potentialCornDays.find(
      (date) => dayjs(date).date() === day.date()
    )

    if (cornDay) {
      return SkiStatus.CORN
    }

    return SkiStatus.NO
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={getStyle(getStatus())}
    >
      <Box>{isInIcon(getStatus())}</Box>
      <Box display="flex" alignItems="center">
        {getWeatherIcon(rockSummary)}
        <Box display="flex" alignItems="center">
          <ThermostatIcon />
          <Box display="flex" flexDirection={"column"}>
            <Typography variant="caption">
              {cToF(rockSummary?.maxTemp || 0)}&#176;
            </Typography>
            <Typography variant="caption">
              {cToF(rockSummary?.minTemp || 0)}&#176;
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
