import { gql } from "urql"

export const pointsQuery = gql`
  query pointsQuery($filters: Filters, $type: Int!) {
    points(filters: $filters, type: $type) {
      id
      name
      lat
      lon
      isFavorite
      avalanche {
        danger {
          lower
          middle
          upper
          valid_day
        }
        url
        expires
      }

      rockClimbingSummary {
        date
        rating
        avgCloudCover
        precip
        maxProbabilityOfPrecip
        maxTemp
        minTemp
      }

      dailyTotals {
        date
        totalSnow
      }
    }
  }
`
