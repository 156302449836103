import { Box, IconButton, Paper, Typography } from "@mui/material"
import { Dayjs } from "dayjs"
import React from "react"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"

export interface DateCarousolProps {
  onDateChange: (date: Dayjs) => any
  selectedDate: Dayjs
  minDate?: Dayjs
  maxDate?: Dayjs
}

export function DateCarousol({
  selectedDate,
  onDateChange,
  minDate,
  maxDate,
}: DateCarousolProps) {
  return (
    <>
      <Box zIndex={5} display="flex" justifyContent={"space-around"}>
        <Paper sx={{ zIndex: 5 }}>
          <Box
            zIndex={5}
            display="flex"
            justifyContent={"space-around"}
            alignItems="center"
          >
            <IconButton
              disabled={
                minDate &&
                selectedDate.format("YYYY-MM-DD") ===
                  minDate.format("YYYY-MM-DD")
              }
              onClick={() => onDateChange(selectedDate.subtract(1, "day"))}
            >
              <ArrowBackIosIcon fontSize="large" />
            </IconButton>
            <Typography fontSize={"large"}>
              {selectedDate.format("ddd MMM-D")}
            </Typography>
            <IconButton
              disabled={
                maxDate &&
                selectedDate.format("YYYY-MM-DD") ===
                  maxDate.format("YYYY-MM-DD")
              }
              onClick={() => onDateChange(selectedDate.add(1, "day"))}
            >
              <ArrowForwardIosIcon fontSize="large" />
            </IconButton>
          </Box>
        </Paper>
      </Box>
    </>
  )
}
