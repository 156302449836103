import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Alert,
  CircularProgress,
  IconButton,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import VideocamIcon from "@mui/icons-material/Videocam"
import {
  getCamerasForPoint,
  getHourlyForecastDateRange,
  getPointSummary,
  getStationsForPoint,
  updateUserPoint,
} from "../api/snarf-api"
import LandscapeIcon from "@mui/icons-material/Landscape"
import { SnowRating } from "./SnowRating"
import dayjs, { Dayjs } from "dayjs"
import { DailyForecast, HourlyForecast, PointSummary } from "../models/models"
import CellTowerIcon from "@mui/icons-material/CellTower"

import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import { useQuery } from "@tanstack/react-query"
import { MapLocation, SnarfMap } from "./Map"
import { SkiPointWeather } from "./SkiPointWeather"
import { Camera, SynopticStation, UserPointOfInterestType } from "../api/models"
import { CameraModal } from "./CameraModal"
import { ActivityContext, UserPremiumContext } from "../pages/Header"
import { PointDetailHeader } from "./PointDetailHeader"
import { RockPointWeather } from "./RockPointWeather"
import { TabBar } from "./MobileTabBar"
import { gql, useQuery as useGraphqlQuery } from "urql"
import { StationList } from "./StationList"
import { StationViewer } from "./StationViewer"
import { Map } from "@mui/icons-material"
import { CameraList } from "./CameraList"
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom"
import { sendEvent, SnarfEvents } from "../utilities/metrics"
import { forecastedSnowTotalsQuery, pointDetailQuery } from "../graphql/queries"
import { AdBanner } from "../ads/AdBanner"
import { pointsQuery } from "../graphql/queries/points"
import { SubscribeModal } from "./SubscribeModal"

const tabs = [
  {
    label: "Weather",
    icon: (
      color:
        | "inherit"
        | "disabled"
        | "action"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning"
        | undefined
    ) => <LandscapeIcon color={color} />,
    active: true,
    id: 0,
  },
  {
    label: "Stations",
    icon: (
      color:
        | "inherit"
        | "disabled"
        | "action"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning"
        | undefined
    ) => <CellTowerIcon color={color} />,
    active: false,
    id: 1,
  },
  {
    label: "Cams",
    icon: (
      color:
        | "inherit"
        | "disabled"
        | "action"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning"
        | undefined
    ) => <VideocamIcon color={color} />,
    active: false,
    id: 2,
  },
  {
    label: "Map",
    icon: (
      color:
        | "inherit"
        | "disabled"
        | "action"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning"
        | undefined
    ) => <Map color={color} />,
    active: false,
    id: 3,
  },
]

export function PointDetail(props: any) {
  const [pointId, setPointId] = useState<string>(props.pointId)
  const [subPointId, setSubPointId] = useState<string>(props.pointId)
  const [pointSummary, setPointSummary] = useState<PointSummary | null>(null)
  const [startDate, setStartDate] = useState<Dayjs>(
    dayjs(new Date()).startOf("day")
  )
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [endDate, setEndDate] = useState<Dayjs>(startDate.add(6, "days"))

  const [processing, setProcessing] = useState(false)
  const [user, loadingAuthState] = useAuthState(auth)
  const [hourlyForecastData, setHourlyForecastData] = useState<
    HourlyForecast[]
  >([])
  const [currentTab, setCurrentTab] = useState(0)
  const [selectedCam, setSelectedCam] = useState<Camera | null>(null)
  const [stations, setStations] = useState<SynopticStation[] | null>(null)
  const [cameras, setCameras] = useState<Camera[] | null>(null)
  const selectedActivity = useContext(ActivityContext)
  const isSmall = useMediaQuery("(max-width:600px)")
  const userIsPremium = useContext(UserPremiumContext)
  const [showSubscribePopup, setShowSubscribePopup] = useState(false)
  const location = useLocation()

  const theme = useTheme()

  const [pointGqlQuery, reexecuteQuery] = useGraphqlQuery({
    query: pointDetailQuery,
    variables: {
      pointId,
      startDate: startDate.toISOString(),
      type: selectedActivity,
    },
    pause: loadingAuthState || !pointSummary || pointSummary.processing,
  })
  const { data, fetching, error } = pointGqlQuery

  const [userQuery] = useGraphqlQuery({
    query: pointsQuery,
    variables: {
      filters: {
        isFavorite: true,
        type: selectedActivity,
      },
      type: selectedActivity,
    },
    pause: loadingAuthState,
  })

  // Todo: depracate for graphql?
  const pointSummaryRequest = useQuery({
    queryKey: ["pointSummary", subPointId, user?.email],
    queryFn: (): any => getPointSummary(subPointId, selectedActivity),
    enabled: !loadingAuthState,
    refetchInterval: processing ? 2000 : false,
  })

  if (!pointSummaryRequest?.isLoading && !pointSummary) {
    if (!pointSummaryRequest?.data?.processing) {
      setProcessing(false)
      setPointSummary({ ...pointSummaryRequest.data })
    } else if (!processing) {
      setProcessing(true)
    }
  }

  // TODO: Have this feed the table only to reduce data load?
  // TODO: graphql for the forecsat overview to reduce data
  const fixedPointHourlyForecastRequest = useQuery({
    queryKey: [
      "fixedPointHourlyForecast",
      subPointId,
      startDate.format("DD/MM/YYYY"),
      endDate.endOf("day").format("DD/MM/YYYY"),
    ],
    queryFn: (): any =>
      getHourlyForecastDateRange(
        subPointId,
        startDate.toDate(),
        endDate.endOf("day").toDate()
      ),
    enabled: pointSummary !== null && !processing,
  })

  if (
    !fixedPointHourlyForecastRequest.isLoading &&
    hourlyForecastData.length === 0
  ) {
    setHourlyForecastData(fixedPointHourlyForecastRequest.data)
  }

  const stationsRequest = useQuery({
    queryKey: ["getStationsForPoint", subPointId],
    queryFn: (): any => getStationsForPoint(subPointId),
    enabled: !loadingAuthState,
  })

  if (!stationsRequest.isLoading && !stations) {
    setStations(stationsRequest.data)
  }

  const camsRequest = useQuery({
    queryKey: ["getCamerasForPoint"],
    queryFn: (): any => getCamerasForPoint(subPointId),
    enabled: fixedPointHourlyForecastRequest.isFetched,
  })

  if (!camsRequest.isLoading && !cameras) {
    setCameras(camsRequest.data)
  }

  useEffect(() => {
    if (props.pointId && props.pointId !== pointId && !loadingAuthState) {
      setPointId(props.pointId)
      setSubPointId(props.pointId)
      setPointSummary(null)
      setHourlyForecastData([])
      setStations(null)
      setCameras(null)
    }
  }, [props, loadingAuthState])

  useEffect(() => {
    if (searchParams.get("tab")) {
      setCurrentTab(Number(searchParams.get("tab")))
    }
  }, [searchParams])

  const onSubPointSelect = (pointId: string) => {
    setSubPointId(pointId)
    setPointSummary(null)
    setHourlyForecastData([])
    setStations(null)
    setCameras(null)
    sendEvent(SnarfEvents.ElevationProfileSwitch)
  }

  const onSetFavorite = (isFavorite: boolean) => {
    if (user) {
      props.setIsFavorite(pointId, isFavorite)

      if (pointSummary) {
        const newPointSummary = { ...pointSummary }
        newPointSummary.isFavorite = isFavorite
        setPointSummary(newPointSummary)
      }
    }
  }

  const onNameUpdate = (name: string) => {
    updateUserPoint(subPointId, { name, type: selectedActivity }).then(() => {
      if (pointSummary) {
        const newPointSummary = { ...pointSummary }
        newPointSummary.name = name
        setPointSummary(newPointSummary)
      }
    })
  }

  const onTabClick = (id: string | number) => {
    const tab = tabs.find((tab) => tab.id === id)
    if (id === 1) {
      sendEvent(SnarfEvents.StationsTabSelected, { tab: tab?.label })
    }

    if (id === 2) {
      sendEvent(SnarfEvents.CamsTabSelected, { tab: tab?.label })
    }
    if (id === 3) {
      navigate(`/map?selectedPointId=${subPointId}`, { state: { previous: `${location.pathname}`}})
      return
    }

    if (id !== 0 && !userIsPremium) {
      setShowSubscribePopup(true)
    } else {
      setCurrentTab(Number(id))
      searchParams.set("tab", String(id))
      setSearchParams(searchParams)
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const onShowHistorical = () => {
    setPointSummary(null)
    setHourlyForecastData([])
    const newDate = startDate.subtract(7, "days")
    setStartDate(newDate)
  }

  const getPointWeatherComponent = () => {
    if (!pointSummary) {
      return null
    }

    switch (selectedActivity) {
      case UserPointOfInterestType.SKI:
        return (
          <SkiPointWeather
            pointId={subPointId}
            elevation={pointSummary?.noaaElevation}
          />
        )
      case UserPointOfInterestType.ROCK_CLIMB:
        return (
          <RockPointWeather
            dailyForecast={data?.point?.dailyWeatherSummary || []}
            hourlyForecast={hourlyForecastData}
            pointId={subPointId}
            pointSummary={pointSummary}
            elevation={pointSummary?.noaaElevation}
            onShowHistorical={onShowHistorical}
          />
        )

      default:
        break
    }
  }

  return (
    <>
      {isSmall ? (
        <Box>
          <TabBar
            tabs={tabs}
            onTabSelect={onTabClick}
            selectedTab={currentTab}
          />
        </Box>
      ) : null}

      <SubscribeModal
        message={"Start a free trial to checkout Maps and more with Snarf Pro."}
        open={showSubscribePopup}
        onClose={() => setShowSubscribePopup(false)}
      />

      <Container sx={{ p: 2 }}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent={"center"}
        >
          <AdBanner />
        </Box>
        {selectedCam ? (
          <CameraModal
            camera={selectedCam}
            open={selectedCam ? true : false}
            onClose={() => setSelectedCam(null)}
          />
        ) : null}

        {processing ? (
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems="center"
            flexDirection="column"
            minHeight="50vh"
          >
            <Typography variant="h5" pb={2}>
              Snarf is loading your forecasts, just a moment!
            </Typography>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid
              container
              direction="column"
              spacing={1}
              maxWidth="lg"
              pr={0}
              alignItems={"center"}
            >
              <Grid item width="100%" p={0} m={0}>
                <PointDetailHeader
                  pointSummary={data?.point}
                  type={selectedActivity}
                  onNameUpdate={onNameUpdate}
                  onSubPointSelect={onSubPointSelect}
                  selectedSubPointId={subPointId}
                  favoritesCount={
                    userQuery?.data?.points?.length
                      ? userQuery?.data?.points?.length
                      : 0
                  }
                />
              </Grid>
{/* ad space 
              <Grid item width='100%'>
                <Box sx={{  height: 100, backgroundColor: 'black'}}>

                </Box>
              </Grid> */}

              {!userIsPremium && (
                <Grid item width="100%" p={0} m={0}>
                  <Box onClick={() => navigate("/subscribe")}>
                    <Alert variant="filled" severity="info">
                      <Link to={"/subscribe"}>Click here</Link> to go ad free
                      and get all Snarf has to offer.
                    </Alert>
                  </Box>
                </Grid>
              )}

              {/* Tabs for large view */}
              {isSmall ? null : (
                <Grid item width="100%">
                  <Box
                    sx={{ py: 1, px: "1px" }}
                    display="flex"
                    overflow={"scroll"}
                    justifyContent={"space-between"}
                    width="100%"
                  >
                    {tabs.map((tab) => (
                      <Paper
                        key={tab.id}
                        elevation={tab.id === currentTab ? 4 : 1}
                        sx={{ height: "50px", width: "130px" }}
                      >
                        <Button
                          sx={{
                            color: "black",
                            height: "100%",
                            width: "100%",
                          }}
                          onClick={() => onTabClick(tab.id)}
                        >
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={
                              tab.id === currentTab
                                ? {
                                    color: theme.palette.primary.main,
                                  }
                                : {}
                            }
                          >
                            {tab.icon(
                              currentTab === tab.id ? "primary" : "disabled"
                            )}
                            <Typography sx={{ pl: 1 }}>{tab.label}</Typography>
                          </Box>
                        </Button>
                      </Paper>
                    ))}
                  </Box>
                </Grid>
              )}

              {currentTab === 0 ? (
                pointSummary ? (
                  <Grid item width="100%" p={0}>
                    {getPointWeatherComponent()}{" "}
                  </Grid>
                ) : (
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    height="75vh"
                  >
                    <CircularProgress />
                  </Box>
                )
              ) : null}

              {currentTab === 1 ? (
                <Box width={"100%"} pl={1}>
                  {stations && stations.length > 0 ? (
                    <StationList stations={stations || []} />
                  ) : (
                    <Box display="flex" justifyContent={"center"} py={2}>
                      {stationsRequest.isLoading ? (
                        <>
                          <CircularProgress />
                        </>
                      ) : (
                        <Paper sx={{ p: 2 }}>
                          <Typography>
                            No stations found within 30 miles
                          </Typography>
                        </Paper>
                      )}
                    </Box>
                  )}
                </Box>
              ) : null}

              {currentTab === 2 ? (
                <>
                  {cameras ? (
                    <Box pl={1}>
                      <CameraList cameras={cameras || []} />
                    </Box>
                  ) : (
                    <Box>
                      <CircularProgress />
                    </Box>
                  )}
                </>
              ) : null}
            </Grid>
          </>
        )}
      </Container>
    </>
  )
}
