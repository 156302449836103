import { Box, Typography } from "@mui/material"
import React from "react"

export function HelpSnarfWeekly() {
  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography variant="h5">What is Snarf Weekly?</Typography>
        <Typography>
          Nobody wants to miss a big storm. Snarf Weekly is sent to your email
          inbox every Monday with all the upcoming Snow Totals for your favorite
          spots. Helping you keep an eye on any incoming powder days so you can
          get a head start planning your next outing.
        </Typography>
      </Box>
    </>
  )
}
