import { Box, Container, Paper, Typography } from "@mui/material"
import React from "react"

export function Contact() {
  return (
    <Container maxWidth="lg" sx={{ pb: 2 }}>
      <Typography variant="h2">Contact</Typography>

      <Box sx={{ py: 1 }}>
        <Paper sx={{ p: 1 }}>
          <Typography>
            Want to talk weather or have some ideas for the site? Shoot me an
            email.
          </Typography>
          <Box>
            <Typography>bob@snarfme.com</Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  )
}
