import { gql } from "urql"

export const pointDetailQuery = gql`
  query ($pointId: ID!, $startDate: String, $type: Int) {
    point(id: $pointId, type: $type) {
      id
      name
      lat
      lon
      isFavorite
      noaaElevation
      climbingAreas {
        name
        openBetaAreaId
      }
      profilePoints {
        id
        noaaElevation
        name
      }
      avalanche {
        danger {
          lower
          middle
          upper
          valid_day
        }
        url
        expires
      }
      dailyWeatherSummary(startDate: $startDate) {
        date
        daytime {
          temperature
          windDirection
          windSpeed
          shortForecast
        }

        nighttime {
          temperature
          windDirection
          windSpeed
          shortForecast
        }
      }
    }
  }
`
