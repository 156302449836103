import dayjs, { Dayjs } from "dayjs"
import { HourlyForecast, RockClimbingSummary } from "../models/models"

// eslint-disable-next-line max-len
export function accumulateHourly(
  hourlyForecast: HourlyForecast[],
  hours: number
): { date: string; snowfall: number }[] {
  const accumArray: { date: string; snowfall: number }[] = []
  const accumMap: { [date: string]: number } = {}
  let startDate: Dayjs | null = null

  hourlyForecast.forEach((cast) => {
    const date = dayjs(cast.date)
    if (!startDate) {
      startDate = date
      accumMap[startDate.toISOString()] = 0
    }

    if (startDate && startDate.add(hours, "hours").isAfter(date)) {
      accumMap[startDate.toISOString()] += cast.snowfallAmount
        ? cast.snowfallAmount
        : 0
    } else {
      startDate = startDate.add(hours, "hours")
      accumMap[startDate.toISOString()] = 0
    }
  })

  Object.keys(accumMap).forEach((date) =>
    accumArray.push({ date, snowfall: accumMap[date] })
  )
  accumArray.sort((a, b) => (a.date < b.date ? -1 : 1))

  return accumArray
}

export function groupHourlyForecastByDay(hourlyForecasts: HourlyForecast[]): {
  [date: string]: HourlyForecast[]
} {
  const dayMap: { [date: string]: HourlyForecast[] } = {}
  for (let cast of hourlyForecasts) {
    let date = dayjs(Number(cast.date)).format("YYYY-MM-DD")

    // HACK around some dates being isostrings some being epochs
    if (date === 'Invalid Date') {
      date = dayjs((cast.date)).format("YYYY-MM-DD")
    }

    if (!dayMap[date]) {
      dayMap[date] = []
    }

    dayMap[date].push(cast)
  }

  return dayMap
}

export function getPreviousRain(rockSummary: RockClimbingSummary[]) {
  let precipLast48 = 0
  if (rockSummary && rockSummary.length > 1) {
    precipLast48 = rockSummary[0].precip + rockSummary[1].precip
  }

  return precipLast48
}
