import { Box, useMediaQuery } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { useContext, useEffect, useState } from "react"
import { MapLocation, SnarfMap } from "../components/Map"
import { ActivityContext } from "./Header"
import { UserPointOfInterestType } from "../api/models"
import { useSearchParams } from "react-router-dom"
import { MapLayers } from "../components/Map/models"
import useSessionMapConfig from "../hooks/useSessionMapConfig"

const defaultConfig: any = {
  FAVORITES: {
    enabled: false,
  },
  SKI_AREAS: {
    enabled: true,
  },
}

const selectedPointConfig: any = {
  ...defaultConfig,
  NEAR_ME: {
    distance: 50,
    enabled: true,
  },
}

export interface MapPageProps {}

export function MapPage(props: MapPageProps) {
  const selectedActivity = useContext(ActivityContext)
  const [searchParams] = useSearchParams()
  const isSmall = useMediaQuery("(max-width:600px)")
  const [sessionMapConfig, setSessionMapConfig] =
    useSessionMapConfig(defaultConfig)

  useState<UserPointOfInterestType>(selectedActivity)

  const selectedPointId = searchParams.get("selectedPointId")

  let center =
    selectedActivity === UserPointOfInterestType.ROCK_CLIMB
      ? { lat: 47.8206605, lon: -121.5551086 }
      : { lat: 47.4361, lon: -121.4354 }
  let controlsClosed = false

  if (selectedPointId) {
    selectedPointConfig[MapLayers.NEAR_ME] = {
      distance: 50,
      enabled: false,
    }

    const [lat, lon] = selectedPointId.split("|")

    center = { lat: Number(lat), lon: Number(lon) }
    controlsClosed = isSmall ? true : false

    selectedPointConfig[MapLayers.SELECTED_POINT] = {
      enabled: true,
      pointId: selectedPointId,
      location: center,
    }
  }

  const onSessionMapConfigChange = (newConfig: any) => {
    setSessionMapConfig(newConfig)
  }

  return (
    <Box height={isSmall ? "88vh" : "100vh"} width="100vw">
      <SnarfMap
        center={center}
        zoom={isSmall ? 7 : 8}
        mapConfig={selectedPointId ? selectedPointConfig : sessionMapConfig}
        controlsClosed={controlsClosed}
        onSessionMapConfigChange={onSessionMapConfigChange}
      />
    </Box>
  )
}
