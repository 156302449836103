import { initializeApp } from "firebase/app"
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithPopup,
} from "firebase/auth"
import { registerNewUser } from "./api/snarf-api"

import { GoogleAuthProvider } from "firebase/auth"

const provider = new GoogleAuthProvider()

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDhiRz9Acm1ITLaWA9UE-0gIcoyhIjC37g",
  authDomain: "snarf-c031c.firebaseapp.com",
  projectId: "snarf-c031c",
  storageBucket: "snarf-c031c.appspot.com",
  messagingSenderId: "798693970651",
  appId: "1:798693970651:web:85ebb70b91c92b16e472fe",
  measurementId: "G-R5911W8FTT",
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider('6Ld921QiAAAAALgthmHps6IC2_lB2WJ3Ad8tEfsh'),
//     isTokenAutoRefreshEnabled: true
//   });

export const logInWithEmailAndPassword = async (
  email: string,
  password: string
) => {
  await signInWithEmailAndPassword(auth, email, password)
}

export const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string,
  emailUpdates: boolean
) => {
  const res = await createUserWithEmailAndPassword(auth, email, password)
  const user = res.user
  await registerNewUser(name, email, user.uid, emailUpdates)
  sendEmailVerification(user)
    .then(() => {})
    .catch((err) => {
      console.error(err)
    })
}

export const registerWithGoogle = async () => {
  const res = await signInWithPopup(auth, provider)
  const credential = GoogleAuthProvider.credentialFromResult(res)

  if (credential) {
    const token = credential.accessToken
    // The signed-in user info.
    const user = res.user

    if (user.email) {
      await registerNewUser(user.displayName, user.email, user.uid, true)
    }
  }
}

export const signInWithGoogle = async () => {
  const res = await signInWithPopup(auth, provider)
  const credential = GoogleAuthProvider.credentialFromResult(res)
}

export const resetPassword = async (email: string) => {
  await sendPasswordResetEmail(auth, email)
}

export const logout = async () => {
  await signOut(auth)
}
