import { gql } from "urql"

export const pointSkiWeatherQuery = gql`
  query ($pointId: ID!, $startDate: String, $endDate: String, $type: Int) {
    point(id: $pointId, type: $type) {
      id
      name
      lat
      lon
      isFavorite
      noaaElevation
      avalanche {
        zone
        zoneId
        notificationsEnabled
        danger {
          lower
          middle
          upper
          valid_day
        }
        bottomLine
        forecastAvalancheProblems {
          icon
          likelihood
        }
        url
        expires
      }
      dailyWeatherSummary(startDate: $startDate) {
        date
        daytime {
          temperature
          windDirection
          windSpeed
          shortForecast
        }

        nighttime {
          temperature
          windDirection
          windSpeed
          shortForecast
        }

        skiOutlook {
          cornDay
        }
      }

      hourlyForecast(startDate: $startDate, endDate: $endDate) {
        date
        snowfallAmount
        snowLevel
      }

      potentialCornDays

      forecastDiscussion {
        id
        officeId
        issuanceTime
        rawText
      }
    }
  }
`
