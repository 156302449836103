import { gql } from "urql"

export const userFavoritesQuery = gql`
  query ($type: Int) {
    user {
      id
      email
      favorites(type: $type) {
        name
        id
        lat
        lon
        isFavorite
        type
        potentialCornDays
        rockClimbingSummary {
          date
          rating
          avgCloudCover
          precip
          maxProbabilityOfPrecip
          maxTemp
          minTemp
        }
      }
    }
  }
`
