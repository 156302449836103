import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  useTheme,
  Checkbox,
} from "@mui/material"
import { error } from "console"
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import { registerWithEmailAndPassword, registerWithGoogle } from "../firebase"

export function Register() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [emailOptIn, setEmailOptIn] = useState(false)
  const navigate = useNavigate()
  const theme = useTheme()
  const register = () => {
    registerWithEmailAndPassword(email, email, password, emailOptIn)
      .then(() => {
        navigate("/home")
      })
      .catch((error) => {
        console.error(error)
        setError(error)
      })
  }

  const registerGoogle = () => {
    registerWithGoogle()
      .then(() => {
        navigate("/home")
      })
      .catch((error) => {
        console.error(error)
        setError(error)
      })
  }
  return (
    <>
      <Box
        display="flex"
        sx={{ color: theme.palette.primary.main, mt: 2 }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h3">Snarf</Typography>
      </Box>

      <Box display="flex" justifyContent="center" sx={{ textAlign: "center" }}>
        <Typography sx={{ maxWidth: "75%" }}>
          Create an account to save spots and get notifications.
        </Typography>
      </Box>

      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ mt: 1 }}
      >
        <Grid item xs={12}>
          <TextField
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail Address"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="password"
            className="login__textBox"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="center"
            sx={{ textAlign: "center" }}
            alignItems="center"
          >
            <Checkbox
              checked={emailOptIn}
              onChange={(event, checked) => setEmailOptIn(checked)}
            />
            <Typography>I want email updates</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={register}>
            {" "}
            Sign Up
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ textAlign: "center" }}>
            Already have an account? <Link to="/login">Login</Link> now.
          </Box>
        </Grid>
        <Grid item xs={12}>
          {error ? (
            <Typography color="red">{error.toString()}</Typography>
          ) : null}
        </Grid>
      </Grid>
    </>
  )
}
