import { gql } from "urql"

export const skiingAreasWithinQuery = gql`
  query skiingAreasWithinQuery($filters: Filters) {
    skiAreas(filters: $filters) {
      id
      name
      type
      point {
        id
        name
        isFavorite
        lat
        lon
        rockClimbingSummary {
          date
          rating
          avgCloudCover
          precip
          maxProbabilityOfPrecip
          maxTemp
          minTemp
        }

        forecastedSnow

        dailyTotals {
          date
          totalSnow
        }
      }
    }
  }
`
