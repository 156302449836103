import { Box, Paper, Typography } from "@mui/material"
import React from "react"
import { Camera } from "../api/models"
import { CameraCard } from "./CameraCard"

export interface CameraListProps {
  cameras: Camera[]
}

export function CameraList({ cameras }: CameraListProps) {
  return (
    <>
      {cameras.length > 0 ? (
        cameras.map((cam) => (
          <Box py={1} key={cam.id}>
            <CameraCard camera={cam} />
          </Box>
        ))
      ) : (
        <Box display="flex" justifyContent={"center"} py={2}>
          <Paper sx={{ p: 2 }}>
            <Typography>No cameras found within 30 miles</Typography>
          </Paper>
        </Box>
      )}
    </>
  )
}
