import React from "react"
import Rating from "@mui/material/Rating"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import { styled } from "@mui/material/styles"

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#00A9EB",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
})

export interface SnowRatingProps {
  rating: number
}
export function SnowRating(props: SnowRatingProps) {
  const ratingFlag = false
  let render = null
  if (ratingFlag) {
    render = (
      <StyledRating
        name="customized-color"
        readOnly
        defaultValue={props.rating}
        precision={0.5}
        icon={<AcUnitIcon fontSize="inherit" />}
        emptyIcon={<AcUnitIcon fontSize="inherit" />}
      />
    )
  }
  return render
}
