import { Box } from "@mui/material"
import React from "react"
import { Camera, CameraDisplayType } from "../../api/models"
import { CameraImage } from "./CameraImage"
import { CameraVideo } from "./CameraVideo"

export interface CameraInlineProps {
  camera: Camera
}

export function CameraInline({ camera }: CameraInlineProps) {
  const getCameraComponent = (type: CameraDisplayType) => {
    switch (type) {
      case CameraDisplayType.IMAGE:
        return <CameraImage camera={camera} />

      case CameraDisplayType.VIDEO:
        return <CameraVideo camera={camera} />

      default:
        break
    }
  }
  return (
    <>
      <Box>{getCameraComponent(camera.displayType)}</Box>
    </>
  )
}
