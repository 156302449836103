import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import { cToF, mmToIn, mToFt } from "../../utilities/conversion"
import NorthIcon from "@mui/icons-material/North"
import { getWindSpeedStyle } from "../../utilities/styling"
import { Station } from "../../api/models"
import { stationMetrics } from "../StationViewer"

export interface StationHourlyTableProps {
  observations: any[]
  spotElevation?: number
  station: Station
}

const rowHeadStyle = {
  minWidth: "100px",
  fontWeight: "bold",
  position: "sticky",
  left: 0,
  background: "white",
  zIndex: 3,
  p: 1,
}

const cellStyle = {
  p: 1,
  textAlign: "center",
}

export const StationHourlyTable = ({
  observations,
  spotElevation,
  station,
}: StationHourlyTableProps) => {
  const theme = useTheme()

  const stationId = station.id
  const sensorVariables = station ? JSON.parse(station.sensorVariables) : {}

  const getCellContent = (config: any, value: any) => {
    const converted = config.conversionFunction
      ? config.conversionFunction(value)
      : value

    if (config.component) {
      return config.component(converted)
    }

    return converted
  }

  const getObservationValue = (obs: any, key: string) => {
    const setKey = key.replace("value", "set")
    if (obs[setKey]) {
      return obs[setKey]
    }

    return obs[key]
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={rowHeadStyle}></TableCell>
              {observations.map((cast) => (
                <TableCell
                  sx={{ cellStyle }}
                  key={`header${cast.date}${stationId}`}
                >
                  <Typography>{dayjs(cast.date).format("ddd HH")}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(sensorVariables)
              .filter((key) => stationMetrics[key])
              .map((key, index) => {
                if (!stationMetrics[key]) {
                  return null
                }

                const config = stationMetrics[key]
                const variableInfo = sensorVariables[key]
                const variableKey = Object.keys(variableInfo)[0]
                return (
                  <TableRow
                    key={config.title}
                    sx={
                      index % 2 === 0
                        ? { "&:last-child td, &:last-child th": { border: 0 } }
                        : {
                            "&:last-child td, &:last-child th": { border: 0 },
                            backgroundColor: theme.palette.action.disabled,
                          }
                    }
                  >
                    <TableCell sx={rowHeadStyle} component="th" scope="row">
                      <Typography sx={{ textAlign: "center" }}>
                        {config.title} ({config.units})
                      </Typography>
                    </TableCell>
                    {observations.map((cast) => (
                      <TableCell
                        sx={cellStyle}
                        key={`${cast.date}${stationId}temp`}
                        component="td"
                      >
                        {getCellContent(
                          config,
                          getObservationValue(cast, variableKey)
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
