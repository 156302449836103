import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import React from "react"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import { Link } from "react-router-dom"

import { PointSummary } from "../models/models"
import { MiniDailyRainBarChart } from "./MiniDailyRainBarChart"
import { RainIcon } from "../icons/RainIcon"
import { MiniDailyWeatherIcons } from "./MiniDailyWeatherIcons"
import { getPreviousRain } from "../utilities/analysis"
import { mmToIn } from "../utilities/conversion"
import WaterDropIcon from "@mui/icons-material/WaterDrop"

export interface RockCardProps {
  pointData: PointSummary
  onClick?: (point: PointSummary) => any
  setIsFavorite?: (pointId: string, isFavorite: boolean) => any
  link?: boolean
}

export function RockCard({
  pointData,
  onClick,
  setIsFavorite,
  link,
}: RockCardProps) {
  const theme = useTheme()
  const onFavorite = (event: any, isFavorite: boolean) => {
    event.stopPropagation()
    if (setIsFavorite) {
      setIsFavorite(pointData.id, isFavorite)
    }
  }

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        minWidth: "255px",
      }}
      onClick={() => (onClick ? onClick(pointData) : null)}
    >
      <CardContent sx={{ flexGrow: 1, mt: 1 }}>
        <Grid container alignItems="center">
          <Grid item xs={10} display="flex" alignItems="center">
            <Typography noWrap variant="h5" component="h2">
              {link ? (
                <Link to={`/point/${pointData.id}`}>{pointData.name}</Link>
              ) : (
                pointData.name
              )}
            </Typography>
          </Grid>

          <Grid
            item
            xs={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {pointData.isFavorite ? (
              <Tooltip title="Save to my spots">
                <IconButton
                  style={{ padding: 0, marginBottom: 1 }}
                  onClick={(event) => onFavorite(event, false)}
                >
                  <StarIcon sx={{ color: "gold" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Save to my spots">
                <IconButton
                  style={{ padding: 0, marginBottom: 1 }}
                  onClick={(event) => onFavorite(event, true)}
                >
                  <StarBorderIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>

        {pointData.processing ? (
          <CircularProgress />
        ) : (
          <Box>
            <Box display="flex" alignItems="center">
              <WaterDropIcon
                color={
                  mmToIn(getPreviousRain(pointData.rockClimbingSummary), 100) >
                  0
                    ? "primary"
                    : "disabled"
                }
              />
              <Typography>
                Past 2 days:{" "}
                {mmToIn(getPreviousRain(pointData.rockClimbingSummary), 100)} In
              </Typography>
            </Box>

            <Box
              display={"flex"}
              justifyContent="center"
              alignItems="center"
              pt={1}
              pb={2}
            >
              <RainIcon
                fill={theme.palette.primary.main}
                height="20px"
                width="20px"
              />
              <Typography pl={1}>Rain</Typography>
            </Box>
            <Box sx={{ ml: "-55px" }}>
              <MiniDailyRainBarChart pointData={pointData} />
            </Box>

            <Box>
              <Box
                height="120px"
                display="flex"
                flexDirection={"column"}
                justifyContent="flex-end"
              >
                <MiniDailyWeatherIcons point={pointData} />
              </Box>
            </Box>
          </Box>
        )}
        {pointData.processing ? <CircularProgress /> : <Box></Box>}
      </CardContent>
    </Card>
  )
}
