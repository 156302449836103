import {
  Box,
  Card,
  Container,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import React from "react"
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  Tooltip as ChartTooltip,
  Bar,
  BarChart,
  YAxis,
  CartesianGrid,
} from "recharts"
import { HourlyForecast } from "../models/models"
import {
  mmToIn,
  mToFt,
  windDirectionToNorthRotation,
} from "../utilities/conversion"
import NorthIcon from "@mui/icons-material/North"
import SouthIcon from "@mui/icons-material/South"
import AcUnitIcon from "@mui/icons-material/AcUnit"

import { getWindSpeedStyle } from "../utilities/styling"
import { SnowRating } from "./SnowRating"
import { MoonIcon } from "../icons/MoonIcon"
import { getWeatherIcon } from "../utilities/helpers"
import { isNumber } from "lodash"

export function WeatherTile(props: any) {
  const {
    hourlyForecast,
    nightForecast,
    dayForecast,
    date,
    maxSnowLevel,
    minSnowLevel,
    totalSnow,
    totalSnowEvening,
    totalSnowMorning,
    elevation,
    morningMaxWindGust,
    eveningMaxWindGust,
    morningMaxWindSpeed,
    eveningMaxWindSpeed,
    rating,
    isCornDay,
    showHistoricalOverview,
  } = props
  const theme = useTheme()

  const getGraphDate = (data: HourlyForecast) => {
    return dayjs(Number(data.date)).format("HH")
  }

  const getTitleDate = (date: Dayjs) => {
    if (date.date() === dayjs().date()) {
      return "Today"
    } else if (date.date() === dayjs().date() - 1) {
      return "Yesterday"
    } else if (date.date() === dayjs().date() + 1) {
      return "Tomorrow"
    } else {
      return date.format("dddd")
    }
  }

  const isToday = (date: Dayjs) => {
    return date.date() === dayjs().date()
  }

  return (
    <Card sx={{ width: "250px", m: 1, pt: 1, borderStyle: "solid", borderWidth: "1px", borderColor: `${(showHistoricalOverview && !isToday(dayjs(date))) ? theme.palette.secondary.main : "white"}` }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box display="flex" flexDirection={"column"} alignItems="center">
          <Typography>{getTitleDate(dayjs(date))}</Typography>
          <Typography>{dayjs(date).format("MMM-DD")}</Typography>
        </Box>

        {isCornDay ? (
          <img
            src="/corn.png"
            style={{ width: "auto", height: "25px" }}
            alt=""
          />
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        pb={2}
      >
        <SnowRating rating={rating} />
      </Box>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle2">Day</Typography>
            </Grid>
            <Grid item>
              <Box sx={{ width: "50px", height: "50px" }}>
                {getWeatherIcon(dayForecast?.shortForecast, true)}
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {dayForecast ? dayForecast.temperature : ""}&#176;F
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 1 }}>
              <Box sx={{ display: "flex" }}>
                <Tooltip title={dayForecast ? dayForecast.windDirection : ""}>
                  <NorthIcon
                    sx={{
                      transform: `rotate(${windDirectionToNorthRotation(
                        dayForecast ? dayForecast.windDirection : ""
                      )}deg)`,
                    }}
                  />
                </Tooltip>
                <Typography
                  style={getWindSpeedStyle(morningMaxWindSpeed, theme)}
                  variant="subtitle2"
                >
                  {dayForecast ? dayForecast.windSpeed : ""}{" "}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Typography>
                <Box sx={{ display: "flex" }}>
                  <AcUnitIcon
                    color={
                      mmToIn(totalSnowMorning) > 0 ? "primary" : "disabled"
                    }
                    sx={{ mr: 1 }}
                  />
                  <Typography>{mmToIn(totalSnowMorning)} in</Typography>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle2">Night</Typography>
            </Grid>
            <Grid item>
              <Box sx={{ width: "50px", height: "50px" }}>
                {getWeatherIcon(nightForecast?.shortForecast, false)}
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="subtitle2">
                {nightForecast ? nightForecast.temperature : ""}&#176;F
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 1 }}>
              <Box sx={{ display: "flex" }}>
                <Tooltip
                  title={nightForecast ? nightForecast.windDirection : ""}
                >
                  <NorthIcon
                    sx={{
                      transform: `rotate(${windDirectionToNorthRotation(
                        nightForecast ? nightForecast.windDirection : ""
                      )}deg)`,
                    }}
                  />
                </Tooltip>

                <Typography
                  style={getWindSpeedStyle(eveningMaxWindSpeed, theme)}
                  variant="subtitle2"
                >
                  {nightForecast ? nightForecast.windSpeed : ""}{" "}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Typography>
                <Box sx={{ display: "flex" }}>
                  <AcUnitIcon
                    color={
                      mmToIn(totalSnowEvening) > 0 ? "primary" : "disabled"
                    }
                    sx={{ mr: 1 }}
                  />
                  <Typography>{mmToIn(totalSnowEvening)} in</Typography>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"column"}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        marginBottom={2}
      >
        <Grid item width="100%" textAlign={"center"}>
          <Typography sx={{ pt: 2 }}>Hourly Snow</Typography>
          <Box sx={{ ml: "-16px" }}>
            <ResponsiveContainer width="90%" height={100}>
              <BarChart data={hourlyForecast || []}>
                <Bar
                  unit=" in"
                  dataKey={(data: HourlyForecast) =>
                    mmToIn(data.snowfallAmount, 100)
                  }
                  fill={
                    (showHistoricalOverview && !isToday(dayjs(date)))
                    ? theme.palette.secondary.main
                    : theme.palette.primary.main}
                />
                <XAxis
                  dataKey={getGraphDate}
                  type="number"
                  ticks={[6, 12, 18]}
                />
                <CartesianGrid vertical={false} strokeDasharray={"3 3"} />
                <YAxis unit={'"'} domain={[0, 1]} type="number" ticks={[0.5]} />
                <ChartTooltip />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
        <Grid item textAlign={"center"} width="100%">
          <Typography>Snow Level:</Typography>
          <Box display="flex" justifyContent={"center"}>
            <Typography>
              <NorthIcon sx={{ color: "red", fontSize: 15 }} /> {maxSnowLevel}{" "}
              ft
            </Typography>
            <Typography sx={{ ml: 1 }}>
              <SouthIcon sx={{ color: "green", fontSize: 15 }} /> {minSnowLevel}{" "}
              ft
            </Typography>
          </Box>
        </Grid>
        <Grid item width={"100%"}>
          <Box sx={{ ml: "-16px" }}>
            <ResponsiveContainer width="90%" height={100}>
              <LineChart data={hourlyForecast || []}>
                <Line
                  type="monotone"
                  unit="ft"
                  dot={false}
                  dataKey={(data) => mToFt(data.snowLevel)}
                  stroke={
                    (showHistoricalOverview && !isToday(dayjs(date)))
                      ? theme.palette.secondary.main
                      : theme.palette.primary.main}
                />
                {/* <Line
                  type="monotone"
                  unit="ft"
                  color="black"
                  dot={false}
                  dataKey={() => mToFt(elevation)}
                /> */}
                <CartesianGrid vertical={false} strokeDasharray={"3 3"} />
                <XAxis
                  dataKey={getGraphDate}
                  type="number"
                  ticks={[6, 12, 18]}
                />
                <YAxis
                  domain={[0, 10000]}
                  type="number"
                  ticks={[5000]}
                  tickFormatter={(value) => value / 1000 + "k"}
                />
                <ChartTooltip />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}
