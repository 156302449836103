import {
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import {
  PointOfInterest,
  PointSummary,
  RockClimbingSummary,
} from "../models/models"
import Map, {
  Layer,
  MapboxEvent,
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
  MapRef,
  Marker,
  Popup,
  Source,
  ViewStateChangeEvent,
} from "react-map-gl" // eslint-disable-line import/no-webpack-loader-syntax
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { MAPBOX_TOKEN } from "../api/utilities"
import { Box } from "@mui/system"
import {
  Camera,
  RockStatus,
  SkiArea,
  SkiAreaType,
  Station,
  Subscription,
  UserPointOfInterestType,
} from "../api/models"
import { ActivityContext, UserPremiumContext } from "../pages/Header"
import { MapControl, MapMode } from "./MapControl"
import { gql, useQuery as useGraphqlQuery } from "urql"
import {
  getAreasNearQuery,
  pointQuery,
  stationsQuery,
  userFavoritesQuery,
} from "../graphql/queries"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import dayjs from "dayjs"
import { DateCarousol } from "./DateCarousol"
import { RockDaySnapshot } from "./RockDaySnapshot"
import {
  Add,
  ArrowBack,
  ChevronLeft,
  ChevronRight,
  Layers,
  Place,
  Remove,
} from "@mui/icons-material"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { PointDetail } from "./PointDetail"
import { LoginModal } from "./LoginModal"
import { MapLayers } from "./Map/models"
import mapboxgl from "mapbox-gl"
import { getDefaultMapConfig } from "../utilities/activity-helpers"
import { climbingAreasWithinQuery } from "../graphql/queries/climbing-areas-within"
import { debounce, set } from "lodash"
import { skiingAreasWithinQuery } from "../graphql/queries/skiing-areas-within"
import { createPoint, search, updateFavorite } from "../api/snarf-api"
import { graphqlClient } from "../graphql"
import { updateFavoriteMutation } from "../graphql/mutations"
import { StationViewer } from "./StationViewer"
import { camerasWithinQuery } from "../graphql/queries/cameras-within"
import { CameraCard } from "./CameraCard"
import { CameraViewer } from "./CameraViewer"
import { MiniInfo } from "./Map/MiniInfo"
import { sendEvent, SnarfEvents } from "../utilities/metrics"
import { useQuery } from "@tanstack/react-query"
import { getAvalancheGeojson } from "../api/avalanche-api"
import { mmToIn } from "../utilities/conversion"
import {
  getRawSkiAreaMetricValue,
  getSkiAreaMetricConfig,
  getSkiAreaMetricValue,
  getStationMetricConfig,
} from "../utilities/map-metrics"
import { AvalancheDanger } from "./AvalancheDanger"
import { avalancheByIdQuery } from "../graphql/queries/avalanche-by-center-and-zone"
import { userSubsQuery } from "../graphql/queries/user-subs"
import { SubscribeModal } from "./SubscribeModal"
import { pointsQuery } from "../graphql/queries/points"
import { userPremiumQuery } from "../graphql/queries/user-premium"
import { geojsonType } from "@turf/turf"

export interface MapLocation {
  lat: number
  lon: number
  name: string
  color?: string
  id: string
  type?: UserPointOfInterestType
  icon?: () => ReactElement
  popover?: () => any
}
export interface MapProps {
  locations?: MapLocation[]
  mapConfig: any
  controlsClosed?: boolean
  mapKey?: { name: string; active: boolean; id: string }[]
  onLocationClick?: (location: any) => any
  center?: { lat: number; lon: number } | null
  zoom?: number
  noCenterMarker?: boolean
  noMapClick?: boolean
  onZoomChange?: () => any
  onSessionMapConfigChange?: (newConfig: any) => any
}

export function SnarfMap(props: MapProps) {
  const [clickedPoint, setClickedPoint] = useState<any>(null)
  const [clickedStation, setClickedStation] = useState<any | null>(null)
  const [clickedAvvyZone, setClickedAvvyZone] = useState<any | null>(null)
  const [clickedCameraId, setClickedCameraId] = useState<string | null>(null)
  const [showPointForecast, setShowPointForecast] = useState(false)
  const [clickedLocation, setClickedLocation] = useState<MapLocation | null>(
    null
  )
  const userIsPremium = useContext(UserPremiumContext)

  const [mapBounds, setMapBounds] = useState<any[] | null>(null)
  const [selectedDay, setSelectedDay] = useState(dayjs())

  const [user, loadingAuthState] = useAuthState(auth)

  const [searchParams, setSearchParams] = useSearchParams()
  const [userLocation, setUserLocation] = useState<{
    lat: number
    lon: number
  } | null>(null)

  const location = useLocation()
  const [backLocation, setBackLocation] = useState<string | null>(null)

  const isSmall = useMediaQuery("(max-width:600px)")

  const selectedActivity = useContext(ActivityContext)

  const [currentHoveredFeature, setCurrentHoveredFeature] =
    useState<MapboxGeoJSONFeature | null>(null)

  const [showControls, setShowControls] = useState(
    props.controlsClosed ? false : true
  )

  const [mapConfigActivity, setMapConfigActivity] = useState(selectedActivity)

  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const [showSubscribePopup, setShowSubscribePopup] = useState(false)
  const [subscribeModalMessage, setSubscribeModalMessage] = useState("")

  const [hoverInfo, setHoverInfo] = useState<any>(null)
  const { center, mapConfig } = props
  const navigate = useNavigate()
  const mapRef = useRef<MapRef | null>(null)
  const mapRefCallback = useCallback((ref: MapRef | null) => {
    if (ref !== null) {
      //Set the actual ref we use elsewhere
      mapRef.current = ref
      const map = ref

      const coords = []
      coords.push(map.getBounds().getNorthWest().toArray())
      coords.push(map.getBounds().getNorthEast().toArray())
      coords.push(map.getBounds().getSouthEast().toArray())
      coords.push(map.getBounds().getSouthWest().toArray())
      coords.push(map.getBounds().getNorthWest().toArray())

      setMapBounds(coords)

      const loadImage = () => {
        ;[
          { name: "climber-green", url: "/climber-green-small.png" },
          { name: "climber-yellow", url: "/climber-yellow-small.png" },
          { name: "climber-red", url: "/climber-red-small.png" },
          { name: "skier-icon", url: "/skier.png" },
          { name: "snowboarder-icon", url: "/snowboarder-circle-black.png" },
          { name: "station-icon", url: "/station-icon-purple.png" },
          { name: "camera-icon", url: "/camera-icon-cinnamon.png" },
          { name: "backcountry-icon", url: "/backcountry-circle-black.png" },
          { name: "label-icon", url: "/map-label.png" },
          { name: "station-label-icon", url: "/station-map-label.png" },
        ].forEach((entry) => {
          const { name, url } = entry
          if (!map.hasImage(name)) {
            if (mapRef.current) {
              const map = mapRef.current.getMap()

              map.loadImage(url, (error: any, image: any) => {
                if (error) {
                  console.error("map image load error error")
                }
                if (!map.hasImage(name))
                  map.addImage(name, image, { sdf: false })
              })
            } else {
              console.log("no map current could not load")
            }
          }
        })
      }

      loadImage()

      //TODO need this?
      map.on("styleimagemissing", (e) => {
        const id = e.id // id of the missing image
        loadImage()
      })
    }
  }, [])

  useEffect(() => {
    if (!searchParams.get("pointForecast") && showPointForecast) {
      setShowPointForecast(false)
    }

    if (!searchParams.get("viewCamera") && clickedCameraId) {
      setClickedCameraId(null)
    }

    if (!searchParams.get("viewStation") && clickedStation) {
      setClickedStation(null)
    }

    if (!backLocation && location.state?.previous) {
      setBackLocation(location.state.previous)
    }

    const selectedPointId = searchParams.get("selectedPointId")
    if (
      selectedPointId !== null &&
      selectedPointId !== mapConfig[MapLayers.SELECTED_POINT]?.pointId
    ) {
      mapConfig[MapLayers.SELECTED_POINT] = {
        enabled: !!selectedPointId,
        pointId: selectedPointId,
      }

      if (selectedPointId) {
        const [lat, lon] = selectedPointId.split("|")
        mapConfig[MapLayers.SELECTED_POINT].location = {
          lat: Number(lat),
          lon: Number(lon),
        }
        mapRef.current?.panTo({ lat: Number(lat), lng: Number(lon) })
      }
    }
  }, [
    searchParams,
    showPointForecast,
    selectedActivity,
    mapConfigActivity,
    mapConfig,
    clickedCameraId,
    clickedStation,
    backLocation,
    location?.state?.previous,
  ])

  const [viewState, setViewState] = React.useState(
    props.mapConfig?.viewState || {
      longitude: center ? center.lon : -94.46131,
      latitude: center ? center.lat : 43.65173,
      zoom: props.zoom ? props.zoom : 11,
    }
  )
  const theme = useTheme()

  const [userSubsRequest] = useGraphqlQuery({
    query: userSubsQuery,
    variables: {},
    pause: !user,
  })

  const [nearMeRequest, reexecuteQuery] = useGraphqlQuery({
    query: getAreasNearQuery({
      includeClimbingAreas:
        selectedActivity === UserPointOfInterestType.ROCK_CLIMB,
      includeSkiAreas: selectedActivity === UserPointOfInterestType.SKI,
    }),
    variables: {
      ...mapConfig[MapLayers.NEAR_ME],
      ...mapConfig[MapLayers.NEAR_ME]?.location,
      selectedActivity,
    },
    pause:
      !mapConfig[MapLayers.NEAR_ME] || !mapConfig[MapLayers.NEAR_ME].enabled,
  })

  const [climbingInBoundsRequest] = useGraphqlQuery({
    query: climbingAreasWithinQuery,
    variables: {
      filters: {
        within: {
          bounds: mapBounds,
        },
      },
    },
    pause:
      !mapConfig[MapLayers.CLIMBING_AREAS] ||
      !mapConfig[MapLayers.CLIMBING_AREAS].enabled,
  })

  const [userQuery] = useGraphqlQuery({
    query: userPremiumQuery,
    pause: loadingAuthState || !user,
  })

  const [skiingInBoundsRequest] = useGraphqlQuery({
    query: skiingAreasWithinQuery,
    variables: {
      filters: {
        within: {
          bounds: mapBounds,
        },
      },
    },
    pause:
      !mapConfig[MapLayers.SKI_AREAS] ||
      !mapConfig[MapLayers.SKI_AREAS].enabled ||
      !mapBounds,
  })

  const [stationsInBoundsRequest] = useGraphqlQuery({
    query: stationsQuery,
    variables: {
      filters: {
        within: {
          bounds: mapBounds,
        },
      },
      type: selectedActivity,
    },
    pause:
      !mapConfig[MapLayers.STATIONS] ||
      !mapConfig[MapLayers.STATIONS].enabled ||
      !mapBounds,
  })

  const [camerasInBoundsRequest] = useGraphqlQuery({
    query: camerasWithinQuery,
    variables: {
      filters: {
        within: {
          bounds: mapBounds,
        },
      },
    },
    pause:
      !mapConfig[MapLayers.CAMERAS] ||
      !mapConfig[MapLayers.CAMERAS].enabled ||
      !mapBounds,
  })

  const [userFavRequest] = useGraphqlQuery({
    query: pointsQuery,
    variables: {
      filters: {
        isFavorite: true,
        type: selectedActivity,
      },
      type: selectedActivity,
    },
    pause: loadingAuthState,
  })

  const [pointRequest] = useGraphqlQuery({
    query: pointQuery,
    variables: {
      pointId: mapConfig[MapLayers.SELECTED_POINT]?.pointId,
      type: selectedActivity,
    },
    pause:
      !mapConfig[MapLayers.SELECTED_POINT] ||
      !mapConfig[MapLayers.SELECTED_POINT].enabled,
  })

  const avvyLayerRequest = useQuery({
    queryKey: ["avalanche-layer"],
    queryFn: (): any => getAvalancheGeojson(),
  })

  if (
    pointRequest.data?.point &&
    mapConfig[MapLayers.SELECTED_POINT] &&
    mapConfig[MapLayers.SELECTED_POINT].enabled &&
    !mapConfig[MapLayers.SELECTED_POINT].name
  ) {
    const newMapConfig = { ...mapConfig }
    newMapConfig[MapLayers.SELECTED_POINT].name = pointRequest.data.point.name
  }

  const loading =
    nearMeRequest.fetching ||
    userFavRequest.fetching ||
    climbingInBoundsRequest.fetching ||
    skiingInBoundsRequest.fetching ||
    camerasInBoundsRequest.fetching ||
    avvyLayerRequest.isLoading ||
    stationsInBoundsRequest.fetching

  const layerGeoJson = useMemo(() => {
    let geoJson: any = { type: "FeatureCollection", features: [] }
    const getStaticIcons = (icon: string) => {
      let day = dayjs()
      const icons: any = {}
      while (day.isBefore(dayjs().add(9, "days"))) {
        icons[day.format("YYYY-MM-DD")] = icon
        day = day.add(1, "day")
      }

      return icons
    }

    const getRatingIcons = (point: PointSummary, area?: any) => {
      // HACK icon selection for now
      let resortIcon = "snowboarder-icon"
      let backcountryIcon = "backcountry-icon"
      const isResort = area && area.type === "RESORT"
      if (selectedActivity === UserPointOfInterestType.SKI) {
        // HACK in skier for now
        const summary = point.rockClimbingSummary
        if (!summary) {
          return {}
        }
        const ratings: any = {}
        summary.forEach((sum: RockClimbingSummary) => {
          if (sum?.date) {
            const day = dayjs(Number(sum.date)).format("YYYY-MM-DD")
            let icon = isResort ? resortIcon : backcountryIcon
            ratings[day] = icon
          }
        })

        return ratings
      } else {
        const summary = point.rockClimbingSummary
        if (!summary) {
          return {}
        }
        const ratings: any = {}
        summary.forEach((sum: RockClimbingSummary) => {
          const day = dayjs(Number(sum.date)).format("YYYY-MM-DD")
          let icon = "climber-red"
          if (sum.rating === RockStatus.YES) {
            icon = "climber-green"
          } else if (sum.rating === RockStatus.MAYBE) {
            icon = "climber-yellow"
          }

          ratings[day] = icon
        })

        return ratings
      }
    }

    let index = 0

    if (
      mapConfig[MapLayers.NEAR_ME] &&
      mapConfig[MapLayers.NEAR_ME].enabled &&
      nearMeRequest.data?.areasNear
    ) {
      let areas = nearMeRequest.data.areasNear.climbingAreas
      let icon = "climber-icon"
      let iconSize = 0.25
      if (selectedActivity === UserPointOfInterestType.SKI) {
        areas = nearMeRequest.data.areasNear.skiAreas
        icon = "snowboarder-icon"
      }

      if (areas) {
        const features = areas.map((area: any) => {
          const ratings = getRatingIcons(area.point, area)
          index = index + 1
          return {
            type: "Feature",
            id: index,
            properties: {
              name: area.name,
              id: area.id,
              pointId: area.point.id,
              ...ratings,
              rockSummary: area.point?.rockClimbingSummary,
              icon,
              iconSize,
            },
            geometry: {
              type: "Point",
              coordinates: [area.point.lon, area.point.lat],
            },
          }
        })
        geoJson.features.push(...features)
      }
    }

    if (
      mapConfig[MapLayers.FAVORITES] &&
      mapConfig[MapLayers.FAVORITES].enabled &&
      userFavRequest.data?.points
    ) {
      let icon = "climber-icon"
      const iconSize = 0.25
      if (selectedActivity === UserPointOfInterestType.SKI) {
        icon = "snowboarder-icon"
      }

      const features = userFavRequest.data?.points.map(
        (point: PointSummary, index: number) => {
          const ratings = getRatingIcons(point)
          index = index + 1
          return {
            type: "Feature",
            id: index,
            properties: {
              name: point.name,
              id: point.id,
              pointId: point.id,
              icon,
              iconSize,
              ...ratings,
              rockSummary: point.rockClimbingSummary,
            },
            geometry: {
              type: "Point",
              coordinates: [point.lon, point.lat],
            },
          }
        }
      )

      geoJson.features.push(...features)
    }

    // if (
    //   mapConfig[MapLayers.CLIMBING_AREAS] &&
    //   mapConfig[MapLayers.CLIMBING_AREAS].enabled &&
    //   climbingInBoundsRequest.data?.climbingAreas
    // ) {
    //   let areas = climbingInBoundsRequest.data.climbingAreas
    //   let icon = "climber-icon"
    //   let iconSize = 0.25

    //   if (areas) {
    //     const features = areas.map((area: any) => {
    //       const ratings = getRatingIcons(area.point)
    //       index = index + 1
    //       return {
    //         type: "Feature",
    //         id: index,
    //         properties: {
    //           name: area.name,
    //           id: area.id,
    //           pointId: area.point.id,
    //           ...ratings,
    //           rockSummary: area.point?.rockClimbingSummary,
    //           icon,
    //           iconSize,
    //         },
    //         geometry: {
    //           type: "Point",
    //           coordinates: [area.point.lon, area.point.lat],
    //         },
    //       }
    //     })
    //     geoJson.features.push(...features)
    //   }
    // }

    if (
      mapConfig[MapLayers.CAMERAS] &&
      mapConfig[MapLayers.CAMERAS].enabled &&
      camerasInBoundsRequest.data?.cameras
    ) {
      let cameras = camerasInBoundsRequest.data.cameras
      let icon = "camera-icon"
      let iconSize = 0.25
      const ratings = getStaticIcons(icon)
      if (cameras) {
        const features = cameras.map((camera: Camera) => {
          index = index + 1
          return {
            type: "Feature",
            id: index,
            properties: {
              name: camera.name,
              id: camera.id,
              icon,
              type: "CAMERA",
              ...ratings,
              iconSize,
            },
            geometry: {
              type: "Point",
              coordinates: [camera.lon, camera.lat],
            },
          }
        })
        geoJson.features.push(...features)
      }
    }

    if (geoJson.features.length === 0) {
      return null
    }

    return geoJson
  }, [
    mapConfig,
    nearMeRequest?.data?.areasNear,
    userFavRequest.data?.points,
    selectedActivity,
    camerasInBoundsRequest.data?.cameras,
  ])

  const stationGeoJson = useMemo(() => {
    let geoJson: any = { type: "FeatureCollection", features: [] }

    if (
      mapConfig[MapLayers.STATIONS] &&
      mapConfig[MapLayers.STATIONS].enabled &&
      stationsInBoundsRequest.data?.stations
    ) {
      let stations = stationsInBoundsRequest.data.stations
      let icon = "station-icon"

      let iconSize = 0.25
      let features = []
      if (stations) {
        if (
          mapConfig[MapLayers.STATIONS].metric &&
          mapConfig[MapLayers.STATIONS].metric !== "none"
        ) {
          const metric = mapConfig[MapLayers.STATIONS].metric
          features = stations
            .filter(
              (station: any) =>
                station[metric] !== null && station[metric] !== undefined
            )
            .map((station: any) => {
              const config = getStationMetricConfig(metric)
              icon = "station-label-icon"
              iconSize = 0.5

              return {
                type: "Feature",
                id: station.id,
                properties: {
                  name: station.name,
                  id: station.id,
                  station,
                  icon,
                  type: "STATION",
                  iconSize,
                  text:
                    station[metric] !== null && station[metric] !== undefined
                      ? config.conversion(station[metric]) + " " + config.units
                      : "--",
                  textOffset: [0, 0.5],
                  textColor: "white",
                },
                geometry: {
                  type: "Point",
                  coordinates: [station.lon, station.lat],
                },
              }
            })
        } else {
          features = stations.map((station: any) => {
            return {
              type: "Feature",
              properties: {
                name: station.name,
                id: station.id,
                station,
                icon,
                type: "STATION",
                textOffset: [0, 3],
                text: station.name,
                iconSize,
              },
              geometry: {
                type: "Point",
                coordinates: [station.lon, station.lat],
              },
            }
          })
        }

        geoJson.features.push(...features)
      }
    }

    return geoJson
  }, [mapConfig, stationsInBoundsRequest.data?.stations])

  const { skiAreasGeoJson, labelsGeoJson } = useMemo(() => {
    let geoJson: any = { type: "FeatureCollection", features: [] }
    let labelsGeoJson: any = { type: "FeatureCollection", features: [] }

    if (
      mapConfig[MapLayers.SKI_AREAS] &&
      mapConfig[MapLayers.SKI_AREAS].enabled &&
      skiingInBoundsRequest.data?.skiAreas
    ) {
      let skiAreas = skiingInBoundsRequest.data.skiAreas
      let icon = "snowboarder-icon"

      let iconSize = 0.25
      let textSize = 12
      let features = []
      let labels = []
      if (skiAreas) {
        if (
          mapConfig[MapLayers.SKI_AREAS].metric &&
          mapConfig[MapLayers.SKI_AREAS].metric !== "none"
        ) {
          features = skiAreas.map((area: any) => {
            icon = "label-icon"
            iconSize = 0.7
            textSize = 14

            const sortKey =
              -getRawSkiAreaMetricValue(
                area.point,
                mapConfig[MapLayers.SKI_AREAS]
              ) - 2

            return {
              type: "Feature",
              id: area.id,
              properties: {
                name: area.name,
                id: area.id,
                area: area,
                icon,
                type: "SKI_AREA",
                iconSize,
                sortKey,
                pointId: area.point.id,
                text: getSkiAreaMetricValue(
                  area.point,
                  mapConfig[MapLayers.SKI_AREAS]
                ),
                textOffset: [0, 0.5],
                textColor: "white",
                textSize,
              },
              geometry: {
                type: "Point",
                coordinates: [area.point.lon, area.point.lat],
              },
            }
          })

          labels = skiAreas.map((area: any) => {
            const sortKey = -getRawSkiAreaMetricValue(
              area.point,
              mapConfig[MapLayers.SKI_AREAS]
            )
            icon = "label-icon"
            iconSize = 0.5

            return {
              type: "Feature",
              id: "label-" + area.id,
              properties: {
                name: area.name,
                id: "label-" + area.id,
                area: area,
                // icon,
                type: "SKI_AREA_LABEL",
                // iconSize,sortKey,
                sortKey,
                pointId: area.point.id,
                text: area.name,
                textOffset: [0, 3],
                textColor: "black",
              },
              geometry: {
                type: "Point",
                coordinates: [area.point.lon, area.point.lat],
              },
            }
          })
        } else {
          features = skiAreas.map((area: any) => {
            const isResort = area && area.type === "RESORT"
            icon = isResort ? "snowboarder-icon" : "backcountry-icon"
            return {
              type: "Feature",
              properties: {
                name: area.name,
                id: area.id,
                area: area,
                pointId: area.point.id,
                icon,
                type: "SKI_AREA",
                textOffset: [0, 3],
                text: area.name,
                iconSize,
              },
              geometry: {
                type: "Point",
                coordinates: [area.point.lon, area.point.lat],
              },
            }
          })
        }

        geoJson.features.push(...features)
        labelsGeoJson.features.push(...labels)
      }
    }

    return { skiAreasGeoJson: geoJson, labelsGeoJson }
  }, [mapConfig, skiingInBoundsRequest.data?.skiAreas])

  const interactiveLayerIds = useMemo(() => {
    const layers = []
    if (
      mapConfig[MapLayers.STATIONS] &&
      mapConfig[MapLayers.STATIONS].enabled
    ) {
      layers.push("stations")
    }

    if (
      mapConfig[MapLayers.AVALANCHE] &&
      mapConfig[MapLayers.AVALANCHE].enabled
    ) {
      layers.push("avalanche")
    }

    if (
      mapConfig[MapLayers.SKI_AREAS] &&
      mapConfig[MapLayers.SKI_AREAS].enabled
    ) {
      layers.push("skiAreas")
      layers.push("skiAreasLabels")
    }

    if (
      (mapConfig[MapLayers.CLIMBING_AREAS] &&
        mapConfig[MapLayers.CLIMBING_AREAS].enabled) ||
      (mapConfig[MapLayers.CAMERAS] && mapConfig[MapLayers.CAMERAS].enabled) ||
      (mapConfig[MapLayers.FAVORITES] && mapConfig[MapLayers.FAVORITES].enabled)
    ) {
      layers.push("points")
    }

    return layers
  }, [mapConfig])

  const onMapClick = async (event: MapLayerMouseEvent) => {
    if (event.features && event.features.length > 0) {
      if (event.features[0].properties) {
        if (event.features[0].properties.type === "STATION") {
          setClickedStation(event.features[0].properties.id)
          searchParams.set("viewStation", "true")
          setSearchParams(searchParams)
          sendEvent(SnarfEvents.MapViewStation)
        } else if (event.features[0].properties.type === "CAMERA") {
          setClickedCameraId(event.features[0].properties.id)
          searchParams.set("viewCamera", "true")
          setSearchParams(searchParams)
          sendEvent(SnarfEvents.MapViewCamera)
        } else if (event.features[0].properties.pointId) {
          sendEvent(SnarfEvents.MapViewPointForecast)

          onViewPointWeather(event.features[0].properties.pointId, true)
        } else if (event.features[0].properties.center_id) {
          const avvyForecast = await graphqlClient
            .query(avalancheByIdQuery, {
              zoneId: event.features[0].id,
            })
            .toPromise()

          setClickedAvvyZone(avvyForecast.data.avalancheForecast)
        } else if (event.features[0].properties.link) {
          window.location.href = event.features[0].properties.link
        }
      }
    } else if (!props.noMapClick && !currentHoveredFeature) {
      // show pop up
      const { lat, lng } = event.lngLat
      createPoint(
        event.lngLat.lat,
        event.lngLat.lng,
        `${lat.toString().substring(0, 6)}, ${lng.toString().substring(0, 6)}`
      ).then((point) => {
        searchParams.set("selectedPointId", point.id)
        setSearchParams(searchParams)
      })
    } else if (currentHoveredFeature) {
      setCurrentHoveredFeature(null)
      setHoverInfo(null)
    }
  }

  const onViewPointWeather = (pointId: string, overridePremium?: boolean) => {
    // if not premium show subscribe modal
    if (userIsPremium || overridePremium) {
      setClickedPoint({ id: pointId })
      setShowPointForecast(true)
      searchParams.set("pointForecast", "true")
      setSearchParams(searchParams)
    } else {
      setSubscribeModalMessage("Subscribe to get unlimited point forecasts.")
      setShowSubscribePopup(true)
    }
  }

  const markers = useMemo(() => {
    const onLocationClick = (
      event: MapboxEvent<any>,
      location: MapLocation
    ) => {
      setClickedLocation(location)
      if (props.onLocationClick) {
        props.onLocationClick(location)
      }
      event?.originalEvent?.stopPropagation()
    }

    if (props.locations) {
      return props.locations.map((location) => (
        <Marker
          key={location.id}
          longitude={location.lon}
          latitude={location.lat}
          anchor="center"
          onClick={(event) => onLocationClick(event, location)}
        >
          {location.icon ? (
            location.icon()
          ) : (
            <Marker
              longitude={location.lon}
              latitude={location.lat}
              anchor="center"
              color="red"
            />
          )}
        </Marker>
      ))
    }
  }, [props])

  const onMapMove = (evt: ViewStateChangeEvent) => {
    setViewState(evt.viewState)
    setHoverInfo(null)
    setCurrentHoveredFeature(null)
    const newMapConfig = { ...mapConfig }
    newMapConfig.viewState = evt.viewState
    if (props.onSessionMapConfigChange) {
      props.onSessionMapConfigChange(newMapConfig)
    }

    if (mapRef.current) {
      const coords = []
      coords.push(mapRef.current.getBounds().getNorthWest().toArray())
      coords.push(mapRef.current.getBounds().getNorthEast().toArray())
      coords.push(mapRef.current.getBounds().getSouthEast().toArray())
      coords.push(mapRef.current.getBounds().getSouthWest().toArray())
      coords.push(mapRef.current.getBounds().getNorthWest().toArray())

      debounceSetBounds(coords)
    }
  }

  const debounceSetBounds = useCallback(
    debounce((bounds) => {
      setMapBounds(bounds)
    }, 500),
    []
  )

  const onHover = useCallback(
    (event: MapLayerMouseEvent) => {
      const {
        features,
        point: { x, y },
      } = event

      if (!isSmall) {
        if (selectedActivity === UserPointOfInterestType.ROCK_CLIMB) {
          if (currentHoveredFeature) {
            mapRef.current?.setFeatureState(currentHoveredFeature, {
              hovered: false,
            })
          }
          const hoveredFeature = features && features[0]

          if (hoveredFeature && hoveredFeature.properties) {
            mapRef.current?.setFeatureState(hoveredFeature, { hovered: true })
            const info = {
              name: hoveredFeature.properties.name,
              x,
              y,
              rockSummary: JSON.parse(
                hoveredFeature.properties.rockSummary
              ).find(
                (sum: RockClimbingSummary) =>
                  dayjs(Number(sum.date)).format("YYYY-MM-DD") ===
                  selectedDay.format("YYYY-MM-DD")
              ),
            }

            // prettier-ignore
            setHoverInfo(hoveredFeature && info)
            setCurrentHoveredFeature(hoveredFeature)
          } else if (currentHoveredFeature) {
            setHoverInfo(null)
            setCurrentHoveredFeature(null)
          }
        }
      }
    },
    [currentHoveredFeature, selectedActivity, selectedDay]
  )

  const onMapControlUpdate = (layerId: MapLayers, payload: any) => {
    switch (layerId) {
      case MapLayers.NEAR_ME: {
        const newMapConfig = { ...mapConfig }
        // Object.keys(newMapConfig).forEach((layer) => {
        //   newMapConfig[layer].enabled = false
        // })
        newMapConfig[MapLayers.NEAR_ME] = { ...payload, enabled: true }
        if (props.onSessionMapConfigChange) {
          props.onSessionMapConfigChange(newMapConfig)
        }
        setUserLocation(payload.location)

        mapRef.current?.panTo(payload.location)
        break
      }
      case MapLayers.STATIONS: {
        const newMapConfig = { ...mapConfig }
        // Object.keys(newMapConfig).forEach((layer) => {
        //   newMapConfig[layer].enabled = false
        // })
        newMapConfig[MapLayers.STATIONS] = { ...payload, enabled: true }
        if (props.onSessionMapConfigChange) {
          props.onSessionMapConfigChange(newMapConfig)
        }
        break
      }
      case MapLayers.SKI_AREAS: {
        const newMapConfig = { ...mapConfig }
        // Object.keys(newMapConfig).forEach((layer) => {
        //   newMapConfig[layer].enabled = false
        // })
        newMapConfig[MapLayers.SKI_AREAS] = { ...payload, enabled: true }
        if (props.onSessionMapConfigChange) {
          props.onSessionMapConfigChange(newMapConfig)
        }
        break
      }

      default:
        break
    }
  }

  const onMapControlToggle = (layerId: MapLayers) => {
    sendEvent(SnarfEvents.MapLayerSwitch, { layerId })
    const newMapConfig = { ...mapConfig }
    if (!newMapConfig[layerId]) {
      newMapConfig[layerId] = {}
    }

    mapRef.current?.moveLayer("points")
    if (layerId === MapLayers.FAVORITES && !user) {
      // show login modal?
      setShowLoginPopup(true)
      return
    }

    newMapConfig[layerId].enabled = !newMapConfig[layerId].enabled
    if (props.onSessionMapConfigChange) {
      props.onSessionMapConfigChange(newMapConfig)
    }
  }

  const onSetFavorite = (pointId: string, isFavorite: boolean) => {
    if (user) {
      const favsCount = userFavRequest.data?.points
        ? userFavRequest.data.points.length
        : 0
      if (!userIsPremium && favsCount >= 3 && isFavorite) {
        setSubscribeModalMessage(
          "You've reached the maximum number of favorites as a free user. Consider subscribing to get unlimited favorites and more."
        )
        setShowSubscribePopup(true)
      } else {
        sendEvent(SnarfEvents.Favorite)

        graphqlClient
          .mutation(updateFavoriteMutation, {
            pointId: pointId,
            isFavorite,
            type: selectedActivity,
          })
          .toPromise()
      }
    } else {
      setShowLoginPopup(true)
    }
  }

  const onLocate = ({ lat, lon }: { lat: number; lon: number }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng: lon })
    }
  }

  const onRemoveMarker = () => {
    const newMapConfig = { ...mapConfig }
    newMapConfig[MapLayers.SELECTED_POINT] = null

    searchParams.delete("selectedPointId")
    setSearchParams(searchParams)
    if (props.onSessionMapConfigChange) {
      props.onSessionMapConfigChange(newMapConfig)
    }
  }

  const onZoomIn = () => {
    if (mapRef.current) {
      mapRef.current.zoomIn()
    }
  }

  const onZoomOut = () => {
    if (mapRef.current) {
      mapRef.current.zoomOut()
    }
  }

  return (
    <>
      <LoginModal
        open={showLoginPopup}
        onClose={() => {
          setShowLoginPopup(false)
        }}
      />
      <SubscribeModal
        message={"Start a free trial to checkout Maps and more with Snarf Pro."}
        open={
          showSubscribePopup ||
          !user ||
          (userQuery.data &&
            (!userQuery.data.user || !userQuery.data.user.isPremium))
        }
        onClose={() => setShowSubscribePopup(false)}
        noClose={
          !userQuery.data ||
          !userQuery.data.user ||
          !userQuery.data.user.isPremium
        }
      />

      <Map
        ref={mapRefCallback}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/outdoors-v12"
        {...viewState}
        onMove={onMapMove}
        onMouseMove={onHover}
        style={{ width: "100%", height: "100%" }}
        onClick={onMapClick}
        interactiveLayerIds={interactiveLayerIds}
      >
        <Box mt={3} ml={3} zIndex={10}>
          <IconButton
            sx={{ backgroundColor: "white" }}
            onClick={() => navigate(backLocation || "/intro")}
          >
            <ArrowBack fontSize="large" />
          </IconButton>
        </Box>

        {!isSmall && (
          <Box
            position={"absolute"}
            style={{ right: 410, bottom: 10 }}
            zIndex={10}
            display="flex"
            flexDirection={"column"}
          >
            <Box py={1}>
              <IconButton sx={{ backgroundColor: "white" }} onClick={onZoomIn}>
                <Add />
              </IconButton>
            </Box>

            <IconButton sx={{ backgroundColor: "white" }} onClick={onZoomOut}>
              <Remove />
            </IconButton>
          </Box>
        )}

        {selectedActivity === UserPointOfInterestType.ROCK_CLIMB && (
          <Box zIndex={5} py={2}>
            <DateCarousol
              selectedDate={selectedDay}
              onDateChange={(date) => setSelectedDay(date)}
              minDate={dayjs()}
              maxDate={dayjs().add(7, "days")}
            />
          </Box>
        )}

        {selectedActivity === UserPointOfInterestType.ROCK_CLIMB &&
          mapConfig.FAVORITES?.enabled &&
          userQuery.data?.favorites?.length === 0 && (
            <Box zIndex={5} py={2}>
              <Typography></Typography>
            </Box>
          )}

        {loading ? (
          <Box
            display="flex"
            height="100%"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="primary" />
          </Box>
        ) : null}

        {mapRef.current &&
        avvyLayerRequest.data &&
        avvyLayerRequest.data.data ? (
          <>
            <Source
              id="avalanche-layers"
              type="geojson"
              data={avvyLayerRequest.data.data}
            >
              <Layer
                id="avalanche"
                type="fill"
                paint={{
                  "fill-color": ["get", "color"],
                  "fill-opacity": ["get", "fillOpacity"],
                  "fill-outline-color": ["get", "stroke"],
                }}
                layout={
                  mapConfig[MapLayers.AVALANCHE] &&
                  mapConfig[MapLayers.AVALANCHE].enabled
                    ? { visibility: "visible" }
                    : { visibility: "none" }
                }
                // layout={{
                //   "icon-image": ["get", selectedDay.format("YYYY-MM-DD")],
                //   "icon-size": ["get", "iconSize"],
                //   "icon-allow-overlap":
                //     mapRef.current.getZoom() > 15 ? true : false,
                //   "text-allow-overlap":
                //     mapRef.current.getZoom() > 15 ? true : false,
                //   "text-field": ["get", "name"],
                //   "text-size": 10,
                //   "text-anchor": "bottom",
                //   "text-offset": [0, 4],
                // }}
              />
            </Source>
          </>
        ) : null}

        {mapRef.current && layerGeoJson ? (
          <>
            <Source id="my-data" type="geojson" data={layerGeoJson}>
              <Layer
                id="points"
                type="symbol"
                layout={{
                  "icon-image": ["get", selectedDay.format("YYYY-MM-DD")],
                  "icon-size": ["get", "iconSize"],
                  "icon-allow-overlap":
                    mapRef.current.getZoom() > 15 ? true : false,
                  "text-allow-overlap":
                    mapRef.current.getZoom() > 15 ? true : false,
                  "text-field": ["get", "name"],
                  "text-size": 10,
                  "text-anchor": "bottom",
                  "text-offset": [0, 3],
                }}
              />
            </Source>
          </>
        ) : null}

        {mapRef.current && stationGeoJson ? (
          <>
            <Source id="stations-source" type="geojson" data={stationGeoJson}>
              <Layer
                id="stations"
                type="symbol"
                paint={{ "text-color": ["get", "textColor"] }}
                layout={{
                  "icon-image": ["get", "icon"],
                  "icon-size": ["get", "iconSize"],
                  "icon-allow-overlap":
                    mapRef.current.getZoom() > 15 ? true : false,
                  "text-allow-overlap":
                    mapRef.current.getZoom() > 15 ? true : false,
                  "text-field": ["get", "text"],
                  "text-size": 12,
                  "text-anchor": "bottom",
                  "text-offset": ["get", "textOffset"],
                }}
              />
            </Source>
          </>
        ) : null}

        {mapRef.current &&
        mapConfig[MapLayers.SKI_AREAS] &&
        mapConfig[MapLayers.SKI_AREAS].enabled &&
        skiAreasGeoJson ? (
          <>
            <Source id="ski-areas-source" type="geojson" data={skiAreasGeoJson}>
              <Layer
                id="skiAreas"
                type="symbol"
                paint={{ "text-color": ["get", "textColor"] }}
                layout={{
                  "icon-image": ["get", "icon"],
                  "icon-size": ["get", "iconSize"],
                  "icon-allow-overlap": true,
                  // mapRef.current.getZoom() > 15 ? true : false,
                  "text-allow-overlap": true,
                  // mapRef.current.getZoom() > 15 ? true : false,
                  "text-field": ["get", "text"],
                  "symbol-sort-key": ["get", "sortKey"],
                  "text-size": ["get", "textSize"],
                  "text-anchor": "bottom",
                  "text-offset": ["get", "textOffset"],
                }}
              />
            </Source>
          </>
        ) : null}

        {mapRef.current && labelsGeoJson ? (
          <>
            <Source
              id="ski-areas-labels-source"
              type="geojson"
              data={labelsGeoJson}
            >
              <Layer
                id="skiAreasLabels"
                type="symbol"
                paint={{ "text-color": ["get", "textColor"] }}
                layout={{
                  "text-allow-overlap": false,
                  "text-field": ["get", "text"],
                  "text-size": 14,
                  "text-optional": true,
                  "symbol-sort-key": ["get", "sortKey"],
                  "text-anchor": "bottom",
                  "text-offset": ["get", "textOffset"],
                }}
              />
            </Source>
          </>
        ) : null}

        {hoverInfo && (
          <Box
            position={"absolute"}
            style={{ left: hoverInfo.x, top: hoverInfo.y + 10, zIndex: 5 }}
            p={1}
          >
            <Paper sx={{ zIndex: 5, p: 2 }}>
              <Box
                display="flex"
                justifyContent={"center"}
                flexDirection="column"
                alignItems="center"
              >
                <Typography>{hoverInfo.name}</Typography>
                <RockDaySnapshot rockSummary={hoverInfo.rockSummary} />
              </Box>
            </Paper>
          </Box>
        )}

        {markers}

        {clickedLocation && clickedLocation.popover ? (
          <Popup
            longitude={clickedLocation.lon}
            latitude={clickedLocation.lat}
            anchor="top"
            onClose={() => setClickedLocation(null)}
            closeOnClick={true}
            maxWidth="none"
          >
            {clickedLocation.popover()}
          </Popup>
        ) : null}

        {userLocation &&
        mapConfig[MapLayers.NEAR_ME] &&
        mapConfig[MapLayers.NEAR_ME].enabled ? (
          <Marker
            longitude={userLocation.lon}
            latitude={userLocation.lat}
            anchor="center"
          >
            <Marker
              longitude={userLocation.lon}
              latitude={userLocation.lat}
              anchor="center"
              color={theme.palette.primary.main}
            />

            <Typography pt={2} fontWeight="bold">
              You are here
            </Typography>
          </Marker>
        ) : null}

        {mapConfig[MapLayers.SELECTED_POINT] &&
        mapConfig[MapLayers.SELECTED_POINT].enabled &&
        pointRequest.data ? (
          <Marker
            longitude={mapConfig[MapLayers.SELECTED_POINT].location.lon}
            latitude={mapConfig[MapLayers.SELECTED_POINT].location.lat}
            anchor="center"
            onClick={() => {
              setClickedPoint({
                id: mapConfig[MapLayers.SELECTED_POINT].pointId,
              })
              setShowPointForecast(true)
              searchParams.set("pointForecast", "true")
              setSearchParams(searchParams)
            }}
          >
            <Marker
              longitude={mapConfig[MapLayers.SELECTED_POINT].location.lon}
              latitude={mapConfig[MapLayers.SELECTED_POINT].location.lat}
              anchor="center"
              color="red"
            />
            <Typography pt={2} fontWeight="bold">
              {pointRequest.data?.point?.name}
            </Typography>
          </Marker>
        ) : null}

        {clickedPoint ? (
          <>
            <Drawer open={showPointForecast} anchor="bottom">
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                alignItems={"center"}
                onClick={() => {
                  setShowPointForecast(false)
                  navigate(-1)
                }}
              >
                <IconButton>
                  <ChevronLeft />
                </IconButton>
                <Typography fontWeight={"bold"}>Back</Typography>
              </Box>
              {clickedPoint ? (
                <PointDetail
                  pointId={clickedPoint.id}
                  setIsFavorite={onSetFavorite}
                />
              ) : null}
            </Drawer>
          </>
        ) : null}

        {clickedStation ? (
          <>
            <Drawer open={true} anchor="bottom">
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                alignItems={"center"}
                onClick={() => {
                  setClickedStation(null)
                  navigate(-1)
                }}
              >
                <IconButton>
                  <ChevronLeft />
                </IconButton>
                <Typography fontWeight={"bold"}>Back</Typography>
              </Box>
              <Container maxWidth="lg">
                <StationViewer stationId={clickedStation} />
              </Container>
            </Drawer>
          </>
        ) : null}

        {clickedAvvyZone ? (
          <>
            <Drawer open={true} anchor="bottom">
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                alignItems={"center"}
                onClick={() => {
                  setClickedAvvyZone(null)
                }}
              >
                <IconButton>
                  <ChevronLeft />
                </IconButton>
                <Typography fontWeight={"bold"}>Back</Typography>
              </Box>
              <Container maxWidth="lg" sx={{ py: 2 }}>
                <AvalancheDanger
                  danger={clickedAvvyZone.danger}
                  url={clickedAvvyZone.url}
                  expires={clickedAvvyZone.expires}
                  bottomLine={clickedAvvyZone.bottomLine}
                  zone={clickedAvvyZone.zone}
                  zoneId={clickedAvvyZone.zoneId}
                  notificationToggleEnabled
                  notificationsEnabled={userSubsRequest.data?.user?.subscriptions.find(
                    (sub: Subscription) => sub.zoneId === clickedAvvyZone.zoneId
                  )}
                />
              </Container>
            </Drawer>
          </>
        ) : null}

        {clickedCameraId ? (
          <>
            <Drawer open={true} anchor="bottom">
              <Box height="100vh">
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  alignItems={"center"}
                  onClick={() => {
                    setClickedCameraId(null)
                    navigate(-1)
                  }}
                >
                  <IconButton>
                    <ChevronLeft />
                  </IconButton>
                  <Typography fontWeight={"bold"}>Back</Typography>
                </Box>
                <Container maxWidth="lg">
                  <CameraViewer cameraId={clickedCameraId} />
                </Container>
              </Box>
            </Drawer>
          </>
        ) : null}

        {isSmall ? (
          <Box
            sx={{
              zIndex: 5,
              position: "fixed",
              bottom: "0px",
              width: "100vw",
            }}
          >
            <Paper sx={{ p: 1, width: "100%", borderRadius: 0 }} elevation={0}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={"60px"}
                onClick={() => setShowControls(!showControls)}
              >
                <IconButton style={{ padding: 0, marginBottom: 1 }}>
                  <Layers fontSize="large" htmlColor="black" />
                </IconButton>
                <Typography fontWeight={"bold"}>Layers</Typography>
              </Box>
            </Paper>
          </Box>
        ) : (
          <Box
            sx={{
              zIndex: 5,
              position: "absolute",
              top: "0px",
              right: "0px",
            }}
          >
            <Paper sx={{ p: 1 }}>
              <IconButton
                style={{ padding: 0, marginBottom: 1 }}
                onClick={() => setShowControls(!showControls)}
              >
                {showControls ? (
                  <ChevronRight fontSize="large" htmlColor="black" />
                ) : (
                  <ChevronLeft fontSize="large" htmlColor="black" />
                )}
              </IconButton>
            </Paper>
          </Box>
        )}
      </Map>

      {pointRequest.data?.point &&
      mapConfig[MapLayers.SELECTED_POINT]?.enabled ? (
        <MiniInfo
          point={pointRequest.data.point}
          onLocate={onLocate}
          onRemoveMarker={onRemoveMarker}
          onViewForecast={(pointId) => onViewPointWeather(pointId)}
          onFavorite={onSetFavorite}
        />
      ) : null}

      <MapControl
        isOpen={showControls}
        onClose={() => setShowControls(false)}
        onUpdate={onMapControlUpdate}
        onLayerToggle={onMapControlToggle}
        config={mapConfig}
      />
    </>
  )
}
