import { Box, useTheme } from "@mui/material"
import React from "react"

export function RockClimberCircle() {
  return (
    <Box
      sx={{
        width: 25,
        height: 25,
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <img
        style={{ width: "25px", height: "25px" }}
        src="/climber.png"
        alt=""
      />
    </Box>
  )
}
