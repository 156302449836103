import { gql } from "urql"

export const pointsHomeQuery = gql`
  query pointsQuery(
    $filters: Filters
    $startDate: String
    $endDate: String
    $type: Int!
  ) {
    points(filters: $filters, type: $type) {
      id
      name
      lat
      lon
      isFavorite
      noaaElevation
      hourlyForecast(startDate: $startDate, endDate: $endDate) {
        date
        snowLevel
      }

      avalanche {
        danger {
          lower
          middle
          upper
          valid_day
        }
        url
        expires
      }

      potentialCornDays

      forecastedSnowTotals(startDay: $startDate, endDay: $endDate) {
        date
        totalSnow
      }

      rockClimbingSummary {
        date
        rating
        avgCloudCover
        precip
        maxProbabilityOfPrecip
        maxTemp
        minTemp
      }

      dailyTotals {
        date
        totalSnow
      }
    }
  }
`
