import {
  Box,
  Card,
  Container,
  Grid,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  Tooltip as ChartTooltip,
  Bar,
  BarChart,
  YAxis,
  CartesianGrid,
} from "recharts"
import { HourlyForecast } from "../models/models"
import {
  mmToIn,
  mToFt,
  windDirectionToNorthRotation,
} from "../utilities/conversion"
import NorthIcon from "@mui/icons-material/North"
import { getWindSpeedStyle } from "../utilities/styling"
import { SnowRating } from "./SnowRating"
import WaterDropIcon from "@mui/icons-material/WaterDrop"
import { getWeatherIcon } from "../utilities/helpers"

export function RockWeatherTile(props: any) {
  const {
    hourlyForecast,
    nightForecast,
    dayForecast,
    date,
    totalPrecipMorning,
    totalPrecipEvening,
    morningMaxWindSpeed,
    eveningMaxWindSpeed,
    rating,
  } = props
  const theme = useTheme()

  const getGraphDate = (data: HourlyForecast) => {
    return dayjs(data.date).format("HH")
  }

  const getTileHeader = () => {
    const today = dayjs(new Date())

    if (today.isSame(date, "day")) {
      return "Today"
    }

    return dayjs(date).format("dddd")
  }

  return (
    <Card sx={{ width: "250px", m: 1, pt: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>{getTileHeader()}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        pb={2}
      >
        <SnowRating rating={rating} />
      </Box>
      <Grid container justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle2">Morning</Typography>
            </Grid>
            <Grid item>
              <Box sx={{ width: "50px", height: "50px" }}>
                {getWeatherIcon(dayForecast?.shortForecast, true)}
              </Box>
            </Grid>

            <Grid item>
              <Typography variant="subtitle2">
                {dayForecast ? dayForecast.temperature : ""}&#176;F
              </Typography>
            </Grid>
            <Grid item sx={{ mt: 1 }}>
              <Box sx={{ display: "flex" }}>
                <Tooltip title={dayForecast ? dayForecast.windDirection : ""}>
                  <NorthIcon
                    sx={{
                      transform: `rotate(${windDirectionToNorthRotation(
                        dayForecast ? dayForecast.windDirection : ""
                      )}deg)`,
                    }}
                  />
                </Tooltip>
                <Typography
                  style={getWindSpeedStyle(morningMaxWindSpeed, theme)}
                  variant="subtitle2"
                >
                  {dayForecast ? dayForecast.windSpeed : ""}{" "}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Typography>
                <Box sx={{ display: "flex" }}>
                  <WaterDropIcon
                    color={
                      mmToIn(totalPrecipMorning, 100) > 0
                        ? "primary"
                        : "disabled"
                    }
                    sx={{ mr: 1 }}
                  />
                  <Typography>{mmToIn(totalPrecipMorning, 100)} in</Typography>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            width={"100%"}
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle2">Evening</Typography>
            </Grid>
            <Grid item>
              <Box sx={{ width: "50px", height: "50px" }}>
                {getWeatherIcon(nightForecast?.shortForecast, false)}
              </Box>
            </Grid>

            <Grid item>
              <Typography variant="subtitle2">
                {nightForecast ? nightForecast.temperature : ""}&#176;F
              </Typography>
            </Grid>

            <Grid item sx={{ mt: 1 }}>
              <Box sx={{ display: "flex" }}>
                <Tooltip
                  title={nightForecast ? nightForecast.windDirection : ""}
                >
                  <NorthIcon
                    sx={{
                      transform: `rotate(${windDirectionToNorthRotation(
                        nightForecast ? nightForecast.windDirection : ""
                      )}deg)`,
                    }}
                  />
                </Tooltip>

                <Typography
                  style={getWindSpeedStyle(eveningMaxWindSpeed, theme)}
                  variant="subtitle2"
                >
                  {nightForecast ? nightForecast.windSpeed : ""}{" "}
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Typography>
                <Box sx={{ display: "flex" }}>
                  <WaterDropIcon
                    color={
                      mmToIn(totalPrecipEvening, 100) > 0
                        ? "primary"
                        : "disabled"
                    }
                    sx={{ mr: 1 }}
                  />
                  <Typography>{mmToIn(totalPrecipEvening, 100)} in</Typography>
                </Box>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction={"column"}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        marginBottom={2}
      >
        <Grid item width="100%" textAlign={"center"}>
          <Typography sx={{ pt: 2 }}>Hourly Precipitation</Typography>
          <Box sx={{ ml: "-16px" }}>
            <ResponsiveContainer width="90%" height={100}>
              <BarChart data={hourlyForecast || []}>
                <Bar
                  unit=" in"
                  dataKey={(data: HourlyForecast) =>
                    mmToIn(data.quantitativePrecipitation, 1000)
                  }
                  fill={theme.palette.primary.main}
                />
                <XAxis
                  dataKey={getGraphDate}
                  type="number"
                  ticks={[6, 12, 18]}
                />
                <CartesianGrid vertical={false} strokeDasharray={"3 3"} />
                <YAxis
                  unit={'"'}
                  domain={[0, 0.2]}
                  type="number"
                  ticks={[0.1]}
                />
                <ChartTooltip />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>

        <Grid item width={"100%"} textAlign="center">
          <Typography sx={{ pt: 2 }}>Probability of Precipitation</Typography>
          <Box sx={{ ml: "-16px" }}>
            <ResponsiveContainer width="90%" height={100}>
              <LineChart data={hourlyForecast || []}>
                <Line
                  type="monotone"
                  unit="%"
                  dot={false}
                  dataKey={(data: HourlyForecast) =>
                    data.probabilityOfPrecipitation
                  }
                />

                <CartesianGrid vertical={false} strokeDasharray={"3 3"} />
                <XAxis
                  dataKey={getGraphDate}
                  type="number"
                  ticks={[6, 12, 18]}
                />
                <YAxis
                  domain={[0, 100]}
                  type="number"
                  ticks={[25, 50, 75]}
                  unit="%"
                />
                <ChartTooltip />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}
