import { gql } from "urql"

export const updateStationFavoriteMutation = gql`
  mutation UpdateStationFavorite($stationId: ID!, $type: Int!, $isFavorite: Boolean!) {
    updateStationFavorite(stationId: $stationId, type: $type, isFavorite: $isFavorite) {
      id
      name
      STID
      lon
      lat
      elevation
      sensorVariables
      isFavorite(type: $type)
      snowDepth
      temperature
      windSpeed
      relativeHumidity
    }
  }
`
