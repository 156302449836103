import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@mui/material"
import React, { useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { saveTextFeedback, saveVoteFeedback, search } from "../api/snarf-api"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"

export function Feedback() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [voteSubmitted, setVoteSubmitted] = React.useState(false)
  const [submittingFeedback, setSubmittingFeedback] = React.useState(false)
  const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false)
  const [feedback, setFeedback] = React.useState("")
  const vote = searchParams.get("vote")
  const [user, loadingAuthState] = useAuthState(auth)

  useEffect(() => {
    if (!loadingAuthState) saveVoteFeedback(vote)
  }, [loadingAuthState, vote])

  const onSubmit = (text: string) => {
    setSubmittingFeedback(true)
    saveTextFeedback(text).then(() => {
      setSubmittingFeedback(false)
      setFeedbackSubmitted(true)
    })
  }
  return (
    <Container maxWidth="lg">
      {searchParams.get("vote") && (
        <Box p={1}>
          <Alert severity="success">
            <Typography>
              Thanks for your response. Feel free to leave additional comments
              below.
            </Typography>
          </Alert>
        </Box>
      )}

      <Box px={1}>
        <Typography>Comments</Typography>
      </Box>
      {feedbackSubmitted ? (
        <Box display="flex" justifyContent={"center"} p={1}>
          <Typography variant="h5">Thank you for your feedback!</Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection={"column"} alignItems="center" p={1}>
          <TextField
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFeedback(event.target.value)
            }}
            value={feedback}
            sx={{ width: "100%" }}
            multiline
            minRows={5}
          ></TextField>
          <Box p={1}>
            <Button
              sx={{ py: 1, maxWidth: 100 }}
              variant="contained"
              disabled={submittingFeedback}
              onClick={() => onSubmit(feedback)}
            >
              Submit
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  )
}
