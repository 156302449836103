import { Box, IconButton, Tooltip } from "@mui/material"
import React from "react"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"

export interface FavoriteProps {
  isFavorite: boolean
  onFavorite: (event: React.MouseEvent, isFavorite: boolean) => any
}

export function Favorite({ isFavorite, onFavorite }: FavoriteProps) {
  return (
    <Box>
      {isFavorite ? (
        <Tooltip title="Save to my favorites">
          <IconButton
            style={{ padding: 0, marginBottom: 1 }}
            onClick={(event) => onFavorite(event, false)}
          >
            <StarIcon sx={{ color: "gold" }} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Save to my favorites">
          <IconButton
            style={{ padding: 0, marginBottom: 1 }}
            onClick={(event) => onFavorite(event, true)}
          >
            <StarBorderIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}
