import { gql } from "urql"

export const userPremiumQuery = gql`
  query ($type: Int) {
    user {
      id
      email
      isPremium
      trialEndDate
      favorites(type: $type) {
        id
      }
    }
  }
`
