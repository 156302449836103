import { useTheme } from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  YAxis,
  LabelList,
} from "recharts"
import { PointSummary } from "../models/models"
import { mmToIn } from "../utilities/conversion"

export interface MiniDailyRainBarChartProps {
  pointData: PointSummary
}

export function MiniDailyRainBarChart({
  pointData,
}: MiniDailyRainBarChartProps) {
  const theme = useTheme()

  let dailyPrecipData: { date: string; total: number }[] = []
  if (pointData?.rockClimbingSummary) {
    dailyPrecipData = pointData.rockClimbingSummary
      .filter((sum) =>
        dayjs(sum.date).isAfter(
          dayjs(Date.now()).startOf("day").subtract(1, "day")
        )
      )
      .map((summary) => {
        return {
          date: summary.date,
          total: summary.precip,
        }
      })
      .sort((a, b) => (a.date > b.date ? 1 : -1))
  }
  return (
    <ResponsiveContainer width="100%" height={120}>
      <BarChart barCategoryGap={4} data={dailyPrecipData}>
        <Bar
          dataKey={(data: any) => mmToIn(data.total, 100)}
          fill={theme.palette.primary.main}
        />
        <ChartTooltip />
        <CartesianGrid vertical={false} strokeDasharray={"3 3"} />
        <XAxis dataKey={(data) => dayjs(data.date).format("dd").slice(0, 1)} />
        <YAxis unit={'"'} domain={[0, 2]} type="number" ticks={[1]} />
      </BarChart>
    </ResponsiveContainer>
  )
}
