import React from "react"
import { Camera } from "../../api/models"

export interface CameraVideoProps {
  camera: Camera
}

export function CameraVideo({ camera }: CameraVideoProps) {
  return (
    <iframe
      width="100%"
      height="315"
      src={camera.url}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  )
}
