import { gql } from "urql"

export const updateFavoriteMutation = gql`
  mutation Mutation($pointId: ID!, $type: Int!, $isFavorite: Boolean!) {
    updateFavorite(pointId: $pointId, type: $type, isFavorite: $isFavorite) {
      id
      isFavorite
    }
  }
`
