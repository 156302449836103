import { Box, Divider, Typography, useTheme } from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import { RainIcon } from "../icons/RainIcon"
import { SunIcon } from "../icons/SunIcon"
import { PointSummary, RockClimbingSummary } from "../models/models"
import CloudQueueIcon from "@mui/icons-material/CloudQueue"
import AirIcon from "@mui/icons-material/Air"
import BoltIcon from "@mui/icons-material/Bolt"
import ThermostatIcon from "@mui/icons-material/Thermostat"

export interface MiniDailyWeatherIconsProps {
  point: PointSummary
}

export function MiniDailyWeatherIcons({ point }: MiniDailyWeatherIconsProps) {
  const theme = useTheme()

  const addWrapper = (element: any) => {
    return (
      <Box
        display="flex"
        justifyContent={"center"}
        alignItems="center"
        height="25px"
      >
        {element}
      </Box>
    )
  }
  const getWeatherIcon = (summary?: RockClimbingSummary) => {
    if (!summary) {
      return null
    }
    if (summary.avgCloudCover < 30 && summary.precip <= 20) {
      return addWrapper(
        <Box mr="-3px" mb="-5px">
          <SunIcon height="25px" width="25px" fill="orange" />
        </Box>
      )
    } else if (summary.avgCloudCover > 20 && summary.precip <= 20) {
      return addWrapper(<CloudQueueIcon fontSize="small" />)
    } else if (summary.precip > 0) {
      return addWrapper(
        <RainIcon
          fill={theme.palette.primary.main}
          height="20px"
          width="20px"
        />
      )
    }
  }

  const getWindSpeedIcon = (windSpeed: number) => {
    if (windSpeed < 5) {
      return addWrapper(<AirIcon fontSize="small" />)
    } else if (windSpeed > 5 && windSpeed < 10) {
      return addWrapper(<AirIcon fontSize="small" />)
    } else if (windSpeed > 10 && windSpeed < 20) {
      return addWrapper(<AirIcon fontSize="small" color="warning" />)
    } else if (windSpeed > 20) {
      return addWrapper(<AirIcon fontSize="small" color="error" />)
    }
  }

  const getLightingIcon = (lightning: number) => {
    if (lightning === 1) {
      return null
    } else if (lightning > 2 && lightning < 4) {
      return addWrapper(<BoltIcon fontSize="small" color="warning" />)
    } else if (lightning > 4) {
      return addWrapper(<BoltIcon fontSize="small" color="error" />)
    }

    return null
  }

  const getTempIcon = (maxTemp: number) => {
    if (maxTemp > 26) {
      return addWrapper(<ThermostatIcon fontSize="small" color="error" />)
    }

    if (maxTemp < 10) {
      return addWrapper(<ThermostatIcon fontSize="small" color="primary" />)
    }

    return null
  }

  return (
    <Box display="flex" justifyContent="space-between">
      {point.rockClimbingSummary
        .sort((a, b) => (a.date > b.date ? -1 : 1))
        .map((summary) => {
          console.log(summary)
          return summary
        })
        .map((summary) => (
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems="center"
            justifyContent={"flex-end"}
          >
            <Box display="flex" alignItems="center">
              {getTempIcon(summary.maxTemp)}
            </Box>
            <Box display="flex" alignItems="center">
              {getLightingIcon(summary.maxLightningActivityLevel)}
            </Box>
            <Box display="flex" alignItems="center">
              {getWindSpeedIcon(summary.avgWindSpeed)}
            </Box>
            <Box display="flex" alignItems="center">
              {getWeatherIcon(summary)}
            </Box>
            <Divider />
            <Box>
              <Typography alignSelf={"flex-end"}>
                {dayjs
                  .unix(summary.date as unknown as number)
                  .format("dd")
                  .slice(0, 1)}
              </Typography>
            </Box>
          </Box>
        ))}
    </Box>
  )
}
