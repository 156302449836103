import { UserPointOfInterestType } from "../api/models"

export const getActivityColor = (activity: UserPointOfInterestType) => {
  switch (activity) {
    case UserPointOfInterestType.SKI:
      return "#1976d2"
    case UserPointOfInterestType.ROCK_CLIMB:
      return "#19d28f"
    default:
      break
  }
}

export const getDefaultMapConfig = (type: UserPointOfInterestType) => {
  switch (type) {
    case UserPointOfInterestType.ROCK_CLIMB:
      const defaultRockConfig = {
        FAVORITES: {
          enabled: false,
        },
        CLIMBING_AREAS: {
          enabled: true,
        },
      }

      return defaultRockConfig

    case UserPointOfInterestType.SKI:
      const defaultSkiConfig = {
        FAVORITES: {
          enabled: false,
        },
        SKI_AREAS: {
          enabled: true,
        },
      }

      return defaultSkiConfig
    default:
      return {
        FAVORITES: {
          enabled: false,
        },
      }
  }
}
