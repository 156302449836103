import { gql } from "urql"

export function getAreasNearQuery({
  includeSkiAreas,
  includeClimbingAreas,
}: {
  includeSkiAreas: boolean
  includeClimbingAreas: boolean
}) {
  const query = `
    query ($lat: Float!, $lon: Float!, $distance: Int!) {
      areasNear(lat: $lat, lon: $lon, miles: $distance) {
        ${
          includeClimbingAreas
            ? `climbingAreas {
            id
            name
            openBetaAreaId
            point {
              id
              name
              isFavorite
              lat
              lon
              rockClimbingSummary {
                date
                rating
                avgCloudCover
                precip
                maxProbabilityOfPrecip
                maxTemp
                minTemp
              }
            }
          }`
            : ""
        }
        
  
        ${
          includeSkiAreas
            ? `skiAreas {
            id
            name
            point {
                id
                name
                isFavorite
                lat
                lon
                potentialCornDays 
                rockClimbingSummary {
                  date
                  rating
                  avgCloudCover
                  precip
                  maxProbabilityOfPrecip
                  maxTemp
                  minTemp
                }
            }
          }`
            : ""
        }
        
      }
    }
  `

  return gql`
    ${query}
  `
}
