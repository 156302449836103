import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
  TableBody,
  Box,
  useMediaQuery,
  TableSortLabel,
  Link as MuiLink,
} from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import React, { useContext, useMemo, useState, UIEvent, useEffect } from "react"
import { Link } from "react-router-dom"
import { RockStatus, UserPointOfInterestType } from "../api/models"
import { PointSummary } from "../models/models"
import { ActivityContext } from "../pages/Header"
import { mmToIn } from "../utilities/conversion"
import { RockDaySnapshot } from "./RockDaySnapshot"
import { SkiDaySnapshot } from "./SkiDaySnapshot"
import { Favorite } from "../components/Favorite"
import SegmentIcon from "@mui/icons-material/Segment"
import WaterDropIcon from "@mui/icons-material/WaterDrop"
import { useRef } from "react"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)
dayjs.extend(timezone)
export interface WhatsInTableProps {
  points: PointSummary[]
  onSetFavorite: (pointId: string, isFavorite: boolean) => any
  onScrollBottom?: () => any
  loadingMore?: boolean
  hideRowHeaders?: boolean
}

export function WhatsInTable({
  points,
  onSetFavorite,
  hideRowHeaders,
  onScrollBottom,
}: WhatsInTableProps) {
  const selectedActivity = useContext(ActivityContext)
  const table = useRef<any | null>(null)
  const isSmall = useMediaQuery("(max-width:600px)")
  const [sortDay, setSortDay] = useState<Dayjs | null>(null)
  const [scrollHeight, setScrollHeight] = useState<number | null>(null)

  const [startDate, setStartDate] = useState<Dayjs>(dayjs.utc(Date.now()))
  const [endDate, setEndDate] = useState<Dayjs>(
    dayjs.utc(Date.now()).add(7, "days")
  )
  const [days, setDays] = useState<Dayjs[]>([])

  useEffect(() => {
    // if (scrollHeight && table.current) {
    //   table.current.scrollTop(scrollHeight)
    // }
  }, [scrollHeight, points])

  const getDays = () => {
    const days: Dayjs[] = []
    let currentDate = startDate.startOf("day").clone().utc()
    while (currentDate.isBefore(endDate)) {
      days.push(currentDate)
      currentDate = currentDate.add(1, "day")
    }

    setDays(days)
    return days
  }

  useMemo(() => getDays(), [startDate, endDate])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getActivityRow = (point: PointSummary) => {
    switch (selectedActivity) {
      case UserPointOfInterestType.SKI:
        if (point.type !== selectedActivity) {
          return null
        }

        return (
          <TableRow
            key={point.name}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {hideRowHeaders ? null : (
              <TableCell
                component="th"
                scope="row"
                sx={{
                  position: "sticky",
                  left: 0,
                  background: "white",
                }}
              >
                <Box display="flex" alignItems="center" id="spot-header">
                  <Typography fontWeight={"bold"} pr={1}>
                    <Link
                      style={{ textDecoration: "none", width: "175px" }}
                      to={`/point/${point.id}`}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          width: "175px",
                        }}
                      >
                        {point.name}
                      </Typography>
                    </Link>
                  </Typography>
                  <Favorite
                    isFavorite={!!point.isFavorite}
                    onFavorite={(event, isFavorite) =>
                      onSetFavorite(point.id, isFavorite)
                    }
                  />
                </Box>
              </TableCell>
            )}

            {days.map((day) => {
              const rockSummary = point.rockClimbingSummary.find((sum) => {
                const dateAsNumber = parseInt(sum.date)
                const containsHyphens = sum.date.includes("-")
                if (containsHyphens) {
                  const isSame = dayjs.utc(sum.date).isSame(day, "day")

                  return isSame
                } else {
                  const isSame = dayjs(dateAsNumber).utc().isSame(day, "day")
                  return isSame
                }
              })

              return (
                <TableCell
                  key={`${point.id}ski${day.toISOString()}`}
                  width="135px"
                >
                  <SkiDaySnapshot
                    point={point}
                    rockSummary={rockSummary}
                    type={selectedActivity}
                    day={day}
                  />
                </TableCell>
              )
            })}
          </TableRow>
        )
      case UserPointOfInterestType.ROCK_CLIMB:
        if (!point.rockClimbingSummary) {
          return null
        }

        let precipLast48 = 0
        if (point.rockClimbingSummary && point.rockClimbingSummary.length > 1) {
          precipLast48 =
            point.rockClimbingSummary[0].precip +
            point.rockClimbingSummary[1].precip
        }

        return (
          <TableRow
            key={point.areaId + "|" + point.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            {hideRowHeaders ? null : (
              <TableCell
                component="th"
                scope="row"
                sx={{
                  position: "sticky",
                  left: 0,
                  background: "white",
                }}
              >
                <Box display="flex" alignItems="center" id="spot-header">
                  <Typography fontWeight={"bold"} pr={1}>
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/point/${point.id}`}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "bold",
                          width: "125px",
                          textOverflow: "elippsis",
                        }}
                      >
                        {point.name}
                      </Typography>
                    </Link>
                  </Typography>
                  <Favorite
                    isFavorite={!!point.isFavorite}
                    onFavorite={(event, isFavorite) =>
                      onSetFavorite(point.id, isFavorite)
                    }
                  />
                </Box>

                {(!point.children || point.children.length === 0) &&
                point.openBetaAreaId ? (
                  <MuiLink
                    target="_blank"
                    rel="noreferrer"
                    href={`https://openbeta.io/crag/${point.openBetaAreaId}`}
                  >
                    Route Info
                  </MuiLink>
                ) : null}

                <Box display="flex" alignItems="center">
                  <WaterDropIcon
                    color={
                      mmToIn(precipLast48, 100) > 0 ? "primary" : "disabled"
                    }
                  />{" "}
                  <Typography>
                    {isSmall
                      ? `${mmToIn(precipLast48, 100)} in`
                      : `past 2 days: ${mmToIn(precipLast48, 100)} in`}
                  </Typography>
                </Box>
              </TableCell>
            )}

            {days.map((day) => {
              const rockSummary = point.rockClimbingSummary.find((sum) => {
                const dateAsNumber = parseInt(sum.date)
                const containsHyphens = sum.date.includes("-")
                if (containsHyphens) {
                  const isSame = dayjs.utc(sum.date).isSame(day, "day")

                  return isSame
                } else {
                  const isSame = dayjs(dateAsNumber).utc().isSame(day, "day")
                  return isSame
                }
              })

              return (
                <TableCell
                  key={`${point.id}rock${day.toISOString()}`}
                  width="135px"
                >
                  <RockDaySnapshot rockSummary={rockSummary} />
                </TableCell>
              )
            })}
          </TableRow>
        )
      default:
        break
    }
  }

  const rows = useMemo(() => {
    const getRatingNumber = (rating: RockStatus) => {
      switch (rating) {
        case RockStatus.YES:
          return 1
        case RockStatus.MAYBE:
          return 0
        case RockStatus.NO:
          return -1
      }
    }
    if (points !== null) {
      let filtered = points
      if (sortDay) {
        filtered.sort((a, b) => {
          const dayA = a.rockClimbingSummary.find((sum) => {
            const dateAsNumber = parseInt(sum.date)
            const containsHyphens = sum.date.includes("-")
            if (containsHyphens) {
              return dayjs(sum.date).isSame(sortDay, "day")
            } else {
              return dayjs(dateAsNumber).isSame(sortDay, "day")
            }
          })

          const dayB = b.rockClimbingSummary.find((sum) => {
            const dateAsNumber = parseInt(sum.date)
            const containsHyphens = sum.date.includes("-")
            if (containsHyphens) {
              return dayjs(sum.date).isSame(sortDay, "day")
            } else {
              return dayjs(dateAsNumber).isSame(sortDay, "day")
            }
          })

          if (dayA && dayB) {
            const ratingA = getRatingNumber(dayA.rating)
            const ratingB = getRatingNumber(dayB.rating)
            return ratingA > ratingB ? -1 : 1
          }
          return 1
        })
      }

      return filtered.map((point) => getActivityRow(point))
    }
  }, [getActivityRow, points, sortDay])

  const onTableScroll = (event: UIEvent<HTMLDivElement>) => {
    const element = event.currentTarget

    const isBottom =
      element.scrollHeight - element.scrollTop === element.clientHeight

    if (isBottom && onScrollBottom) {
      // setScrollHeight(element.scrollHeight)
      // onScrollBottom()
    }
  }

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ overflowX: "auto", maxHeight: "70vh" }}
        onScroll={onTableScroll}
        ref={table}
      >
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          stickyHeader={true}
        >
          <TableHead>
            <TableRow>
              {hideRowHeaders ? null : (
                <TableCell hidden={hideRowHeaders} width={200}></TableCell>
              )}
              {days.map((day) => (
                <TableCell key={day.toISOString()} align="center">
                  <TableSortLabel
                    active={
                      sortDay && sortDay.toISOString() === day.toISOString()
                        ? true
                        : false
                    }
                    direction={"asc"}
                    onClick={() => setSortDay(day)}
                  >
                    <Typography fontWeight={"bold"}>
                      {day.format("ddd")}
                    </Typography>
                    {/* {true ? (
                      <Box component="span" sx={visuallyHidden}>
                        {"desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null} */}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
