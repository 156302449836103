import { Box, Container, Grid, Typography } from "@mui/material"
import React from "react"
import { FAQ } from "./FAQ"
import { Subscribe } from "./subscribe"

export function About() {
  return (
    <>
      <Container maxWidth="lg" sx={{ pb: 2 }}>
        <Box p={2}>
          <Box display="flex" flexDirection={"column"} alignItems={"center"}>
            <Typography variant="h3" fontWeight={"bold"}>
              Snarf
            </Typography>
            <Typography variant="caption">verb, informal</Typography>
          </Box>
          <Box display="flex" justifyContent={"center"}>
            <Typography variant="body1">
              "The act of seeking out low density frozen H2O for the sake of
              personal empowerment."
            </Typography>
          </Box>
        </Box>

        <Grid container>
          <Grid item lg={4} xs={12}>
            <Box>
              <img
                style={{ width: "100%" }}
                src={"/me_ice.jpg"}
                alt=""
                loading="lazy"
              />
            </Box>
          </Grid>
          <Grid sx={{ ml: 2 }} item lg={7} xs={12}>
            <Typography variant="h5">Robby Riley aka Bob</Typography>
            <Typography sx={{ pb: 2 }} variant="subtitle1">
              Slinger of code / Founder
            </Typography>
            <Typography sx={{ pb: 2 }} variant="body1">
              I like to snowboard in the backcountry with my friends because
              it's fun and wholesome. I made this website so that I can plan
              more trips and find the best conditions.
            </Typography>

            <Typography sx={{ pb: 2 }} variant="body1">
              Working for tech companies is a bit soul sucking, so I'm hoping
              this site can be useful enough for others it can be a side gig or
              maybe even full time.
            </Typography>

            <Typography variant="body1">
              If you have feedback or ideas on how this site would be more
              useful, or want to shred in the greater PNW, hit me up at
              bob@snarfme.com.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
