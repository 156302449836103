import { useEffect, useState } from "react"

export function useGeolocate() {
  const [userLocation, setUserLocataion] = useState<any | null>(null)

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocataion({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          })
        },
        (error) => {
          console.error(error)
        }
      )
    }
  }, [])

  return [userLocation]
}
