import { gql } from "urql"

export const updateSubscriptionMutation = gql`
  mutation Mutation($type: String!, $status: String!, $zoneId: Int!) {
    updateSubscription(type: $type, status: $status, zoneId: $zoneId) {
      id
      status
      type
      zoneId
    }
  }
`
