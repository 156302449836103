import {
  Grid,
  Paper,
  Box,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import LandscapeIcon from "@mui/icons-material/Landscape"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import { useAuthState } from "react-firebase-hooks/auth"
import { Station } from "../api/models"
import { auth } from "../firebase"
import { LoginModal } from "./LoginModal"
import { useNavigate } from "react-router-dom"
import { CellTower } from "@mui/icons-material"
import { graphqlClient } from "../graphql"
import { updateStationFavoriteMutation } from "../graphql/mutations"
import { ActivityContext, UserPremiumContext } from "../pages/Header"
import { SubscribeModal } from "./SubscribeModal"

export interface StationDetailHeaderProps {
  station: Station
}

export function StationDetailHeader({ station }: StationDetailHeaderProps) {
  const [user, loadingAuthState] = useAuthState(auth)
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const isSmall = useMediaQuery("(max-width:600px)")
  const navigate = useNavigate()
  const [isFavorite, setIsFavorite] = useState(false)
  const selectedActivity = useContext(ActivityContext)
  const userIsPremium = useContext(UserPremiumContext)
  const [showSubscribePopup, setShowSubscribePopup] = useState(false)

  const onSetFavorite = (isFav: boolean) => {
    if (user && userIsPremium) {
      setIsFavorite(isFav)
      graphqlClient
        .mutation(updateStationFavoriteMutation, {
          stationId: station.id,
          isFavorite: isFav,
          type: selectedActivity,
        })
        .toPromise()
    } else {
      setShowSubscribePopup(true)
    }
  }

  useEffect(() => {
    if (station && station.isFavorite) {
      setIsFavorite(station.isFavorite)
    }
  }, [station])

  const getFavoriteIcon = (isFavorite: boolean) => {
    if (isFavorite) {
      return (
        <Tooltip title="Save to my spots">
          <IconButton
            onClick={() => {
              if (user && userIsPremium) {
                onSetFavorite(!isFavorite)
              } else {
                setShowSubscribePopup(true)
              }
            }}
          >
            <StarIcon sx={{ fontSize: "2rem", color: "gold" }} />
          </IconButton>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title="Save to my stations">
          <IconButton
            onClick={() => {
              if (user && userIsPremium) {
                onSetFavorite(!isFavorite)
              } else {
                setShowSubscribePopup(true)
              }
            }}
          >
            <StarBorderIcon sx={{ fontSize: "2rem" }} />
          </IconButton>
        </Tooltip>
      )
    }
  }

  return (
    <>
      <LoginModal
        open={showLoginPopup}
        onClose={() => setShowLoginPopup(false)}
      />

      <SubscribeModal
        message={
          "You've reached the maximum number of favorites as a free user. Consider subscribing to get unlimited favorites and more."
        }
        open={showSubscribePopup}
        onClose={() => setShowSubscribePopup(false)}
      />
      <Grid item xs={12}>
        <Box
          sx={{
            p: 2,
          }}
        >
          <Box display="flex" justifyContent={"space-between"}>
            <Box>
              <Box
                maxWidth={isSmall ? "250px" : undefined}
                sx={{ display: "flex" }}
                alignItems="center"
              >
                <Tooltip title="This location is a weather station, meaning it takes live measurements such as temperature and snowfall">
                  <CellTower />
                </Tooltip>
                <Typography noWrap variant="h5">
                  {station ? station.name : ""}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", color: "black" }}
                alignItems="center"
                py={1}
              >
                <>
                  <LandscapeIcon />
                  <Typography>
                    {Math.round(station ? station.elevation : 0)} ft
                  </Typography>
                </>
              </Box>
            </Box>
            <Box display="flex" alignItems="flex-start">
              {getFavoriteIcon(isFavorite)}
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  )
}
