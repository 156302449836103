import React, { useEffect, useState } from "react"
import { Grid, Paper, Box, Typography, useTheme, Link } from "@mui/material"
import { WeatherViewer } from "./WeatherViewer"
import { GraphHQ } from "./GraphHQ"
import { HourlyTable } from "./HourlyTable"
import { DailyForecast, HourlyForecast, PointSummary } from "../models/models"
import dayjs from "dayjs"
import { HelpMe } from "./HelpMe"
import { FAQAnchors } from "../pages/FAQ"
import { RockWeatherViewer } from "./RockWeatherViewer"
import { RockHourlyTable } from "./RockHourlyTable"
import { WhatsInTable } from "./WhatsInTable"
import { UserPointOfInterestType } from "../api/models"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { SnarfEvents } from "../utilities/metrics"
export interface RockPointWeatherProps {
  dailyForecast: DailyForecast[]
  hourlyForecast: HourlyForecast[]
  pointId: string
  pointSummary: PointSummary
  onShowHistorical: () => any
  elevation?: number
}

export const RockPointWeather = ({
  dailyForecast,
  hourlyForecast,
  pointId,
  pointSummary,
  elevation,
  onShowHistorical,
}: RockPointWeatherProps) => {
  const theme = useTheme()

  return (
    <>
      <Grid sx={{ px: 0 }} container direction="column">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight={"bold"}>
            What's In
          </Typography>
          <HelpMe tag={FAQAnchors.rockIconGuide} event={SnarfEvents.HelpMeRockIconGuide}/>
        </Box>
        <Box maxWidth={"100%"}>
          <WhatsInTable
            hideRowHeaders={true}
            points={[
              { ...pointSummary, type: UserPointOfInterestType.ROCK_CLIMB },
            ]}
            onSetFavorite={function (pointId: string, isFavorite: boolean) {
              return
            }}
          />
        </Box>

        <Box display="flex" alignItems="center" pt={2}>
          <Typography variant="h5" fontWeight={"bold"}>
            Forecast Overview
          </Typography>
          <HelpMe tag={FAQAnchors.rockForecastOverview} event={SnarfEvents.HelpMeRockForcastOverview}/>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          pt={1}
          onClick={onShowHistorical}
        >
          <ArrowBackIcon />
          <Typography sx={{ textDecoration: "underline", cursor: "pointer" }}>
            Show Prev 3 Days
          </Typography>
        </Box>
      </Grid>

      <Grid sx={{ p: 2 }} container overflow="scroll" maxWidth={"100%"}>
        <RockWeatherViewer
          dailyWeather={dailyForecast}
          hourlyForecast={hourlyForecast}
          pointSummary={pointSummary}
          elevation={elevation}
        />
      </Grid>
      <Grid sx={{ pt: 2, px: 2 }} container direction="column">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight={"bold"}>
            7 Day Hourly Forecast
          </Typography>
          <HelpMe tag={FAQAnchors.rockHourlyTable} event={SnarfEvents.HelpMeRockHourlyTable}/>
        </Box>
      </Grid>
      <Grid sx={{ py: 2, pl: 1 }} container overflow="scroll" maxWidth={"100%"}>
        <RockHourlyTable
          hourlyForecast={hourlyForecast}
          spotElevation={elevation}
          pointSummary={pointSummary}
        />
      </Grid>
      <Grid sx={{ py: 2 }} item xs={12}>
        <GraphHQ pointId={pointId} />
      </Grid>

      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection={"column"}>
          <Typography>
            <Link
              sx={{ color: "black", cursor: "pointer" }}
              href={`https://caltopo.com/map.html#ll=${
                pointSummary ? pointSummary.lat : 0
              },${pointSummary ? pointSummary.lon : 0}&z=14`}
              target="_blank"
              rel="noreferrer"
            >
              Caltopo
            </Link>
          </Typography>

          <Typography>
            <Link
              href={`https://www.gaiagps.com/map/?loc=13.4/${pointSummary?.lon}/${pointSummary?.lat}&layer=avalanche-forecast,GaiaTopoRasterFeet`}
              sx={{ color: "black", cursor: "pointer" }}
              target="_blank"
            >
              Gaia
            </Link>
          </Typography>
        </Box>
      </Paper>
    </>
  )
}
