import { Box, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import { getWindSpeedStyle } from "../../utilities/styling"
import WaterDropIcon from "@mui/icons-material/WaterDrop"

export function HelpRockForecastOverview() {
  const theme = useTheme()
  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography variant="h5">
          How do I use the Forecast Overview for Rock Climbing?
        </Typography>
        <Grid container direction={"row"}>
          {/* <Grid item lg={3} xs={12}>
            <img src={"/rock-fo-tile.png"} alt="" loading="lazy" />
          </Grid> */}
          <Grid item lg={9} xs={12}>
            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Weather Icon: </span>
              Below the "Morning" (6 am - 6 pm) and "Evening" (6 pm - 6 am)
              you'll see the icon that best represents the weather that day.
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Wind: </span>
              Underneath each icon you'll see a wind direction and wind speed
              for that section of the day.
            </Typography>
            <ul>
              <li>
                <Typography>
                  <span style={getWindSpeedStyle(30, theme)}>Yellow </span>
                  represents at least 1 hour of wind speeds between 15 - 30 mph
                </Typography>
              </li>
              <li>
                <Typography>
                  <span style={getWindSpeedStyle(60, theme)}>Red </span>
                  represents at least 1 hour of wind speeds greater than 30 mph
                </Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Temperature: </span>
              On the morning side the high temperature for that period, on the
              evening the low temperature for that period.
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Precipitation: </span>
              The amount of precipitation expected to fall during that period.
              The precipitation icon will be blue when any amount of
              precipitation is expected <WaterDropIcon color="primary" /> and it
              will be grey <WaterDropIcon color="disabled" /> when no
              precipitation is expected
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Hourly Precipitation: </span>
              A graph showing the amount of precipitation expected to fall each
              hour. This gives you an idea of when it will be raining (or
              snowing), and how heavily.
            </Typography>
            <ul>
              <li>
                <Typography>
                  X-Axis: Hour of the day in 24-hour time.
                </Typography>
              </li>
              <li>
                <Typography>Y-Axis: Inches of Precipitation</Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>
                Probability of Precipitation:{" "}
              </span>
              According to Noaa, this metric means The "Probability of
              Precipitation (PoP) simply describes the probability that the
              forecast grid/point in question will receive at least 0.01" of
              rain."
            </Typography>
            <ul>
              <li>
                <Typography>
                  X-Axis: Hour of the day in 24-hour time.
                </Typography>
              </li>
              <li>
                <Typography>Y-Axis: Snow level in feet</Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
