import { Container } from "@mui/material"
import React from "react"
import { StationViewer } from "../components/StationViewer"
import { useParams } from "react-router-dom"
import { AdBanner } from "../ads/AdBanner"

export function StationPage() {
  const params = useParams()

  return (
    <Container>
      <StationViewer stationId={params.stid || ""} />
    </Container>
  )
}
