import axios, { AxiosResponse } from "axios"
import dayjs from "dayjs"
import { auth } from "../firebase"
import { PointOfInterest, PointSummary, User } from "../models/models"
import { Camera, SearchResult, UserPointOfInterestType } from "./models"

const ROOT_URL =
  process.env.REACT_APP_SNARF_API_URL ||
  "https://snarf-beta-s7eq2e6llq-uw.a.run.app"

export async function registerNewUser(
  name: string | null,
  email: string,
  id: string,
  emailUpdates: boolean
): Promise<AxiosResponse<any>> {
  return axios.post(`${ROOT_URL}/sign-up`, { name, email, id, emailUpdates })
}

export async function getSummary() {
  const headers = await getHeaders()
  return axios.get(`${ROOT_URL}/summary`, headers)
}

export async function getStateSummary(state: string) {
  const headers = await getHeaders()
  return axios.get(`${ROOT_URL}/summary/${state}`, headers)
}

export async function getCornRatedPoints() {
  const headers = await getHeaders()
  return axios.get(`${ROOT_URL}/analysis/rating`, headers)
}

export async function getDashboardPointsOfInterest(
  state: string,
  type: UserPointOfInterestType
) {
  const headers = await getHeaders()
  return axios.get(`${ROOT_URL}/explore/${type}/${state}`, headers)
}

export async function getAreaChildrenPointsOfInterest(
  id: string,
  type: UserPointOfInterestType
) {
  if (!id) {
    return Promise.resolve([])
  }
  const headers = await getHeaders()
  return axios.get(`${ROOT_URL}/explore/${type}/${id}/children`, headers)
}

export async function getAreaPointOfInterest(
  id: string,
  type: UserPointOfInterestType
) {
  if (!id) {
    return Promise.resolve([])
  }
  const headers = await getHeaders()
  return axios
    .get(`${ROOT_URL}/explore/${type}/${id}`, headers)
    .then((res) => res.data)
}

export async function geocode({
  term,
}: {
  term: string
}): Promise<google.maps.GeocoderResult[]> {
  return axios.post(`${ROOT_URL}/geocode`, { term }).then((res) => res.data)
}

export async function search({
  term,
  excludeClimbs,
  excludeSkiAreas,
}: {
  term: string
  excludeClimbs?: boolean
  excludeSkiAreas?: boolean
}): Promise<SearchResult[]> {
  return axios
    .get(`${ROOT_URL}/search`, {
      params: { term, excludeClimbs, excludeSkiAreas },
    })
    .then((res) => res.data)
}

export async function reverseGeocode(
  lat: number,
  lon: number
): Promise<google.maps.GeocoderResult[]> {
  return axios
    .post(`${ROOT_URL}/reverse-geocode`, { lat, lon })
    .then((res) => res.data)
}

export async function getAnonSummary(lat: number, lon: number, name: string) {
  return axios
    .get(`${ROOT_URL}/anon-point-summary`, { params: { lat, lon, name } })
    .then((res) => res.data)
}

export async function getDailyForecastForPoint(pointId: string) {
  return axios
    .get(`${ROOT_URL}/forecasts/daily/${pointId}`)
    .then((res) => res.data)
}

export async function getHourlyForecastForPoint(pointId: string, date: Date) {
  return axios
    .get(`${ROOT_URL}/forecasts/hourly/${pointId}`, {
      params: { date: date.toISOString() },
    })
    .then((res) => res.data)
}

export async function getHourlyForecastDateRange(
  pointId: string,
  startDate: Date,
  endDate: Date
) {
  const startDateUtc = dayjs(startDate).hour(0).minute(0).second(0)
  const endDateUtc = dayjs(endDate)
  return axios
    .get(`${ROOT_URL}/forecasts/hourly/${pointId}`, {
      params: {
        startDate: startDateUtc.toISOString(),
        endDate: endDateUtc.toISOString(),
      },
    })
    .then((res) => res.data)
}

export async function getStationObservationsForPoint(
  pointId: string,
  startDate: Date,
  endDate: Date
) {
  const startDateUtc = dayjs(startDate).hour(0).minute(0).second(0)
  const endDateUtc = dayjs(endDate).hour(0).minute(0).second(0)
  return axios
    .get(`${ROOT_URL}/observations/${pointId}`, {
      params: {
        startDate: startDateUtc.toISOString(),
        endDate: endDateUtc.toISOString(),
      },
    })
    .then((res) => res.data)
}

export async function getObservationAndHourlyGraphData(
  pointId: string,
  startDate: Date,
  endDate: Date
) {
  const startDateUtc = dayjs(startDate).hour(0).minute(0).second(0)
  const endDateUtc = dayjs(endDate).hour(0).minute(0).second(0)
  return axios
    .get(`${ROOT_URL}/graph/hourly/${pointId}`, {
      params: {
        startDate: startDateUtc.toISOString(),
        endDate: endDateUtc.toISOString(),
      },
    })
    .then((res) => res.data)
}

export async function getStationObservations(
  stid: string,
  startDate: Date,
  endDate: Date
) {
  return axios
    .get(`${ROOT_URL}/stations/${stid}/observations`, {
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    })
    .then((res) => res.data)
}
export async function getPointSummary(
  pointId: string,
  type: UserPointOfInterestType
): Promise<PointSummary> {
  const headers = await getHeaders()
  return axios
    .get(`${ROOT_URL}/point/${pointId}/${type}/summary`, headers)
    .then((res) => res.data)
}

export async function updateFavorite(
  pointId: string,
  isFavorite: boolean,
  type: UserPointOfInterestType
) {
  const headers = await getHeaders()
  return axios
    .post(`${ROOT_URL}/user/favorites`, { pointId, isFavorite, type }, headers)
    .then((res) => res.data)
}

export async function updateUserPoint(
  pointId: string,
  { name, type }: { name: string; type: UserPointOfInterestType }
) {
  const headers = await getHeaders()
  return axios
    .post(`${ROOT_URL}/user/points/${pointId}`, { name, type }, headers)
    .then((res) => res.data)
}

export async function getUser() {
  const headers = await getHeaders()
  return axios.get(`${ROOT_URL}/user`, headers).then((res) => res.data)
}

export async function getStations() {
  const headers = await getHeaders()
  return axios.get(`${ROOT_URL}/stations`, headers).then((res) => res.data)
}

export async function getStationsForPoint(pointId: string) {
  const headers = await getHeaders()
  return axios
    .get(`${ROOT_URL}/points/${pointId}/stations`, headers)
    .then((res) => res.data)
}

export async function getCams(): Promise<Camera[]> {
  const headers = await getHeaders()
  return axios.get(`${ROOT_URL}/cameras`, headers).then((res) => res.data)
}

export async function getCamerasForPoint(pointId: string) {
  const headers = await getHeaders()
  return axios
    .get(`${ROOT_URL}/points/${pointId}/cameras`, headers)
    .then((res) => res.data)
}

export async function getExploreAreas(
  type: UserPointOfInterestType
): Promise<PointOfInterest[]> {
  const headers = await getHeaders()
  return axios
    .get(`${ROOT_URL}/explore/${type}`, headers)
    .then((res) => res.data)
}

export async function pointFromGeocode(
  geocodeResult: google.maps.GeocoderResult
): Promise<PointSummary> {
  const headers = await getHeaders()
  return axios
    .post(`${ROOT_URL}/point/from-geocode`, geocodeResult, headers)
    .then((res) => res.data)
}

export async function getClimbingAreasWithin(bbox: any): Promise<any> {
  const headers = await getHeaders()
  return axios
    .post(`${ROOT_URL}/climbing-areas/within`, { bbox }, headers)
    .then((res) => res.data)
}

export async function createPoint(
  lat: number,
  lon: number,
  name?: string
): Promise<PointSummary> {
  let pointName = `${lat}, ${lon}`
  if (name && name.length > 0) {
    pointName = name
  }
  const headers = await getHeaders()
  return axios
    .post(`${ROOT_URL}/point`, { lat, lon, name: pointName }, headers)
    .then((res) => res.data)
}

export async function setNotifications({
  snarfWeekly,
  snarfUpdates,
}: {
  snarfWeekly?: boolean
  snarfUpdates?: boolean
}): Promise<User> {
  const headers = await getHeaders()
  return axios
    .post(
      `${ROOT_URL}/user/notifications`,
      { snarfWeekly, snarfUpdates },
      headers
    )
    .then((res) => res.data)
}

export async function saveTextFeedback(text: string): Promise<void> {
  const headers = await getHeaders()
  return axios.post(`${ROOT_URL}/feedback/text`, { text }, headers)
}

export async function saveVoteFeedback(vote: string | null): Promise<void> {
  const headers = await getHeaders()
  if (vote) {
    return axios.post(`${ROOT_URL}/feedback/vote`, { vote }, headers)
  }
}

export async function createPaymentSession(priceId: String) {
  const headers = await getHeaders()
  return axios
    .post(`${ROOT_URL}/payments/session`, { priceId }, headers)
    .then((res) => res.data)
}

export async function createPaymentPortalSession() {
  const headers = await getHeaders()
  return axios
    .post(`${ROOT_URL}/payments/portalSession`, {}, headers)
    .then((res) => res.data)
}

async function getHeaders() {
  let userToken = ""
  if (auth.currentUser) {
    userToken = await auth.currentUser.getIdToken()
  }

  return { headers: { authorization: `Bearer ${userToken}` } }
}
