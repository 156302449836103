import { Box } from "@mui/material"
import React from "react"
import { SynopticStation } from "../api/models"
import { StationCard } from "./StationCard"

export interface StationListProps {
  stations: SynopticStation[]
}

export function StationList({ stations }: StationListProps) {
  return (
    <Box display="flex" flexWrap={"wrap"} justifyContent={"center"}>
      {stations.map((station) => (
        <Box p={1}>
          <StationCard
            station={{
              ...station,
              lat: station.LATITUDE,
              lon: station.LONGITUDE,
            }}
          />
        </Box>
      ))}
    </Box>
  )
}
