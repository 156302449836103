import {
  Alert,
  Box,
  Container,
  CssBaseline,
  Divider,
  Typography,
  Link as MUILink,
} from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import { SearchResult } from "../api/models"
import { createPoint } from "../api/snarf-api"
import { LocationSearch } from "../components/LocationSearch"
import { SnarfMap } from "../components/Map"
import { PointSummary } from "../models/models"

export function Help() {
  const navigate = useNavigate()
  const onLocationSelected = (location: SearchResult) => {
    createPoint(location.lat, location.lon, location.name).then(
      (point: PointSummary) => {
        navigate(`/point/${point.id}`)
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      }
    )
  }
  return (
    <>
      <CssBaseline />
      <Container sx={{ pt: 5 }} maxWidth="lg">
        <Box textAlign={"center"}>
          <Typography variant="h3">The Way of Snarf</Typography>
        </Box>

        <Box></Box>
        <Typography fontWeight={"bold"} sx={{ pt: 3 }} variant={"h6"}>
          1. How to Create an Account
        </Typography>
        <Box sx={{ pl: 3 }} maxWidth={"500px"}>
          <Typography variant="body1">
            If you want to customize your home page to show your favorite
            locations you'll want to create an account. You can skip this step
            if you prefer.
          </Typography>
          <Typography sx={{ pl: 3 }} variant="body1">
            <Link to="/register">Click here</Link> to create an account.
          </Typography>
        </Box>

        <Divider sx={{ pt: 3 }} variant="middle" />

        <Typography fontWeight={"bold"} sx={{ pt: 3 }} variant={"h6"}>
          2. Find your locations
        </Typography>
        <Box sx={{ pl: 3 }}>
          <Typography variant="body1">
            You can find for your favorite places to ride in two ways:
          </Typography>
          <Typography sx={{ py: 2 }} fontWeight={"bold"}>
            Location Search Box
          </Typography>
          <Typography variant="body1">
            Try searching for a location using the search box below.
          </Typography>
          <Box sx={{ pb: 2, m: 1 }} maxWidth="500px">
            <LocationSearch onLocationSelected={onLocationSelected} />
            <Alert sx={{ mt: 1 }} severity="info">
              Google provides the search functionality, but isn't always
              perfect. Include the State in your search to help get the correct
              location. e.g. <b>"Mount Margaret Washington"</b>
            </Alert>
          </Box>
          <Typography fontWeight={"bold"}>Map</Typography>
          <Typography sx={{ mb: 1 }} variant="body1">
            Need to mark your secret pow stash or get weather for that specific
            basin? Try <b>clicking on the map</b> below, then hitting the view
            weather button.
          </Typography>
          <SnarfMap
            center={{ lat: 47.46237, lon: -121.63928 }}
            zoom={15}
            noCenterMarker={true}
            mapConfig={{}}
          />
        </Box>
        <Divider sx={{ pt: 3 }} variant="middle" />
        <Typography sx={{ pt: 3 }} fontWeight={"bold"} variant={"h6"}>
          3. Save your locations to home page
        </Typography>
        <Box sx={{ pl: 3 }}>
          <Typography sx={{ pt: 3 }} fontWeight={"bold"}>
            Favorite Location
          </Typography>
          <Typography sx={{ pb: 2 }} variant="body1">
            Alright you're now ready to add all your favorite places to ride on
            and are ready to save them to your favorite spots.
          </Typography>
          <Box sx={{ ml: -2 }} maxWidth={"100%"}>
            <img src={"/point-details-fav.png"} alt="" loading="lazy" />
          </Box>
          <Alert sx={{ mt: 1 }} severity="info">
            Go to your spot and <b>Click the Star</b> on the right of the avvy
            forecast to favorite a spot. It will now show up on your home page
            for easier access.
          </Alert>

          <Typography sx={{ pt: 3 }} fontWeight={"bold"}>
            Rename a Location
          </Typography>
          <Typography sx={{ pb: 2 }} variant="body1">
            If you clicked a spot on the map you probably saw a very useful name
            like below:
          </Typography>
          <Box sx={{ ml: -2 }}>
            <img src={"/unnamed.png"} alt="" loading="lazy" />
          </Box>
          <Alert sx={{ mt: 1, mb: 2 }} severity="info">
            Go to your spot and <b>Click the pencil icon</b> on the right of the
            coordinates, enter your codename.
          </Alert>
          <Box sx={{ ml: -2 }}>
            <img src={"/named.png"} alt="" loading="lazy" />
          </Box>
        </Box>
        <Divider sx={{ pt: 3 }} variant="middle" />
        <Typography sx={{ pt: 3 }} fontWeight={"bold"} variant={"h6"}>
          4. Go play
        </Typography>
        <Box sx={{ pl: 3 }}>
          <Typography sx={{ pb: 2 }} variant="body1">
            Questions or feedback?
            <MUILink> bob@snarfme.com</MUILink>
          </Typography>
        </Box>
      </Container>
    </>
  )
}
