import { Box, Container, Paper, Typography } from "@mui/material"
import React from "react"

export function Terms() {
  return (
    <Container maxWidth="lg" sx={{ pb: 2 }}>
      <Typography variant="h3">Terms of Use</Typography>

      <Box sx={{ py: 1 }} display="flex" flexDirection={"column"}>
        <Box>
          <Typography>
            Welcome to Snarf. By using our website, you agree to comply with and
            be bound by the following terms and conditions of use. Please read
            these terms carefully before using our website.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5">Use of Our Website</Typography>
          <Typography>
            Our website is intended for personal and non-commercial use only.
            You may not use our website for any illegal or unauthorized
            purposes. You agree to comply with all applicable laws and
            regulations when using our website.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5">Intellectual Property</Typography>
          <Typography>
            All content on our website, including but not limited to text,
            graphics, logos, images, and software, is the property of our
            website or its content suppliers and is protected by United States
            and international copyright laws. You may not reproduce, distribute,
            display, transmit, or create derivative works from any of the
            content on our website without our prior written consent.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5">Disclaimer of Warranties</Typography>
          <Typography>
            Our website is provided on an "as is" and "as available" basis. We
            make no representations or warranties of any kind, express or
            implied, as to the operation of our website or the information,
            content, materials, or products included on our website. You
            expressly agree that your use of our website is at your sole risk.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5">Limitation of Liability</Typography>
          <Typography>
            Under no circumstances shall we be liable for any direct, indirect,
            incidental, special, or consequential damages arising out of or in
            connection with the use of our website or the inability to use our
            website, even if we have been advised of the possibility of such
            damages.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5">Governing Law </Typography>
          <Typography>
            These terms and conditions are governed by and construed in
            accordance with the laws of the United States of America and the
            state of Washington, and you irrevocably submit to the exclusive
            jurisdiction of the courts in that State or location.
          </Typography>
        </Box>
        <Box>
          <Typography variant="h5">Changes to Terms of Service </Typography>
          <Typography>
            We reserve the right to modify these terms and conditions at any
            time without prior notice. You agree to review the terms and
            conditions periodically and your continued use of our website
            following any modifications constitutes your acceptance of the
            modified terms and conditions. If you have any questions or concerns
            regarding our terms of service, please contact us at
            bob@snarfme.com.
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}
