import { Box, Typography } from "@mui/material"
import React from "react"

export function HelpStations() {
  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography variant="h5">Station Data</Typography>
        <Typography></Typography>
      </Box>
    </>
  )
}
