import { Box, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import { getWindSpeedStyle } from "../../utilities/styling"

export function HelpRockHourlyTable() {
  const theme = useTheme()
  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography variant="h5">
          How do I use the 7 Day Hourly Forecast for Rock Climbing
        </Typography>
        <Grid container direction={"row"}>
          {/* <Grid item lg={5} xs={12}>
            <img src={"/7hf-rock.png"} alt="" loading="lazy" />
          </Grid> */}
          <Grid item lg={7} xs={12}>
            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Temp: </span>The temperature
              for that hour, pretty self explanatory.
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Precip: </span>
              The forecasted amount of precipitation, keep that rock dry!
            </Typography>
            <ul>
              <li>
                <Typography>
                  <span style={{ color: theme.palette.primary.main }}>
                    Blue{" "}
                  </span>
                  highlights any measurable precipitation values
                </Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>
                Probability of Precip:{" "}
              </span>
              The "Probability of Precipitation" (PoP) simply describes the
              probability that the forecast grid/point in question will receive
              at least 0.01" of rain.
            </Typography>
            <ul>
              <li>
                <Typography>
                  <span style={{ color: theme.palette.primary.main }}>
                    Blue{" "}
                  </span>
                  highlights any probabilities above 30 %
                </Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Wind Speed / Gusts: </span>
              How fast the wind is a blowin.
            </Typography>
            <ul>
              <li>
                <Typography>
                  <span style={getWindSpeedStyle(30, theme)}>Yellow </span>
                  represents at least 1 hour of wind speeds between 15 - 30 mph
                </Typography>
              </li>
              <li>
                <Typography>
                  <span style={getWindSpeedStyle(60, theme)}>Red </span>
                  represents at least 1 hour of wind speeds greater than 30 mph
                </Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Wind Direction: </span>
              Which way the wind is a blowin.
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Cloud Cover: </span> How much
              of the big blue sky is going to be hidden behind clouds.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
