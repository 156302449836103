import {
  Autocomplete,
  TextField,
  CircularProgress,
  debounce,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { geocode, search } from "../api/snarf-api"
import SearchIcon from "@mui/icons-material/Search"
import {
  SearchResult,
  SearchResultType,
  UserPointOfInterestType,
} from "../api/models"
import { MobileSearch } from "./Search/MobileSearch"
import { sendEvent, SnarfEvents } from "../utilities/metrics"
import { Context, gql, useClient, useQuery as useGraphqlQuery } from "urql"
import { searchQuery } from "../graphql/queries/search"

export interface LocationSearchProps {
  excludeClimbs?: boolean
  excludeSkiAreas?: boolean
  onLocationSelected?: (result: SearchResult) => any
  searchTerm?: string
}

export function LocationSearch(props: LocationSearchProps) {
  const [searchTerm, setSearchTerm] = useState(props.searchTerm || "")
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [loadingResults, setLoadingResults] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const urqlClient = useClient()
  const theme = useTheme()
  const isSmall = useMediaQuery("(max-width:600px)")

  const onSearchUpdate = (newValue: string, categories?: any) => {
    setSearchTerm(newValue)
    setSearchResults([])
    if (newValue.length > 0) {
      setLoadingResults(true)
      debounceSearch(newValue, categories)
    }
  }

  const debounceSearch = useCallback(
    debounce((term, categories) => {
      urqlClient
        .executeQuery({
          query: searchQuery,
          variables: {
            term,
            categories,
          },
          key: 0,
        })
        .then((res) => {
          sendEvent(SnarfEvents.Search)
          setSearchResults(res.data?.search)
          setLoadingResults(false)
          setIsOpen(true)
        })
    }, 200),
    [props.excludeClimbs, props.excludeSkiAreas]
  )

  const onLocationSelect = (location: SearchResult | string) => {
    const searchResult = location as SearchResult

    sendEvent(SnarfEvents.SearchSelect)
    if (searchResult.name) {
      setIsOpen(false)
      setSearchTerm(searchResult.name)
      if (props.onLocationSelected) {
        props.onLocationSelected(searchResult)
      }
    } else {
      setSearchTerm(location as string)
    }
  }

  const getGroupLabel = (option: SearchResult) => {
    if (!option.type) {
      return "Places"
    }

    switch (option.type) {
      case SearchResultType.ROCK_CLIMB:
        return "Climbing Areas"
      case SearchResultType.SKI_AREA:
        return "Ski Areas"
      case SearchResultType.STATION:
        return "Stations"
      default:
        return "Places"
    }
  }

  const renderGroup = (params: any) => {
    const { group, children, key } = params
    return (
      <Box key={key}>
        <Box pl={1} pt={1} display="flex" alignItems="center">
          <Typography color={"primary.main"}>{group}</Typography>
        </Box>
        <Box>{children}</Box>
      </Box>
    )
  }

  if (isSmall) {
    return (
      <MobileSearch
        term={searchTerm}
        onSearchTermUpdate={onSearchUpdate}
        results={searchResults}
        loading={loadingResults}
        onSelect={onLocationSelect}
      />
    )
  }

  return (
    <Autocomplete
      id="free-solo-demo"
      freeSolo
      open={isOpen}
      onFocus={() => setIsOpen(true)}
      onBlur={() => setIsOpen(false)}
      onChange={(event, newvalue) => onLocationSelect(newvalue as SearchResult)}
      inputValue={searchTerm}
      onInputChange={(event, newInputValue) => {
        onSearchUpdate(newInputValue)
      }}
      style={{ borderRadius: "4px" }}
      sx={{ backgroundColor: "white", width: "100%" }}
      filterOptions={(x) => x}
      getOptionLabel={(option: SearchResult | string) => {
        const searchResult = option as SearchResult

        if (searchResult && searchResult.name) {
          return searchResult.name
        } else {
          return option as string
        }
      }}
      renderOption={(props, option) => {
        return (
          <Box
            pl={2}
            pt={1}
            sx={{
              ":hover": {
                backgroundColor: theme.palette.grey[300],
                cursor: "pointer",
              },
            }}
            onClick={() => onLocationSelect(option)}
          >
            <Typography>{option.name}</Typography>
            <Typography variant="caption">
              {option && option.pathTokens
                ? option.pathTokens.join(" / ")
                : null}
            </Typography>
          </Box>
        )
      }}
      loading={loadingResults}
      options={searchResults.sort((a, b) => {
        if (!a.type) {
          return -1
        }

        if (!b.type) {
          return 1
        }

        return a.type > b.type ? -1 : 1
      })}
      groupBy={getGroupLabel}
      renderGroup={renderGroup}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon />,
            endAdornment: (
              <React.Fragment>
                {loadingResults ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}
