import { Box, Typography, useTheme } from "@mui/material"
import React from "react"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { RainIcon } from "../../icons/RainIcon"
import { SunIcon } from "../../icons/SunIcon"
import ThermostatIcon from "@mui/icons-material/Thermostat"
import CloudQueueIcon from "@mui/icons-material/CloudQueue"

export function HelpRockIconGuide() {
  const theme = useTheme()
  return (
    <>
      <Box display="flex" flexDirection={"column"} p={2}>
        <Typography variant="h5">Rock Climbing Icon Guide</Typography>
        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="50px" display="flex" justifyContent="center">
            <CheckCircleIcon htmlColor="green" />
          </Box>
          <Typography ml={1}>
            Go climb! No rain in the last 24 hours, something will be dry!
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="50px" display="flex" justifyContent="center">
            <CheckCircleOutlineIcon color="warning" />
          </Box>
          <Typography ml={1}>
            It rained yesterday, but will be dry today. There's a chance you can
            find something dry. Better than sitting at home!
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} pb={1}>
          <Box pl="4px" width="50px" display="flex" justifyContent="center">
            <SunIcon height="30px" width="30px" fill="orange" />
          </Box>
          <Typography ml={1}>
            It's going to be a sunny clear day. Great day to go get some views
            and a tan.
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="50px" display="flex" justifyContent="center">
            <CloudQueueIcon fontSize="small" />
          </Box>
          <Typography ml={1}>
            Cloudy day, no reason not to go outside.
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="50px" display="flex" justifyContent="center">
            <Box display="flex" alignItems="center">
              <RainIcon
                fill={theme.palette.primary.main}
                height="25px"
                width="25px"
              />
              <Box
                display="flex"
                flexDirection={"column"}
                justifyContent="center"
                alignItems="center"
                pl="2px"
              >
                <Typography variant="caption">30%</Typography>
                <Typography variant="caption">0.04</Typography>
              </Box>
            </Box>
          </Box>

          <Typography ml={1}>
            It's might rain. Top number signifies the Probability of
            Precipitation and the bottom number the inches of precipitation.
            While wet slab is a delicacy, might be better to look elsewhere.
          </Typography>
        </Box>

        <Box display="flex" alignItems={"center"} pb={1}>
          <Box width="50px" display="flex">
            <ThermostatIcon />
            <Box display="flex" flexDirection={"column"}>
              <Typography variant="caption">70&#176;</Typography>
              <Typography variant="caption">50&#176;</Typography>
            </Box>
          </Box>

          <Typography ml={1}>
            The high and low temperature from sun up to sun down. Do I need the
            puffy or not?
          </Typography>
        </Box>
      </Box>
    </>
  )
}
