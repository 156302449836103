import { Box, MenuItem, Select, Typography } from "@mui/material"
import React from "react"

const metrics = [
  { name: "None", id: "none" },
  { name: "Snow Depth", id: "snowDepth" },
  { name: "Temperature", id: "temperature" },
  { name: "Snow Accumulation", id: "twentyFourHourSnow" },
]

interface StationControlsProps {
  onChange: (metric: string) => any
  config?: { metric: string }
}

export function StationControls({ config, onChange }: StationControlsProps) {
  return (
    <Box>
      <Typography>Metric:</Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={config?.metric || "none"}
        onChange={(event) => onChange(event.target.value as string)}
      >
        {metrics.map((metric) => (
          <MenuItem key={metric.id} value={metric.id}>
            {metric.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}
