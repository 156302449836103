export enum SnarfEvents {
  Browse = "snarf_browse",
  Poster = "snarf_poster_visit",
  JoyrideFinish = "snarf_joyride_finish",
  JoyrideSkip = "snarf_joyride_skip",
  ActivitySwitch = "snarf_activity_switch",
  NearMeLocationSet = "snarf_near_me_location_set",
  EmailClick = "snarf_email_click",
  Reddit = "snarf_reddit_click",
  MapLayerSwitch = "snarf_map_layer_switch",
  Search = "snarf_search",
  MapViewStation = "snarf_map_view_station",
  MapViewCamera = "snarf_map_view_camera",
  MapViewPointForecast = "snarf_map_view_point_forecast",
  HelpMeSnowSummary = "snarf_help_me_snow_summary",
  HelpMeSnowIconGuide = "snarf_help_me_snow_icon_guide",
  HelpMeForcastOverView = "snarf_help_me_forecast_overview",
  HelpMeHourlyTable = "snarf_help_me_hourly_table",
  HelpMeSnarfWeekly = "snarf_help_me_snarf_weekly",
  HelpMeStations = "snarf_help_me_stations",
  HelpMeCornAlerts = "snarf_help_me_corn_alerts",
  HelpMeRockForcastOverview = "snarf_help_me_rock_forecast_overview",
  HelpMeRockHourlyTable = "snarf_help_me_rock_hourly_table",
  HelpMeRockIconGuide = "snarf_help_me_rock_icon_guide",
  HelpMeForcastElevation = "snarf_help_me_forecast_elevation",
  GuidedStartFinished = "snarf_guided_finished",
  GuidedStartNext = "snarf_guided_next",
  GuidedStartClose = "snarf_guided_close",
  GuidedStartBegin = "snarf_guided_begin",
  SearchAllSelect = "snarf_search_all_select",
  SearchSkiSelect = "snarf_search_ski_select",
  SearchClimbSelect = "snarf_search_climb_select",
  SearchMountainsSelect = "snarf_search_mountains_select",
  SearchGoogleSelect = "snarf_search_google_select",
  SearchSelect = "snarf_search_point_select",
  ElevationProfileSwitch = "snarf_elevation_profile_switch",
  PointTabSwitch = "snarf_point_tab_switch",
  Share = "snarf_share",
  ShowLoginModal = "snarf_show_login_modal",
  ShowSubscribeModal = "snarf_show_subscribe_modal",
  ForecastDiscussionExpanded = "snarf_forecast_discussion_expanded",
  StationsTabSelected = "snarf_stations_tab_selected",
  CamsTabSelected = "snarf_cams_tab_selected",
  Favorite = "snarf_add_favorite",
  InstallPrompt = "snarf_install_prompt",
  FavoriteClick = "snarf_favorite_tile_click",
  SubscribeYearly = "snarf_subscribe_yearly",
  SubscribeMonthly = "snarf_subscribe_monthly",
}
declare global {
  interface Window {
    dataLayer: any
    fbq: any
  }
}

export const trackPurchase = (value: number) => {
  window.fbq("track", "Purchase", { value, currency: "USD" })
}

export const setUser = (email: string) => {
  window.dataLayer.push({
    user_id: email,
  })
}

export const sendEvent = (event: SnarfEvents, params?: any) => {
  window.dataLayer.push({
    event: event,
    params,
  })
}
