import { Box, Typography } from "@mui/material"
import React from "react"

export function HelpCorn() {
  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography>
          <img
            src="/corn.png"
            style={{ width: "auto", height: "25px" }}
            alt=""
          />
          What does the corn on the cob mean?
        </Typography>
        <Typography>
          There's a potential corn snow day in the making! Corn Snow is the
          result of melt-freeze cycles, and is a close cousin to powder snow as
          it's fun to ride. We analyze the upcoming forecast to determine if
          conditions are ripe for a corn harvest so you know when to go reap it.
        </Typography>
      </Box>
    </>
  )
}
