import {
  ArrowBack,
  CellTower,
  ChevronRight,
  Close,
  ExpandMore,
  Landscape,
  NearMe,
  Place,
  Star,
  Videocam,
} from "@mui/icons-material"
import {
  Drawer,
  Grid,
  Typography,
  IconButton,
  Paper,
  Box,
  useMediaQuery,
  Checkbox,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material"
import React, { useContext, useMemo, useState } from "react"
import { UserPointOfInterestType } from "../api/models"
import { ActivityContext } from "../pages/Header"
import { LayerControls } from "./Map/LayerControls"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { MapLayers } from "./Map/models"
import { RockClimberCircle } from "../icons/RockClimberCircle"
import SnowboardingIcon from "@mui/icons-material/Snowboarding"
export interface Layer {
  id: MapLayers
  name: string
  icon: any
  options: boolean
  activities: UserPointOfInterestType[]
}

export enum MapMode {
  STANDARD = "STANDARD",
  ADD_POINT = "ADD_POINT",
}

const layers = [
  {
    id: MapLayers.FAVORITES,
    name: "My Favorites",
    icon: <Star sx={{ color: "gold" }} />,
    options: false,
    activities: [
      UserPointOfInterestType.ROCK_CLIMB,
      UserPointOfInterestType.SKI,
    ],
  },
  // {
  //   id: MapLayers.CLIMBING_AREAS,
  //   name: "Climbing Areas",
  //   icon: <RockClimberCircle />,
  //   options: false,
  //   activities: [UserPointOfInterestType.ROCK_CLIMB],
  // },
  {
    id: MapLayers.SKI_AREAS,
    name: "Ski Areas",
    icon: <SnowboardingIcon htmlColor="black" />,
    options: true,
    activities: [
      UserPointOfInterestType.ROCK_CLIMB,
      UserPointOfInterestType.SKI,
    ],
  },
  {
    id: MapLayers.AVALANCHE,
    name: "Avalanche",
    icon: <Landscape htmlColor="black" />,
    options: false,
    activities: [
      UserPointOfInterestType.ROCK_CLIMB,
      UserPointOfInterestType.SKI,
    ],
  },
  {
    id: MapLayers.STATIONS,
    name: "Stations",
    icon: <CellTower style={{ color: "#3F2B7A" }} />,
    options: true,
    activities: [
      UserPointOfInterestType.ROCK_CLIMB,
      UserPointOfInterestType.SKI,
    ],
    filters: [{ name: "Snow Depth", id: "snow_depth" }],
  },
  {
    id: MapLayers.CAMERAS,
    name: "Cameras",
    icon: <Videocam style={{ color: "#622A0F" }} />,
    options: false,
    activities: [
      UserPointOfInterestType.ROCK_CLIMB,
      UserPointOfInterestType.SKI,
    ],
  },
]

const layerConfigIsValid = (layerId: MapLayers, layer: any) => {
  switch (layerId) {
    case MapLayers.NEAR_ME:
      if (!layer.location || !layer.location.lat || !layer.location.lon) {
        return false
      }
      return true
    default:
      return true
  }
}
export interface MapControlProps {
  isOpen: boolean
  onClose: () => any
  onUpdate: (layerId: MapLayers, payload: any) => any
  onLayerToggle: (layerId: MapLayers) => any
  config: any
}
export function MapControl({
  isOpen,
  onClose,
  onUpdate,
  onLayerToggle,
  config,
}: MapControlProps) {
  const [selectedLayer, setSelectedLayer] = useState<Layer | null>(null)
  const isSmall = useMediaQuery("(max-width:600px)")
  const selectedActivity = useContext(ActivityContext)

  const activeLayers = useMemo(() => {
    const onToggle = (layerId: MapLayers, event: React.MouseEvent) => {
      if (layerConfigIsValid(layerId, config[layerId])) {
        event.stopPropagation()
        onLayerToggle(layerId)
      }
    }

    const onLayerClick = (layer: Layer) => {
      setSelectedLayer(layer)
      onLayerToggle(layer.id)
    }

    const onLayerConfigUpdate = (layerId: MapLayers, payload: any) => {
      setSelectedLayer(null)
      if (!config[layerId] || !config[layerId].enabled) {
        onLayerToggle(layerId)
      }
      onUpdate(layerId, payload)
    }

    return layers
      .filter((layer) => layer.activities.includes(selectedActivity))
      .map((layer) => (
        <Grid item py={1} key={layer.id}>
          {layer.options ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ px: 1 }}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    <Checkbox
                      checked={config[layer.id] && config[layer.id].enabled}
                      onClick={(event) => onToggle(layer.id, event)}
                    />
                  </Box>
                  <Box display="flex" alignItems="center">
                    {layer.icon}
                    <Typography>{layer.name}</Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ pt: 0 }}>
                <LayerControls
                  config={config[layer.id]}
                  layerId={layer.id}
                  onUpdate={onLayerConfigUpdate}
                />
              </AccordionDetails>
            </Accordion>
          ) : (
            <>
              <Paper
                key={layer.id}
                sx={{ p: 1, cursor: "pointer" }}
                onClick={() => onLayerClick(layer)}
              >
                <Box display="flex" alignItems="center">
                  <Box>
                    {config[layer.id] && config[layer.id].enabled ? (
                      <Checkbox
                        key={layer.id}
                        checked={true}
                        onClick={(event) => onToggle(layer.id, event)}
                      />
                    ) : (
                      <Checkbox
                        key={layer.id}
                        checked={false}
                        onClick={(event) => onToggle(layer.id, event)}
                      />
                    )}
                  </Box>
                  <Box display="flex" alignItems="center">
                    {layer.icon}
                    <Typography>{layer.name}</Typography>
                  </Box>
                </Box>
              </Paper>
            </>
          )}
        </Grid>
      ))
  }, [config, onLayerToggle, onUpdate, selectedActivity])

  return (
    <>
      <Drawer
        anchor={isSmall ? "bottom" : "right"}
        open={isOpen}
        variant={isSmall ? "temporary" : "persistent"}
        onClose={onClose}
      >
        <Grid
          height={isSmall ? "fit-content" : "80vh"}
          width={isSmall ? "100vw" : "20vw"}
          px={2}
          py={1}
          container
          direction={"column"}
        >
          {isSmall ? (
            <Grid item>
              <Box
                display="flex"
                justifyContent={"center"}
                alignItems="center"
                height="20px"
              >
                <IconButton sx={{}} onClick={onClose}>
                  <KeyboardArrowDownIcon fontSize="large" htmlColor="black" />
                </IconButton>
              </Box>
            </Grid>
          ) : null}

          {/* <Grid item pb={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Markers</Typography>
              {!isSmall ? (
                <IconButton
                  style={{ padding: 0, marginBottom: 1 }}
                  onClick={onClose}
                >
                  <ChevronRight fontSize="large" htmlColor="black" />
                </IconButton>
              ) : null}
            </Box>
          </Grid>

          {config[MapLayers.SELECTED_POINT] &&
          config[MapLayers.SELECTED_POINT].enabled ? (
            <Grid item pb={2}>
              <Paper sx={{ p: 1 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      key={MapLayers.SELECTED_POINT}
                      checked={config[MapLayers.SELECTED_POINT].enabled}
                      onClick={() => onLayerToggle(MapLayers.SELECTED_POINT)}
                    />
                    <Place htmlColor="red" />
                    <Typography>
                      {config[MapLayers.SELECTED_POINT].name
                        ? config[MapLayers.SELECTED_POINT].name
                        : "Selected Point"}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        onLocate(config[MapLayers.SELECTED_POINT].location)
                      }
                    >
                      <LocationSearchingIcon htmlColor="black" />
                    </IconButton>
                  </Box>
                  <IconButton onClick={onRemoveMarker}>
                    <Close />
                  </IconButton>
                </Box>
              </Paper>
            </Grid>
          ) : (
            <Grid item pb={2}>
              <Paper sx={{ p: 1 }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    pl={1}
                    py={1}
                    sx={{ cursor: "pointer" }}
                    onClick={() => onModeChange(MapMode.ADD_POINT)}
                  >
                    <AddCircleOutlineIcon />
                    <Place htmlColor="red" />
                    <Typography>Add Forecast Point</Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          )} */}

          <Grid
            item
            pb={1}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {selectedLayer && selectedLayer.options ? (
              <Box display="flex" alignItems="center">
                <IconButton onClick={() => setSelectedLayer(null)}>
                  <ArrowBack />
                </IconButton>
                <Box display="flex" alignItems="center" pl={1}>
                  {selectedLayer.icon}
                  <Typography variant="h5">{selectedLayer.name}</Typography>
                </Box>
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Typography variant="h5">Layers</Typography>
                {!isSmall ? (
                  <IconButton
                    style={{ padding: 0, marginBottom: 1 }}
                    onClick={onClose}
                  >
                    <ChevronRight fontSize="large" htmlColor="black" />
                  </IconButton>
                ) : null}
              </Box>
            )}
          </Grid>

          {activeLayers}
        </Grid>
      </Drawer>
    </>
  )
}
