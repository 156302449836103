import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
  chipClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React, { useContext, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate, useSearchParams } from "react-router-dom"
import { SearchResult } from "../api/models"
import { createPoint } from "../api/snarf-api"
import { LocationSearch } from "../components/LocationSearch"
import { auth } from "../firebase"
import { PointOfInterest, PointSummary } from "../models/models"
import { sendEvent, SnarfEvents } from "../utilities/metrics"
import { SkiCompactTile } from "../components/Snow/SkiCompactTile"
import { topSkiAreas } from "../graphql/queries/top-ski-areas"
import { gql, useQuery as useGraphqlQuery } from "urql"
import { AdSplashPage } from "../ads/AdSplashPage"
import { useGeolocate } from "../hooks/useGeolocate"
import { NearMe, Public } from "@mui/icons-material"
import dayjs from "dayjs"
import { UserPremiumContext } from "./Header"
import { graphqlClient } from "../graphql"
import { startTrialMutation } from "../graphql/mutations/start-trial"

export function Splash() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [user, loadingAuthState] = useAuthState(auth)
  const isSmall = useMediaQuery("(max-width:600px)")
  const [userLocation] = useGeolocate()
  const [nearMeForecasts, setNearMeForecasts] = useState(false)

  const userIsPremium = useContext(UserPremiumContext)

  const theme = useTheme()

  const enabledChip = {
    backgroundColor: theme.palette.primary.main,
    textColor: "white",
    color: "white",
    cursor: "pointer",
    width: "100%",
    fontWeight: "bold",
  }
  const disabledChip = {
    backgroundColor: "white",
    textColor: "grey",
    cursor: "pointer",
    fontWeight: "bold",
    width: "100%",
  }

  if (user && !searchParams.get("stay") && userIsPremium) {
    navigate("/home")
  }

  // if (
  //   !loadingAuthState &&
  //   !user &&
  //   !localStorage.getItem("snarf-tour") &&
  //   isSmall
  // ) {
  //   const random = Math.random()
  //   if (random <= 0.5) {
  //     navigate("/getting-started")
  //   }
  // }

  const onLocationSelected = (location: SearchResult) => {
    createPoint(location.lat, location.lon, location.name).then(
      (point: PointSummary) => {
        navigate(`/point/${point.id}`, { state: { back: true } })
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      }
    )
  }

  const [skiAreasQuery] = useGraphqlQuery({
    query: topSkiAreas,
    variables: {
      startDay: dayjs().startOf("day").toISOString(),
      endDay: dayjs().endOf("day").add(7, "day").toISOString(),
      filters: {},
      paging: {
        limit: 3,
        skip: 0,
      },
      sort: { forecastedSnow: true },
    },
  })

  const [nearbySkiAreasQuery] = useGraphqlQuery({
    query: topSkiAreas,
    variables: {
      startDay: dayjs().startOf("day").subtract(1, "day").toISOString(),
      endDay: dayjs().endOf("day").subtract(1, "day").toISOString(),
      filters: {
        nearby: {
          lat: userLocation?.lat,
          lon: userLocation?.lon,
          miles: 200,
        },
      },
      paging: {
        limit: 3,
        skip: 0,
      },
      sort: { forecastedSnow: true },
    },
    pause: !userLocation,
  })

  const onCardClick = (pointData: PointOfInterest) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    navigate(`/point/${pointData.id}`, { state: { back: true } })
  }

  const PublicIconOverride = ({ color }: { color: string }) => {
    return (
      <>
        <Public htmlColor={color} />
      </>
    )
  }

  const NearMeIconOverride = ({ color }: { color: string }) => {
    return (
      <>
        <NearMe htmlColor={color} />
      </>
    )
  }

  const onTrialClick = () => {
    if (user) {
      // set their end date, can only be set once on the back end without me
      graphqlClient.mutation(startTrialMutation, {}).then(() => {
        window.location.href = window.location.href
      })
    } else {
      // navigate to register with a token signifying trial
      // after signup set up their free trial
      // funnel them into favorites / map etc?
      navigate("/register?trial=true")
    }
  }

  return (
    <>
      {loadingAuthState ? (
        <Box
          display="flex"
          height="100vh"
          width="100%"
          justifyContent={"center"}
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              background:
                "linear-gradient(rgba(41,61,82,.4),rgba(41,61,82,.4)),url(/castle-splash-small.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "45%",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Container
              maxWidth="lg"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                py: 1,
              }}
            >
              <Grid container>
                <Grid item xs={12} lg={6}>
                  <Box
                    sx={{ p: 2 }}
                    textAlign="center"
                    display="flex"
                    flexDirection={"column"}
                  >
                    <Typography variant="h1" sx={{ color: "white" }}>
                      Snarf
                    </Typography>
                    <Typography variant="h5" sx={{ color: "white" }}>
                      Snarf: Your Ultimate Skiing Weather App! Get hyperlocal
                      forecasts, live weather station data, webcams, and maps
                      for all your favorite ski spots. Plan your perfect powder
                      day now!
                    </Typography>

                    <Box
                      display="flex"
                      alignItems="center"
                      py={1}
                      justifyContent={"center"}
                    >
                      <Box px={2}>
                        <Button onClick={onTrialClick} variant="contained">
                          Start Free Trial
                        </Button>
                      </Box>
                    </Box>

                    <Box>
                      <Typography variant="h5" sx={{ color: "white" }}>
                        Or search for your favorite Ski Resort
                      </Typography>
                    </Box>

                    <Box
                      display="flex"
                      alignItems="center"
                      py={1}
                      justifyContent={"center"}
                      width="100%"
                    >
                      <LocationSearch onLocationSelected={onLocationSelected} />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    alignItems="center"
                    height="100%"
                  >
                    <img
                      src="/forecast-screens-in-phone.png"
                      style={isSmall ? { width: "300px" } : { width: "400px" }}
                      alt="Snarf Weather Logo"
                    />
                  </Box>
                </Grid>
              </Grid>

              {/* <Typography variant="h4" sx={{ color: "white" }} py={1}>
              Top Snow Forecasts
            </Typography>
            <Box display="flex" justifyContent={"center"} py={1}>
              <Box px={2} width={175}>
                <Chip
                  style={!nearMeForecasts ? enabledChip : disabledChip}
                  label={"USA"}
                  sx={{ px: 2 }}
                  icon={
                    <PublicIconOverride
                      color={!nearMeForecasts ? "white" : "grey"}
                    />
                  }
                  onClick={() => setNearMeForecasts(false)}
                />
              </Box>
              <Box px={2} width={175}>
                <Chip
                  style={nearMeForecasts ? enabledChip : disabledChip}
                  label="Near Me"
                  icon={
                    <NearMeIconOverride
                      color={nearMeForecasts ? "white" : "grey"}
                    />
                  }
                  sx={{ px: 2 }}
                  onClick={() => setNearMeForecasts(true)}
                />
              </Box>
            </Box>

            {!nearMeForecasts ? (
              <Grid container justifyContent={"center"} spacing={1}>
                {skiAreasQuery.data &&
                  skiAreasQuery.data.skiAreas
                    .slice(0, 3)
                    .map((area: any, index: number) => {
                      // hack around name change summary -> snowSummary for now
                      const totalsByDay: any = {}
                      area.point.snowSummary.totalsByDay.forEach(
                        (daySummary: any) => {
                          totalsByDay[daySummary.date] = daySummary.totalSnow
                        }
                      )

                      area.point.summary = { ...area.point.snowSummary }
                      area.point.summary.totalsByDay = totalsByDay
                      return (
                        <>
                          <Grid
                            sx={{ padding: 0 }}
                            item
                            key={area.id}
                            lg={12}
                            display="flex"
                            justifyContent={"center"}
                          >
                            <Box width={isSmall ? "100vw" : "400px"} px={1}>
                              <SkiCompactTile
                                area={area}
                                onCardClick={onCardClick}
                              />
                            </Box>
                          </Grid>
                          {index === 0 && isSmall && (
                            <Box width={"100vw"} key={Date.now()}>
                              <AdSplashPage />
                            </Box>
                          )}
                        </>
                      )
                    })}
              </Grid>
            ) : (
              <Grid container justifyContent={"center"} spacing={1}>
                {!userLocation && (
                  <Box py={2}>
                    <Paper sx={{ p: 2 }}>
                      <Typography>
                        Please enable location services to see nearby ski areas
                      </Typography>
                    </Paper>
                  </Box>
                )}

                {nearbySkiAreasQuery.fetching && (
                  <Box display="flex" justifyContent={"center"} py={2}>
                    <CircularProgress />
                  </Box>
                )}

                {nearbySkiAreasQuery.data &&
                  nearbySkiAreasQuery.data.skiAreas.map(
                    (area: any, index: number) => {
                      // hack around name change summary -> snowSummary for now
                      const totalsByDay: any = {}
                      area.point.snowSummary.totalsByDay.forEach(
                        (daySummary: any) => {
                          totalsByDay[daySummary.date] = daySummary.totalSnow
                        }
                      )

                      area.point.summary = { ...area.point.snowSummary }
                      area.point.summary.totalsByDay = totalsByDay
                      return (
                        <>
                          <Grid
                            sx={{ padding: 0 }}
                            item
                            key={area.id}
                            lg={12}
                            display="flex"
                            justifyContent={"center"}
                          >
                            <Box width={isSmall ? "100vw" : "400px"} px={1}>
                              <SkiCompactTile
                                area={area}
                                onCardClick={onCardClick}
                              />
                            </Box>
                          </Grid>
                          {index === 0 && isSmall && (
                            <Box
                              sx={{ padding: 1 }}
                              width={"100vw"}
                              height={165}
                              key={Date.now()}
                            >
                              <AdSplashPage />
                            </Box>
                          )}
                        </>
                      )
                    }
                  )}
              </Grid>
            )} */}

              {/* <Box width={"100%"} height={400}>
              <AdSplashPage />
            </Box> */}
            </Container>
          </Box>

          <Grid container>
            {isSmall && (
              <Grid item xs={12} lg={6}>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height="100%"
                  textAlign={"center"}
                  p={1}
                >
                  <Typography variant="h5" color="primary">
                    Find the Deepest Forecasts with Snarf's Innovative Map
                    Layers.
                  </Typography>
                  <Typography variant="h6">
                    Snarf takes the guesswork out of weather maps. With Snarf's
                    map layers you can easily find the deepest forecasted snow
                    totals for any location, snow accumulation, current snow
                    depth data and more.
                  </Typography>
                </Box>
              </Grid>
            )}

            <Grid item xs={12} lg={7}>
              <Box
                display="flex"
                py={1}
                alignItems={"center"}
                justifyContent={isSmall ? "" : "center"}
                maxWidth={"100vw"}
              >
                <Box>
                  <img
                    src="/rainier-amy.jpg"
                    style={isSmall ? { width: "75vw" } : { width: "600px" }}
                    alt="Snarf Weather App"
                  />
                </Box>
                <Box
                  sx={
                    isSmall
                      ? { position: "absolute", left: "45vw" }
                      : { position: "relative", left: "-50px" }
                  }
                >
                  <img
                    src="/map-in-phone.png"
                    style={{ width: "200px" }}
                    alt="Snarf Weather App"
                  />
                </Box>
              </Box>
            </Grid>

            {!isSmall && (
              <Grid item xs={12} lg={5}>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height="100%"
                >
                  <Typography variant="h5" color="primary">
                    Find the Deepest Forecasts and Current Ski Conditions with
                    Snarf's Innovative Map Layers
                  </Typography>
                  <Typography variant="h6">
                    Snarf takes the guesswork out of weather maps. With Snarf's
                    map layers you can easily find the deepest forecasted snow
                    totals for any location, snow accumulation, current snow
                    depth data and more.
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container>
            <Grid item xs={12} lg={5}>
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                height="100%"
                textAlign={"center"}
                p={1}
              >
                <Typography variant="h5" color="primary">
                  Save Favorite Locations and Compare Forecasts
                </Typography>
                <Typography variant={"h6"}>
                  Make tracking all the places you like to ride a breeze.
                  Staying up to date on the latest forecasts has never been so
                  easy. Quickly scan the important information for all your
                  locations with detailed information just one tap away.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={7}>
              <Box
                display="flex"
                py={1}
                alignItems={"center"}
                justifyContent={isSmall ? "flex-end" : "center"}
                maxWidth={"100vw"}
              >
                <Box
                  sx={
                    isSmall
                      ? { position: "absolute", right: "45vw" }
                      : { position: "relative", left: "50px" }
                  }
                >
                  <img
                    src="/favorites-in-phone.png"
                    style={{ width: "200px" }}
                    alt="Snarf Weather App"
                  />
                </Box>
                <Box>
                  <img
                    src="/skyline-track.jpg"
                    style={isSmall ? { width: "75vw" } : { width: "600px" }}
                    alt="Snarf Weather App"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            {isSmall && (
              <Grid item xs={12} lg={6}>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height="100%"
                  textAlign={"center"}
                  p={1}
                >
                  <Typography variant="h5" color="primary">
                    Live Webcams
                  </Typography>
                  <Typography variant={"h6"}>
                    Seeing is believing. View thousands of resort and highway
                    cams before you head out. Know exactly how things are
                    looking out there both on the slopes and on the roads.
                  </Typography>
                </Box>
              </Grid>
            )}

            <Grid item xs={12} lg={7}>
              <Box
                display="flex"
                py={1}
                alignItems={"center"}
                justifyContent={isSmall ? "" : "center"}
                maxWidth={"100vw"}
              >
                <Box>
                  <img
                    src="/snowboard-slash.jpg"
                    style={isSmall ? { width: "75vw" } : { width: "500px" }}
                    alt="Snarf Weather App"
                  />
                </Box>
                <Box
                  sx={
                    isSmall
                      ? { position: "absolute", left: "45vw" }
                      : { position: "relative", left: "-50px" }
                  }
                >
                  <img
                    src="/cams-in-phone.png"
                    style={{ width: "200px" }}
                    alt="Snarf Weather App"
                  />
                </Box>
              </Box>
            </Grid>

            {!isSmall && (
              <Grid item xs={12} lg={5}>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  height="100%"
                >
                  <Typography variant="h5" color="primary">
                    Live Webcams
                  </Typography>
                  <Typography variant={"h6"}>
                    Seeing is believing. View thousands of resort and highway
                    cams before you head out. Know exactly how it looks both on
                    the slopes and on the roads.
                  </Typography>
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid container>
            <Grid item xs={12} lg={5}>
              <Box
                display="flex"
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                height="100%"
                textAlign={"center"}
                py={1}
              >
                <Typography variant="h5" color="primary">
                  Powder Chasing Tools
                </Typography>
                <Typography variant={"h6"}>
                  There's good snow somewhere, with Snarf you'll always be able
                  to find it. Forecast discussions from your local NWS
                  Forecasters, Live Weather Station Data, and Snarf's Snow
                  Scanner give you the edge you need to make the most of every
                  season.
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} lg={7}>
              <Box
                display="flex"
                py={1}
                alignItems={"center"}
                justifyContent={isSmall ? "flex-end" : "center"}
                maxWidth={"100vw"}
              >
                <Box
                  sx={
                    isSmall
                      ? { position: "absolute", right: "45vw" }
                      : { position: "relative", left: "50px" }
                  }
                >
                  <img
                    src="/snow-scanner-in-phone.png"
                    style={{ width: "200px" }}
                    alt="Snarf Weather App"
                  />
                </Box>
                <Box>
                  <img
                    src="/skyline-track.jpg"
                    style={isSmall ? { width: "75vw" } : { width: "600px" }}
                    alt="Snarf Weather App"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box
            display="flex"
            alignItems="center"
            py={1}
            justifyContent={"center"}
          >
            <Box px={2}>
              <Button onClick={onTrialClick} variant="contained">
                Start Free Trial
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}
