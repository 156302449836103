import { useTheme } from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  YAxis,
  LabelList,
} from "recharts"
import { PointSummary } from "../models/models"
import { mmToIn } from "../utilities/conversion"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

export interface MiniDailySnowBarChartProps {
  pointData: PointSummary
  height?: number
}

export function MiniDailySnowBarChart({
  pointData,
  height,
}: MiniDailySnowBarChartProps) {
  const theme = useTheme()

  return (
    <ResponsiveContainer width="100%" height={height || 120}>
      <BarChart barCategoryGap={4} data={pointData.forecastedSnowTotals}>
        <Bar
          dataKey={(data: any) => mmToIn(data.totalSnow)}
          fill={theme.palette.primary.main}
        >
          <LabelList
            position="top"
            dataKey={(data: any) => Math.round(mmToIn(data.totalSnow)) + '"'}
          />
        </Bar>
        {/* <ChartTooltip /> */}
        <XAxis
          dataKey={(data) => dayjs.utc(data.date).format("dd").slice(0, 1)}
        />
        <YAxis
          domain={[0, "dataMax + 4"]}
          tick={false}
          axisLine={false}
          tickLine={false}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}
