import { Box, IconButton, Paper, Typography } from "@mui/material"
import React from "react"
import { Camera } from "../api/models"
import PlaceIcon from "@mui/icons-material/Place"
import { IosShare, Terrain, Videocam } from "@mui/icons-material"
import { CameraInline } from "./Camera/CameraInline"
import { mToFt } from "../utilities/conversion"

export interface CameraCardProps {
  camera: Camera
}

export function CameraCard({ camera }: CameraCardProps) {
  return (
    <>
      <Box>
        <Paper sx={{ p: 1 }}>
          <Box pb={1} display="flex" justifyContent={'space-between'}>

            <Box display='flex' alignItems={'center'}>
              <Videocam />
              <Typography fontWeight="bold">{camera.name}</Typography>
            </Box>

            {/* <Box display='flex' alignItems={'center'}>
              <IconButton>
                <IosShare />
              </IconButton>
              <Typography fontWeight="bold">Share</Typography>
            </Box> */}

          </Box>
          {camera.distance ? (
            <Box pb={1} display="flex" alignItems="center">
              <PlaceIcon />
              <Typography>
                {Math.round(camera.distance * 10) / 10} miles
              </Typography>
            </Box>
          ) : null}
          <Box pb={1} display="flex" alignItems="center">
            <Terrain />
            <Typography>
              {mToFt(camera.elevation)} ft
            </Typography>
          </Box>
          <Box>
            <CameraInline camera={camera} />
          </Box>
        </Paper>
      </Box>
    </>
  )
}
