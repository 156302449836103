import {
  Alert,
  Box,
  Container,
  CssBaseline,
  Divider,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  useTheme,
} from "@mui/material"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import EditIcon from "@mui/icons-material/Edit"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { getWindSpeedStyle } from "../utilities/styling"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import NorthIcon from "@mui/icons-material/North"
import SouthIcon from "@mui/icons-material/South"
import { useLocation } from "react-router-dom"
import WaterDropIcon from "@mui/icons-material/WaterDrop"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { RainIcon } from "../icons/RainIcon"
import { SunIcon } from "../icons/SunIcon"
import ThermostatIcon from "@mui/icons-material/Thermostat"
import CloudQueueIcon from "@mui/icons-material/CloudQueue"

export enum FAQAnchors {
  snowSummary = "snowSummary",
  snowIconGuide = "snowIconGuide",
  forecastOverview = "forecastOverview",
  hourlyTable = "hourlyTable",
  snarfWeekly = "snarfWeekly",
  stations = "stations",
  cornAlerts = "cornAlerts",
  rockForecastOverview = "rockForecastOverview",
  rockHourlyTable = "rockHourlyTable",
  rockIconGuide = "rockIconGuide",
  forecastElevation = "forecastElevation",
}

export function FAQ() {
  const theme = useTheme()
  const { hash } = useLocation()

  return (
    <>
      <CssBaseline />
      <Container sx={{ py: 5 }} maxWidth="lg">
        <Typography variant="h4">Frequently Asked Questions</Typography>

        <Typography py={2} variant="h5">
          General
        </Typography>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              Where do you get your Weather Forecast Data?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The National Weather Service aka weather.gov. Shout out to our tax
              dollars at work!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              How do I add a new location to my Favorites?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Click the "Star" <StarBorderIcon /> Icon for any spot you want to
              save as a favorite.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How can I rename a spot?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Click the "Pencil" <EditIcon /> Icon and enter the name of your
              spot.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Typography py={2} variant="h5">
          Ski/Ride
        </Typography>

        <Accordion defaultExpanded={hash === "#" + FAQAnchors.snowSummary}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How do I read the Snow Forecast?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              The Snow Forecast is all the hourly snow forecasts added up from
              midnight - midnight of that day. This gives you a high level view
              of which days it's going to dump!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={hash === "#" + FAQAnchors.cornAlerts}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <img
                src="/corn.png"
                style={{ width: "auto", height: "25px" }}
                alt=""
              />
              What does the corn on the cob mean?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              There's a potential corn snow day in the making! Corn Snow is the
              result of melt-freeze cycles, and is a close cousin to powder snow
              as it's fun to ride. We analyze the upcoming forecast to determine
              if conditions are ripe for a corn harvest so you know when to go
              reap it.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={hash === "#" + FAQAnchors.forecastOverview}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              How do I read the Forecast Overview for Skiing/Riding?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction={"row"}>
              <Grid item lg={3} xs={12}>
                <img src={"/fo-tile.png"} alt="" loading="lazy" />
              </Grid>
              <Grid item lg={9} xs={12}>
                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Weather Icon: </span>
                  Below the "Morning" (6 am - 6 pm) and "Evening" (6 pm - 6 am)
                  you'll see the icon that best represents the weather that day.
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Wind: </span>
                  Underneath each icon you'll see a wind direction and wind
                  speed for that section of the day.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      <span style={getWindSpeedStyle(30, theme)}>Yellow </span>
                      represents at least 1 hour of wind speeds between 15 - 30
                      mph
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <span style={getWindSpeedStyle(60, theme)}>Red </span>
                      represents at least 1 hour of wind speeds greater than 30
                      mph
                    </Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Temperature: </span>
                  On the morning side the high temperature for that period, on
                  the evening the low temperature for that period.
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Snow: </span>
                  The amount of snow expected to fall during that period. The
                  Snow icon will be blue when any amount of snow is expected{" "}
                  <AcUnitIcon color="primary" /> and it will be grey{" "}
                  <AcUnitIcon color="disabled" /> when no snow is expected
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Hourly Snow: </span>A
                  graph showing the amount of snow expected to fall each hour.
                  This gives you an idea of when it will be snowing and how
                  heavily.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      X-Axis: Hour of the day in 24-hour time.
                    </Typography>
                  </li>
                  <li>
                    <Typography>Y-Axis: Inches of snowfall</Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Snow Level: </span>The
                  elevation above which it's expected to snow, and below where
                  it's expected to rain (gasp!). Pray for low snow levels.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      The <NorthIcon sx={{ color: "red", fontSize: 15 }} /> snow
                      level represents the maximum snow level expected that day
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      The <SouthIcon sx={{ color: "green", fontSize: 15 }} />{" "}
                      snow level represents the maximum snow level expected that
                      day
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      X-Axis: Hour of the day in 24-hour time.
                    </Typography>
                  </li>
                  <li>
                    <Typography>Y-Axis: Snow level in feet</Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={hash === "#" + FAQAnchors.hourlyTable}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>How do I read the 7 Day Hourly Forecast</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction={"row"}>
              <Grid item lg={5} xs={12}>
                <img src={"/7hf.png"} alt="" loading="lazy" />
              </Grid>
              <Grid item lg={7} xs={12}>
                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Temp: </span>The
                  temperature for that hour, pretty self explanatory.
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Snow Level: </span>The
                  elevation above which it's expected to snow, and below where
                  it's expected to rain (gasp!). Pray for low snow levels.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      <span style={{ color: theme.palette.primary.main }}>
                        Blue{" "}
                      </span>
                      shows the forecasted freezing level is below the elevation
                      of the spot you are forecasting for.
                    </Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Snow: </span>
                  Amount of snow forecasted for that hour.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      <span style={{ color: theme.palette.primary.main }}>
                        Blue{" "}
                      </span>
                      highlights any hours with snow forecasted.
                    </Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Precip: </span>
                  The forecasted amount of precipitation, theoretically the
                  amount of liquid if you melted the snow.
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>
                    Wind Speed / Gusts:{" "}
                  </span>
                  How fast the wind is a blowin.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      <span style={getWindSpeedStyle(30, theme)}>Yellow </span>
                      represents at least 1 hour of wind speeds between 15 - 30
                      mph
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <span style={getWindSpeedStyle(60, theme)}>Red </span>
                      represents at least 1 hour of wind speeds greater than 30
                      mph
                    </Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Wind Direction: </span>
                  Which way the wind is a blowin.
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Cloud Cover: </span> How
                  much of the big blue sky is going to be hidden behind clouds.
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={hash === "#" + FAQAnchors.snowIconGuide}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Ski/Ride Icon Guide</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="30px">
                <img
                  src="/corn.png"
                  style={{ width: "auto", height: "25px" }}
                  alt=""
                />
              </Box>
              <Typography ml={1}>
                Keep an eye out for the corn icon! It tells you when an upcoming
                melt freeze cycle is forecasted so you can hit that perfect
                window.
              </Typography>
            </Box>

            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="30px">
                <SunIcon height="30px" width="30px" fill="orange" />
              </Box>
              <Typography ml={1}>
                It's going to be a sunny clear day. Great day to go get some
                views and a tan.
              </Typography>
            </Box>

            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="30px">
                <CloudQueueIcon fontSize="small" />
              </Box>
              <Typography ml={1}>
                Cloudy day, no reason not to go outside.
              </Typography>
            </Box>

            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="30px">
                <RainIcon
                  fill={theme.palette.primary.main}
                  height="25px"
                  width="25px"
                />
              </Box>

              <Typography ml={1}>
                It's gonna rain. No need to let it stop you, but less than
                ideal.
              </Typography>
            </Box>

            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="40px" display="flex" ml="-10px">
                <ThermostatIcon />
                <Box display="flex" flexDirection={"column"}>
                  <Typography variant="caption">70&#176;</Typography>
                  <Typography variant="caption">50&#176;</Typography>
                </Box>
              </Box>

              <Typography ml={1}>
                The high and low temperature from sun up to sun down. Do I need
                the puffy or not?
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={hash === "#" + FAQAnchors.snarfWeekly}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>What is Snarf Weekly?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Nobody wants to miss a big storm. Snarf Weekly is sent to your
              email inbox every Monday with all the upcoming Snow Totals for
              your favorite spots. Helping you keep an eye on any incoming
              powder days so you can get a head start planning your next outing.
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion defaultExpanded={hash === "#" + AboutAnchors.stations}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Where does the station data come from?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              
            </Typography>
          </AccordionDetails>
        </Accordion> */}

        <Typography py={2} variant="h5">
          Rock Climbing
        </Typography>
        <Accordion
          defaultExpanded={hash === "#" + FAQAnchors.rockForecastOverview}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              How do I read the Forecast Overview for Rock Climbing?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction={"row"}>
              <Grid item lg={3} xs={12}>
                <img src={"/rock-fo-tile.png"} alt="" loading="lazy" />
              </Grid>
              <Grid item lg={9} xs={12}>
                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Weather Icon: </span>
                  Below the "Morning" (6 am - 6 pm) and "Evening" (6 pm - 6 am)
                  you'll see the icon that best represents the weather that day.
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Wind: </span>
                  Underneath each icon you'll see a wind direction and wind
                  speed for that section of the day.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      <span style={getWindSpeedStyle(30, theme)}>Yellow </span>
                      represents at least 1 hour of wind speeds between 15 - 30
                      mph
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <span style={getWindSpeedStyle(60, theme)}>Red </span>
                      represents at least 1 hour of wind speeds greater than 30
                      mph
                    </Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Temperature: </span>
                  On the morning side the high temperature for that period, on
                  the evening the low temperature for that period.
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Precipitation: </span>
                  The amount of precipitation expected to fall during that
                  period. The precipitation icon will be blue when any amount of
                  precipitation is expected <WaterDropIcon color="primary" />{" "}
                  and it will be grey <WaterDropIcon color="disabled" /> when no
                  precipitation is expected
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>
                    Hourly Precipitation:{" "}
                  </span>
                  A graph showing the amount of precipitation expected to fall
                  each hour. This gives you an idea of when it will be raining
                  (or snowing), and how heavily.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      X-Axis: Hour of the day in 24-hour time.
                    </Typography>
                  </li>
                  <li>
                    <Typography>Y-Axis: Inches of Precipitation</Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>
                    Probability of Precipitation:{" "}
                  </span>
                  According to Noaa, this metric means The "Probability of
                  Precipitation (PoP) simply describes the probability that the
                  forecast grid/point in question will receive at least 0.01" of
                  rain."
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      X-Axis: Hour of the day in 24-hour time.
                    </Typography>
                  </li>
                  <li>
                    <Typography>Y-Axis: Snow level in feet</Typography>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={hash === "#" + FAQAnchors.rockHourlyTable}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              How do I read the 7 Day Hourly Forecast for Rock Climbing
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container direction={"row"}>
              <Grid item lg={5} xs={12}>
                <img src={"/7hf-rock.png"} alt="" loading="lazy" />
              </Grid>
              <Grid item lg={7} xs={12}>
                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Temp: </span>The
                  temperature for that hour, pretty self explanatory.
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Precip: </span>
                  The forecasted amount of precipitation, keep that rock dry!
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      <span style={{ color: theme.palette.primary.main }}>
                        Blue{" "}
                      </span>
                      highlights any measurable precipitation values
                    </Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>
                    Probability of Precip:{" "}
                  </span>
                  The "Probability of Precipitation" (PoP) simply describes the
                  probability that the forecast grid/point in question will
                  receive at least 0.01" of rain.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      <span style={{ color: theme.palette.primary.main }}>
                        Blue{" "}
                      </span>
                      highlights any probabilities above 30 %
                    </Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>
                    Wind Speed / Gusts:{" "}
                  </span>
                  How fast the wind is a blowin.
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      <span style={getWindSpeedStyle(30, theme)}>Yellow </span>
                      represents at least 1 hour of wind speeds between 15 - 30
                      mph
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      <span style={getWindSpeedStyle(60, theme)}>Red </span>
                      represents at least 1 hour of wind speeds greater than 30
                      mph
                    </Typography>
                  </li>
                </ul>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Wind Direction: </span>
                  Which way the wind is a blowin.
                </Typography>

                <Typography sx={{ mt: 2 }}>
                  <span style={{ fontWeight: "bold" }}>Cloud Cover: </span> How
                  much of the big blue sky is going to be hidden behind clouds.
                </Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded={hash === "#" + FAQAnchors.rockIconGuide}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Rock Climbing Icon Guide</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="50px" display="flex" justifyContent="center">
                <CheckCircleIcon htmlColor="green" />
              </Box>
              <Typography ml={1}>
                Go climb! No rain in the last 24 hours, something will be dry!
              </Typography>
            </Box>

            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="50px" display="flex" justifyContent="center">
                <CheckCircleOutlineIcon color="warning" />
              </Box>
              <Typography ml={1}>
                It rained yesterday, but will be dry today. There's a chance you
                can find something dry. Better than sitting at home!
              </Typography>
            </Box>

            <Box display="flex" alignItems={"center"} pb={1}>
              <Box pl="4px" width="50px" display="flex" justifyContent="center">
                <SunIcon height="30px" width="30px" fill="orange" />
              </Box>
              <Typography ml={1}>
                It's going to be a sunny clear day. Great day to go get some
                views and a tan.
              </Typography>
            </Box>

            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="50px" display="flex" justifyContent="center">
                <CloudQueueIcon fontSize="small" />
              </Box>
              <Typography ml={1}>
                Cloudy day, no reason not to go outside.
              </Typography>
            </Box>

            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="50px" display="flex" justifyContent="center">
                <Box display="flex" alignItems="center">
                  <RainIcon
                    fill={theme.palette.primary.main}
                    height="25px"
                    width="25px"
                  />
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    justifyContent="center"
                    alignItems="center"
                    pl="2px"
                  >
                    <Typography variant="caption">30%</Typography>
                    <Typography variant="caption">0.04</Typography>
                  </Box>
                </Box>
              </Box>

              <Typography ml={1}>
                It's might rain. Top number signifies the Probability of
                Precipitation and the bottom number the inches of precipitation.
                While wet slab is a delicacy, might be better to look elsewhere.
              </Typography>
            </Box>

            <Box display="flex" alignItems={"center"} pb={1}>
              <Box width="50px" display="flex">
                <ThermostatIcon />
                <Box display="flex" flexDirection={"column"}>
                  <Typography variant="caption">70&#176;</Typography>
                  <Typography variant="caption">50&#176;</Typography>
                </Box>
              </Box>

              <Typography ml={1}>
                The high and low temperature from sun up to sun down. Do I need
                the puffy or not?
              </Typography>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  )
}
