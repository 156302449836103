import { Box, Chip, Grid, Paper, Typography, useTheme } from "@mui/material"
import { Container } from "@mui/system"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { gql, useQuery as useGraphqlQuery } from "urql"
import { topSkiAreas } from "../graphql/queries/top-ski-areas"
import { PointOfInterest } from "../models/models"
import { SkiCompactTile } from "../components/Snow/SkiCompactTile"
import dayjs from "dayjs"

const stateFilters = [
  {
    label: "USA",
    value: "usa",
    id: "usa",
    filter: { state: undefined },
  },
  {
    label: "Arizona",
    value: "arizona",
    id: "arizona",
    filter: { state: "arizona" },
  },
  {
    label: "California",
    value: "california",
    id: "california",
    filter: { state: "california" },
  },
  {
    label: "Colorado",
    value: "colorado",
    id: "colorado",
    filter: { state: "colorado" },
  },
  {
    label: "Montana",
    value: "montana",
    id: "montana",
    filter: { state: "montana" },
  },
  {
    label: "New Mexico",
    value: "new mexico",
    id: "new mexico",
    filter: { state: "new mexico" },
  },
  {
    label: "Nevada",
    value: "nevada",
    id: "nevada",
    filter: { state: "nevada" },
  },
  {
    label: "Utah",
    value: "utah",
    id: "utah",
    filter: { state: "utah" },
  },
  {
    label: "Oregon",
    value: "oregon",
    id: "oregon",
    filter: { state: "oregon" },
  },
  {
    label: "Washington",
    value: "washington",
    id: "washington",
    filter: { state: "washington" },
  },
  {
    label: "Wyoming",
    value: "wyoming",
    id: "wyoming",
    filter: { state: "wyoming" },
  },
]

export function SnowScanner() {
  const navigate = useNavigate()
  const theme = useTheme()
  const [filters, setFilters] = useState<any>({})

  const [skiAreasQuery] = useGraphqlQuery({
    query: topSkiAreas,
    variables: {
      startDay: dayjs().startOf("day").subtract(1, "day").toISOString(),
      endDay: dayjs().endOf("day").add(7, "days").toISOString(),
      filters,
      sort: { forecastedSnow: true },
      paging: { skip: 0, limit: 10 },
    },
  })

  const onCardClick = (pointData: PointOfInterest) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    navigate(`/point/${pointData.id}`)
  }

  const updateFilters = (filter: any) => {
    setFilters({ ...filters, ...filter })
  }

  return (
    <Container maxWidth="lg" sx={{ py: 1 }}>
      <Box display="flex" justifyContent={"center"} py={1}>
        <Typography
          component="h1"
          variant="h3"
          color={theme.palette.primary.main}
        >
          Top Forecasts
        </Typography>
      </Box>

      <Box display="flex" py={1} overflow={"scroll"}>
        {stateFilters &&
          stateFilters.map((state) => {
            return (
              <Box sx={{ px: "4px" }} key={state.id}>
                <Chip
                  color={
                    (filters.state && filters.state === state.id) ||
                    (!filters.state && state.id === "usa")
                      ? "primary"
                      : "default"
                  }
                  label={<Typography>{state.label}</Typography>}
                  sx={{ pointer: "cursor" }}
                  onClick={() => updateFilters(state.filter)}
                />
              </Box>
            )
          })}
      </Box>
      <Grid container justifyContent={"center"} spacing={1}>
        {skiAreasQuery.data &&
          skiAreasQuery.data.skiAreas.map((area: any) => {
            return (
              <Grid sx={{ padding: 0 }} item key={area.id} xs={12}>
                <SkiCompactTile area={area} onCardClick={onCardClick} />
              </Grid>
            )
          })}
      </Grid>
    </Container>
  )
}
