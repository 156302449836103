import { PointSummary } from "../models/models"

export enum CameraSource {
  WSDOT,
}

export enum CameraDisplayType {
  IMAGE,
  VIDEO,
}

export enum SubscriptionType {
  AVALANCHE = "AVALANCHE",
}

export enum SubscriptionStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export interface Subscription {
  id: string
  type: SubscriptionType
  status: SubscriptionStatus
  zoneId?: number
  avalancheZone?: AvalancheZone
}

export interface AvalancheZone {
  id: string
  name: string
  url: string
}

export interface Camera {
  id: string
  distance: number
  source: CameraSource
  displayType: CameraDisplayType
  lat: number
  lon: number
  url: string
  name: string
  elevation: number
}

export interface SkiRating {
  rating: number
}

export interface DaySummary {
  date: string
  totalSnow: number
}

export enum UserPointOfInterestType {
  SKI,
  ROCK_CLIMB,
  HIKING,
}

export enum SearchResultType {
  SKI_AREA = "SKI_AREA",
  ROCK_CLIMB = "ROCK_CLIMB",
  MOUNTAIN = "MOUNTAIN",
  STATION = "STATION",
  PLACE = "PLACE",
}

export interface SearchResult {
  id: string
  name: string
  lat: number
  lon: number
  type?: SearchResultType
  pathTokens?: string[]
  source: string
}

export enum RockStatus {
  YES = "YES",
  MAYBE = "MAYBE",
  NO = "NO",
}

export interface Station {
  id: string
  STID: string
  name: string
  lat: number
  lon: number
  elevation: number
  sensorVariables: string
  isFavorite: boolean
}

export interface SynopticStation {
  id: string
  distance: number
  NWSFIREZONE: string
  ELEV_DEM: string
  UNITS: {
    position: string
    elevation: string
  }
  TIMEZONE: string
  SGID: string
  SHORTNAME: string
  ELEVATION: string
  STID: string
  WIMS_ID: string | null
  GACC: string
  STATUS: string
  PERIOD_OF_RECORD: {
    start: string
    end: string
  }
  LONGITUDE: string
  COUNTY: string
  STATE: string
  CWA: string
  NWSZONE: string
  ID: string
  MNET_ID: string
  NAME: string
  COUNTRY: string
  RESTRICTED: boolean
  LATITUDE: string
  sensorVariables: any
  snowDepth?: number
  temperature?: number
}

export interface SkiArea {
  id: string
  name: string
  lat: number
  lon: number
  pointId: string
  state: string
  dashboard?: boolean
  type: SkiAreaType
  forecastedSnow: number
  point: PointSummary
}

export enum SkiAreaType {
  RESORT = "RESORT",
  BACKCOUNTRY = "BACKCOUNTRY",
}
