import { Box, Grid, Typography } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import React, { useEffect, useState } from "react"
import { DailyForecast } from "../models/models"
import { groupHourlyForecastByDay } from "../utilities/analysis"
import { mmToIn, mToFt } from "../utilities/conversion"
import { RockWeatherTile } from "./RockWeatherTile"
import { WeatherTile } from "./WeatherTile"

export interface RockWeatherViewerProps {
  dailyWeather: DailyForecast[]
  hourlyForecast: any[]
  elevation?: number
  pointSummary: any
}

export function RockWeatherViewer({
  dailyWeather,
  hourlyForecast,
  elevation,
  pointSummary,
}: RockWeatherViewerProps) {
  const [hourlyCastByDay, setHourlyCastByDay] = useState<any>({})

  useEffect(() => {
    if (hourlyForecast) {
      setHourlyCastByDay(groupHourlyForecastByDay(hourlyForecast))
    }
  }, [hourlyForecast])

  return (
    <Box>
      <Grid container wrap="nowrap" overflow="scroll" spacing={1}>
        {Object.keys(hourlyCastByDay).map((date) => {
          const dayCast = dailyWeather.find((daily) =>
            dayjs(Number(daily.date)).isSame(dayjs(date), "day")
          )

          const ratingKey = dayjs(date).format("YYYY-MM-DD")
          let rating = 0
          if (pointSummary?.skiRatings && pointSummary.skiRatings[ratingKey]) {
            rating = pointSummary.skiRatings[ratingKey].rating
          }

          let totalPrecipMorning = 0
          let totalPrecipEvening = 0
          let morningMaxWindSpeed: number | null = null
          let morningMaxWindGust: number | null = null
          let eveningMaxWindSpeed: number | null = null
          let eveningMaxWindGust: number | null = null
          for (let cast of hourlyCastByDay[date]) {
            const ts = dayjs(cast.date)
            if (ts.hour() >= 0 && ts.hour() < 12) {
              if (cast.quantitativePrecipitation) {
                totalPrecipMorning += cast.quantitativePrecipitation
              }

              if (cast.windSpeed) {
                if (
                  morningMaxWindSpeed === null ||
                  cast.windSpeed > morningMaxWindSpeed
                ) {
                  morningMaxWindSpeed = cast.windSpeed
                }
              }

              if (cast.windGust) {
                if (
                  morningMaxWindGust === null ||
                  cast.windGust > morningMaxWindGust
                ) {
                  morningMaxWindGust = cast.windGust
                }
              }
            } else {
              // evening

              if (cast.quantitativePrecipitation) {
                totalPrecipEvening += cast.quantitativePrecipitation
              }

              if (cast.windSpeed) {
                if (
                  eveningMaxWindSpeed === null ||
                  cast.windSpeed > eveningMaxWindSpeed
                ) {
                  eveningMaxWindSpeed = cast.windSpeed
                }
              }

              if (cast.windGust) {
                if (
                  eveningMaxWindGust === null ||
                  cast.windGust > eveningMaxWindGust
                ) {
                  eveningMaxWindGust = cast.windGust
                }
              }
            }
          }

          return (
            <RockWeatherTile
              key={date}
              date={date}
              totalPrecipMorning={totalPrecipMorning}
              totalPrecipEvening={totalPrecipEvening}
              morningMaxWindGust={morningMaxWindGust}
              eveningMaxWindGust={eveningMaxWindGust}
              morningMaxWindSpeed={morningMaxWindSpeed}
              eveningMaxWindSpeed={eveningMaxWindSpeed}
              dayForecast={dayCast?.daytime}
              nightForecast={dayCast?.nighttime}
              hourlyForecast={hourlyCastByDay[date]}
              elevation={elevation}
              rating={rating}
            />
          )
        })}
      </Grid>
    </Box>
  )
}
