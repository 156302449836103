import { HelpOutline } from "@mui/icons-material"
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { sendEvent, SnarfEvents } from "../utilities/metrics"
import { HelpCorn } from "./HelpContent/HelpCorn"
import { HelpForecastElevation } from "./HelpContent/HelpForecastElevation"
import { HelpForecastOverview } from "./HelpContent/HelpForecastOverview"
import { HelpHourlyTable } from "./HelpContent/HelpHourlyTable"
import { HelpRockForecastOverview } from "./HelpContent/HelpRockForecastOverview"
import { HelpRockHourlyTable } from "./HelpContent/HelpRockHourlyTable"
import { HelpRockIconGuide } from "./HelpContent/HelpRockIconGuide"
import { HelpSnarfWeekly } from "./HelpContent/HelpSnarfWeekly"
import { HelpSnowIcons } from "./HelpContent/HelpSnowIcons"
import { HelpSnowSummary } from "./HelpContent/HelpSnowSummary"
import { HelpStations } from "./HelpContent/HelpStations"

const contentMap: { [key: string]: any } = {
  snowSummary: <HelpSnowSummary />,
  snowIconGuide: <HelpSnowIcons />,
  forecastOverview: <HelpForecastOverview />,
  hourlyTable: <HelpHourlyTable />,
  snarfWeekly: <HelpSnarfWeekly />,
  stations: <HelpStations />,
  cornAlerts: <HelpCorn />,
  rockForecastOverview: <HelpRockForecastOverview />,
  rockHourlyTable: <HelpRockHourlyTable />,
  rockIconGuide: <HelpRockIconGuide />,
  forecastElevation: <HelpForecastElevation />,
}
export interface HelpMeProps {
  tag: string,
  event: SnarfEvents
}

export function HelpMe({ tag, event }: HelpMeProps) {
  const navigate = useNavigate()
  const [showHelp, setShowHelp] = useState(false)

  const onClick = () => {
    setShowHelp(true)
    sendEvent(event)
  }
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={onClick}>
          <HelpOutline fontSize="small" />
        </IconButton>
        {/* <Typography
          onClick={onClick}
          sx={{
            fontWeight: "italic",
            textDecoration: "underline",
            mt: "2px",
            ml: "2px",
            cursor: "pointer",
          }}
          variant="caption"
        >
          Help me understand this
        </Typography> */}
      </Box>
      <Dialog open={showHelp} onClose={() => setShowHelp(false)} maxWidth="lg">
        {contentMap[tag]}
        <Box display="flex" justifyContent="center" py={1}>
          <Button variant={"contained"} onClick={() => setShowHelp(false)}>
            Close
          </Button>
        </Box>
      </Dialog>
    </>
  )
}
