import React from "react"
import { PointSummary } from "../models/models"
import { UserPointOfInterestType } from "../api/models"
import { SkiCard } from "./SkiCard"
import { RockCard } from "./RockClimbCard"

export interface PointCardProps {
  pointData: PointSummary
  onClick?: (point: PointSummary) => any
  setIsFavorite?: (pointId: string, isFavorite: boolean) => any
  favoritesCount: number
  link?: boolean
}

export function PointCard(props: PointCardProps) {
  switch (props.pointData.type) {
    case UserPointOfInterestType.SKI:
      return <SkiCard {...props} />
    case UserPointOfInterestType.ROCK_CLIMB:
      return <RockCard {...props} />
    default:
      return <SkiCard {...props} />
  }
}
