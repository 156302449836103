import { Box, CircularProgress } from "@mui/material"
import React from "react"
import { gql, useQuery as useGraphqlQuery } from "urql"
import { cameraByIdQuery } from "../graphql/queries/camera-by-id"
import { CameraCard } from "./CameraCard"

export interface CameraViewerProps {
  cameraId: string
}

export function CameraViewer({ cameraId }: CameraViewerProps) {
  const [cameraRequest] = useGraphqlQuery({
    query: cameraByIdQuery,
    variables: {
      id: cameraId,
    },
    pause: !cameraId,
  })

  return (
    <>
      {cameraRequest.data?.camera ? (
        <CameraCard camera={cameraRequest.data?.camera} />
      ) : (
        <Box
          display="flex"
          height="inherit"
          width="inherit"
          justifyContent={"center"}
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </>
  )
}
