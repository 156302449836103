export enum MapLayers {
  CLIMBING_AREAS = "CLIMBING_AREAS",
  SKI_AREAS = "SKI_AREAS",
  NEAR_ME = "NEAR_ME",
  FAVORITES = "FAVORITES",
  POINT_FORECAST = "POINT_FORECAST",
  SELECTED_POINT = "SELECTED_POINT",
  STATIONS = "STATIONS",
  CAMERAS = "CAMERAS",
  AVALANCHE = "AVALANCHE",
}
