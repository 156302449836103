import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  useMediaQuery,
} from "@mui/material"
import Box from "@mui/material/Box"
import Container from "@mui/material/Container"
import CssBaseline from "@mui/material/CssBaseline"
import Grid from "@mui/material/Grid"
import { createTheme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { updateFavorite, getSummary, getUser } from "../api/snarf-api"
import { PointCard } from "../components/PointCard"
import { auth } from "../firebase"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { PointOfInterest, PointSummary, User } from "../models/models"
import { LoginModal } from "../components/LoginModal"
import { useQuery } from "@tanstack/react-query"
import { Station, UserPointOfInterestType } from "../api/models"
import { ActivityContext, UserPremiumContext } from "./Header"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import { FavoritesHelpBanner } from "../components/FavoritesHelpBanner"
import { TabBar } from "../components/MobileTabBar"
import { WhatsInTable } from "../components/WhatsInTable"
import { CellTower, Landscape } from "@mui/icons-material"
import { gql, useQuery as useGraphqlQuery } from "urql"
import { StationCard } from "../components/StationCard"
import useDocumentTitle from "../hooks/use-document-title"
import { stationsQuery } from "../graphql/queries"
import { pointsQuery } from "../graphql/queries/points"
import { AdSplashPage } from "../ads/AdSplashPage"
import { SnarfEvents, sendEvent } from "../utilities/metrics"
import { SubscribeModal } from "../components/SubscribeModal"
import { userPremiumQuery } from "../graphql/queries/user-premium"
import { pointsHomeQuery } from "../graphql/queries/points-home"
import dayjs from "dayjs"

const theme = createTheme()

const tabs = [
  {
    label: "Locations",
    icon: (
      color:
        | "inherit"
        | "disabled"
        | "action"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning"
        | undefined
    ) => <Landscape color={color} />,
    active: true,
    id: 0,
  },
  {
    label: "Stations",
    icon: (
      color:
        | "inherit"
        | "disabled"
        | "action"
        | "primary"
        | "secondary"
        | "error"
        | "info"
        | "success"
        | "warning"
        | undefined
    ) => <CellTower color={color} />,
    active: false,
    id: 1,
  },
]

interface HomeProps {
  points: PointOfInterest[]
}

export function Home(props: HomeProps) {
  const navigate = useNavigate()
  const [points, setPoints] = useState<PointSummary[]>([])
  const [pointsSet, setPointsSet] = useState(false)
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const [user, loadingAuthState] = useAuthState(auth)
  const [currentTab, setCurrentTab] = useState(0)

  const isSmall = useMediaQuery("(max-width:600px)")
  const selectedActivity = useContext(ActivityContext)
  const userIsPremium = useContext(UserPremiumContext)
  const [snarfUser, setSnarfUser] = useState<User | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()
  useDocumentTitle("Snarf Favorites")

  useEffect(() => {
    if (searchParams.get("tab")) {
      setCurrentTab(Number(searchParams.get("tab")))
    }
  }, [searchParams])

  const [userStationsRequest] = useGraphqlQuery({
    query: stationsQuery,
    variables: {
      filters: {
        isFavorite: true,
      },
      type: selectedActivity,
    },
    pause: loadingAuthState || currentTab !== 1,
  })

  const [userQuery] = useGraphqlQuery({
    query: userPremiumQuery,
    pause: loadingAuthState || !user,
  })

  const [userLocationsRequest] = useGraphqlQuery({
    query: pointsHomeQuery,
    variables: {
      startDate: dayjs().startOf("day").toISOString(),
      endDate: dayjs().add(7, "days").endOf("day").toISOString(),
      filters: {
        isFavorite: true,
        type: selectedActivity,
      },
      type: selectedActivity,
    },
    pause: loadingAuthState,
  })

  const userRequest = useQuery({
    queryKey: ["user", user?.email],
    queryFn: (): any => getUser(),
    enabled: !!user,
  })

  if (!userRequest.isLoading && !snarfUser) {
    if (!userRequest?.data?.processing) {
      setSnarfUser({ ...userRequest.data })
    }
  }

  const summaryRequest = useQuery({
    queryKey: ["summary", user?.email],
    queryFn: (): any => getSummary(),
    enabled:
      !loadingAuthState &&
      selectedActivity === UserPointOfInterestType.ROCK_CLIMB,
  })

  if (!summaryRequest.isLoading && !pointsSet && points.length === 0) {
    if (!summaryRequest.data.processing) {
      setPointsSet(true)
      setPoints(
        summaryRequest.data.data.sort((a: PointSummary, b: PointSummary) =>
          b.name > a.name ? -1 : 1
        )
      )
    }
  }

  const isLoading =
    (selectedActivity === UserPointOfInterestType.SKI &&
      userLocationsRequest.fetching) ||
    (selectedActivity === UserPointOfInterestType.ROCK_CLIMB &&
      summaryRequest.isLoading)

  const onTabClick = (id: string | number) => {
    setCurrentTab(Number(id))
    searchParams.set("tab", String(id))
    setSearchParams(searchParams)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const getFavoriteContent = useMemo(() => {
    const onCardClick = (pointData: PointOfInterest) => {
      sendEvent(SnarfEvents.FavoriteClick)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      navigate(`/point/${pointData.id}`, { state: { back: "Favorites" } })
    }

    const onSetFavorite = (pointId: string, isFavorite: boolean) => {
      if (user) {
        // HACK need to get off summary request for rock climbing
        if (selectedActivity === UserPointOfInterestType.ROCK_CLIMB) {
          const newPoints = [...points]
          const pointToUpdate = points.find((point) => point.id === pointId)
          if (pointToUpdate) {
            pointToUpdate.isFavorite = isFavorite
            setPoints(newPoints)
          }

          updateFavorite(pointId, isFavorite, selectedActivity).then(() => {
            summaryRequest.refetch()
            setPoints([])
            setPointsSet(false)
          })
        } else {
          // Handled in cards
        }
      } else {
        setShowLoginPopup(true)
      }
    }

    switch (currentTab) {
      case 0: {
        let points = userLocationsRequest.data
          ? userLocationsRequest.data.points
          : []

        if (!userIsPremium && points.length > 3) {
          points = points.slice(0, 3)
        }
        return (
          <>
            {selectedActivity === UserPointOfInterestType.SKI && (
              <Grid container justifyContent={"center"} spacing={1} p={1}>
                {points.map((card: PointSummary, index: number) => {
                  return (
                    <>
                      <Grid
                        sx={{ padding: 0 }}
                        item
                        key={card.id}
                        xs={12}
                        sm={6}
                        md={3}
                      >
                        <PointCard
                          pointData={card}
                          onClick={onCardClick}
                          setIsFavorite={onSetFavorite}
                          favoritesCount={points.length}
                        />
                      </Grid>
                      <Box maxWidth={280}>
                        <AdSplashPage />
                      </Box>
                    </>
                  )
                })}
              </Grid>
            )}

            {selectedActivity === UserPointOfInterestType.ROCK_CLIMB && (
              <Container>
                <WhatsInTable points={points} onSetFavorite={onSetFavorite} />
              </Container>
            )}
          </>
        )
      }
      case 1: {
        if (!userStationsRequest.data) {
          return (
            <Box
              display="flex"
              height="100vh"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Box>
          )
        }

        let stations = userStationsRequest.data.stations
        if (!userIsPremium && stations.length > 3) {
          stations = stations.slice(0, 3)
        }

        return (
          <Box display="flex" flexWrap={"wrap"} justifyContent={"center"}>
            {stations
              .sort((a: Station, b: Station) => a.name > b.name)
              .map((station: Station) => {
                return (
                  <Box key={station.id} p={1}>
                    <StationCard station={station} />
                  </Box>
                )
              })}
          </Box>
        )
      }
      default:
        break
    }
  }, [
    currentTab,
    navigate,
    points,
    selectedActivity,
    summaryRequest,
    user,
    userLocationsRequest.data,
    userStationsRequest.data,
  ])

  return (
    <>
      <CssBaseline />
      <LoginModal
        open={showLoginPopup}
        onClose={() => {
          setShowLoginPopup(false)
          navigate(0)
        }}
      />
      <SubscribeModal
        message={"Start a free trial to checkout Maps and more with Snarf Pro."}
        open={
          (!loadingAuthState && !user) ||
          (userQuery.data &&
            (!userQuery.data.user || !userQuery.data.user.isPremium))
        }
        noClose={
          !user ||
          !userQuery.data ||
          !userQuery.data.user ||
          !userQuery.data.user.isPremium
        }
      />

      {isSmall ? (
        <Box pb={1}>
          <TabBar
            tabs={tabs}
            onTabSelect={onTabClick}
            selectedTab={currentTab}
          />
        </Box>
      ) : null}

      {!loadingAuthState &&
        userQuery.data &&
        (!userQuery.data.user || !userQuery.data.user.isPremium) &&
        isSmall && (
          <Box p={1}>
            <Alert severity="info">
              <Typography>
                <Link to="/subscribe">Subscribe</Link> to get unlimited
                Favorites and go Ad free.
              </Typography>
            </Alert>
          </Box>
        )}

      {isLoading ? (
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <>
          <Container sx={{ pb: 5 }} maxWidth="lg">
            <FavoritesHelpBanner />
            {!userIsPremium && !isSmall && (
              <Box py={1}>
                <Alert severity="info">
                  <Typography>
                    <Link to="/subscribe">Subscribe</Link> to get unlimited
                    Favorites.
                  </Typography>
                </Alert>
              </Box>
            )}
            {isSmall ? null : (
              <Box display="flex" justifyContent={"space-around"} py={1}>
                {tabs.map((tab) => (
                  <Paper
                    key={tab.id}
                    elevation={tab.id === currentTab ? 4 : 1}
                    sx={{ height: "50px", width: "130px" }}
                  >
                    <Button
                      sx={{
                        color: "black",
                        height: "100%",
                        width: "100%",
                      }}
                      onClick={() => onTabClick(tab.id)}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={
                          tab.id === currentTab
                            ? {
                                color: theme.palette.primary.main,
                              }
                            : {}
                        }
                      >
                        {tab.icon(
                          currentTab === tab.id ? "primary" : "disabled"
                        )}
                        <Typography sx={{ pl: 1 }}>{tab.label}</Typography>
                      </Box>
                    </Button>
                  </Paper>
                ))}
              </Box>
            )}

            {userLocationsRequest.data &&
            userLocationsRequest.data.points.length === 0 &&
            currentTab === 0 ? (
              <Box display="flex" justifyContent={"center"} textAlign="center">
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h5">
                    It's looking a little empty here
                  </Typography>
                  <Typography py={1}>
                    Add some favorites by visiting a location and clicking the{" "}
                    <StarBorderIcon />. Try searching for a location using the
                    search bar, or explore some popular spots to recreate on the
                    Map.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate("/map", { state: { previous: "/home" } })
                    }
                  >
                    Map
                  </Button>
                </Paper>
              </Box>
            ) : null}

            {userStationsRequest.data &&
            userStationsRequest.data.stations.length === 0 &&
            currentTab === 1 ? (
              <Box display="flex" justifyContent={"center"} textAlign="center">
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h5">
                    It's looking a little empty here
                  </Typography>
                  <Typography py={1}>
                    Add some favorites by visiting a station and clicking the{" "}
                    <StarBorderIcon />. Try searching for a station using the
                    search bar, or find stations using the Map.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate("/map", { state: { previous: "/home" } })
                    }
                  >
                    Map
                  </Button>
                </Paper>
              </Box>
            ) : null}

            {getFavoriteContent}
          </Container>
        </>
      )}
    </>
  )
}
