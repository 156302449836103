import {
  Alert,
  Box,
  Container,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Link as MUILink,
  Chip,
  useMediaQuery,
  CircularProgress,
  Button,
  useTheme,
  IconButton,
} from "@mui/material"
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import dayjs, { Dayjs } from "dayjs"
import React, { useContext, useEffect, useState } from "react"
import {
  Tooltip as ChartTooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  BarChart,
} from "recharts"
import CellTowerIcon from "@mui/icons-material/CellTower"
import PlaceIcon from "@mui/icons-material/Place"
import { gql, useQuery as useGraphqlQuery } from "urql"

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import LandscapeIcon from "@mui/icons-material/Landscape"
import { cToF, msToMph, mmToIn } from "../utilities/conversion"
import { useQuery } from "@tanstack/react-query"
import { getStationObservations } from "../api/snarf-api"
import { stationByIdQuery } from "../graphql/queries/station-by-id"
import { StationDetailHeader } from "./StationDetailHeader"
import { DateCarousol } from "./DateCarousol"
import { StationHourlyTable } from "./Station/StationHourlyTable"
import { North, ShowChart, TableRows } from "@mui/icons-material"
import { ActivityContext } from "../pages/Header"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import { stat } from "fs"

export enum GraphType {
  Bar = "BAR",
}

export const stationMetrics: {
  [key: string]: {
    conversionFunction?: (value: any) => any
    title: string
    units?: string
    graphType?: GraphType
    component?: (params: any) => any
  }
} = {
  air_temp: {
    title: "Temperature",
    conversionFunction: cToF,
    units: "F",
  },
  wind_gust: {
    title: "Wind Gust",
    conversionFunction: msToMph,
    units: "Mph",
  },
  snow_interval: {
    title: "24 Hour Snow total",
    conversionFunction: mmToIn,
    units: "In",
  },
  wind_direction: {
    title: "Wind Direction",
    units: "Deg",
    component: (windDirection: number) =>
      windDirection !== null && windDirection !== undefined ? (
        <North
          sx={{
            transform: `rotate(${windDirection - 180}deg)`,
          }}
        />
      ) : null,
  },
  wind_chill: {
    conversionFunction: cToF,
    units: "F",
    title: "Wind Chill",
  },
  relative_humidity: {
    title: "Relative Humidity",
    units: "%",
    conversionFunction: Math.round,
  },
  wind_speed: {
    title: "Wind Speed",
    conversionFunction: msToMph,
    units: "Mph",
  },
  dew_point_temperature: {
    title: "Dew Point Temperature",
    conversionFunction: cToF,
    units: "F",
  },
  precip_accum_24_hour: {
    conversionFunction: mmToIn,
    title: "24 hour precip",
    units: "In",
  },
  precip_accum_since_local_midnight: {
    conversionFunction: mmToIn,
    title: "Precip since midnight",
    units: "In",
  },
  solar_radiation: {
    conversionFunction: mmToIn,
    title: "Solar Radiation",
    units: "W/m**2",
  },
  snow_depth: {
    conversionFunction: mmToIn,
    title: "Snow Depth",
    units: "In",
  },
  snow_water_equiv: {
    conversionFunction: mmToIn,
    title: "Snow Water Equivilent",
    units: "In",
  },
  snow_smoothed: {
    conversionFunction: mmToIn,
    title: "Snow Smoothed",
    units: "In",
  },
  precip_accum: {
    conversionFunction: mmToIn,
    title: "Precip accum",
    units: "In",
  },
  precip_accum_one_hour: {
    conversionFunction: mmToIn,
    title: "Precip",
    units: "In",
    graphType: GraphType.Bar,
  },
  precip_smoothed: {
    conversionFunction: mmToIn,
    title: "Precip smoothed",
    units: "In",
  },
  pressure: {
    units: "Pascals",
    title: "Pressure",
  },
}

export interface StationViewerProps {
  stationId: string
}

export const StationViewer = ({ stationId }: StationViewerProps) => {
  const [beginDate, setBeginDate] = useState(
    dayjs(Date.now()).subtract(7, "day")
  )
  const [stationIdInternal, setStationIdInternal] = useState(stationId)
  const [observationData, setObservationData] = useState<any[] | null>(null)
  const [showGraphs, setShowGraphs] = useState(true)
  const [selectedGraphMetric, setSelectedGraphMetric] = useState<string | null>(
    null
  )
  const [endDate, setEndDate] = useState(dayjs(Date.now()).add(1, "day"))
  const theme = useTheme()

  const [day, setDay] = useState(dayjs().startOf("day"))
  const isSmall = useMediaQuery("(max-width:600px)")
  const selectedActivity = useContext(ActivityContext)
  const [user, loadingAuthState] = useAuthState(auth)

  useEffect(() => {
    if (stationId !== stationIdInternal) {
      setStationIdInternal(stationId)
      setSelectedGraphMetric(null)
    }
  }, [stationId, stationIdInternal])

  const onDayChange = (date: Dayjs | null) => {
    if (date) {
      setObservationData(null)
      setDay(date)
    }
  }

  const getObservationValue = (obs: any, key: string) => {
    const setKey = key.replace("value", "set")
    if (obs[setKey]) {
      return obs[setKey]
    }

    return obs[key]
  }

  const [stationRequest] = useGraphqlQuery({
    query: stationByIdQuery,
    variables: {
      id: stationId,
      type: selectedActivity,
    },
    pause: !stationId || loadingAuthState,
  })

  const stationObservationRequest = useQuery({
    queryKey: [
      "stationObservationRequest",
      stationRequest.data?.station ? stationRequest.data?.station.id : "",
      day.startOf("day").toISOString(),
      day.endOf("day").toISOString(),
    ],
    queryFn: (): any =>
      getStationObservations(
        stationRequest.data?.station.STID,
        day.startOf("day").toDate(),
        day.endOf("day").toDate()
      ),
    enabled: !!stationRequest.data?.station,
  })

  if (!stationObservationRequest.isLoading && observationData === null) {
    setObservationData(stationObservationRequest.data)
  }

  const getGraphDate = (data: any) => {
    if (data && data.date) {
      return dayjs(data.date).format("h:mm")
    }

    return ""
  }

  const getTable = () => {
    if (!observationData || observationData.length === 0) {
      return (
        <Box
          height={300}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          {stationObservationRequest.isLoading ? (
            <CircularProgress />
          ) : (
            <Typography>No Data</Typography>
          )}
        </Box>
      )
    }

    return (
      <StationHourlyTable
        observations={observationData || []}
        station={stationRequest.data?.station}
      />
    )
  }

  const getSelectedGraph = (key: string, date: string) => {
    if (!stationMetrics[key]) {
      return null
    }

    if (!observationData || observationData.length === 0) {
      return (
        <Box
          height={300}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
        >
          {stationObservationRequest.isLoading ? (
            <CircularProgress />
          ) : (
            <Typography>No Data</Typography>
          )}
        </Box>
      )
    }

    const config = stationMetrics[key]
    const variableInfo = sensorVariables[key]
    const variableKey = Object.keys(variableInfo)[0]
    return (
      <div key={station.STID + key}>
        <Container sx={{ justifyContent: "center", display: "flex" }}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={"center"}
            alignItems="center"
          >
            <Typography>{config.title}</Typography>
            <Typography variant="caption">{date}</Typography>
          </Box>
        </Container>
        {config.graphType && config.graphType === GraphType.Bar ? (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={observationData === null ? [] : observationData}>
              <Line
                type="monotone"
                unit={config.units || ""}
                dataKey={(data) => {
                  let value = getObservationValue(data, variableKey)
                  if (config.conversionFunction) {
                    value = config.conversionFunction(value)
                  }

                  return value
                }}
              />
              <ChartTooltip />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey={getGraphDate} />
              <YAxis />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <ResponsiveContainer width="90%" height={300}>
            <LineChart data={observationData === null ? [] : observationData}>
              <Line
                type="monotone"
                dot={false}
                unit={config.units || ""}
                dataKey={(data) => {
                  let value = getObservationValue(data, variableKey)
                  if (config.conversionFunction) {
                    value = config.conversionFunction(value)
                  }

                  return value
                }}
              />
              <ChartTooltip />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey={getGraphDate} />
              <YAxis />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    )
  }

  const station = stationRequest.data?.station
  const sensorVariables = station ? JSON.parse(station.sensorVariables) : {}
  if (!selectedGraphMetric && sensorVariables) {
    const firstValidVar = Object.keys(sensorVariables).find(
      (key) => stationMetrics[key]
    )
    if (firstValidVar) {
      setSelectedGraphMetric(firstValidVar)
    }
  }
  return (
    station && (
      <>
        <Paper sx={{ my: 2 }}>
          <StationDetailHeader station={station} />

          <Box p={1} display="flex" justifyContent={"space-between"}>
            <Box
              sx={{
                color: "black",
                height: "100%",
                width: "100%",
                cursor: "pointer",
              }}
              border="1px solid"
              borderColor={
                showGraphs
                  ? theme.palette.primary.main
                  : theme.palette.action.disabled
              }
              borderRight={0}
              onClick={() => setShowGraphs(true)}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={
                  showGraphs
                    ? {
                        color: "white",
                        backgroundColor: theme.palette.primary.main,
                      }
                    : {}
                }
              >
                <ShowChart
                  htmlColor={
                    showGraphs ? "white" : theme.palette.action.disabled
                  }
                />
                {/* {tab.icon(currentTab === tab.id ? "primary" : "disabled")} */}
                <Typography sx={{ pl: 1 }}>Graph</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                color: "black",
                height: "100%",
                width: "100%",
                cursor: "pointer",
              }}
              border="1px solid"
              borderColor={
                !showGraphs
                  ? theme.palette.primary.main
                  : theme.palette.action.disabled
              }
              onClick={() => setShowGraphs(false)}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={
                  !showGraphs
                    ? {
                        color: "white",
                        backgroundColor: theme.palette.primary.main,
                      }
                    : {}
                }
              >
                <TableRows
                  htmlColor={
                    !showGraphs ? "white" : theme.palette.action.disabled
                  }
                />
                {/* {tab.icon(currentTab === tab.id ? "primary" : "disabled")} */}
                <Typography sx={{ pl: 1 }}>Table</Typography>
              </Box>
            </Box>
          </Box>

          {!showGraphs && (
            <Box py={2}>
              {/* <Box display="flex" alignItems="center" justifyContent={"center"}>
                <Box
                  display="flex"
                  flexDirection={"column"}
                  alignItems="center"
                >
                  <Typography>Hourly Measurements</Typography>
                  <Typography variant="caption">
                    {day.format("ddd MMM D")}
                  </Typography>
                </Box>
              </Box> */}
              {getTable()}
            </Box>
          )}

          {/* graphs */}
          {showGraphs && (
            <>
              <Box>
                {sensorVariables &&
                  selectedGraphMetric &&
                  getSelectedGraph(
                    selectedGraphMetric,
                    day.format("ddd MMM D")
                  )}
              </Box>

              <Box
                display="flex"
                maxWidth={"100vw"}
                overflow={"scroll"}
                py={2}
                justifyContent={isSmall ? undefined : "center"}
              >
                {sensorVariables &&
                  Object.keys(sensorVariables).map((key) => {
                    if (!stationMetrics[key]) {
                      return null
                    }

                    const config: any = stationMetrics[key]
                    return (
                      <Box sx={{ px: "4px" }} key={station.id + key}>
                        <Chip
                          color={
                            selectedGraphMetric && selectedGraphMetric === key
                              ? "primary"
                              : "default"
                          }
                          label={<Typography>{config.title}</Typography>}
                          onClick={() => setSelectedGraphMetric(key)}
                        />
                      </Box>
                    )
                  })}
              </Box>
            </>
          )}
          <Box display="flex" justifyContent={"space-between"}>
            <Box
              display="flex"
              alignItems="center"
              sx={{ cursor: "pointer" }}
              onClick={() => onDayChange(day.subtract(1, "day"))}
            >
              <IconButton color="primary" sx={{ pr: 0, mr: -1 }}>
                <ArrowBackIosIcon fontSize="large" />
              </IconButton>
              <Box
                display="flex"
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems="center"
              >
                <Typography color="primary">
                  {day.subtract(1, "day").format("ddd MMM D")}
                </Typography>
              </Box>
            </Box>

            {!day.isSame(dayjs(), "date") && (
              <Box
                display="flex"
                alignItems="center"
                onClick={() => onDayChange(day.add(1, "day"))}
                sx={{ cursor: "pointer" }}
              >
                <Box
                  display="flex"
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems="center"
                  mr={-1}
                >
                  <Typography color="primary">
                    {day.add(1, "day").format("ddd MMM D")}
                  </Typography>
                </Box>

                <IconButton color="primary" sx={{ pr: 0 }}>
                  <ArrowForwardIosIcon fontSize="large" />
                </IconButton>
              </Box>
            )}
          </Box>
        </Paper>
      </>
    )
  )
}
