import { gql } from "urql"

export const climbingAreasWithinQuery = gql`
  query climbingAreasWithinQuery($filters: Filters, $paging: PaginationParams) {
    climbingAreas(filters: $filters, paging: $paging) {
      id
      name
      openBetaAreaId
      point {
        id
        name
        isFavorite
        lat
        lon
        rockClimbingSummary {
          date
          rating
          avgCloudCover
          precip
          maxProbabilityOfPrecip
          maxTemp
          minTemp
        }
      }
    }
  }
`
