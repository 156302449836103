import React, { useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { registerWithEmailAndPassword, registerWithGoogle } from "../firebase"
import Paper from "@mui/material/Paper"
import Container from "@mui/material/Container"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Checkbox,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import { graphqlClient } from "../graphql"
import { startTrialMutation } from "../graphql/mutations/start-trial"
import GoogleButton from "react-google-button"

export interface RegisterProps {
  onRegister?: () => any
}

export function RegisterPage(props: RegisterProps) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [emailOptIn, setEmailOptIn] = useState(false)
  const navigate = useNavigate()
  const theme = useTheme()
  const [params] = useSearchParams()
  const isSmall = useMediaQuery("(max-width:600px)")

  const register = () => {
    registerWithEmailAndPassword(email, email, password, emailOptIn)
      .then(() => {
        if (props.onRegister) {
          props.onRegister()
        } else {
          if (params.get("trial")) {
            graphqlClient.mutation(startTrialMutation, {}).then(() => {
              window.location.href = "https://snarfme.com/home"
            })
          } else if (params.get("monthly")) {
            window.location.href = "https://snarfme.com/subscribe?monthly=true"
          } else if (params.get("annual")) {
            window.location.href = "https://snarfme.com/subscribe?annual=true"
          }
        }
      })
      .catch((error) => {
        console.error(error)
        setError(error)
      })
  }

  const registerGoogle = () => {
    registerWithGoogle()
      .then(() => {
        if (props.onRegister) {
          props.onRegister()
        } else {
          if (params.get("trial")) {
            graphqlClient.mutation(startTrialMutation, {}).then(() => {
              window.location.href = "https://snarfme.com/home"
            })
          } else if (params.get("monthly")) {
            window.location.href = "https://snarfme.com/subscribe?monthly=true"
          } else if (params.get("annual")) {
            window.location.href = "https://snarfme.com/subscribe?annual=true"
          }
        }
      })
      .catch((error) => {
        console.error(error)
        setError(error)
      })
  }

  return (
    <>
      {isSmall ? (
        <>
          <Box
            display="flex"
            flexDirection={"column"}
            width="100%"
            height="90vh"
            p={3}
            sx={{
              background:
                "linear-gradient(rgba(41,61,82,.4),rgba(41,61,82,.4)),url(/rainier-amy-crop.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "60%",
            }}
          >
            <Box
              display="flex"
              sx={{ color: theme.palette.primary.main }}
              alignItems="center"
              justifyContent={"center"}
            >
              <img
                src="/Icon-48.png"
                alt="snarf icon"
                width={"48px"}
                height={"48px"}
              />
              <Typography variant="h3">Snarf</Typography>
            </Box>

            <Box display="flex" flexDirection={"column"} py={1} mt="auto">
              <Typography variant="h5" color="white">
                Create an account to ride more Powder.
              </Typography>
              <Box color="white">
                Already have an account? <Link to="/login">Login</Link>
              </Box>

              <TextField
                type="text"
                sx={{ my: 2, background: "white" }}
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address"
              />

              <TextField
                sx={{ my: 2, background: "white" }}
                type="password"
                className="login__textBox"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />

              <Box
                display="flex"
                justifyContent="center"
                sx={{ textAlign: "center" }}
                alignItems="center"
                color="white"
              >
                <Checkbox
                  checked={emailOptIn}
                  sx={{ color: "white" }}
                  onChange={(event, checked) => setEmailOptIn(checked)}
                />
                <Typography color="white">I want email updates</Typography>
              </Box>

              <Button variant="contained" onClick={register}>
                Create Account
              </Button>

              <Box py={1} display="flex" justifyContent={"center"}>
                <GoogleButton onClick={registerGoogle} />
              </Box>

              {error ? (
                <Typography color="red">{error.toString()}</Typography>
              ) : null}
            </Box>
          </Box>
        </>
      ) : (
        <Box display="flex">
          <Box maxHeight={"95vh"} sx={{ overflow: "hidden" }}>
            <img src="/rainier-amy-crop.jpg" alt="" width="100%" />
          </Box>
          <Box display="flex" flexDirection={"column"} width="100%" p={3}>
            <Box
              display="flex"
              sx={{ color: theme.palette.primary.main }}
              alignItems="center"
            >
              <img
                src="/Icon-48.png"
                alt="snarf icon"
                width={"48px"}
                height={"48px"}
              />
              <Typography variant="h3">Snarf</Typography>
            </Box>

            <Box display="flex" flexDirection={"column"} py={1}>
              <Typography variant="h5">
                Create an account to access all Snarf has to offer.
              </Typography>
              <Box>
                Already have an account? <Link to="/login">Login</Link>
              </Box>

              <TextField
                type="text"
                sx={{ py: 2 }}
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address"
              />

              <TextField
                sx={{ py: 2 }}
                type="password"
                className="login__textBox"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />

              <Box
                display="flex"
                justifyContent="center"
                sx={{ textAlign: "center" }}
                alignItems="center"
              >
                <Checkbox
                  checked={emailOptIn}
                  onChange={(event, checked) => setEmailOptIn(checked)}
                />
                <Typography>I want email updates</Typography>
              </Box>

              <Button variant="contained" onClick={register}>
                Create Account
              </Button>

              <Box py={1} display="flex" justifyContent={"center"}>
                <GoogleButton onClick={registerGoogle} />
              </Box>

              {error ? (
                <Typography color="red">{error.toString()}</Typography>
              ) : null}
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}
