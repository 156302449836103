import React, { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { App } from "./App"

declare global {
  interface Window {
    adsbygoogle: any
  }
}

window.adsbygoogle = window.adsbygoogle || []

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "https://snarfme.com/sw.js",
        {
          scope: "./",
        }
      )
      if (registration.installing) {
        console.log("Service worker installing")
      } else if (registration.waiting) {
        console.log("Service worker installed")
      } else if (registration.active) {
        console.log("Service worker active")
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`)
    }
  }
}

registerServiceWorker()

// const root = ReactDOM.createRoot(document.getElementById('root') as Element);
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const stateSumString = document.getElementById("ssr-data")?.dataset.ssr
let props = null
if (stateSumString) {
  props = JSON.parse(decodeURIComponent(stateSumString))
}
const rootEl = document.querySelector("#root") as Element
ReactDOM.hydrateRoot(
  rootEl,
  <StrictMode>
    <BrowserRouter>
      <App props={props} />
    </BrowserRouter>
  </StrictMode>
)
