import { Paper, Box, Typography } from "@mui/material"
import { area } from "@turf/turf"
import React from "react"
import { SkiAreaIcon } from "../Icons/SkiAreaIcon"
import { MiniDailySnowBarChart } from "../MiniDailySnowBarChart"
import { PointSummary } from "../../models/models"
import { SkiArea } from "../../api/models"
import { capitalizeFirstLetter } from "../../utilities/helpers"
import { mmToIn } from "../../utilities/conversion"
import { AcUnit } from "@mui/icons-material"
import { Favorite } from "../Favorite"

export interface SkiCompactTileProps {
  onCardClick: (point: PointSummary) => any
  area: SkiArea
}

export function SkiCompactTile({ onCardClick, area }: SkiCompactTileProps) {
  return (
    <Paper
      sx={{ p: 1, cursor: "pointer" }}
      onClick={() => onCardClick(area.point)}
    >
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Box display="flex" alignItems={"center"}>
            <SkiAreaIcon type={area.type} width={40} height={40} />
            <Box display="flex" flexDirection={"column"} pl="4px">
              <Typography variant="h6" sx={{ textDecoration: "underline" }}>
                {area.point.name}
              </Typography>
              <Typography variant="body1" lineHeight={1}>
                {capitalizeFirstLetter(area.state)}
              </Typography>
            </Box>
          </Box>
          {/* <Box>
            <Favorite
              isFavorite={area.point.isFavorite || false}
              onFavorite={() => {
                console.log("on favorite")
              }}
            />
          </Box> */}

          {area.point.forecastedSnowTotals.length > 0 && (
            <Box
              p={1}
              mx={1}
              border="1px solid grey"
              borderRadius={2}
              width="fit-content"
            >
              <Box display="flex" alignItems="center">
                <AcUnit color="primary" fontSize="medium" />
                <Typography fontWeight={"bold"}>
                  {mmToIn(area.point.forecastedSnowTotals[0].totalSnow)} in
                </Typography>
              </Box>

              <Typography fontWeight={"bold"}>Yesterday</Typography>
            </Box>
          )}
        </Box>
        <Box p={1}>
          <Box sx={{ ml: "-55px" }}>
            <MiniDailySnowBarChart pointData={area.point} height={150} />
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}
