import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  Container,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useQuery } from "@tanstack/react-query"
import dayjs, { Dayjs } from "dayjs"
import React, { useContext, useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import {
  ResponsiveContainer,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip as ChartTooltip,
  Bar,
  BarChart,
} from "recharts"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import {
  getHourlyForecastDateRange,
  getObservationAndHourlyGraphData,
  getPointSummary,
} from "../api/snarf-api"
import { auth } from "../firebase"
import { HourlyForecast, PointOfInterest } from "../models/models"
import { FAQAnchors } from "../pages/FAQ"
import { ActivityContext } from "../pages/Header"
import { cToF } from "../utilities/conversion"
import { History, Update } from "@mui/icons-material"

export interface GraphHQProps {
  pointId: string
}

export function GraphHQ(props: GraphHQProps) {
  const [pointId, setPointId] = useState<string>(props.pointId)
  const [beginDate, setBeginDate] = useState(dayjs(Date.now()))
  const [endDate, setEndDate] = useState(dayjs(Date.now()).add(7, "day"))
  const [graphData, setGraphData] = useState<any[] | null>(null)
  const [loadingGraphData, setLoadingGraphData] = useState(true)
  const [user, loadingAuthState] = useAuthState(auth)
  const [isOpen, setIsOpen] = useState(false)
  const [pointSummary, setPointSummary] = useState<PointOfInterest | null>(null)
  const [processing, setProcessing] = useState(true)
  const theme = useTheme()
  const [showHistory, setShowHistory] = useState(false)
  const selectedActivity = useContext(ActivityContext)

  const pointSummaryRequest = useQuery({
    queryKey: ["pointSummary", pointId],
    queryFn: (): any => getPointSummary(pointId, selectedActivity),
    refetchInterval: processing ? 2000 : false,
  })

  if (!pointSummaryRequest.isLoading && !pointSummary) {
    if (!pointSummaryRequest.data.processing) {
      setProcessing(false)
      setPointSummary(pointSummaryRequest.data)
    } else if (!processing) {
      setProcessing(true)
    }
  }

  useEffect(() => {
    if (props.pointId && props.pointId !== pointId && !loadingAuthState) {
      setPointId(props.pointId)
      setPointSummary(null)
      setGraphData(null)
      setLoadingGraphData(true)
    }
  }, [props, loadingAuthState])

  const pointHourlyForecastRequest = useQuery({
    queryKey: [
      "pointHourlyForecast",
      props.pointId,
      showHistory
        ? beginDate.subtract(7, "day").format("DD/MM/YYYY")
        : beginDate.format("DD/MM/YYYY"),
      showHistory
        ? beginDate.format("DD/MM/YYYY")
        : endDate.format("DD/MM/YYYY"),
    ],
    queryFn: (): any =>
      getHourlyForecastDateRange(
        props.pointId,
        showHistory
          ? beginDate.subtract(7, "day").toDate()
          : beginDate.toDate(),
        showHistory ? beginDate.toDate() : endDate.toDate()
      ),
    enabled:
      pointSummary !== null &&
      !pointSummary.noaaStationIdentifier &&
      !processing &&
      beginDate.isValid() &&
      endDate.isValid(),
  })

  if (
    pointHourlyForecastRequest &&
    !pointHourlyForecastRequest.isLoading &&
    !graphData
  ) {
    const tempHistogram: any = {}
    const buckets: any = {}
    setGraphData(
      pointHourlyForecastRequest.data.map((data: HourlyForecast) => {
        const startOfDay = dayjs(data.date).startOf("day").toISOString()
        if (!tempHistogram[startOfDay]) {
          tempHistogram[startOfDay] = {}
        }

        let tempBucket = (
          Math.round(cToF(data.temperature, 100) * 2) / 2
        ).toFixed(1)

        if (!data.temperature) {
          tempBucket = "32.0"
        }
        if (!tempHistogram[startOfDay][tempBucket]) {
          tempHistogram[startOfDay][tempBucket] = 0
        }

        if (!buckets[tempBucket]) {
          buckets[tempBucket] = true
        }

        tempHistogram[startOfDay][tempBucket] += 1
        return { forecast: { ...data } }
      })
    )

    setLoadingGraphData(false)
  }

  const stationGraphDataRequest = useQuery({
    queryKey: [
      "stationGraphData",
      pointId,
      beginDate.format("DD/MM/YYYY"),
      endDate.format("DD/MM/YYYY"),
    ],
    queryFn: (): any =>
      getObservationAndHourlyGraphData(
        pointId,
        beginDate.toDate(),
        endDate.toDate()
      ),
    enabled:
      pointSummary !== null &&
      !!pointSummary.noaaStationIdentifier &&
      !processing,
  })

  if (!stationGraphDataRequest.isLoading && !graphData) {
    setGraphData(stationGraphDataRequest.data)
    setLoadingGraphData(false)
  }

  const getHourlyForecast = (cast: any, key: string, conversion?: Function) => {
    if (cast && Object.prototype.hasOwnProperty.call(cast, key)) {
      if (conversion) {
        return conversion(cast[key])
      }

      return cast[key]
    }

    return undefined
  }

  const getGraphDate = (data: any) => {
    if (data.forecast) {
      return dayjs(data.forecast.date).format("MM/DD")
    } else if (data.observation) {
      return dayjs(data.observation.date).format("dd")
    }

    return ""
  }

  return (
    <Box sx={{ m: 0, p: 0 }}>
      {loadingGraphData ? (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Accordion
          expanded={isOpen}
          onChange={() => setIsOpen(!isOpen)}
          sx={{ width: "100%" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" fontWeight="bold">
              Hourly Graphs
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ width: "100%", height: "2900px" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => {
                    setShowHistory(true)
                    setGraphData(null)
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <Chip
                    color={showHistory ? "secondary" : undefined}
                    icon={<History />}
                    label="Last 7 days"
                  />
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  onClick={() => {
                    setShowHistory(false)
                    setGraphData(null)
                  }}
                >
                  <Chip
                    color={!showHistory ? "primary" : undefined}
                    icon={<Update />}
                    label="Next 7 days"
                    sx={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
              <Container sx={{ justifyContent: "center", display: "flex" }}>
                <Typography>Temperature</Typography>
              </Container>
              <ResponsiveContainer width="90%" height={300}>
                <LineChart data={graphData || []}>
                  <Line
                    type="monotone"
                    unit="F"
                    name="Forecasted"
                    dot={false}
                    stroke={
                      showHistory
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }
                    dataKey={(data) =>
                      getHourlyForecast(data.forecast, "temperature", cToF)
                    }
                  />
                  <Line
                    type="monotone"
                    unit="F"
                    name="Freezing Line"
                    dot={false}
                    dataKey={(data) => 32}
                    stroke="black"
                    strokeDasharray={"5 5"}
                  />
                  <ChartTooltip />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis interval={36} dataKey={getGraphDate} />
                  <YAxis />
                </LineChart>
              </ResponsiveContainer>

              <Container sx={{ justifyContent: "center", display: "flex" }}>
                <Typography>Snow Level</Typography>
              </Container>
              <ResponsiveContainer width="90%" height={300}>
                <LineChart data={graphData || []}>
                  <Line
                    type="monotone"
                    unit="ft"
                    name="Forecasted"
                    dot={false}
                    stroke={
                      showHistory
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }
                    dataKey={(data) =>
                      Math.round(
                        getHourlyForecast(data.forecast, "snowLevel") * 3.28084
                      )
                    }
                  />
                  {/* <Line
                type="monotone"
                unit="ft"
                name="Spot elevation"
                dot={false}
                dataKey={(data) => pointSummary ? pointSummary.noaaElevation || 0}
                stroke="black"
                strokeDasharray={"5 5"}
              /> */}
                  <ChartTooltip />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis interval={36} dataKey={getGraphDate} />
                  <YAxis domain={[0, 10000]} />
                </LineChart>
              </ResponsiveContainer>

              <Container sx={{ justifyContent: "center", display: "flex" }}>
                <Typography>Snowfall Amount</Typography>
              </Container>
              <ResponsiveContainer width="90%" height={300}>
                <BarChart data={graphData || []}>
                  <Bar
                    type="monotone"
                    unit="in"
                    name="Forecasted"
                    fill={
                      showHistory
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }
                    dataKey={(data) =>
                      Math.round(
                        (getHourlyForecast(data.forecast, "snowfallAmount") /
                          25.4) *
                          100
                      ) / 100
                    }
                  />
                  <ChartTooltip />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis interval={36} dataKey={getGraphDate} />
                  <YAxis
                    domain={[0, 2]}
                    type="number"
                    ticks={[0.5, 1, 1.5, 2]}
                  />
                </BarChart>
              </ResponsiveContainer>

              <Container sx={{ justifyContent: "center", display: "flex" }}>
                <Typography>Snow Ratio</Typography>
              </Container>
              <ResponsiveContainer width="90%" height={300}>
                <LineChart data={graphData || []}>
                  <Line
                    type="monotone"
                    dot={false}
                    stroke={
                      showHistory
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }
                    dataKey={(data) => {
                      if (
                        data.forecast.snowfallAmount &&
                        data.forecast.quantitativePrecipitation
                      ) {
                        const ratio =
                          data.forecast.snowfallAmount /
                          data.forecast.quantitativePrecipitation
                        return Math.round(ratio * 10) / 10
                      }

                      return null
                    }}
                  />
                  <ChartTooltip />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis interval={36} dataKey={getGraphDate} />
                  <YAxis />
                </LineChart>
              </ResponsiveContainer>

              <Container sx={{ justifyContent: "center", display: "flex" }}>
                <Typography>Wind Speed</Typography>
              </Container>
              <ResponsiveContainer width="90%" height={300}>
                <LineChart data={graphData || []}>
                  <Line
                    type="monotone"
                    unit="mph"
                    name="Forecasted"
                    stroke={
                      showHistory
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }
                    dot={false}
                    dataKey={(data) =>
                      Math.round(
                        getHourlyForecast(data.forecast, "windSpeed") / 1.609
                      )
                    }
                  />
                  <ChartTooltip />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis interval={36} dataKey={getGraphDate} />
                  <YAxis type="number" domain={[0, 60]} />
                </LineChart>
              </ResponsiveContainer>

              <Container sx={{ justifyContent: "center", display: "flex" }}>
                <Typography>Chance Precip</Typography>
              </Container>
              <ResponsiveContainer width="90%" height={300}>
                <LineChart data={graphData || []}>
                  <Line
                    type="monotone"
                    unit="%"
                    dot={false}
                    stroke={
                      showHistory
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }
                    dataKey={(data) =>
                      Math.round(
                        getHourlyForecast(
                          data.forecast,
                          "probabilityOfPrecipitation"
                        )
                      )
                    }
                  />
                  <ChartTooltip />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis interval={36} dataKey={getGraphDate} />
                  <YAxis type="number" domain={[0, 100]} />
                </LineChart>
              </ResponsiveContainer>

              <Container sx={{ justifyContent: "center", display: "flex" }}>
                <Typography>Quantitative Precip</Typography>
              </Container>
              <ResponsiveContainer width="90%" height={300}>
                <LineChart data={graphData || []}>
                  <Line
                    type="monotone"
                    unit="in"
                    dot={false}
                    stroke={
                      showHistory
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }
                    dataKey={(data) =>
                      getHourlyForecast(
                        data.forecast,
                        "quantitativePrecipitation"
                      )
                    }
                  />
                  <ChartTooltip />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis interval={36} dataKey={getGraphDate} />
                  <YAxis type="number" domain={[0, 2]} />
                </LineChart>
              </ResponsiveContainer>

              <Container sx={{ justifyContent: "center", display: "flex" }}>
                <Typography>Percent Cloud Cover</Typography>
              </Container>
              <ResponsiveContainer width="90%" height={300}>
                <LineChart data={graphData || []}>
                  <Line
                    type="monotone"
                    unit="%"
                    name="Forecasted"
                    stroke={
                      showHistory
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }
                    dot={false}
                    dataKey={(data) =>
                      getHourlyForecast(data.forecast, "skyCover")
                    }
                  />
                  <ChartTooltip />
                  <CartesianGrid stroke="#ccc" />
                  <XAxis interval={36} dataKey={getGraphDate} />
                  <YAxis type="number" domain={[0, 100]} />
                </LineChart>
              </ResponsiveContainer>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  )
}
