import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import { HourlyForecast, PointSummary } from "../models/models"
import { cToF, mmToIn, mToFt } from "../utilities/conversion"
import NorthIcon from "@mui/icons-material/North"
import { getWindSpeedStyle } from "../utilities/styling"

export interface HourlyTableProps {
  hourlyForecast: HourlyForecast[]
  spotElevation?: number
  pointId: string
}

const rowHeadStyle = {
  minWidth: "100px",
  fontWeight: "bold",
  position: "sticky",
  left: 0,
  background: "white",
  zIndex: 3,
  p: 1,
}

const cellStyle = {
  p: 1,
}

export const HourlyTable = ({
  hourlyForecast,
  spotElevation,
  pointId,
}: HourlyTableProps) => {
  const theme = useTheme()

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={rowHeadStyle}></TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={
                    dayjs(cast.date).isBefore(dayjs())
                      ? { ...cellStyle, color: theme.palette.secondary.main }
                      : cellStyle
                  }
                  key={`header${cast.date}${pointId}`}
                  style={
                    dayjs(cast.date).day() % 2 === 0
                      ? { fontWeight: "bold" }
                      : {}
                  }
                >
                  {dayjs(cast.date).format("dd HH")}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={"temp"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={rowHeadStyle} component="th" scope="row">
                Temp (F)
              </TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={cellStyle}
                  key={`${cast.date}${pointId}temp`}
                  component="td"
                >
                  {cToF(cast.temperature ? cast.temperature : 0)}
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              key={"snowLevel"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={rowHeadStyle} component="th" scope="row">
                Snow Level (ft)
              </TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={cellStyle}
                  key={`${cast.date}${pointId}snowlevel`}
                  style={
                    spotElevation && spotElevation > cast.snowLevel
                      ? { color: theme.palette.primary.main }
                      : {}
                  }
                  component="td"
                >
                  {mToFt(cast.snowLevel)}
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              key={"snowAmount"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={rowHeadStyle} component="th" scope="row">
                Snow (in)
              </TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={cellStyle}
                  key={`${cast.date}${pointId}snowfall$`}
                  style={
                    mmToIn(cast.snowfallAmount, 1000) >= 0
                      ? { color: theme.palette.primary.main }
                      : {}
                  }
                  component="td"
                >
                  {mmToIn(cast.snowfallAmount, 1000)}
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              key={"precipitation"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={rowHeadStyle} component="th" scope="row">
                Precip (in)
              </TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={cellStyle}
                  key={`${cast.date}${pointId}precip`}
                  style={
                    mmToIn(cast.quantitativePrecipitation, 1000) > 0 ? {} : {}
                  }
                  component="td"
                >
                  {mmToIn(cast.quantitativePrecipitation, 1000)}
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              key={"probabilityofprecipitation"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={rowHeadStyle} component="th" scope="row">
                Precip (%)
              </TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={cellStyle}
                  key={`${cast.date}${pointId}probabilityofprecipitation`}
                  // style={
                  //   mmToIn(cast.quantitativePrecipitation, 1000) > 0 ? {} : {}
                  // }
                  component="td"
                >
                  {cast.probabilityOfPrecipitation}
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              key={"windSpeed"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={rowHeadStyle} component="th" scope="row">
                Wind Speed (mph)
              </TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={cellStyle}
                  key={`${cast.date}${pointId}windspeed`}
                  style={getWindSpeedStyle(cast.windSpeed, theme)}
                  component="td"
                >
                  {Math.round(cast.windSpeed / 1.609)}
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              key={"windGust"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={rowHeadStyle} component="th" scope="row">
                Wind Gust (mph)
              </TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={cellStyle}
                  key={`${cast.date}${pointId}windgust`}
                  style={getWindSpeedStyle(cast.windGust, theme)}
                  component="td"
                >
                  {Math.round(cast.windGust / 1.609)}
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              key={"windDirection"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={rowHeadStyle} component="th" scope="row">
                Wind Direction
              </TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={cellStyle}
                  key={`${cast.date}${pointId}winddir`}
                  component="td"
                >
                  {
                    <NorthIcon
                      sx={{
                        transform: `rotate(${cast.windDirection - 180}deg)`,
                      }}
                    />
                  }
                </TableCell>
              ))}
            </TableRow>

            <TableRow
              key={"cloudCover"}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell sx={rowHeadStyle} component="th" scope="row">
                Cloud Cover (%)
              </TableCell>
              {hourlyForecast.map((cast) => (
                <TableCell
                  sx={cellStyle}
                  key={`${cast.date}${pointId}cloudcover`}
                  component="td"
                >
                  {cast.skyCover}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
