import { gql } from "urql"

export const stationByIdQuery = gql`
  query stationByIdQuery($id: ID!, $type: Int!) {
    station(id: $id) {
      id
      STID
      name
      lat
      lon
      elevation
      sensorVariables
      isFavorite(type: $type)
    }
  }
`
