import { Grid, Box, Slider, Typography, Button, TextField } from "@mui/material"
import React, { useState } from "react"
import { SearchResult } from "../../api/models"
import { LocationSearch } from "../LocationSearch"
import LocationSearchingIcon from "@mui/icons-material/LocationSearching"
import { Place } from "@mui/icons-material"
import { reverseGeocode } from "../../api/snarf-api"
export interface NearMeControlsProps {
  onChange: (location: any, distance: number) => any
  config?: { location: any; distance: number }
}

export function NearMeControls({ onChange, config }: NearMeControlsProps) {
  const [distance, setDistance] = useState(
    config && config.distance ? config.distance : 25
  )
  const [location, setLocation] = useState<SearchResult | null>(
    config && config.location ? config.location : null
  )

  const onLocationSelected = (loc: SearchResult) => {
    setLocation(loc)
  }

  const locate = () => {
    const successCallback = async (position: {
      coords: { latitude: number; longitude: number }
    }) => {
      const geo = await reverseGeocode(
        position.coords.latitude,
        position.coords.longitude
      )

      let name = ""
      let id = ""
      if (geo.length > 0) {
        // setSearchValue(geo[0].formatted_address)
        name = geo[0].formatted_address
        id = geo[0].place_id
      }

      setLocation({
        name,
        id,
        source: "reverse-geo",
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      })
    }

    const errorCallback = (error: any) => {
      console.log(error)
    }

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          pb={1}
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          width="100%"
        >
          <TextField
            fullWidth
            id="outlined-required"
            label="Your Location"
            value={location ? location.name : ""}
            defaultValue=""
            aria-readonly
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" pb={1}>
          <Button variant="contained" onClick={locate}>
            <LocationSearchingIcon />
            <Typography ml={1}>Locate</Typography>
          </Button>
        </Box>
      </Grid>

      <Grid item xs={12} py={1}>
        <Typography>Distance</Typography>
        <Box px={2}>
          <Slider
            aria-label="Distance"
            defaultValue={25}
            valueLabelDisplay="auto"
            step={25}
            marks={[
              {
                value: 50,
                label: "50 mi",
              },
              {
                value: 100,
                label: "100 mi",
              },
              {
                value: 150,
                label: "150 mi",
              },
            ]}
            value={distance}
            onChange={(event, value) => setDistance(value as number)}
            min={25}
            max={150}
          />
        </Box>
      </Grid>

      <Grid item xs={12} alignSelf={"center"}>
        <Box display="flex" justifyContent={"center"}>
          <Button
            onClick={() => onChange(location, distance)}
            variant="contained"
          >
            Update
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}
