import { useContext,useEffect, useState } from "react"
import { ActivityContext } from "../pages/Header"

function useSessionMapConfig(initialValue: any) {
  const selectedActivity = useContext(ActivityContext);
  const [previousActivity, setPreviousActivity] = useState(selectedActivity); 
  const [sessionMapConfig, setSessionMapConfig] = useState(() => {
    const existingSessionMapConfig = sessionStorage.getItem("mapConfig")
    if (existingSessionMapConfig) {
      return JSON.parse(existingSessionMapConfig)
    } else {
      return initialValue
    }
  })

  useEffect(() => {
    if (selectedActivity !== previousActivity) {
      setSessionMapConfig(initialValue)
      setPreviousActivity(selectedActivity)
    }
  }, [selectedActivity, previousActivity, initialValue])

  useEffect(() => {
    sessionStorage.setItem("mapConfig", JSON.stringify(sessionMapConfig))
  }, [sessionMapConfig])

  return [sessionMapConfig, setSessionMapConfig]
}

export default useSessionMapConfig
