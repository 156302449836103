import { Box, Container, Link, Typography } from "@mui/material"
import React from "react"

export function MobileApps() {
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        py={1}
      >
        <Typography variant="h3">Snarf Apps</Typography>
      </Box>
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Box px={1}>
          <img src="/apple.png" alt="apple icon" width={45} />
        </Box>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() =>
            (window.location.href =
              "https://play.google.com/store/apps/details?id=com.snarfme.twa")
          }
        >
          <img src="/play-store.png" alt="Play Store" />
        </Box>
      </Box>

      <Container maxWidth="lg">
        <Box>
          <Typography variant="h5" fontWeight={"bold"}>
            Android
          </Typography>
          <Typography>
            <Link href="https://play.google.com/store/apps/details?id=com.snarfme.twa">
              Install Android App
            </Link>
          </Typography>
        </Box>

        <Box py={1}>
          <Typography variant="h5" fontWeight={"bold"}>
            Apple
          </Typography>

          <Typography>
            <ol>
              <li>
                <Typography fontWeight={"bold"}>
                  Open Snarf in Safari.
                </Typography>
              </li>
              <li>
                <Typography fontWeight={"bold"}>
                  Tap the “Share” icon in Safari.
                </Typography>
              </li>
              <li>
                <Typography fontWeight={"bold"}>
                  Select “Add to Home Screen” from the options.
                </Typography>
              </li>
              <li>
                <Typography fontWeight={"bold"}>
                  Confirm the installation by tapping the “Add” button.
                </Typography>
              </li>
            </ol>
          </Typography>

          <Typography fontWeight={"bold"}>
            Why aren't you on the Apple App Store?
          </Typography>
          <Typography>
            Snarf is a Progressive Web App, which allows small businesses and
            independent developers like myself to build a single application
            that can be used across all devices. Apple chooses not to support
            this technology as they can't profit off of others work if they do.
          </Typography>
        </Box>
      </Container>
    </>
  )
}
