import { Modal, Box, Typography, useTheme } from "@mui/material"
import React, { useEffect } from "react"
import { Register } from "./Register"
import { SnarfEvents, sendEvent } from "../utilities/metrics"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  p: 2,
}

export function LoginModal(props: any) {
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()

  const handleClose = () => {
    setOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (open !== props.open) {
      setOpen(props.open)

      if (props.open) {
        sendEvent(SnarfEvents.ShowLoginModal)
      }
    }
  }, [props])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* <Box
          display="flex"
          sx={{ color: theme.palette.primary.main, mt: 2, ml: -2 }}
          justifyContent="center"
        >
          <AcUnitIcon sx={{ fontSize: "42px" }} />
          <Typography variant="h3">Snarf</Typography>
        </Box> */}
        {/* <Login onLogin={() => handleClose()} /> */}
        <Register />
      </Box>
    </Modal>
  )
}
