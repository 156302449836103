import { Close, LocationSearching, Place } from "@mui/icons-material"
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material"
import React from "react"
import { PointOfInterest } from "../../models/models"

export interface MiniInfoProps {
  point: PointOfInterest
  onLocate: ({ lat, lon }: { lat: number; lon: number }) => any
  onRemoveMarker: () => any
  onViewForecast: (pointId: string) => any
  onFavorite: (pointId: string, isFavorite: boolean) => any
}

const smallStyle = {
  position: "fixed",
  bottom: "130px",
  width: "100vw",
  height: "100px",
  zIndex: 10,
  p: 2,
}

const largeStyle = {
  position: "absolute",
  top: "100px",
  left: "32px",
  width: "300px",
}

export function MiniInfo({
  point,
  onLocate,
  onRemoveMarker,
  onViewForecast,
  onFavorite,
}: MiniInfoProps) {
  const isSmall = useMediaQuery("(max-width:600px)")

  return (
    <Box sx={isSmall ? smallStyle : largeStyle}>
      <Paper sx={{ p: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Place htmlColor="red" />
            <Typography fontWeight="bold" px={"4px"}>
              {point?.name || "Anonymous Point"}
            </Typography>
            <IconButton onClick={() => onLocate(point)}>
              <LocationSearching />
            </IconButton>
          </Box>
          <IconButton onClick={onRemoveMarker}>
            <Close />
          </IconButton>
        </Box>

        <Box display="flex" justifyContent="center" p={1}>
          <Button variant="contained" onClick={() => onViewForecast(point.id)}>
            <Typography>View Forecast</Typography>
          </Button>

          {/* <Button onClick={() => onFavorite(point.id, !point.isFavorite)}>
            <Box display="flex" alignItems="center">
              {point.isFavorite ? <Star htmlColor="gold" /> : <StarBorder />}
              <Typography>Add Favorite</Typography>
            </Box>
          </Button> */}
        </Box>
      </Paper>
    </Box>
  )
}
