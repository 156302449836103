import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate, useSearchParams } from "react-router-dom"
import { createPaymentSession } from "../api/snarf-api"
import { LoginModal } from "../components/LoginModal"
import { auth } from "../firebase"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { Check } from "@mui/icons-material"
import { UserPremiumContext, UserTrialEndDateContext } from "./Header"
import { SnarfEvents, sendEvent, trackPurchase } from "../utilities/metrics"
import { graphqlClient } from "../graphql"
import { startTrialMutation } from "../graphql/mutations/start-trial"

export function Subscribe() {
  const isSmall = useMediaQuery("(max-width:600px)")
  const [searchParams, setSearchParams] = useSearchParams()
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const [user, loadingAuthState] = useAuthState(auth)
  const userIsPremium = useContext(UserPremiumContext)
  const userTrialEndDate = useContext(UserTrialEndDateContext)
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const theme = useTheme()

  const onTrialClick = () => {
    if (user) {
      // set their end date, can only be set once on the back end without me
      graphqlClient.mutation(startTrialMutation, {}).then(() => {
        window.location.href = window.location.href
      })
    } else {
      // navigate to register with a token signifying trial
      // after signup set up their free trial
      // funnel them into favorites / map etc?
      navigate("/register?trial=true")
    }
  }

  useEffect(() => {
    if (!loadingAuthState) {
      if (params.get("annual")) {
        onAnnualClick()
      }
    }
  })

  // const onMonthlyClick = async () => {
  //   if (!user) {
  //     navigate("/register?monthly=true")
  //   }
  //   sendEvent(SnarfEvents.SubscribeMonthly)
  //   trackPurchase(10)
  //   // 10 bucks
  //   const body = await createPaymentSession("price_1OaM5tL7hSf9bWTFlvpd6YQx")
  //   window.location.href = body.url
  // }

  const onAnnualClick = async () => {
    if (!user) {
      navigate("/register?annual=true")
    }
    sendEvent(SnarfEvents.SubscribeYearly)
    trackPurchase(20)
    // 20 bucks
    const body = await createPaymentSession("price_1OoYvqL7hSf9bWTFCvNS6Dtc")
    window.location.href = body.url
  }

  if (params.get("monthly") || params.get("annual")) {
    return (
      <Box
        width="100vw"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent={"center"}
      >
        <CircularProgress />
      </Box>
    )
  }
  return (
    <>
      <LoginModal
        open={showLoginPopup}
        onClose={() => {
          setShowLoginPopup(false)
        }}
      />
      <Container maxWidth="lg" sx={{ pb: 2 }}>
        <Box
          pt={2}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <img
            src="/Icon-48.png"
            alt="Snarf icon"
            style={{ width: "48px", height: "48px" }}
          />
          <Typography variant="h3" color="primary" sx={{ pl: 1 }}>
            Snarf Pro
          </Typography>
        </Box>
        <Box pb={1} display="flex" justifyContent="center">
          <Typography variant="h6">Surf Powder, not the web.</Typography>
        </Box>

        {searchParams.get("success") ? (
          <Box py={2}>
            <Paper sx={{ p: 2 }}>
              <Box display="flex" justifyContent="center">
                <CheckCircleIcon htmlColor="green" />
                <Typography>
                  Thank you for your support! If you have any requests for
                  future features send them to bob@snarfme.com
                </Typography>
              </Box>
            </Paper>
          </Box>
        ) : null}

        {searchParams.get("cancel") ? (
          <Box py={2}>
            <Paper sx={{ p: 2 }}>
              <Box display="flex" justifyContent="center">
                <HighlightOffIcon color="error" />
                <Typography>
                  Subscription cancelled. Send any complaints to bob@snarfme.com
                </Typography>
              </Box>
            </Paper>
          </Box>
        ) : null}

        {userIsPremium ? (
          <Box>
            <Typography>
              You are already subscribed, thank you for your support!
            </Typography>
          </Box>
        ) : (
          <Box>
            {(!user || !userTrialEndDate) && (
              <Box>
                <Alert
                  severity="success"
                  icon={<></>}
                  sx={{ justifyContent: "center" }}
                >
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    width="100%"
                    margin="auto"
                  >
                    <Typography align="center">
                      Start your free 14 day trial!
                    </Typography>
                    <Typography align="center">
                      No credit card required.
                    </Typography>
                    <Box p={1}>
                      <Button
                        sx={{ p: 1 }}
                        variant="contained"
                        onClick={onTrialClick}
                      >
                        Start Trial
                      </Button>
                    </Box>
                  </Box>
                </Alert>
              </Box>
            )}
            <Box
              pb={2}
              pt={1}
              display="flex"
              justifyContent={"center"}
              width="100%"
            >
              <Paper sx={{ p: 1, maxWidth: 800, width: "100%" }}>
                <Box p={1}>
                  <Typography textAlign={"center"} variant="h5">
                    Plan Options
                  </Typography>
                  <Typography textAlign={"center"} variant="body2">
                    Questions: bob@snarfme.com
                  </Typography>
                </Box>
                <Box display="flex" justifyContent={"space-around"}>
                  {/* <Box>
                    <Typography fontWeight={"bold"} textAlign={"center"}>
                      Monthly
                    </Typography>
                    <Typography sx={{ pb: 2 }} textAlign={"center"}>
                      $10/Month
                    </Typography>
                    <Button variant="contained" onClick={onMonthlyClick}>
                      Buy Monthly
                    </Button>
                  </Box> */}

                  <Box>
                    <Typography fontWeight={"bold"} textAlign={"center"}>
                      Annual
                    </Typography>
                    <Typography textAlign={"center"} sx={{ pb: 2 }}>
                      $20/Year
                    </Typography>
                    <Button variant="contained" onClick={onAnnualClick}>
                      Buy Annual
                    </Button>
                  </Box>
                </Box>
              </Paper>
            </Box>

            <Box py={1}>
              <Typography variant="h5" align="center">
                What your money buys you.
              </Typography>
              <Typography align="center"></Typography>
            </Box>

            <Box display="flex" justifyContent={"center"}>
              <TableContainer sx={{ maxWidth: 400 }} component={Paper}>
                <Table sx={{ maxWidth: 400 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={200}></TableCell>
                      <TableCell width={100} align="center">
                        <Typography>Free</Typography>
                      </TableCell>
                      <TableCell width={100} align="center">
                        <Typography fontWeight={"bold"} color="primary">
                          Pro
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          7 Day Forecasts
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          7 Day Hourly Forecasts
                        </Typography>
                      </TableCell>

                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          7 Day Historical Weather
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Forecast Analysis from Local Forecast Office
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Avalanche Forecast Links
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Favorite Ski Areas List
                        </Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Compare Favorites
                        </Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>Webcams</Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Live Weather Station Data
                        </Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Favorite Weather Stations List
                        </Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Weather Maps
                        </Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Point Forecasts for Anywhere in the US
                        </Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>
                        <Typography fontWeight={"bold"}>
                          Ad Free Experience
                        </Typography>
                      </TableCell>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">
                        <Check htmlColor="green" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        )}

        {/* <Box pb={1}>
          <Box display="flex" justifyContent="space-evenly" flexWrap={"wrap"}>
            <Paper sx={{ p: 2, my: 1 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                height={"100%"}
                flexDirection="column"
              >
                <Box
                  display="flex"
                  width="100%"
                  alignItems={"center"}
                  flexDirection={"column"}
                  sx={{
                    color: "white",
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "5px",
                  }}
                >
                  <Typography variant="h5">Free</Typography>
                  <Typography pb={2} variant="h6">
                    $0
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Hourly and Daily Forecasts</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Webcams</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>3 Favorites</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check />
                  <Typography sx={{ textDecoration: "line-through" }}>
                    Ad Free
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check />
                  <Typography sx={{ textDecoration: "line-through" }}>
                    Custom Point Forecasts
                  </Typography>
                </Box>

                <Button
                  onClick={() => {
                    navigate("/register")
                  }}
                  disabled={userIsPremium}
                  variant="contained"
                >
                  Continue to Free
                </Button>
              </Box>
            </Paper>

            <Paper sx={{ p: 2, my: 1 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                height={"100%"}
                flexDirection="column"
              >
                <Box
                  display="flex"
                  width="100%"
                  alignItems={"center"}
                  flexDirection={"column"}
                  sx={{
                    color: "white",
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "5px",
                  }}
                >
                  <Typography variant="h5">Monthly</Typography>
                  <Typography pb={2} variant="h6">
                    $5/month
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Hourly and Daily Forecasts</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Webcams</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Unlimited Favorites</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Ad Free</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Custom Point Forecasts</Typography>
                </Box>

                <Button
                  onClick={async () => {
                    if (!user) {
                      setShowLoginPopup(true)
                      return
                    }
                    sendEvent(SnarfEvents.SubscribeMonthly)
                    const body = await createPaymentSession(
                      "price_1Oj8JvL7hSf9bWTFd9Hl8dby"
                    )
                    window.location.href = body.url
                  }}
                  disabled={userIsPremium}
                  variant="contained"
                >
                  Subscribe
                </Button>
              </Box>
            </Paper>

            <Paper sx={{ p: 2, my: 1 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection="column"
                height={"100%"}
              >
                <Box
                  display="flex"
                  width="100%"
                  alignItems={"center"}
                  flexDirection={"column"}
                  sx={{
                    color: "white",
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: "5px",
                  }}
                >
                  <Typography variant="h5">Yearly</Typography>
                  <Typography pb={2} variant="h6">
                    $40/year
                  </Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Hourly and Daily Forecasts</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Webcams</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Unlimited Favorites</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Ad Free</Typography>
                </Box>

                <Box display="flex" alignItems="center" p={1}>
                  <Check htmlColor="green" />
                  <Typography>Custom Point Forecasts</Typography>
                </Box>

                <Button
                  onClick={async () => {
                    if (!user) {
                      setShowLoginPopup(true)
                      return
                    }
                    sendEvent(SnarfEvents.SubscribeYearly)
                    const body = await createPaymentSession(
                      "price_1Oj8K4L7hSf9bWTFm6fEjodc"
                    )
                    window.location.href = body.url
                  }}
                  disabled={userIsPremium}
                  variant="contained"
                >
                  Subscribe
                </Button>
              </Box>
            </Paper>
          </Box>
        </Box> */}
      </Container>
    </>
  )
}
