import { Box, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import NorthIcon from "@mui/icons-material/North"
import SouthIcon from "@mui/icons-material/South"
import { getWindSpeedStyle } from "../../utilities/styling"

export function HelpForecastOverview() {
  const theme = useTheme()

  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography variant="h5">Forecast Overview</Typography>
        <Grid container direction={"row"}>
          {/* <Grid item lg={3} xs={12}>
            <img src={"/fo-tile.png"} alt="" loading="lazy" />
          </Grid> */}
          <Grid item lg={9} xs={12}>
            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Weather Icon: </span>
              Below the "Day" (6 am - 6 pm) and "Night" (6 pm - 6 am) you'll see
              the icon that best represents the weather that day.
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Wind: </span>
              Underneath each icon you'll see a wind direction and wind speed
              for that section of the day.
            </Typography>
            <ul>
              <li>
                <Typography>
                  <span style={getWindSpeedStyle(30, theme)}>Yellow </span>
                  represents at least 1 hour of wind speeds between 15 - 30 mph
                </Typography>
              </li>
              <li>
                <Typography>
                  <span style={getWindSpeedStyle(60, theme)}>Red </span>
                  represents at least 1 hour of wind speeds greater than 30 mph
                </Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Temperature: </span>
              On the day side the high temperature for that period, on the night
              the low temperature for that period.
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Snow: </span>
              The amount of snow expected to fall during that period. The Snow
              icon will be blue when any amount of snow is expected{" "}
              <AcUnitIcon color="primary" /> and it will be grey{" "}
              <AcUnitIcon color="disabled" /> when no snow is expected
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Hourly Snow: </span>A graph
              showing the amount of snow expected to fall each hour. This gives
              you an idea of when it will be snowing and how heavily.
            </Typography>
            <ul>
              <li>
                <Typography>
                  X-Axis: Hour of the day in 24-hour time.
                </Typography>
              </li>
              <li>
                <Typography>Y-Axis: Inches of snowfall</Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Snow Level: </span>The
              elevation above which it's expected to snow, and below where it's
              expected to rain (gasp!). Pray for low snow levels.
            </Typography>
            <ul>
              <li>
                <Typography>
                  The <NorthIcon sx={{ color: "red", fontSize: 15 }} /> snow
                  level represents the maximum snow level expected that day
                </Typography>
              </li>
              <li>
                <Typography>
                  The <SouthIcon sx={{ color: "green", fontSize: 15 }} /> snow
                  level represents the maximum snow level expected that day
                </Typography>
              </li>
              <li>
                <Typography>
                  X-Axis: Hour of the day in 24-hour time.
                </Typography>
              </li>
              <li>
                <Typography>Y-Axis: Snow level in feet</Typography>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
