import {
  CellTower,
  ChevronLeft,
  Clear,
  Search,
  Snowboarding,
  Terrain,
} from "@mui/icons-material"
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material"
import React, { useContext, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { SearchResult } from "../../api/models"
import { sendEvent, SnarfEvents } from "../../utilities/metrics"
import { SearchResultItem } from "./SearchResultItem"

enum SearchTypes {
  Climbing = "climbing",
  All = "all",
  Skiing = "skiing",
  Mountains = "mountains",
  Stations = "stations",
  google = "google",
}

const searchTypesConfig = [
  {
    name: "All",
    icon: <></>,
    key: SearchTypes.All,
  },
  {
    name: "Skiing",
    icon: <Snowboarding htmlColor="black" />,
    key: SearchTypes.Skiing,
  },
  {
    name: "Mountains",
    icon: <Terrain htmlColor="black" />,
    key: SearchTypes.Mountains,
  },
  {
    name: "Stations",
    icon: <CellTower htmlColor="black" />,
    key: SearchTypes.Stations,
  },
  {
    name: "Climbing",
    icon: (
      <img
        style={{ width: "20px", height: "20px" }}
        src="/climber.png"
        alt=""
      />
    ),
    key: SearchTypes.Climbing,
  },
  {
    name: "Google",
    icon: <></>,
    key: SearchTypes.google,
  },
]
export interface MobileSearchProps {
  term: string
  onSearchTermUpdate: (term: string, categories: any) => any
  onSelect: (result: SearchResult) => any
  loading?: boolean
  results?: SearchResult[]
}

export function MobileSearch({
  term,
  onSearchTermUpdate,
  results,
  loading,
  onSelect,
}: MobileSearchProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchCategories, setSearchCategories] = useState<any>({ all: true })

  const onFocus = () => {
    if (!searchParams.has("search")) {
      searchParams.set("search", "true")
      setSearchParams(searchParams)
    }
  }

  const onTypeToggle = (key: SearchTypes) => {
    const newSearchTypes: any = { ...searchCategories }
    switch (key) {
      case SearchTypes.All: {
        const searchTypes = {
          all: true,
        }
        setSearchCategories(searchTypes)
        sendEvent(SnarfEvents.SearchAllSelect)
        break
      }
      case SearchTypes.Skiing: {
        newSearchTypes[SearchTypes.All] = false
        newSearchTypes[SearchTypes.Skiing] = !newSearchTypes[SearchTypes.Skiing]
        setSearchCategories(newSearchTypes)
        sendEvent(SnarfEvents.SearchSkiSelect)
        break
      }

      case SearchTypes.Mountains: {
        newSearchTypes[SearchTypes.All] = false
        newSearchTypes[SearchTypes.Mountains] =
          !newSearchTypes[SearchTypes.Mountains]
        setSearchCategories(newSearchTypes)
        sendEvent(SnarfEvents.SearchMountainsSelect)
        break
      }

      case SearchTypes.Climbing: {
        newSearchTypes[SearchTypes.All] = false
        newSearchTypes[SearchTypes.Climbing] =
          !newSearchTypes[SearchTypes.Climbing]
        setSearchCategories(newSearchTypes)
        sendEvent(SnarfEvents.SearchClimbSelect)
        break
      }

      case SearchTypes.Stations: {
        newSearchTypes[SearchTypes.All] = false
        newSearchTypes[SearchTypes.Stations] =
          !newSearchTypes[SearchTypes.Climbing]
        setSearchCategories(newSearchTypes)
        break
      }

      case SearchTypes.google: {
        newSearchTypes[SearchTypes.All] = false
        newSearchTypes[SearchTypes.google] = !newSearchTypes[SearchTypes.google]
        setSearchCategories(newSearchTypes)
        sendEvent(SnarfEvents.SearchGoogleSelect)
        break
      }

      default:
        break
    }

    onSearchTermUpdate(term, newSearchTypes)
  }

  return (
    <>
      <FormControl
        sx={{ backgroundColor: "white", borderRadius: "4px", width: "100%" }}
        variant="outlined"
      >
        <OutlinedInput
          id="outlined-adornment-password"
          type={"text"}
          value={term}
          onFocus={onFocus}
          onChange={(event) =>
            onSearchTermUpdate(event.currentTarget.value, searchCategories)
          }
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          endAdornment={
            term && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => onSearchTermUpdate("", searchCategories)}
                  edge="end"
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </FormControl>
      {searchParams.has("search") && (
        <Box
          position="absolute"
          sx={{
            top: 0,
            left: 0,
            backgroundColor: "white",
          }}
          width="100vw"
          height="100vh"
          p={2}
          zIndex={10}
        >
          <Box>
            <FormControl
              sx={{
                backgroundColor: "white",
                borderRadius: "4px",
                width: "100%",
              }}
              variant="outlined"
            >
              <OutlinedInput
                autoFocus
                id="outlined-adornment-password"
                type={"text"}
                value={term}
                onChange={(event) =>
                  onSearchTermUpdate(
                    event.currentTarget.value,
                    searchCategories
                  )
                }
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
                endAdornment={
                  term && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => onSearchTermUpdate("", searchCategories)}
                        edge="end"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
          </Box>

          {loading && (
            <Box
              display="flex"
              height="100%"
              width="100%"
              justifyContent={"center"}
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          )}

          <Stack
            direction="row"
            spacing={1}
            py={1}
            overflow="scroll"
            maxWidth={"100vw"}
          >
            {searchTypesConfig.map((config: any) => (
              <Chip
                icon={config.icon}
                color={
                  searchCategories && searchCategories[config.key]
                    ? "primary"
                    : "default"
                }
                label={<Typography>{config.name}</Typography>}
                onClick={() => onTypeToggle(config.key)}
              />
            ))}
          </Stack>

          {/* {!results ||
            (results.length === 0 && !loading && (
              <Box sx={{ color: "black" }}>
                <Typography>Try searching for a Crag or Ski area</Typography>
              </Box>
            ))} */}

          {results && results.length > 0 && !loading && (
            <Box pb={1}>
              {results.map((res) => (
                <SearchResultItem
                  key={res.id}
                  onClick={onSelect}
                  searchResult={res}
                />
              ))}
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
