import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { auth, logInWithEmailAndPassword, signInWithGoogle } from "../firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { Header } from "./Header"
import Paper from "@mui/material/Paper"
import Container from "@mui/material/Container"
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import CssBaseline from "@mui/material/CssBaseline"
import { Box, Typography, useTheme } from "@mui/material"
import AcUnitIcon from "@mui/icons-material/AcUnit"
import { sendPasswordResetEmail } from "firebase/auth"
import GoogleButton from "react-google-button"

export function LoginPage() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState("")
  const [user, loading] = useAuthState(auth)
  const [resetSent, setResetSent] = useState(false)
  const navigate = useNavigate()
  const theme = useTheme()

  const onLogin = () => {
    logInWithEmailAndPassword(email, password)
      .then(() => {
        window.location.href = "https://snarfme.com/intro"
      })
      .catch((err) => {
        console.error(err)
        setError(err)
      })
  }

  const onResetPassword = () => {
    if (email && email.length > 0) {
      sendPasswordResetEmail(auth, email).then(() => {
        setResetSent(true)
      })
    }
  }

  const googlesignIn = () => {
    signInWithGoogle()
      .then(() => {
        window.location.href = "https://snarfme.com/intro"
      })
      .catch((err) => {
        console.error(err)
        setError(err)
      })
  }

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return
    }
    if (user) window.location.href = "https://snarfme.com/intro"
  }, [user, loading])
  return (
    <>
      <CssBaseline />
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 2,
        }}
      >
        <Paper elevation={3} sx={{ m: 2, width: "400px" }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ mt: 2 }}
          >
            <Grid item xs={12}>
              <Box
                display="flex"
                sx={{ color: theme.palette.primary.main, mt: 2, ml: -2 }}
                justifyContent="center"
                alignItems="center"
              >
                <AcUnitIcon sx={{ fontSize: "42px" }} />
                <Typography variant="h3">Snarf</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                className="login__textBox"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="E-mail Address"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="password"
                className="login__textBox"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                disabled={!email || !password}
                onClick={onLogin}
                type="submit"
              >
                {" "}
                Login
              </Button>
            </Grid>
            <Box py={1} display="flex" justifyContent={"center"}>
              <GoogleButton onClick={googlesignIn} />
            </Box>

            <Grid item xs={12}>
              <Typography>
                Don't have an account? <Link to="/register">Sign Up</Link> now.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{ textDecoration: "underline" }}
                onClick={onResetPassword}
              >
                Forgot Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {error ? (
                <Typography color="red">Incorrect username/password</Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} py={1}>
              {resetSent ? (
                <Typography color="green">
                  Password reset email sent!
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  )
}
