import { Box, MenuItem, Radio, Select, Slider, Typography } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"

const metrics = [
  { name: "Resort/Backcountry", id: "none" },
  { name: "Forecasted Snow", id: "forecastedSnow" },
]

interface SkiAreaControlsProps {
  onChange: (payload: any) => any
  config?: {
    metric: string
    startDate: Dayjs
    endDate: Dayjs
    dateRange: [number, number]
  }
}

const marks = [
  {
    value: 0,
    label: dayjs().format("ddd"),
  },
  {
    value: 15,
    label: dayjs().add(1, "day").format("ddd"),
  },
  {
    value: 15 * 2,
    label: dayjs().add(2, "day").format("ddd"),
  },
  {
    value: 15 * 3,
    label: dayjs().add(3, "day").format("ddd"),
  },
  {
    value: 15 * 4,
    label: dayjs().add(4, "day").format("ddd"),
  },
  {
    value: 15 * 5,
    label: dayjs().add(5, "day").format("ddd"),
  },
  {
    value: 15 * 6,
    label: dayjs().add(6, "day").format("ddd"),
  },
  {
    value: 100,
    label: dayjs().add(7, "day").format("ddd"),
  },
]

export function SkiAreaControls({ config, onChange }: SkiAreaControlsProps) {
  const handleChange = (event: Event, newValue: number | number[]) => {
    const [startOffset, endoffset] = newValue as [number, number]
    const startDate = dayjs()
      .add(startOffset / 15, "days")
      .startOf("day")
    const endDate = dayjs()
      .add(endoffset / 15, "days")
      .endOf("day")

    onChange({
      ...config,
      startDate,
      endDate,
      dateRange: newValue as [number, number],
    })
  }

  return (
    <Box>
      <Box>
        <Typography fontWeight={"bold"}>Icon</Typography>

        {metrics.map((metric) => (
          <Box display="flex" alignItems={"center"}>
            <Radio
              checked={
                (!config?.metric && metric.id === "none") ||
                config?.metric === metric.id
              }
              onChange={() => onChange({ ...config, metric: metric.id })}
              value={metric.id}
              name="radio-buttons"
              inputProps={{ "aria-label": metric.name }}
            />
            <Typography fontWeight={"bold"}>{metric.name}</Typography>
          </Box>
        ))}

        {/* <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={config?.metric || "none"}
          onChange={(event) =>
            onChange({ ...config, metric: event.target.value as string })
          }
        >
          {metrics.map((metric) => (
            <MenuItem key={metric.id} value={metric.id}>
              {metric.name}
            </MenuItem>
          ))}
        </Select> */}
      </Box>

      {config?.metric === "forecastedSnow" && (
        <Box p={1}>
          <Box display="flex" justifyContent={"center"}>
            <Typography>Forecasted Snow Between:</Typography>
          </Box>
          <Slider
            getAriaLabel={() => "Date range"}
            value={config?.dateRange || [0, 100]}
            onChange={handleChange}
            step={null}
            valueLabelDisplay="off"
            marks={marks}
            getAriaValueText={(value) =>
              dayjs().add(value, "day").format("ddd")
            }
          />
        </Box>
      )}
    </Box>
  )
}
