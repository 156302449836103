import React, { useEffect, useState } from "react"
import { redirect, Route, Routes } from "react-router-dom"
import { FAQ } from "./pages/FAQ"
import { Account } from "./pages/Account"
import { GettingStarted } from "./pages/GettingStarted"
import { Header } from "./pages/Header"
import { Help } from "./pages/Help"
import { Home } from "./pages/Home"
import { LoginPage } from "./pages/LoginPage"
import { PointDetailPage } from "./pages/PointDetailPage"
import { RegisterPage } from "./pages/RegisterPage"
import { Splash } from "./pages/Splash"
import { About } from "./pages/About"
import { MapPage } from "./pages/MapPage"
import { Subscribe } from "./pages/subscribe"
import { Contact } from "./pages/Contact"
import { Terms } from "./pages/Terms"
import { SnowScanner } from "./pages/SnowScanner"
import { StationPage } from "./pages/StationPage"
import { PrivacyPolicy } from "./pages/PrivacyPolicy"
import { Feedback } from "./pages/Feedback"
import { MobileApps } from "./pages/MobileApps"

export function App(props: any) {
  const [domLoaded, setDomLoaded] = useState(false)

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  if (typeof window === "undefined" || !domLoaded) {
    return <></>
  } else {
    return (
      <Header>
        <Routes>
          <Route path={"/"} element={<Splash />} />
          <Route path={"/home"} element={<Home points={props.props || []} />} />
          <Route path={"/login"} element={<LoginPage />} />
          <Route path={"/register"} element={<RegisterPage />} />
          <Route path={"/point/:pointId"} element={<PointDetailPage />} />
          <Route path={"/pointads/:pointId"} element={<PointDetailPage />} />
          <Route path={"/station/:stid"} element={<StationPage />} />
          <Route path={"/howto"} element={<Help />} />
          <Route path={"/faq"} element={<FAQ />} />
          <Route path={"/about"} element={<About />} />
          <Route path={"/account"} element={<Account />} />
          <Route path={"/intro"} element={<Splash />} />
          <Route path={"/contact"} element={<Contact />} />
          <Route path={"/getting-started"} element={<GettingStarted />} />
          <Route path={"/map"} element={<MapPage />} />
          {/* <Route path={"/support"} element={<Subscribe />} /> */}
          <Route path={"/subscribe"} element={<Subscribe />} />
          <Route path={"/terms"} element={<Terms />} />
          <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={"/snow-scanner"} element={<SnowScanner />} />
          <Route path={"/feedback"} element={<Feedback />} />
          <Route path={"/apps"} element={<MobileApps />} />
        </Routes>
      </Header>
    )
  }
}
