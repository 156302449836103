import { ExpandMore } from "@mui/icons-material"
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
} from "@mui/material"
import React from "react"
import { SnarfEvents, sendEvent } from "../utilities/metrics"

export interface ForecastDiscussionProps {
  issuanceTime: string
  rawText: string
}

export function ForecastDiscussion({ rawText }: ForecastDiscussionProps) {
  return (
    <>
      <Accordion
        onChange={(event: React.SyntheticEvent, expanded: boolean) => {
          if (expanded) {
            sendEvent(SnarfEvents.ForecastDiscussionExpanded)
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="h5" fontWeight={"bold"}>
            Forecast Discussion
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            {rawText.split("\n").map((text, index) => (
              <Typography key={index}>{text}</Typography>
            ))}
            <Typography>{rawText}</Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  )
}
