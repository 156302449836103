import { gql } from "urql"

export const cameraByIdQuery = gql`
  query cameraByIdQuery($id: ID!) {
    camera(id: $id) {
      id
      url
      name
      lat
      lon
      elevation
      displayType
    }
  }
`
