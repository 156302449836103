import { Box, Typography } from "@mui/material"
import React from "react"

export function HelpForecastElevation() {
  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography variant="h5">What is the Forecast Elevation?</Typography>
        <Typography>
          Forecasts are created for a 2.5km x 2.5km Grid Square by NOAA/NWS. As
          you might imagine this could include varying topography. This
          elevation is calculated by Noaa's Global Forecasting System to give
          you the best possible forecast for that area.
        </Typography>
      </Box>
    </>
  )
}
