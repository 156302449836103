import {
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Snackbar,
  Typography,
} from "@mui/material"
import dayjs from "dayjs"
import React, { useMemo, useState } from "react"
import { FunnelChart, Funnel, LabelList } from "recharts"
import { getAvvyDanger } from "../api/utilities"
import { Danger } from "../models/models"
import { Close, Notifications, NotificationsNone } from "@mui/icons-material"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import { graphqlClient } from "../graphql"
import { updateSubscriptionMutation } from "../graphql/mutations"
import { SubscriptionStatus, SubscriptionType } from "../api/models"
import { LoginModal } from "./LoginModal"
import { useNavigate } from "react-router-dom"

export interface AvalancheDangerProps {
  danger: Danger[]
  url: string
  expires: string
  zone?: string
  zoneId?: number
  bottomLine?: string
  notificationsEnabled?: boolean
  notificationToggleEnabled?: boolean
}

export function AvalancheDanger(props: AvalancheDangerProps) {
  const {
    bottomLine,
    notificationsEnabled,
    zone,
    zoneId,
    notificationToggleEnabled,
  } = props
  const [user, loadingAuthState] = useAuthState(auth)
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const [showNotificaitonUpdated, setShowNotificaitonUpdated] = useState(false)
  const navigate = useNavigate()

  const getAvvyDangerFunnelData = (danger: Danger) => {
    if (!danger) {
      return [
        {
          value: 75,
          fill: "grey",
        },
        {
          value: 50,
          fill: "grey",
        },
        {
          value: 25,
          fill: "grey",
        },
      ]
    }
    const low = getAvvyDanger(danger.lower)
    const mid = getAvvyDanger(danger.middle)
    const high = getAvvyDanger(danger.upper)
    return [
      {
        value: 75,
        fill: low.color,
      },
      {
        value: 50,
        fill: mid.color,
      },
      {
        value: 25,
        fill: high.color,
      },
    ]
  }

  const onAvalancheNotificationClick = () => {
    if (user) {
      graphqlClient
        .mutation(updateSubscriptionMutation, {
          type: SubscriptionType.AVALANCHE,
          status: notificationsEnabled
            ? SubscriptionStatus.INACTIVE
            : SubscriptionStatus.ACTIVE,
          zoneId,
        })
        .toPromise()
        .then(() => {
          setShowNotificaitonUpdated(true)
        })
    } else {
      setShowLoginPopup(true)
    }
  }

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={() => navigate("/account")}
      >
        Manage
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setShowNotificaitonUpdated(false)}
      >
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  const bottomLineCleaned = useMemo(() => {
    if (bottomLine) {
      return bottomLine.replace(/<.+?>/g, "").replace(/&nbsp;/g, " ")
    }

    return bottomLine
  }, [bottomLine])
  return (
    <>
      <Snackbar
        open={showNotificaitonUpdated}
        autoHideDuration={6000}
        onClose={() => setShowNotificaitonUpdated(false)}
        message="Notifications Updated"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        action={action}
      />
      <LoginModal
        open={showLoginPopup}
        onClose={() => {
          setShowLoginPopup(false)
        }}
      />
      {/* <Box display="flex" alignItems={"center"} justifyContent={"center"}>
        <Box>
          <Typography variant="h5">{zone}</Typography>
        </Box>
        {notificationToggleEnabled && (
          <Box>
            <IconButton onClick={onAvalancheNotificationClick}>
              {notificationsEnabled ? (
                <Notifications color="primary" />
              ) : (
                <NotificationsNone />
              )}
            </IconButton>
          </Box>
        )}
      </Box> */}
      <Box display="flex" justifyContent={"center"}>
        {/* <Box>
          <Box style={{ rotate: "180deg" }}>
            <FunnelChart width={125} height={75}>
              <Funnel
                dataKey="value"
                data={getAvvyDangerFunnelData(props.danger[0])}
                isAnimationActive
              >
                <LabelList
                  position="right"
                  fill="#000"
                  stroke="none"
                  dataKey="name"
                />
              </Funnel>
            </FunnelChart>
          </Box>
        </Box> */}

        {/* <Box>
          <Box style={{ rotate: "180deg" }}>
            <FunnelChart width={125} height={75}>
              <Funnel
                dataKey="value"
                data={getAvvyDangerFunnelData(props.danger[1])}
                isAnimationActive
              >
                <LabelList
                  position="right"
                  fill="#000"
                  stroke="none"
                  dataKey="name"
                />
              </Funnel>
            </FunnelChart>
          </Box>
        </Box> */}
      </Box>
      <Grid container justifyContent={"center"}>
        <Grid item>
          <Typography sx={{ textAlign: "center", ml: 1 }}>
            <Link href={props.url} target="_blank" rel="noopener noreferrer">
              <Button variant="contained">
                <Typography>Avalanche Forecast</Typography>
              </Button>
            </Link>
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid container justifyContent={"center"}>
        <Grid item>
          <Typography variant="caption" sx={{ textAlign: "center", ml: 1 }}>
            Expires: {dayjs(props.expires).format("ddd MMM D hh:mma")}
          </Typography>
        </Grid>
      </Grid> */}

      {/* {bottomLine && (
        <Box display="flex" flexDirection={"column"} alignItems="center" p={1}>
          <Typography fontWeight={"bold"}>Bottom Line</Typography>
          <Typography>{bottomLineCleaned}</Typography>
        </Box>
      )} */}
    </>
  )
}
