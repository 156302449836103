import { Box, Grid, Typography, useTheme } from "@mui/material"
import React from "react"
import { getWindSpeedStyle } from "../../utilities/styling"

export function HelpHourlyTable() {
  const theme = useTheme()
  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography variant="h5">
          How do I use the 7 Day Hourly Forecast
        </Typography>
        <Grid container direction={"row"}>
          {/* <Grid item lg={5} xs={12}>
            <img src={"/7hf.png"} alt="" loading="lazy" />
          </Grid> */}
          <Grid item lg={7} xs={12}>
            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Temp: </span>The temperature
              for that hour, pretty self explanatory.
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Snow Level: </span>The
              elevation above which it's expected to snow, and below where it's
              expected to rain (gasp!). Pray for low snow levels.
            </Typography>
            <ul>
              <li>
                <Typography>
                  <span style={{ color: theme.palette.primary.main }}>
                    Blue{" "}
                  </span>
                  shows the forecasted freezing level is below the elevation of
                  the spot you are forecasting for.
                </Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Snow: </span>
              Amount of snow forecasted for that hour.
            </Typography>
            <ul>
              <li>
                <Typography>
                  <span style={{ color: theme.palette.primary.main }}>
                    Blue{" "}
                  </span>
                  highlights any hours with snow forecasted.
                </Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Precip: </span>
              The forecasted amount of precipitation, theoretically the amount
              of liquid if you melted the snow.
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Wind Speed / Gusts: </span>
              How fast the wind is a blowin.
            </Typography>
            <ul>
              <li>
                <Typography>
                  <span style={getWindSpeedStyle(30, theme)}>Yellow </span>
                  represents at least 1 hour of wind speeds between 15 - 30 mph
                </Typography>
              </li>
              <li>
                <Typography>
                  <span style={getWindSpeedStyle(60, theme)}>Red </span>
                  represents at least 1 hour of wind speeds greater than 30 mph
                </Typography>
              </li>
            </ul>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Wind Direction: </span>
              Which way the wind is a blowin.
            </Typography>

            <Typography sx={{ mt: 2 }}>
              <span style={{ fontWeight: "bold" }}>Cloud Cover: </span> How much
              of the big blue sky is going to be hidden behind clouds.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
