import {
  Grid,
  Paper,
  Box,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Link,
  useMediaQuery,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { mmToIn, mToFt } from "../utilities/conversion"
import { AvalancheDanger } from "./AvalancheDanger"
import LandscapeIcon from "@mui/icons-material/Landscape"
import { PointSummary } from "../models/models"
import EditIcon from "@mui/icons-material/Edit"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import { useAuthState } from "react-firebase-hooks/auth"
import { UserPointOfInterestType } from "../api/models"
import { auth } from "../firebase"
import { LoginModal } from "./LoginModal"
import WaterDropIcon from "@mui/icons-material/WaterDrop"
import { getPreviousRain } from "../utilities/analysis"
import MapIcon from "@mui/icons-material/Map"
import { useNavigate } from "react-router-dom"
import { RockClimberCircle } from "../icons/RockClimberCircle"
import { points } from "@turf/turf"
import { HelpMe } from "./HelpMe"
import { FAQAnchors } from "../pages/FAQ"
import { getStaticTileUrl } from "../api/utilities"
import { is } from "date-fns/locale"
import { graphqlClient } from "../graphql"
import { updateFavoriteMutation } from "../graphql/mutations"
import { ActivityContext, UserPremiumContext } from "../pages/Header"
import { IosShare } from "@mui/icons-material"
import { SnarfEvents, sendEvent } from "../utilities/metrics"
import { SubscribeModal } from "./SubscribeModal"

export interface PointDetailHeaderProps {
  pointSummary: PointSummary
  hideFavorite?: boolean
  type: UserPointOfInterestType
  onNameUpdate: (name: string) => any
  onSubPointSelect: (pointId: string) => any
  selectedSubPointId: string
  favoritesCount: number
}

export function PointDetailHeader({
  pointSummary,
  hideFavorite,
  type,
  onNameUpdate,
  onSubPointSelect,
  selectedSubPointId,
  favoritesCount,
}: PointDetailHeaderProps) {
  const [editName, setEditName] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const [showLinkCopied, setShowLinkCopied] = useState(false)
  const [name, setName] = useState("")
  const [user, loadingAuthState] = useAuthState(auth)
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const [showSubscribePopup, setShowSubscribePopup] = useState(false)
  const isSmall = useMediaQuery("(max-width:600px)")
  const navigate = useNavigate()
  const selectedActivity = useContext(ActivityContext)
  const userIsPremium = useContext(UserPremiumContext)

  const onSetFavorite = (isFav: boolean) => {
    if (user && userIsPremium) {
      setIsFavorite(isFav)
      sendEvent(SnarfEvents.Favorite)
      graphqlClient
        .mutation(updateFavoriteMutation, {
          pointId: pointSummary.id,
          isFavorite: isFav,
          type: selectedActivity,
        })
        .toPromise()
    } else {
      setShowSubscribePopup(true)
    }
  }

  useEffect(() => {
    if (pointSummary && pointSummary.isFavorite) {
      setIsFavorite(pointSummary.isFavorite)
    }
  }, [pointSummary])

  const onEditClick = () => {
    if (user && userIsPremium) {
      setName(pointSummary ? pointSummary.name : "")
      setEditName(true)
    } else {
      setShowSubscribePopup(true)
    }
  }

  const onEditClose = () => {
    if (pointSummary) {
      pointSummary.name = name
    }
    onNameUpdate(name)
    setEditName(false)
  }

  const getFavoriteIcon = (isFavorite: boolean) => {
    if (hideFavorite) {
      return null
    }

    if (isFavorite) {
      return (
        <Tooltip title="Save to my spots">
          <IconButton
            onClick={() => {
              if (user && userIsPremium) {
                onSetFavorite(!isFavorite)
              } else {
                setShowSubscribePopup(true)
              }
            }}
          >
            <StarIcon sx={{ fontSize: "2rem", color: "gold" }} />
          </IconButton>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title="Save to my spots">
          <IconButton
            onClick={() => {
              if (user && userIsPremium) {
                onSetFavorite(!isFavorite)
              } else {
                setShowSubscribePopup(true)
              }
            }}
          >
            <StarBorderIcon sx={{ fontSize: "2rem" }} />
          </IconButton>
        </Tooltip>
      )
    }
  }

  return (
    <>
      <LoginModal
        open={showLoginPopup}
        onClose={() => setShowLoginPopup(false)}
      />

      <SubscribeModal
        message={
          "You've reached the maximum number of favorites as a free user. Consider subscribing to get unlimited favorites and more."
        }
        open={showSubscribePopup}
        onClose={() => setShowSubscribePopup(false)}
      />

      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
          }}
        >
          {!pointSummary ? (
            <>
              <Box
                display="flex"
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent={"center"}
              >
                <CircularProgress />
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                  pb: 1,
                }}
              >
                <Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box
                      display="flex"
                      alignItems={"center"}
                      maxWidth={isSmall ? "250px" : undefined}
                    >
                      {editName ? (
                        <TextField
                          type="text"
                          value={name}
                          autoFocus
                          onChange={(e) => setName(e.target.value)}
                          onBlur={onEditClose}
                        />
                      ) : (
                        <Typography noWrap variant="h5">
                          {pointSummary ? pointSummary.name : ""}
                        </Typography>
                      )}
                      <Box
                        onClick={onEditClick}
                        sx={{ pl: 1, cursor: "pointer" }}
                      >
                        <EditIcon />
                      </Box>
                    </Box>

                    <Box>{getFavoriteIcon(isFavorite)}</Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", color: "black" }}
                    alignItems="center"
                  >
                    {pointSummary?.profilePoints ? (
                      <Box display="flex" flexDirection={"column"}>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectedSubPointId}
                          onChange={(event) =>
                            onSubPointSelect(event.target.value)
                          }
                        >
                          <MenuItem value={pointSummary.id}>
                            {mToFt(pointSummary.noaaElevation)} ft
                          </MenuItem>
                          {pointSummary.profilePoints.map((point, index) => (
                            <MenuItem value={point.id}>
                              {mToFt(point.noaaElevation)} ft
                            </MenuItem>
                          ))}
                        </Select>
                        <Box display="flex" alignItems="center">
                          <Typography variant="caption">
                            Forecast Elevation
                          </Typography>
                          <HelpMe tag={FAQAnchors.forecastElevation} event={SnarfEvents.HelpMeForcastElevation}/>
                        </Box>
                      </Box>
                    ) : (
                      <>
                        <LandscapeIcon />
                        <Typography>
                          {Math.round(
                            pointSummary
                              ? pointSummary.noaaElevation * 3.28084
                              : 0
                          )}{" "}
                          ft
                        </Typography>
                      </>
                    )}
                  </Box>

                  {type === UserPointOfInterestType.ROCK_CLIMB &&
                    pointSummary?.climbingAreas &&
                    pointSummary.climbingAreas.length > 0 && (
                      <Box display="flex" pt={1} alignItems="center">
                        <RockClimberCircle />
                        <Link
                          href={`https://openbeta.io/crag/${pointSummary.climbingAreas[0].openBetaAreaId}`}
                          sx={{ color: "black", cursor: "pointer" }}
                          target="_blank"
                        >
                          Route Info
                        </Link>
                      </Box>
                    )}

                  {type === UserPointOfInterestType.ROCK_CLIMB &&
                  pointSummary ? (
                    <Box display="flex" alignItems="center">
                      <WaterDropIcon
                        color={
                          mmToIn(
                            getPreviousRain(pointSummary.rockClimbingSummary),
                            100
                          ) > 0
                            ? "primary"
                            : "disabled"
                        }
                      />
                      <Typography mt={1}>
                        Past 2 days:{" "}
                        {mmToIn(
                          getPreviousRain(pointSummary.rockClimbingSummary),
                          100
                        )}{" "}
                        in
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>

              <Box display="flex" justifyContent={"space-between"}>
                <Box display="flex" justifyContent="flex-start">
                  <Typography variant="caption">Sources: </Typography>

                  <Box pl={"2px"} sx={{ cursor: "pointer" }}>
                    <img
                      src="/synoptic-logo.jpg"
                      width="20px"
                      height="20px"
                      alt=""
                    />
                  </Box>
                  <Typography variant="caption">
                    <Link
                      href={"https://synopticdata.com/"}
                      sx={{ color: "black", cursor: "pointer" }}
                      target="_blank"
                    >
                      Synoptic,
                    </Link>
                  </Typography>

                  <Typography variant="caption" pl={"2px"}>
                    <Link
                      href={`https://forecast.weather.gov/MapClick.php?lat=${pointSummary?.lat}&lon=${pointSummary?.lon}`}
                      sx={{ color: "black", cursor: "pointer" }}
                      target="_blank"
                    >
                      NOAA,
                    </Link>
                  </Typography>

                  <Typography variant="caption" pl={"2px"}>
                    <Link
                      href={"https://avalanche.org/"}
                      sx={{ color: "black", cursor: "pointer" }}
                      target="_blank"
                    >
                      Avalanche.org
                    </Link>
                  </Typography>
                </Box>
                <Box>
                  <Tooltip open={showLinkCopied} title="Link Copied!">
                    <IconButton
                      sx={{ pr: "7px" }}
                      onClick={() => {
                        sendEvent(SnarfEvents.Share, {
                          pointId: pointSummary.id,
                        })
                        setShowLinkCopied(true)
                        navigator.clipboard.writeText(window.location.href)
                        setTimeout(() => {
                          setShowLinkCopied(false)
                        }, 1700)
                      }}
                    >
                      <IosShare fontSize="large" />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </>
          )}
        </Paper>
      </Grid>
    </>
  )
}
