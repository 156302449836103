import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Link,
  Paper,
  Switch,
  Typography,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"
import { sendEmailVerification } from "firebase/auth"
import React, { useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import {
  createPaymentPortalSession,
  getUser,
  setNotifications,
} from "../api/snarf-api"
import { HelpMe } from "../components/HelpMe"
import { auth, logout } from "../firebase"
import { User } from "../models/models"
import { FAQAnchors } from "./FAQ"
import { useQuery as useGraphqlQuery } from "urql"
import { userSubsQuery } from "../graphql/queries/user-subs"
import {
  Subscription,
  SubscriptionStatus,
  SubscriptionType,
} from "../api/models"
import { graphqlClient } from "../graphql"
import { updateSubscriptionMutation } from "../graphql/mutations"

export interface AccountProps {}

export function Account() {
  const [user] = useAuthState(auth)
  const [emailSent, setEmailSent] = useState(false)
  const [snarfUser, setSnarfUser] = useState<User | null>(null)
  const navigate = useNavigate()

  const userRequest = useQuery({
    queryKey: ["user", user?.email],
    queryFn: (): any => getUser(),
    enabled: !!user,
  })

  const [userSubsRequest] = useGraphqlQuery({
    query: userSubsQuery,
    variables: {},
    pause: !user,
  })

  if (!userRequest.isLoading && !snarfUser) {
    if (!userRequest.data.processing) {
      setSnarfUser({ ...userRequest.data })
    }
  }

  const verifyEmail = () => {
    if (user) {
      sendEmailVerification(user).then(() => {
        setEmailSent(true)
      })
    }
  }

  const setNotificationsSnarfWeekly = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setNotifications({ snarfWeekly: checked }).then((newSnarfUser) => {
      setSnarfUser(newSnarfUser)
    })
  }

  const onAvalancheUpdate = (zoneId: number, checked: boolean) => {
    graphqlClient
      .mutation(updateSubscriptionMutation, {
        type: SubscriptionType.AVALANCHE,
        status: checked
          ? SubscriptionStatus.INACTIVE
          : SubscriptionStatus.ACTIVE,
        zoneId,
      })
      .toPromise()
  }

  const setNotificationsSnarfUpdates = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setNotifications({ snarfUpdates: checked }).then((newSnarfUser) => {
      setSnarfUser(newSnarfUser)
    })
  }

  const onLogout = () => {
    logout().then(() => (window.location.href = window.location.href))
  }

  return (
    <Container maxWidth="lg" sx={{ pb: 2 }}>
      <Grid container direction="column" spacing={1}>
        <Grid item sx={{ p: 1, mt: 4 }}>
          <Typography variant="h5">User Info</Typography>
          <Paper sx={{ p: 2 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box display={"flex"} flexDirection="column">
                  <Typography variant="caption">Email Address</Typography>
                  <Typography>{user?.email}</Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box display={"flex"} flexDirection="column">
                  <Typography variant="caption">Name</Typography>
                  <Typography>{snarfUser?.name}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item sx={{ p: 1, mt: 4 }}>
          <Typography variant="h5">Subscribe</Typography>
          <Paper sx={{ p: 2 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box display="flex" justifyContent={"space-between"}>
                  <Box display={"flex"} flexDirection="column">
                    <Typography variant="caption">Status</Typography>
                    {snarfUser?.subscription?.status
                      ? snarfUser?.subscription?.status
                      : "Not Active"}
                  </Box>

                  <Box>
                    {snarfUser?.subscription?.status ? (
                      <Button
                        onClick={async () => {
                          const body = await createPaymentPortalSession()
                          window.location.href = body.url
                        }}
                        variant="contained"
                      >
                        Manage Subscription
                      </Button>
                    ) : (
                      <Button
                        onClick={() => navigate("/subscribe")}
                        variant="contained"
                      >
                        Subscribe
                      </Button>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item sx={{ p: 1, mt: 4 }}>
          <Typography variant="h5">Email Notifications</Typography>
          {user && !user.emailVerified ? (
            <Alert severity="warning">
              {emailSent ? (
                "Email sent! Once you verify your email refresh this page and notifications will be enabled."
              ) : (
                <>
                  You'll need to verify your email before you can recieve
                  notifications,{" "}
                  <Link sx={{ cursor: "pointer" }} onClick={verifyEmail}>
                    click here
                  </Link>{" "}
                  to send verification email.
                </>
              )}
            </Alert>
          ) : null}
          <Paper sx={{ p: 2 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Typography>Snarf Updates</Typography>
                  <Checkbox
                    checked={snarfUser?.snarfUpdates || false}
                    onChange={setNotificationsSnarfUpdates}
                  />
                </Box>
              </Grid>
              {userSubsRequest.data?.user?.subscriptions.map(
                (sub: Subscription) => {
                  return (
                    <Grid item key={sub.id}>
                      <Box
                        display={"flex"}
                        justifyContent="space-between"
                        alignItems={"center"}
                      >
                        <Box>
                          <Typography>{sub.avalancheZone?.name}</Typography>
                          <Typography variant="caption">
                            {sub.type === SubscriptionType.AVALANCHE
                              ? "Avalanche Forecast"
                              : ""}
                          </Typography>
                        </Box>
                        <Checkbox
                          checked={
                            sub.status === SubscriptionStatus.ACTIVE || false
                          }
                          onChange={(event: any, checked: boolean) =>
                            onAvalancheUpdate(sub.zoneId || 0, !checked)
                          }
                        />
                      </Box>
                    </Grid>
                  )
                }
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item sx={{ p: 1, mt: 4 }}>
          <Typography variant="h5">Contact</Typography>
          <Paper sx={{ p: 2 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Box display={"flex"} alignItems={"center"}>
                  <Typography>
                    Got ideas or feedback on how to make Snarf more useful for
                    yourself and others? Something broken? Send me a message at{" "}
                    <Link>bob@snarfme.com</Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item sx={{ p: 1, mt: 4 }}>
          <Box display="flex" width={"100%"} justifyContent="center">
            <Button variant="contained" onClick={onLogout}>
              Logout
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
