import { Danger } from "../models/models"

export const MAPBOX_TOKEN =
  "pk.eyJ1Ijoicm9iYnlyaWxleTEyIiwiYSI6ImNsYjJwNTh5YTA2ZmEzb25wZWZlaGNjdWkifQ.Jz4f0WJaWL_ve7Hhz1Pqqg"

export function getFullDisplayNameForGeocoderResult(
  geocodeResult: google.maps.GeocoderResult
): string {
  let retString = ""
  geocodeResult.address_components.forEach((res, index) => {
    if (index === 0) {
      retString = res.short_name
    } else {
      retString = retString + ", " + res.short_name
    }
  })

  return retString
}

export function getMaxDanger(danger: Danger | null) {
  if (!danger) {
    return 0
  }

  let max = danger.lower

  if (danger.middle > max) {
    max = danger.middle
  }

  if (danger.upper > max) {
    max = danger.upper
  }

  return max
}

export function getAvvyDanger(rating: number) {
  switch (rating) {
    case 1:
      return { color: "#50b848", label: "Low" }
    case 2:
      return { color: "#fff200", label: "Moderate" }
    case 3:
      return { color: "#f7941e", label: "Considerable" }
    case 4:
      return { color: "#ed1c24", label: "High" }
    case 5:
      return { color: "#231f20", label: "Extreme" }
    default:
      return { color: "", label: "No rating" }
  }
}

export function getStaticTileUrl({
  lat,
  lon,
  width,
  height,
  zoom,
}: {
  lat: number
  lon: number
  width: number
  height: number
  zoom: number
}) {
  return `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/pin-s+3584e4(${lon},${lat})/${lon},${lat},${zoom},0/${width}x${height}?access_token=${MAPBOX_TOKEN}`
}
