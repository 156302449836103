import { gql } from "urql"

export const topSkiAreas = gql`
  query topSkiAreasQuery(
    $filters: Filters
    $sort: Sort
    $paging: PaginationParams
    $startDay: String
    $endDay: String
  ) {
    skiAreas(filters: $filters, sort: $sort, paging: $paging) {
      id
      name
      type
      state
      point {
        id
        name
        isFavorite
        lat
        lon

        snowSummary {
          forecastedSnow
          totalsByDay {
            date
            totalSnow
          }
        }

        forecastedSnowTotals(startDay: $startDay, endDay: $endDay) {
          date
          totalSnow
        }
      }
    }
  }
`
