import { gql } from "urql"

export const stationsQuery = gql`
  query stationsQuery($filters: Filters, $type: Int!) {
    stations(filters: $filters) {
      id
      name
      STID
      lon
      lat
      elevation
      sensorVariables
      isFavorite(type: $type)
      snowDepth
      temperature
      windSpeed
      relativeHumidity
      twentyFourHourSnow
    }
  }
`
