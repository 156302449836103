import dayjs from "dayjs"
import { PointOfInterest } from "../models/models"
import { cToF, mmToIn, msToMph } from "./conversion"

export const stationMetrics: any = {
  snowDepth: {
    units: '"',
    conversion: mmToIn,
    label: "Snow Depth",
  },
  temperature: {
    label: "Temperature",
    conversion: cToF,
    units: "F",
  },
  windSpeed: {
    label: "Wind Speed",
    units: "mph",
    conversion: msToMph,
  },
  twentyFourHourSnow: {
    label: "Snow Accumulation",
    units: '"',
    conversion: mmToIn,
  },
}

export function getStationMetricConfig(metric: string) {
  return stationMetrics[metric]
}

export const skiAreaMetrics: any = {
  forecastedSnow: {
    units: "In",
    conversion: mmToIn,
    label: "Forecasted Snow",
  },
}

export function getSkiAreaMetricConfig(metric: string) {
  return skiAreaMetrics[metric]
}

export function getSkiAreaMetricValue(point: any, mapConfig: any) {
  const { metric } = mapConfig
  const startDate = mapConfig.startDate || dayjs()
  const endDate = mapConfig.endDate || dayjs().add(7, "days")

  const config = getSkiAreaMetricConfig(metric)
  if (metric === "forecastedSnow") {
    let total = 0

    point.dailyTotals.forEach((dailyTotal: any) => {
      if (
        (dayjs(dailyTotal.date).isAfter(startDate) ||
          dayjs(dailyTotal.date).isSame(startDate, "day")) &&
        dayjs(dailyTotal.date).isBefore(endDate)
      ) {
        total += dailyTotal.totalSnow
      }
    })

    return config.conversion(total) + " " + config.units
  }

  if (point[metric] !== null && point[metric] !== undefined) {
    return config.conversion(point[metric]) + " " + config.units
  }

  return "--"
}

export function getRawSkiAreaMetricValue(point: any, mapConfig: any) {
  const { metric } = mapConfig
  const startDate = mapConfig.startDate || dayjs()
  const endDate = mapConfig.endDate || dayjs().add(7, "days")

  const config = getSkiAreaMetricConfig(metric)
  if (metric === "forecastedSnow") {
    let total = 0

    point.dailyTotals.forEach((dailyTotal: any) => {
      if (
        (dayjs(dailyTotal.date).isAfter(startDate) ||
          dayjs(dailyTotal.date).isSame(startDate, "day")) &&
        dayjs(dailyTotal.date).isBefore(endDate)
      ) {
        total += dailyTotal.totalSnow
      }
    })

    return config.conversion(total)
  }

  if (point[metric] !== null && point[metric] !== undefined) {
    return config.conversion(point[metric])
  }

  return 0
}
