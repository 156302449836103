import { gql } from "urql"

export const searchQuery = gql`
  query search($term: String!, $categories: SearchCategories) {
    search(term: $term, categories: $categories) {
      id
      name
      lat
      lon
      type
      pathTokens
    }
  }
`
