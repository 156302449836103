import { Client, fetchExchange, gql } from "urql"
import { cacheExchange } from "@urql/exchange-graphcache"
import { auth } from "../firebase"
import { authExchange } from "@urql/exchange-auth"
import { pointsQuery } from "./queries/points"
import { PointSummary } from "../models/models"
import { stationsQuery } from "./queries"
import { Station, SubscriptionStatus } from "../api/models"
import { updateSubscriptionMutation } from "./mutations"
import { userSubsQuery } from "./queries/user-subs"
import { Subscript } from "@mui/icons-material"

const ROOT_URL =
  process.env.REACT_APP_SNARF_API_URL ||
  "https://snarf-beta-s7eq2e6llq-uw.a.run.app"

let token = ""
async function initializeAuthState() {
  if (auth.currentUser) {
    token = await auth.currentUser.getIdToken()
  }
  return { token }
}

function getToken() {
  return token
}

export const graphqlClient = new Client({
  url: `${ROOT_URL}/graphql`,
  exchanges: [
    cacheExchange({
      keys: {
        TimeWindowSummary: () => null,
        HourlyForecast: () => null,
        ForecastAvalancheProblem: () => null,
        DailyWeatherSummary: () => null,
        AvalancheDanger: () => null,
        AvalancheForecast: () => null,
        DaySummary: () => null,
        RockClimbingSummary: () => null,
      },
      updates: {
        Mutation: {
          updateFavorite(result: any, args, cache, _info) {
            cache.updateQuery(
              {
                query: pointsQuery,
                variables: {
                  filters: {
                    isFavorite: true,
                    type: 0,
                  },
                  type: 0,
                },
              },
              (data) => {
                if (data && data.points) {
                  if (result.updateFavorite.isFavorite) {
                    data.points.push(result.updateFavorite)
                  } else {
                    data.points = data.points.filter(
                      (point: PointSummary) =>
                        point.id !== result.updateFavorite.id
                    )
                  }
                }

                return data
              }
            )
          },
          updateStationFavorite(result: any, _args, cache, _info) {
            cache.updateQuery(
              {
                query: stationsQuery,
                variables: {
                  filters: {
                    isFavorite: true,
                  },
                  type: 0,
                },
              },
              (data) => {
                if (data && data.stations) {
                  if (result.updateStationFavorite.isFavorite) {
                    data.stations.push(result.updateStationFavorite)
                  } else {
                    data.stations = data.stations.filter(
                      (station: Station) =>
                        station.id !== result.updateStationFavorite.id
                    )
                  }
                }

                return data
              }
            )
          },
          updateSubscription(result, _args, cache, _info) {
            cache.updateQuery({ query: userSubsQuery }, (data) => {
              const newSub: any = result.updateSubscription
              const currentSub = data.user.subscriptions.find(
                (sub: any) => sub.id === newSub.id
              )

              if (newSub.status === SubscriptionStatus.ACTIVE) {
                if (currentSub) {
                  currentSub.status = SubscriptionStatus.ACTIVE
                } else {
                  data.user.subscriptions.push(result.updateSubscription)
                }
              } else {
                data.user.subscriptions = data.user.subscriptions.filter(
                  (sub: any) => sub.id !== newSub.id
                )
              }
              return data
            })
          },
        },
      },
    }),
    authExchange(async (utils) => {
      const { token } = await initializeAuthState()
      return {
        addAuthToOperation(operation) {
          const token = getToken()
          if (!token) return operation
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          })
        },
        didAuthError(error, _operation) {
          return error.response.status === 401 || error.graphQLErrors.length > 0
        },
        async refreshAuth() {
          await initializeAuthState()
        },
        willAuthError(operation) {
          return !token
        },
      }
    }),
    fetchExchange,
  ],
})
