import React, { useMemo, useState } from "react"
import {
  Grid,
  Paper,
  Box,
  Typography,
  useTheme,
  Link,
  IconButton,
  Chip,
  Checkbox,
  CircularProgress,
} from "@mui/material"
import { WeatherViewer } from "./WeatherViewer"
import { GraphHQ } from "./GraphHQ"
import { HourlyTable } from "./HourlyTable"
import { DailyForecast, HourlyForecast, PointSummary } from "../models/models"
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  LabelList,
  Cell,
} from "recharts"
import dayjs from "dayjs"
import { mmToIn } from "../utilities/conversion"
import { HelpMe } from "./HelpMe"
import { FAQAnchors } from "../pages/FAQ"
import { SnowpackVisualizer } from "./SnowpackVisualizer"
import { AvalancheDanger } from "./AvalancheDanger"
import { History, Update } from "@mui/icons-material"
import { DaySummary, UserPointOfInterestType } from "../api/models"
import { useQuery } from "urql"
import { forecastedSnowTotalsQuery, pointDetailQuery } from "../graphql/queries"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth } from "../firebase"
import { pointSkiWeatherQuery } from "../graphql/queries/point-ski-weather"
import { useQuery as useReactQuery } from "@tanstack/react-query"
import { getHourlyForecastDateRange } from "../api/snarf-api"
import { ForecastDiscussion } from "./ForecastDiscussion"
import useDocumentTitle from "../hooks/use-document-title"
import { userSubsQuery } from "../graphql/queries/user-subs"
import { SnarfEvents } from "../utilities/metrics"

export interface SkiPointWeatherProps {
  pointId: string
  elevation?: number
}

export const SkiPointWeather = ({
  pointId,
  elevation,
}: SkiPointWeatherProps) => {
  const theme = useTheme()
  const [showPastSnowfall, setShowPastSnowfall] = useState(false)
  const [showHisotircalOverview, setShowHisotircalOverview] = useState(false)
  const [showHourlyHistorical, setShowHourlyHistorical] = useState(false)
  const [hourlyForecasts, setHourlyForecasts] = useState<
    HourlyForecast[] | null
  >(null)
  const [user, loadingAuthState] = useAuthState(auth)

  const [pointDetailRequest] = useQuery({
    query: pointSkiWeatherQuery,
    variables: {
      pointId,
      startDate: showHisotircalOverview
        ? dayjs().subtract(7, "days").startOf("day").toISOString()
        : dayjs().startOf("day").toISOString(),
      endDate: !showHisotircalOverview
        ? dayjs().add(7, "days").endOf("day").toISOString()
        : dayjs().endOf("day").toISOString(),
      type: UserPointOfInterestType.SKI,
    },
    pause: loadingAuthState,
  })

  const [userRequest] = useQuery({
    query: userSubsQuery,
    variables: {},
    pause: loadingAuthState,
  })

  useDocumentTitle(
    `${pointDetailRequest.data?.point?.name || ""} Skiing Weather`
  )

  const fixedPointHourlyForecastRequest = useReactQuery({
    queryKey: [
      "fixedPointHourlyForecast",
      pointId,
      showHourlyHistorical
        ? dayjs().startOf("day").subtract(7, "day").format("DD/MM/YYYY")
        : dayjs().startOf("day").format("DD/MM/YYYY"),
      showHourlyHistorical
        ? dayjs().endOf("day").format("DD/MM/YYYY")
        : dayjs().endOf("day").add(7, "days").format("DD/MM/YYYY"),
    ],
    queryFn: (): any =>
      getHourlyForecastDateRange(
        pointId,
        showHourlyHistorical
          ? dayjs().startOf("day").subtract(7, "days").toDate()
          : dayjs().startOf("day").toDate(),
        showHourlyHistorical
          ? dayjs().endOf("day").toDate()
          : dayjs().endOf("day").add(7, "days").endOf("day").toDate()
      ),
    enabled: !!pointId,
  })

  if (!hourlyForecasts && fixedPointHourlyForecastRequest.data) {
    if (showHourlyHistorical) {
      const newHourly = [...fixedPointHourlyForecastRequest.data]
      setHourlyForecasts(newHourly.reverse())
    } else {
      setHourlyForecasts(fixedPointHourlyForecastRequest.data)
    }
  }

  const [forecastedSnowTotalsRequest] = useQuery({
    query: forecastedSnowTotalsQuery,
    variables: {
      pointId,
      startDay: dayjs().startOf("day").subtract(8, "days").toISOString(),
      endDay: dayjs().endOf("day").add(7, "day").toISOString(),
    },
  })

  const avvyNotificationsEnabled = useMemo(() => {
    const sub = userRequest.data?.user?.subscriptions.find((sub: any) => {
      return sub.zoneId === pointDetailRequest.data?.point?.avalanche?.zoneId
    })

    if (sub && sub.status === "active") {
      return true
    }
    return false
  }, [
    pointDetailRequest.data?.point?.avalanche?.zoneId,
    userRequest.data?.user?.subscriptions,
  ])

  return (
    <>
      {/* <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems={"center"}
        flexDirection={"column"}
        sx={{ backgroundColor: "#436785", borderRadius: "4px" }}
      >
        <Box display="flex" justifyContent={"center"}>
          <Typography sx={{ color: "white" }}>
            Forecast sponsered by:
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          width="100%"
          m={1}
          p={1}
        >
          <img src="/Icon-144.png" alt="" height={100} />
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Typography
              fontWeight={"bold"}
              variant="h5"
              sx={{ color: "white" }}
              ml={"4px"}
            >
              Snarf
            </Typography>
            <Typography fontWeight={"bold"} sx={{ color: "white" }} ml={"4px"}>
              Surf Powder, not the web.
            </Typography>
          </Box>
        </Box>
      </Box> */}

      <Grid sx={{ pt: 2 }} container direction="column">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight={"bold"}>
            Snow Forecast
          </Typography>
          <HelpMe tag={FAQAnchors.snowSummary} event={SnarfEvents.HelpMeSnowSummary}/>
        </Box>
      </Grid>

      <Grid sx={{ py: 2 }} container>
        <Paper sx={{ width: "100%", height: "250px", p: 1 }}>
          {forecastedSnowTotalsRequest.fetching ? (
            <Box
              width="100%"
              height={200}
              display="flex"
              justifyContent={"center"}
              alignItems="center"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box sx={{ ml: "-32px" }}>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  data={forecastedSnowTotalsRequest.data.point.forecastedSnowTotals.filter(
                    (entry: any) => {
                      if (showPastSnowfall) {
                        return entry.date < dayjs().startOf("day").toISOString()
                      } else {
                        return (
                          entry.date >= dayjs().startOf("day").toISOString()
                        )
                      }
                    }
                  )}
                >
                  <XAxis dataKey={(data) => dayjs(data.date).format("dd")} />
                  <YAxis
                    domain={[0, "dataMax + 2"]}
                    tick={false}
                    axisLine={false}
                    tickLine={false}
                  />
                  <Bar
                    dataKey={(data: any) => {
                      return mmToIn(data.totalSnow)
                    }}
                    fill={
                      showPastSnowfall
                        ? theme.palette.secondary.main
                        : theme.palette.primary.main
                    }
                  >
                    <LabelList
                      position="top"
                      dataKey={(data: any) => {
                        return mmToIn(data.totalSnow) + '"'
                      }}
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}

          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box
              display="flex"
              alignItems="center"
              onClick={() => setShowPastSnowfall(true)}
              sx={{ cursor: "pointer" }}
            >
              <Chip
                color={showPastSnowfall ? "secondary" : undefined}
                icon={<History />}
                label="Last 7 days"
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              onClick={() => setShowPastSnowfall(false)}
            >
              <Chip
                color={!showPastSnowfall ? "primary" : undefined}
                icon={<Update />}
                label="Next 7 days"
                sx={{ cursor: "pointer" }}
              />
            </Box>
          </Box>
        </Paper>
      </Grid>

      <Grid sx={{ pt: 2 }} container direction="column">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight={"bold"}>
            Forecast Overview
          </Typography>
          <HelpMe tag={FAQAnchors.forecastOverview} event={SnarfEvents.HelpMeForcastOverView}/>
        </Box>
      </Grid>

      <Grid
        sx={{ p: 2, pr: "4px", ml: "-16px" }}
        container
        width="105%"
        overflow="scroll"
        display="flex"
      >
        {pointDetailRequest.fetching ? (
          <Box
            display="flex"
            width="100%"
            height={200}
            justifyContent={"center"}
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper sx={{ p: 1, width: "100%" }}>
            <Box display="flex" overflow={"scroll"} p={2}>
              <WeatherViewer
                dailyWeather={
                  pointDetailRequest?.data?.point?.dailyWeatherSummary || []
                }
                cornDays={pointDetailRequest?.data?.point?.potentialCornDays}
                hourlyForecast={
                  pointDetailRequest?.data?.point?.hourlyForecast || []
                }
                elevation={elevation}
                sortReverse={showHisotircalOverview}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Box
                display="flex"
                alignItems="center"
                onClick={() => setShowHisotircalOverview(true)}
                sx={{ cursor: "pointer" }}
              >
                <Chip
                  color={showHisotircalOverview ? "secondary" : undefined}
                  icon={<History />}
                  label="Last 7 days"
                />
              </Box>

              <Box
                display="flex"
                alignItems="center"
                onClick={() => setShowHisotircalOverview(false)}
              >
                <Chip
                  color={!showHisotircalOverview ? "primary" : undefined}
                  icon={<Update />}
                  label="Next 7 days"
                  sx={{ cursor: "pointer" }}
                />
              </Box>
            </Box>
          </Paper>
        )}
      </Grid>

      <Grid sx={{ pt: 2 }} container direction="column">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight={"bold"}>
            7 Day Hourly Forecast
          </Typography>
          <HelpMe tag={FAQAnchors.hourlyTable} event={SnarfEvents.HelpMeHourlyTable} />
        </Box>
      </Grid>
      <Box>
        <Paper sx={{ p: 1 }}>
          <Grid pb={2} container overflow="scroll" maxWidth={"100%"}>
            <HourlyTable
              hourlyForecast={hourlyForecasts || []}
              spotElevation={elevation}
              pointId={pointId}
            />
          </Grid>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Box
              display="flex"
              alignItems="center"
              onClick={() => {
                setHourlyForecasts(null)
                setShowHourlyHistorical(true)
              }}
              sx={{ cursor: "pointer" }}
            >
              <Chip
                color={showHourlyHistorical ? "secondary" : undefined}
                icon={<History />}
                label="Last 7 days"
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              onClick={() => {
                setHourlyForecasts(null)
                setShowHourlyHistorical(false)
              }}
            >
              <Chip
                color={!showHourlyHistorical ? "primary" : undefined}
                icon={<Update />}
                label="Next 7 days"
                sx={{ cursor: "pointer" }}
              />
            </Box>
          </Box>
        </Paper>
      </Box>

      {!pointDetailRequest.fetching &&
        pointDetailRequest?.data?.point?.avalanche && (
          <>
            <Grid sx={{ pt: 2 }} container direction="column">
              <Box display="flex" alignItems="center">
                <Typography variant="h5" fontWeight={"bold"}>
                  Avalanche Forecast
                </Typography>
              </Box>
            </Grid>
            <Grid sx={{ py: 2 }} container>
              <Paper sx={{ width: "100%", p: 1 }}>
                <AvalancheDanger
                  danger={pointDetailRequest.data.point.avalanche.danger}
                  url={pointDetailRequest.data.point.avalanche.url}
                  zone={pointDetailRequest.data.point.avalanche.zone}
                  zoneId={pointDetailRequest.data.point.avalanche.zoneId}
                  expires={pointDetailRequest.data.point.avalanche.expires}
                  notificationsEnabled={avvyNotificationsEnabled}
                  notificationToggleEnabled={true}
                  bottomLine={
                    pointDetailRequest.data.point.avalanche.bottomLine
                  }
                />
              </Paper>
            </Grid>
          </>
        )}

      <Grid sx={{ py: 2 }} item xs={12}>
        <GraphHQ pointId={pointId} />
      </Grid>

      {pointDetailRequest.data?.point?.forecastDiscussion && (
        <Grid sx={{ py: 2 }} item xs={12}>
          <ForecastDiscussion
            rawText={pointDetailRequest.data?.point?.forecastDiscussion.rawText}
            issuanceTime={
              pointDetailRequest.data?.point?.forecastDiscussion.issuanceTime
            }
          />
        </Grid>
      )}

      <Grid sx={{ pt: 2 }} container direction="column">
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight={"bold"}>
            Links
          </Typography>
          {/* <HelpMe tag={FAQAnchors.hourlyTable} /> */}
        </Box>
      </Grid>

      <Paper sx={{ p: 2 }}>
        <Box display="flex" flexDirection={"column"}>
          <Typography>
            <Link
              sx={{ color: "black", cursor: "pointer" }}
              href={`https://caltopo.com/map.html#ll=${
                pointDetailRequest.data ? pointDetailRequest.data.point.lat : 0
              },${
                pointDetailRequest.data ? pointDetailRequest.data.point.lon : 0
              }&z=14`}
              target="_blank"
              rel="noreferrer"
            >
              Caltopo
            </Link>
          </Typography>

          <Typography>
            <Link
              href={`https://www.gaiagps.com/map/?loc=13.4/${pointDetailRequest.data?.point?.lon}/${pointDetailRequest.data?.point?.lat}&layer=avalanche-forecast,GaiaTopoRasterFeet`}
              sx={{ color: "black", cursor: "pointer" }}
              target="_blank"
            >
              Gaia
            </Link>
          </Typography>
        </Box>
      </Paper>
    </>
  )
}
