import { gql } from "urql"

export const avalancheByIdQuery = gql`
  query avalancheById($zoneId: Int!) {
    avalancheForecast(zoneId: $zoneId) {
      zone
      zoneId
      notificationsEnabled
      danger {
        lower
        middle
        upper
        valid_day
      }
      bottomLine
      forecastAvalancheProblems {
        icon
        likelihood
      }
      url
      expires
    }
  }
`
