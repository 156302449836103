import { gql } from "urql"

export const forecastedSnowTotalsQuery = gql`
  query ($pointId: ID!, $startDay: String, $endDay: String) {
    point(id: $pointId) {
      id
      forecastedSnowTotals(startDay: $startDay, endDay: $endDay) {
        date
        totalSnow
      }
    }
  }
`
