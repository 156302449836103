import {
  Modal,
  Box,
  Typography,
  useTheme,
  IconButton,
  Button,
} from "@mui/material"
import React, { useEffect } from "react"
import { Register } from "./Register"
import { SnarfEvents, sendEvent } from "../utilities/metrics"
import { ArrowBack, Check, Close, Home } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import { Subscribe } from "../pages/subscribe"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 800,
  width: "90%",
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  maxHeight: "90vh",
  overflow: "scroll",
  p: 1,
}
export interface SubscribeModalProps {
  message: string
  onClose?: () => any
  open: boolean
  noClose?: boolean
}

export function SubscribeModal({
  message,
  onClose,
  open,
  noClose,
}: SubscribeModalProps) {
  const [internalOpen, setOpen] = React.useState(false)
  const theme = useTheme()
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (internalOpen !== open) {
      setOpen(open)

      if (open) {
        sendEvent(SnarfEvents.ShowSubscribeModal)
      }
    }
  }, [internalOpen, open])

  return (
    <Modal
      open={internalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {!noClose ? (
          <Box display="flex" justifyContent={"flex-end"}>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        ) : (
          <Box
            display="flex"
            justifyContent={"flex-start"}
            alignItems={"center"}
            onClick={() => navigate(-1)}
          >
            <IconButton>
              <ArrowBack />
            </IconButton>
            <Typography fontWeight={"bold"}>Back</Typography>
          </Box>
        )}

        <Subscribe />
      </Box>
    </Modal>
  )
}
