import { Box, Typography } from "@mui/material"
import React from "react"

export function HelpSnowSummary() {
  return (
    <>
      <Box
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent={"center"}
        p={2}
      >
        <Typography variant="h5">How do I use the Snow Summary?</Typography>
        <Typography>
          The Snow Forecast shows how much snow is expected for the 24 hour
          period from Midnight - Midnight over the next week. Know which days
          are expected to have the most snow.
        </Typography>
      </Box>
    </>
  )
}
