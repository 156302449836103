import { Box, Grid, Typography } from "@mui/material"
import dayjs, { Dayjs } from "dayjs"
import React, { useEffect, useState } from "react"
import { DailyForecast } from "../models/models"
import { groupHourlyForecastByDay } from "../utilities/analysis"
import { mmToIn, mToFt } from "../utilities/conversion"
import { WeatherTile } from "./WeatherTile"

export interface WeatherViewerProps {
  dailyWeather: DailyForecast[]
  hourlyForecast: any[]
  elevation?: number
  sortReverse?: boolean
  cornDays: any
}

export function WeatherViewer({
  dailyWeather,
  hourlyForecast,
  elevation,
  sortReverse,
  cornDays,
}: WeatherViewerProps) {
  const [hourlyCastByDay, setHourlyCastByDay] = useState<any>({})

  useEffect(() => {
    if (hourlyForecast) {
      setHourlyCastByDay(groupHourlyForecastByDay(hourlyForecast))
    }
  }, [hourlyForecast])

  const orderHourlyCasts = (casts: any, reverse?: boolean) => {
    if (reverse) {
      return Object.keys(casts).reverse()
    }

    return Object.keys(casts)
  }

  return (
    <Box>
      <Grid container wrap="nowrap" overflow="scroll" spacing={1}>
        {orderHourlyCasts(hourlyCastByDay, sortReverse).map((date) => {
          const dayCast = dailyWeather.find((daily) =>
            dayjs(Number(daily.date)).isSame(dayjs(date), "day")
          )

          let rating = 0
          let totalSnow = 0
          let totalSnowMorning = 0
          let totalSnowEvening = 0
          let minSnowLevel: number | null = null
          let maxSnowLevel: number | null = null
          let morningMaxWindSpeed: number | null = null
          let morningMaxWindGust: number | null = null
          let eveningMaxWindSpeed: number | null = null
          let eveningMaxWindGust: number | null = null
          for (let cast of hourlyCastByDay[date]) {
            const ts = dayjs(Number(cast.date))
            if (ts.hour() >= 0 && ts.hour() < 12) {
              if (cast.snowfallAmount) {
                totalSnowMorning += cast.snowfallAmount
              }

              if (cast.windSpeed) {
                if (
                  morningMaxWindSpeed === null ||
                  cast.windSpeed > morningMaxWindSpeed
                ) {
                  morningMaxWindSpeed = cast.windSpeed
                }
              }

              if (cast.windGust) {
                if (
                  morningMaxWindGust === null ||
                  cast.windGust > morningMaxWindGust
                ) {
                  morningMaxWindGust = cast.windGust
                }
              }
            } else {
              // evening

              if (cast.snowfallAmount) {
                totalSnowEvening += cast.snowfallAmount
              }

              if (cast.windSpeed) {
                if (
                  eveningMaxWindSpeed === null ||
                  cast.windSpeed > eveningMaxWindSpeed
                ) {
                  eveningMaxWindSpeed = cast.windSpeed
                }
              }

              if (cast.windGust) {
                if (
                  eveningMaxWindGust === null ||
                  cast.windGust > eveningMaxWindGust
                ) {
                  eveningMaxWindGust = cast.windGust
                }
              }
            }

            if (cast.snowfallAmount) {
              totalSnow += cast.snowfallAmount
            }

            if (cast.snowLevel) {
              if (maxSnowLevel === null || cast.snowLevel > maxSnowLevel) {
                maxSnowLevel = cast.snowLevel
              }

              if (minSnowLevel === null || cast.snowLevel < minSnowLevel) {
                minSnowLevel = cast.snowLevel
              }
            }
          }

          return (
            <WeatherTile
              key={date}
              date={date}
              totalSnow={mmToIn(totalSnow)}
              totalSnowMorning={totalSnowMorning}
              totalSnowEvening={totalSnowEvening}
              minSnowLevel={mToFt(minSnowLevel || 0)}
              maxSnowLevel={mToFt(maxSnowLevel || 0)}
              morningMaxWindGust={morningMaxWindGust}
              eveningMaxWindGust={eveningMaxWindGust}
              morningMaxWindSpeed={morningMaxWindSpeed}
              eveningMaxWindSpeed={eveningMaxWindSpeed}
              dayForecast={dayCast?.daytime}
              nightForecast={dayCast?.nighttime}
              hourlyForecast={hourlyCastByDay[date]}
              elevation={elevation}
              rating={rating}
              isCornDay={cornDays.find((cornDay: string) =>
                dayjs(cornDay).isSame(dayjs(date), "day")
              )}
              showHistoricalOverview={sortReverse}
            />
          )
        })}
      </Grid>
    </Box>
  )
}
