import { Instagram } from "@mui/icons-material"
import { IconButton, Link, useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import React from "react"

function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: "white" }} align="center">
      {"Copyright © Riley Enterprises LLC"}
      <footer color="inherit">Snarf, developed by Bob Riley.</footer>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export function Footer() {
  const theme = useTheme()
  return (
    <>
      <Box
        sx={{
          bgcolor: theme.palette.primary.main,
          p: 6,
          width: "100vw",
        }}
        component="footer"
      >
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          sx={{ color: "white" }}
        >
          Snarf
        </Typography>

        <Box display="flex" sx={{ color: "white" }} justifyContent="center">
          <Typography sx={{ color: "white", px: 1 }}>
            <Link
              href="/about"
              sx={{ color: "white", textDecoration: "underline" }}
            >
              About
            </Link>
          </Typography>
          <Typography>
            <Link
              href="/contact"
              sx={{ color: "white", textDecoration: "underline" }}
            >
              Contact
            </Link>
          </Typography>

          <Typography sx={{ color: "white", px: 1 }}>
            <Link
              href="/terms"
              sx={{ color: "white", textDecoration: "underline" }}
            >
              Terms of Use
            </Link>
          </Typography>

          <Typography sx={{ color: "white", px: 1 }}>
            <Link
              href="/privacy-policy"
              sx={{ color: "white", textDecoration: "underline" }}
            >
              Privacy Policy
            </Link>
          </Typography>
        </Box>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: "white" }}
          component="p"
        >
          Spend more time outdoors.
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          sx={{ color: "white" }}
          component="p"
        >
          Questions or feedback?
          <Link sx={{ color: "white" }}> bob@snarfme.com</Link>
        </Typography>
        <Box display="flex" justifyContent={"center"} py={1}>
          <Link href="https://www.instagram.com/snarf_outdoors/">
            <Instagram htmlColor="white" fontSize="large" />
          </Link>
        </Box>
        <Copyright />
      </Box>
    </>
  )
}
