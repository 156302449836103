import { gql } from "urql"

export const camerasWithinQuery = gql`
  query camerasWithinQuery($filters: Filters) {
    cameras(filters: $filters) {
      id
      name
      url
      lon
      lat
      displayType
    }
  }
`
