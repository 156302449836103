import { Landscape, Place } from "@mui/icons-material"
import { Box, Paper, Typography, useTheme } from "@mui/material"
import React from "react"
import {
  SearchResult,
  SearchResultType,
  UserPointOfInterestType,
} from "../../api/models"
import SnowboardingIcon from "@mui/icons-material/Snowboarding"
import { CellTower } from "@mui/icons-material"

export interface SearchResultProps {
  searchResult: SearchResult
  onClick: (searchResult: SearchResult) => any
}

const getSearchIcon = (type: SearchResultType | undefined) => {
  switch (type) {
    case SearchResultType.ROCK_CLIMB:
      return (
        <img
          style={{ width: "25px", height: "25px" }}
          src="/climber.png"
          alt=""
        />
      )

    case SearchResultType.SKI_AREA:
      return <SnowboardingIcon fontSize="medium" />

    case SearchResultType.MOUNTAIN:
      return <Landscape fontSize="medium" />
    case SearchResultType.STATION:
      return <CellTower fontSize="medium" />
    default:
      return <Place />
  }
}

export function SearchResultItem({ searchResult, onClick }: SearchResultProps) {
  const theme = useTheme()
  return (
    <Box
      pt={1}
      sx={{
        ":hover": {
          backgroundColor: theme.palette.grey[300],
          cursor: "pointer",
        },
        color: "black",
      }}
      display="flex"
      justifyContent="flex-start"
      onClick={() => onClick(searchResult)}
    >
      <Paper sx={{ p: 1, width: "100%" }}>
        <Box display={"flex"}>
          <Box display="flex" justifyContent={"center"} alignItems="center">
            {getSearchIcon(searchResult.type)}
          </Box>
          <Box
            display="flex"
            flexDirection={"column"}
            alignItems="flex-start"
            textAlign={"start"}
            pl={1}
          >
            <Typography>{searchResult.name}</Typography>
            <Typography variant="caption">
              {searchResult && searchResult.pathTokens
                ? searchResult.pathTokens.join(" / ")
                : null}
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}
