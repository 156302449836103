export function cToF(value: number, precision?: number) {
  let modified = value
  if (!value) {
    modified = 0
  }
  if (precision) {
    return Math.round((modified * (9 / 5) + 32) * precision) / precision
  }
  return Math.round(modified * (9 / 5) + 32)
}

export function mmToIn(value: number, precision?: number) {
  if (value) {
    if (precision) {
      return Math.round((value / 25.4) * precision) / precision
    }
    return Math.round((value / 25.4) * 10) / 10
  }

  return 0
}

export function mToFt(value: number) {
  if (!value) {
    return 0
  }
  return Math.round(value * 3.281)
}

export function windDirectionToNorthRotation(direction: string): number {
  switch (direction) {
    case "NNE":
      return 202.5
    case "NE":
      return 225
    case "ENE":
      return 247.5
    case "E":
      return 270
    case "ESE":
      return 292.5
    case "SSE":
      return 337.5
    case "S":
      return 360
    case "SSW":
      return 22.5
    case "SW":
      return 45
    case "WSW":
      return 67.5
    case "W":
      return 90
    case "WNW":
      return 112.5
    case "NW":
      return 135
    case "NNW":
      return 157.5
    case "N":
      return 0
    default:
      return 0
  }
}

export function msToMph(value: number) {
  return Math.round(value * 2.237)
}
