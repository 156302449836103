import {
  AccountCircle,
  Close,
  Home,
  Info,
  LoginOutlined,
  Logout,
  Map,
  Paid,
  Radar,
  Star,
} from "@mui/icons-material"
import {
  Drawer,
  Box,
  IconButton,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material"
import LandscapeIcon from "@mui/icons-material/Landscape"
import React from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth, logout } from "../firebase"
import { Navigate } from "react-router-dom"
import { getActivityIcon } from "../utilities/helpers"
import { UserPointOfInterestType } from "../api/models"
import SnowboardingIcon from "@mui/icons-material/Snowboarding"

const menuOptions = [
  {
    id: "home",
    display: "Home",
    path: "/intro?stay=true",
    icon: <Home sx={{ color: "black" }} />,
  },
  {
    id: "map",
    display: "Map",
    path: "/map",
    icon: <Map color="secondary" />,
  },
  {
    id: "favorites",
    display: "Favorites",
    path: "/home",
    icon: <Star sx={{ color: "gold" }} />,
  },
  {
    id: "snow-scanner",
    display: "Snow Scanner",
    path: "/snow-scanner",
    icon: <Radar color="primary" />,
  },
  {
    id: "subscribe",
    display: "Subscribe",
    path: "/subscribe",
    icon: <Paid sx={{ color: "green" }} />,
  },
  {
    id: "account",
    display: "Account",
    path: "/account",
    icon: <AccountCircle />,
  },
]

const activities = [
  {
    type: UserPointOfInterestType.SKI,
    name: "Ski/Board",
    icon: () => <SnowboardingIcon />,
  },
  {
    type: UserPointOfInterestType.ROCK_CLIMB,
    name: "Rock Climb",
    icon: () => (
      <img
        style={{ width: "25px", height: "25px" }}
        src="/climber.png"
        alt=""
      />
    ),
  },
]

export interface MobileMenueProps {
  isOpen: boolean
  onClose: () => any
  onSelect: (path: string) => any
  onActivitySwitch: (activity: UserPointOfInterestType) => any
  selectedActivity: UserPointOfInterestType
  showActivitySelect: boolean
  onShowActivitySelect: () => any
}

export function MobileMenu({
  isOpen,
  onClose,
  onSelect,
  onActivitySwitch,
  selectedActivity,
  showActivitySelect,
  onShowActivitySelect,
}: MobileMenueProps) {
  const [user, loadingAuthState] = useAuthState(auth)

  const onLoginLogout = () => {
    if (user) {
      logout().then(() => window.location.reload())
    } else {
      onSelect("/login")
    }
    onClose()
  }

  const theme = useTheme()
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        variant={"temporary"}
        onClose={onClose}
        sx={{ width: "100vw" }}
      >
        <Box width="100vw">
          <Box display="flex" justifyContent={"space-between"}>
            <Box
              display="flex"
              sx={{ color: theme.palette.primary.main }}
              justifyContent="center"
              alignItems="center"
              pl={2}
              pb={1}
            >
              <Typography variant="h3">Snarf</Typography>
            </Box>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </Box>

          {showActivitySelect ? (
            <Box px={1}>
              {activities.map((activity) => (
                <Box
                  onClick={() => {
                    onActivitySwitch(activity.type)
                  }}
                  zIndex={5}
                  display="flex"
                  p={2}
                  key={activity.name}
                  sx={
                    activity.type === selectedActivity
                      ? {
                          border: "2px solid green",
                          borderRadius: "10px",
                          width: "100%",
                        }
                      : {}
                  }
                >
                  {activity.icon()}
                  <Typography>{activity.name}</Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Grid container direction="column" px={1} spacing={1}>
              <Grid item>
                <Box sx={{ p: 1 }} onClick={onShowActivitySelect}>
                  <Box display="flex" alignItems="center">
                    {getActivityIcon(selectedActivity)}
                    <Typography pl={"4px"} fontWeight={"bold"}>
                      Select Activity
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {menuOptions.map((opt) => {
                if (opt.id === "account" && !user) {
                  return null
                }

                return (
                  <Grid item key={opt.id}>
                    <Box sx={{ p: 1 }} onClick={() => onSelect(opt.path)}>
                      <Box display="flex" alignItems="center">
                        {opt.icon}
                        <Typography pl={"4px"} fontWeight={"bold"}>
                          {opt.display}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                )
              })}

              <Grid item>
                <Box sx={{ p: 1 }} onClick={onLoginLogout}>
                  <Box display="flex" alignItems="center">
                    {user ? <Logout /> : <LoginOutlined />}
                    <Typography pl={"4px"} fontWeight={"bold"}>
                      {user ? "Logout" : "Login"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Drawer>
    </>
  )
}
