import { Modal, Box } from "@mui/material"
import React, { useEffect } from "react"
import { Camera } from "../api/models"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minWidth: "70%",
  maxWidth: "1200px",
}

export interface CameraModalProps {
  camera: Camera
  onClose: () => any
  open?: boolean
}

export function CameraModal(props: CameraModalProps) {
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (open !== props.open) {
      setOpen(!!props.open)
    }
  }, [props])

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <img src={props.camera.url} style={{ width: "100%" }} alt="camera" />
      </Box>
    </Modal>
  )
}
