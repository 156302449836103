import AcUnitIcon from "@mui/icons-material/AcUnit"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import React, { createContext, useEffect, useState } from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Footer } from "./Footer"
import { Provider, useQuery } from "urql"
import {
  Alert,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Container,
  Drawer,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  useMediaQuery,
} from "@mui/material"
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import { useAuthState } from "react-firebase-hooks/auth"
import { auth, logout } from "../firebase"
import CssBaseline from "@mui/material/CssBaseline"
import { LocationSearch } from "../components/LocationSearch"
import { createPoint, pointFromGeocode } from "../api/snarf-api"
import { PointSummary } from "../models/models"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import {
  AccountCircle,
  ArrowBack,
  Close,
  Favorite,
  Help,
  Star,
} from "@mui/icons-material"
import { sendEvent, setUser, SnarfEvents } from "../utilities/metrics"
import MenuIcon from "@mui/icons-material/Menu"
import {
  SearchResult,
  SearchResultType,
  UserPointOfInterestType,
} from "../api/models"
import SnowboardingIcon from "@mui/icons-material/Snowboarding"
import { graphqlClient } from "../graphql"
import { MobileMenu } from "../components/MobileMenu"
import { getActivityColor } from "../utilities/activity-helpers"
import useDocumentTitle from "../hooks/use-document-title"
import { userSubsQuery } from "../graphql/queries/user-subs"
import { userPremiumQuery } from "../graphql/queries/user-premium"
import { graphql } from "graphql"

const theme = createTheme({
  typography: {
    h1: {
      fontWeight: 500,
    },
    h2: {
      fontWeight: 500,
    },
    h3: {
      fontWeight: 500,
    },
    h5: {
      fontWeight: 500,
    },
    fontFamily: ["Barlow"].join(","),
  },
  palette: {
    secondary: {
      main: "#D27519",
    },
  },
})

export const ActivityContext = createContext<UserPointOfInterestType>(
  UserPointOfInterestType.SKI
)

export const UserPremiumContext = createContext<boolean>(false)
export const UserTrialEndDateContext = createContext<string | null>(null)

export function Header(props: { children?: React.ReactNode }) {
  const isMap = useMatch("map")
  const isGuided = useMatch("getting-started")
  const navigate = useNavigate()
  const [user, loadingAuthState] = useAuthState(auth)
  const queryClient = new QueryClient()
  const isSmall = useMediaQuery("(max-width:600px)")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [showNavDrawer, setShowNavDrawer] = useState(false)
  const [userPremium, setUserPremium] = useState(false)
  const [userTrialEndDate, setUserTrialEndDate] = useState(
    "1993-04-20T23:44:40.251Z"
  )
  const [userPremiumLoaded, setUserPremiumLoaded] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const [openActivitySelect, setOpenActivitySelect] = React.useState(false)

  const [selectedActivity, setSelectedActivity] =
    React.useState<UserPointOfInterestType>(UserPointOfInterestType.SKI)

  if (user && !userPremiumLoaded) {
    graphqlClient.query(userPremiumQuery, {}).then((result) => {
      setUserPremium(result.data.user.isPremium)
      setUserTrialEndDate(result.data.user.trialEndDate)
      setUserPremiumLoaded(true)
    })
  }

  useEffect(() => {
    if (
      Number(localStorage.getItem("snarf-selected-activity")) ||
      Number(searchParams.get("activity"))
    ) {
      setSelectedActivity(
        Number(localStorage.getItem("snarf-selected-activity")) ||
          Number(searchParams.get("activity"))
      )
    }

    window.addEventListener("beforeinstallprompt", () => {
      sendEvent(SnarfEvents.InstallPrompt)
    })
  }, [searchParams])

  if (user && user.email) {
    setUser(user.email)
  }

  if (searchParams.get("s") === "r") {
    sendEvent(SnarfEvents.Reddit)
  }

  if (searchParams.get("qr") === "a") {
    sendEvent(SnarfEvents.Poster)
  }

  // eslint-disable-next-line no-undef
  const onLocationSelected = (location: SearchResult) => {
    if (location.type === SearchResultType.STATION) {
      navigate(`/station/${location.id}`)
    } else {
      createPoint(location.lat, location.lon, location.name).then(
        (point: PointSummary) => {
          if (isMap) {
            searchParams.set("selectedPointId", point.id)
            searchParams.delete("search")
            setSearchParams(searchParams)
          } else {
            navigate(`/point/${point.id}`)
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
          }
        }
      )
    }
  }

  const onSetSelectedActivity = (activity: UserPointOfInterestType) => {
    sendEvent(SnarfEvents.ActivitySwitch)
    setSelectedActivity(activity)
    localStorage.setItem("snarf-selected-activity", activity.toString())
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (isSmall) {
      setShowNavDrawer(true)
    } else {
      if (user) {
        navigate("/account")
      } else {
        navigate("/login")
      }
    }
  }

  const getUserMenu = (loggedIn: boolean, small: boolean) => {
    return loggedIn || small ? (
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          {small ? <MenuIcon /> : <AccountCircle />}
        </IconButton>
      </Box>
    ) : (
      <Button color="inherit" onClick={() => navigate("/login")}>
        Login
      </Button>
    )
  }

  const getMobilePageIcon = (page: string) => {
    switch (page) {
      case "/home":
        return <Star fontSize="large" sx={{ color: "gold" }} />
      default:
        return <AcUnitIcon fontSize="large" />
    }
  }

  return (
    <Provider value={graphqlClient}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          {!isMap ? (
            <>
              <MobileMenu
                isOpen={showNavDrawer}
                onClose={() => {
                  setShowNavDrawer(false)
                  setOpenActivitySelect(false)
                }}
                onSelect={(path) => {
                  setShowNavDrawer(false)
                  setOpenActivitySelect(false)
                  navigate(path, { state: { previous: location.pathname } })
                }}
                showActivitySelect={openActivitySelect}
                onActivitySwitch={(type) => {
                  setShowNavDrawer(false)
                  setOpenActivitySelect(false)
                  localStorage.setItem(
                    "snarf-selected-activity",
                    type.toString()
                  )
                  sendEvent(SnarfEvents.ActivitySwitch)
                  setSelectedActivity(type)
                }}
                onShowActivitySelect={() => setOpenActivitySelect(true)}
                selectedActivity={selectedActivity}
              />
              <Box
                display="flex"
                flexDirection={"column"}
                minHeight={"100vh"}
                justifyContent="space-between"
              >
                <Box>
                  <AppBar
                    sx={{ backgroundColor: getActivityColor(selectedActivity) }}
                    position="static"
                  >
                    <Toolbar sx={{ px: 1 }}>
                      {isSmall ? (
                        <Grid container alignItems="center">
                          <Grid item xs={10}>
                            <Container
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                py: 1,
                                px: 0,
                              }}
                            >
                              {location?.state?.back ? (
                                <Box
                                  display="flex"
                                  alignItems={"center"}
                                  pr={1}
                                >
                                  <IconButton onClick={() => navigate(-1)}>
                                    <ArrowBack
                                      fontSize="large"
                                      htmlColor="white"
                                    />
                                  </IconButton>
                                </Box>
                              ) : (
                                <Box
                                  pl={1}
                                  pr={2}
                                  display="flex"
                                  alignItems="center"
                                  justifyContent={"center"}
                                >
                                  {getMobilePageIcon(location.pathname)}
                                </Box>
                              )}

                              <Box
                                sx={{
                                  width: "80%",
                                  height: "70%",
                                }}
                                id="header-location-search"
                              >
                                <LocationSearch
                                  key={"header-small"}
                                  onLocationSelected={onLocationSelected}
                                  excludeClimbs={
                                    selectedActivity !==
                                    UserPointOfInterestType.ROCK_CLIMB
                                  }
                                  excludeSkiAreas={
                                    selectedActivity !==
                                    UserPointOfInterestType.SKI
                                  }
                                />
                              </Box>
                            </Container>
                          </Grid>
                          <Grid item xs={2}>
                            {getUserMenu(!!user, isSmall)}
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item lg={7}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <Box width={"100px"}>
                                <Button
                                  onClick={() => navigate("/intro?stay=true")}
                                  color="inherit"
                                >
                                  <AcUnitIcon sx={{ mr: 2 }} />
                                  <Typography
                                    variant="h6"
                                    color="inherit"
                                    noWrap
                                  >
                                    Snarf
                                  </Typography>
                                </Button>
                              </Box>
                              <Button
                                sx={{ ml: 2 }}
                                onClick={() =>
                                  navigate("/map", {
                                    state: { previous: location.pathname },
                                  })
                                }
                                color="inherit"
                                id="header-explore"
                              >
                                <Typography>Map</Typography>
                              </Button>
                              <Button
                                onClick={() => navigate("/home")}
                                color="inherit"
                              >
                                <Typography>Favorites</Typography>
                              </Button>

                              <Button
                                onClick={() => navigate("/snow-scanner")}
                                color="inherit"
                              >
                                <Typography>Snow Scanner</Typography>
                              </Button>

                              <Button
                                onClick={() => navigate("/subscribe")}
                                color="inherit"
                              >
                                <Typography>Subscribe</Typography>
                              </Button>

                              <Button
                                onClick={() => navigate("/about")}
                                color="inherit"
                              >
                                <Typography>About</Typography>
                              </Button>
                            </Box>
                          </Grid>

                          <Grid
                            item
                            lg={5}
                            alignItems="center"
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                          >
                            <Box
                              sx={{
                                background: "white",
                                width: "100%",
                                maxWidth: "400px",
                                borderRadius: "12px",
                              }}
                              id="header-location-search"
                            >
                              <LocationSearch
                                key={"header"}
                                onLocationSelected={onLocationSelected}
                                excludeClimbs={
                                  selectedActivity !==
                                  UserPointOfInterestType.ROCK_CLIMB
                                }
                                excludeSkiAreas={
                                  selectedActivity !==
                                  UserPointOfInterestType.SKI
                                }
                              />
                            </Box>
                            <Box id="header-activity-select">
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectedActivity}
                                  onChange={(event) =>
                                    onSetSelectedActivity(
                                      event.target
                                        .value as UserPointOfInterestType
                                    )
                                  }
                                  sx={{
                                    backgroundColor: "white",
                                    borderRadius: "12px",
                                    mx: 1,
                                    width: "170px",
                                  }}
                                >
                                  <MenuItem value={UserPointOfInterestType.SKI}>
                                    <Box display="flex" alignItems="center">
                                      <SnowboardingIcon htmlColor="black" />
                                      <Typography>Ski/Board</Typography>
                                    </Box>
                                  </MenuItem>
                                  <MenuItem
                                    value={UserPointOfInterestType.ROCK_CLIMB}
                                  >
                                    <Box display="flex" alignItems="center">
                                      <img
                                        style={{
                                          width: "25px",
                                          height: "25px",
                                        }}
                                        src="/climber.png"
                                        alt=""
                                      />
                                      <Typography>Rock Climb</Typography>
                                    </Box>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                            {getUserMenu(!!user, isSmall)}
                          </Grid>
                        </Grid>
                      )}
                    </Toolbar>
                  </AppBar>
                  <ActivityContext.Provider value={selectedActivity}>
                    <UserPremiumContext.Provider value={userPremium}>
                      <UserTrialEndDateContext.Provider
                        value={userTrialEndDate}
                      >
                        {!searchParams.has("search") && props.children}
                      </UserTrialEndDateContext.Provider>
                    </UserPremiumContext.Provider>
                  </ActivityContext.Provider>
                </Box>

                {!isMap && !isGuided ? <Footer /> : null}
              </Box>
            </>
          ) : (
            <ActivityContext.Provider value={selectedActivity}>
              <UserPremiumContext.Provider value={userPremium}>
                <UserTrialEndDateContext.Provider value={userTrialEndDate}>
                  {!searchParams.has("search") && props.children}
                </UserTrialEndDateContext.Provider>
              </UserPremiumContext.Provider>
            </ActivityContext.Provider>
          )}
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  )
}
