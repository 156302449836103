import React from "react"
import { MapLayers } from "./models"
import { NearMeControls } from "./NearMeControls"
import { Box, Typography } from "@mui/material"
import { StationControls } from "./StationControls"
import { SkiAreaControls } from "./SkiAreaControls"

export interface LayerControlsProps {
  layerId: MapLayers
  onUpdate: (layerId: MapLayers, payload: any) => any
  config: any
}

export function LayerControls({
  layerId,
  onUpdate,
  config,
}: LayerControlsProps) {
  switch (layerId) {
    case MapLayers.NEAR_ME:
      return (
        <NearMeControls
          config={config}
          onChange={(location, distance) =>
            onUpdate(layerId, { location, distance })
          }
        />
      )
    case MapLayers.STATIONS:
      return (
        <StationControls
          config={config}
          onChange={(metric) => onUpdate(layerId, { metric })}
        />
      )
    case MapLayers.SKI_AREAS:
      return (
        <SkiAreaControls
          config={config}
          onChange={(payload) => onUpdate(layerId, payload)}
        />
      )
    default:
      return <></>
  }
}
