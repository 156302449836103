import { Box } from "@mui/material"
import React, { useContext, useEffect } from "react"
import { UserPremiumContext } from "../pages/Header"

export function AdSplashPage() {
  const userIsPremium = useContext(UserPremiumContext)

  useEffect(() => {
    try {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    } catch (e) {
      console.error(e)
    }
  }, [])

  if (userIsPremium) {
    return null
  }

  return (
    <Box
      width="100%"
      height="100%"
      sx={{
        'ins.adsbygoogle[data-ad-status="unfilled"]': {
          display: "none !important",
        },
      }}
      key={Date.now()}
    >
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-6288231818875389"
        data-ad-slot="8503826880"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </Box>
  )
}
